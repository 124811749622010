import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  GeoAnalysisHistoryItem,
  IGeoAnalysisReport,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/types';

export const geoAnalysisAdapter = createEntityAdapter<IGeoAnalysisReport>({
  selectId: (report) => report.location,
});

export const geoAnalysisHistoryAdapter = createEntityAdapter<GeoAnalysisHistoryItem>({
  selectId: (report) => report.date,
});
