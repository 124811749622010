import { cn, en, id, my, th, vn } from '@/assets/images/languages';

export const languages = [
  { id: 'en', img: en, label: 'English' },
  { id: 'id', img: id, label: 'Bahasa Indonesia' },
  { id: 'my', img: my, label: 'Bahasa Melayu' },
  { id: 'th', img: th, label: 'ภาษาไทย' },
  { id: 'vn', img: vn, label: 'Tiếng Việt' },
  { id: 'cn', img: cn, label: '中文' },
];

export const SUPPORTED_LANGUAGE = ['en', 'cn', 'id', 'my', 'th', 'vn'];
