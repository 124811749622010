import { GettingProductsAnalysis } from '@/types/IProduct';

import qs from 'qs';

import { apiParser } from '@/fsd/shared/api';

import {
  ActionProduct,
  GetFavoriteProducts,
  GetProductCard,
  Products,
  Sellers,
} from '@/store/entities/tools/products/types';

export const productsApi = {
  getProducts(data: Products) {
    return apiParser.service.request<GettingProductsAnalysis>({
      method: 'get',
      url: 'api/reports/products',
      params: {
        user_id: data.userId,
        page: data.page,
        items_per_page: data.items_per_page,
        marketplace: data.marketplace,
        sort_order: data.sort_order,
        sort_by: data.sort_by,
        category_ids: data.requested?.category_ids,
        product_rating_min: data.requested?.product_rating_range?.min_val,
        product_rating_max: data.requested?.product_rating_range?.max_val,
        store_rating_min: data.requested?.store_rating_range?.min_val,
        store_rating_max: data.requested?.store_rating_range?.max_val,
        variations_num_min: data.requested?.variations_num?.min_val,
        variations_num_max: data.requested?.variations_num?.max_val,
        description_include_keywords: data.requested?.keywords?.include_in_description,
        description_exclude_keywords: data.requested?.keywords?.exclude_from_description,
        title_include_keywords: data.requested?.keywords?.include_in_title,
        title_exclude_keywords: data.requested?.keywords?.exclude_from_title,
        description_quality_min: data.requested?.description_quality?.min_val,
        description_quality_max: data.requested?.description_quality?.max_val,
        pictures_num_min: data.requested?.pictures_num_range?.min_val,
        pictures_num_max: data.requested?.pictures_num_range?.max_val,
        video_present: data.requested?.video_present,
        product_created_from: data.requested?.product_created_from,
        product_created_to: data.requested?.product_created_to,
        cash_on_delivery: data.requested?.cash_on_delivery,
        exclude_zero_stock: data.requested?.exclude_zero_stock,
        review_count_min: data.requested?.rewiew_count_range?.min_val,
        review_count_max: data.requested?.rewiew_count_range?.max_val,
        product_views_min: data.requested?.product_views_range?.min_val,
        product_views_max: data.requested?.product_views_range?.max_val,
        product_questions_min: data.requested?.product_questions_range?.min_val,
        product_questions_max: data.requested?.product_questions_range?.max_val,
        product_wishlisted_min: data.requested?.product_wishlisted_range?.min_val,
        product_wishlisted_max: data.requested?.product_wishlisted_range?.max_val,
        price_range_min: data.requested?.product_price_range?.min_val,
        price_range_max: data.requested?.product_price_range?.max_val,
        price_change_min: data.requested?.price_change_range?.min_val,
        price_change_max: data.requested?.price_change_range?.max_val,
        monthly_sales_min: data.requested?.monthly_sales_range?.min_val,
        monthly_sales_max: data.requested?.monthly_sales_range?.max_val,
        sales_change_min: data.requested?.sales_change_range?.min_val,
        sales_change_max: data.requested?.sales_change_range?.max_val,
        monthly_revenue_min: data.requested?.monthly_revenue_range?.min_val,
        monthly_revenue_max: data.requested?.monthly_revenue_range?.max_val,
        revenue_change_min: data.requested?.revenue_change_range?.min_val,
        revenue_change_max: data.requested?.revenue_change_range?.max_val,
        exclude_sellers_ids: data.requested?.exclude_sellers_ids,
        include_sellers_ids: data.requested?.include_sellers_ids,
        views_to_sales_min: data.requested?.views_to_sales?.min_val,
        views_to_sales_max: data.requested?.views_to_sales?.max_val,
        sales_to_review_min: data.requested?.sales_to_review?.min_val,
        sales_to_review_max: data.requested?.sales_to_review?.max_val,
        location: data.requested?.location,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },

  getExtremum(name: string) {
    return apiParser.service.request({
      method: 'get',
      url: 'api/reports/extremum',
      params: {
        marketplace: name,
      },
    });
  },

  getSellers(data: Sellers) {
    return apiParser.service.request({
      method: 'get',
      url: 'api/reports/sellers/',
      params: {
        page: data.page,
        items_per_page: data.items_per_page,
        marketplace: data.marketplace,
        search_substring: data.search_substring,
      },
    });
  },

  getSellerLocations(data: Sellers) {
    return apiParser.service.request({
      method: 'get',
      url: 'api/reports/seller_locations/',
      params: {
        page: data.page,
        items_per_page: data.items_per_page,
        marketplace: data.marketplace,
        search_substring: data.search_substring,
      },
    });
  },

  getProductCard(data: GetProductCard) {
    return apiParser.service.request({
      method: 'get',
      url: `api/reports/${data.marketplace}/${data.product_id}/`,
      params: {
        min_val: data.min_val,
        max_val: data.max_val,
        user_id: data?.user_id,
      },
    });
  },

  getFavoriteProducts(data: GetFavoriteProducts) {
    return apiParser.service.request({
      method: 'get',
      url: `api/products/favorite/`,
      params: {
        page: data?.page,
        user_id: data.user_id,
        items_per_page: data?.items_per_page,
        product_ids: data?.product_ids,
      },
    });
  },

  createFavoriteProduct(data: ActionProduct) {
    return apiParser.service.request({
      method: 'post',
      url: `api/products/favorite/`,
      params: {
        marketplace: data.marketplace,
        product_id: data.product_id,
        user_id: data.user_id,
      },
    });
  },

  deleteFavoriteProduct(data: ActionProduct) {
    return apiParser.service.request({
      method: 'delete',
      url: `api/products/favorite/`,
      params: {
        marketplace: data.marketplace,
        product_id: data.product_id,
        user_id: data.user_id,
      },
    });
  },

  addProductAnalyzer(data: ActionProduct) {
    return apiParser.service.request({
      method: 'post',
      url: `api/products/analyzer/`,
      params: {
        marketplace: data.marketplace,
        product_id: data.product_id,
        user_id: data.user_id,
      },
    });
  },

  deleteProductAnalyzer(data: ActionProduct) {
    return apiParser.service.request({
      method: 'delete',
      url: `api/products/analyzer/`,
      params: {
        marketplace: data.marketplace,
        product_id: data.product_id,
        user_id: data.user_id,
      },
    });
  },
};
