import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { classNames } from '@/fsd/shared/lib/classNames/classNames';

import cls from './Logo.module.scss';

interface LogoProps {
  className?: string;
  clickable?: boolean;
}

export const Logo = ({ className, clickable = true }: LogoProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname !== '/') {
      navigate(AppPaths.HOME);
    }
  };

  return (
    <div className={classNames(cls.Logo, {}, [className])}>
      {clickable ? (
        <Box className={cls.logo} onClick={handleClick}>
          sellmatica
        </Box>
      ) : (
        <Box className={cls.logo} sx={{ cursor: 'auto!important' }}>
          sellmatica
        </Box>
      )}
    </div>
  );
};
