import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUsersFilters, IUsersFiltersState } from '@/store/entities/users/usersFilters/types';

const initialState: IUsersFiltersState = {
  usersFilters: {
    role: undefined,
    status: undefined,
    email: undefined,
    isRegistration: undefined,
  },
};
export const usersFiltersSlice = createSlice({
  name: 'usersFilters',
  initialState,
  reducers: {
    setUsersFiltersSlice: (state, action: PayloadAction<IUsersFilters>) => {
      state.usersFilters = action.payload;
    },
  },
  extraReducers: {},
});
