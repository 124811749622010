import { GetSeller, GettingSeller } from '@/types/ISeller';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { sellerApi } from '@/api/parser/sellerApi';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

export const getSeller = createAsyncThunk<GettingSeller, GetSeller, { rejectValue: string }>(
  'seller/getSeller',
  async (options, { rejectWithValue }) => {
    try {
      setCurrentCurrencyByMarketplace(options.marketplace);
      const { data } = await sellerApi.getSeller(options);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);
