import { MantineThemeOverride } from '@mantine/core';

import { BREAKPOINTS } from '@/fsd/shared/constants/breakpoints';

export const appTheme: MantineThemeOverride = {
  breakpoints: BREAKPOINTS,
  cursorType: 'pointer',
  fontFamily: 'Noto Sans, sans-serif',
  fontFamilyMonospace: 'Noto Sans, monospace',
  headings: { fontFamily: 'Noto Sans, sans-serif' },

  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#F0F1F2',
      letterSpacing: 0.5,
    },
  }),

  components: {
    AppShell: {
      defaultProps: {
        fixed: false,
      },
      styles: () => ({
        root: {
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        body: {
          flex: '1 1 0%',
        },
        main: {
          minWidth: 0,
        },
      }),
    },
    Button: {
      styles: (theme, params) => ({
        root: {
          backgroundColor: params.variant === 'light' ? theme.colors.violet[1] : undefined,
          '&:hover': {
            backgroundColor:
              params.variant === 'light'
                ? theme.colors.violet[0]
                : params.variant === 'outline'
                ? theme.colors.brand[1]
                : undefined,
          },
        },
        label: {
          fontWeight: 500,
        },
      }),
    },
    Accordion: {
      styles: () => ({
        control: {
          paddingLeft: 10,
          paddingTop: 10,
          paddingBottom: 10,
          fontFamily: 'inherit',
        },
        item: {
          borderBottom: 0,
        },
        panel: {
          fontFamily: 'inherit',
        },
      }),
    },
    TextInput: {
      styles: () => ({
        input: {
          borderRadius: 8,
        },
        label: {
          fontWeight: 700,
        },
        error: {
          fontSize: 12,
        },
      }),
    },
    PasswordInput: {
      styles: () => ({
        input: {
          borderRadius: 8,
        },
        label: {
          fontWeight: 700,
        },
        error: {
          fontSize: 12,
        },
      }),
    },
    Text: {
      styles: (theme, params) => ({
        root: {
          color:
            params.variant === 'link' && !params.color
              ? theme.colors[theme.primaryColor][9]
              : undefined,
        },
      }),
    },
    UnstyledButton: {
      styles: () => ({
        root: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
      }),
    },
  },
};
