import { MarketplaceType } from '@/fsd/shared/constants/marketplaces';

export const setCurrentCurrencyByMarketplace = (marketplace: MarketplaceType | null) => {
  let marketplacesString = localStorage.getItem('marketplaces');
  if (!marketplacesString) return;
  let marketplaces: {
    [key in MarketplaceType]: {
      currency_code: string;
      price_divider: number;
    };
  } = JSON.parse(marketplacesString);

  sessionStorage.setItem(
    'currentMarketplace',
    JSON.stringify(marketplace ? marketplaces[marketplace] : null)
  );
};
