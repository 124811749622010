import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { uniqFast } from '@/utils/helpers';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { getCategories, getChildren } from './actions';
import {
  CategoriesState,
  ICategory,
  ICategoryChildren,
  IRubricator,
  ISelectedRubricatorItem,
} from './types';

import {
  categoryExistInState,
  getArraysByCategories,
  getCategoryById,
  manageParentCheckbox,
  parentIdValue,
  recursionFunction,
  recursionFunctionEdit,
  recursionFunctionSetCheckbox,
  resetCategoryCheckboxInFalse,
} from '@/store/entities/tools/categories/utils';

const initialState: CategoriesState = {
  categoryResults: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: null,
  selectedCategories: [],
};
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<{ id: number; category: IRubricator }>) {
      // по id находим категорию и меняем на новую
      state.categoryResults = recursionFunctionEdit(state.categoryResults, action.payload.category);
    },
    setCheckbox(
      state,
      action: PayloadAction<{
        id: number;
        parentId: number | null;
        checked: boolean;
      }>
    ) {
      let categories = state.categoryResults;
      let id = action.payload.id;
      let checked = action.payload.checked;
      let parentId = action.payload.parentId;

      // существует ли категория в текущем стейте категорий
      const existInState = categoryExistInState(categories, id);

      if (!existInState) {
        state.selectedCategories = state.selectedCategories.filter((item) => item.id !== id);
      } else {
        // переключаем checked текущую категорию и все дочернии
        categories = recursionFunctionSetCheckbox(categories, id, checked);

        // переключаем checked у категорий родителей
        do {
          categories = manageParentCheckbox(categories, parentId);
          parentId = parentIdValue;
        } while (parentId !== null);

        // обновляем список выбранных категорий
        const { selectedCategories, deleteIds } = getArraysByCategories(
          categories,
          state.selectedCategories,
          []
        );

        // deleteIds список родительских категорий которые не должны быть у текущих выбранных
        const s = selectedCategories.filter(
          (value) => value.parentId === null || !deleteIds.includes(value.parentId)
        );

        // uniqFast - функция удаляет одинаковые значения если они есть
        state.selectedCategories = uniqFast(s);
        state.categoryResults = categories;
      }
    },
    clearSelectedItem(state) {
      state.selectedCategories = [];
      state.categoryResults = resetCategoryCheckboxInFalse(state.categoryResults);
    },
    setSelectedItem(state, action: PayloadAction<ISelectedRubricatorItem[]>) {
      state.selectedCategories = action.payload;
    },
    pushSelectedItem(state, action: PayloadAction<ISelectedRubricatorItem>) {
      state.selectedCategories = [...state.selectedCategories, action.payload];
    },
    editItemInRubricator(
      state,
      action: PayloadAction<{
        id: number;
        category: Partial<IRubricator>;
      }>
    ) {
      let rubricator = state.categoryResults;
      // по id находим категорию и меняем на новую
      state.categoryResults = recursionFunctionEdit(rubricator, action.payload.category);
    },
  },
  extraReducers: (builder) => {
    // getCategories
    builder.addCase(getCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCategories.fulfilled,
      (
        state,
        action: PayloadAction<{
          results: ICategory[];
        }>
      ) => {
        state.isLoading = false;
        state.isError = false;
        state.categoryResults = action.payload.results.map((item) => {
          let checked = false;
          if (state.selectedCategories.find((selectedItem) => selectedItem.id === item.id)) {
            checked = true;
          }
          return {
            id: item.id,
            name: item.name,
            checked: checked,
            children: [],
            hasChildren: item.has_children,
            expanded: false,
            isLoading: false,
            parentId: null,
            catId: item.cat_id,
          };
        });
      }
    );
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    });

    //getChildren
    builder.addCase(getChildren.pending, (state, action) => {
      let indexParent = state.categoryResults.findIndex(
        (item) => item.id === action.meta.arg.parent_id
      );
      state.categoryResults[indexParent] = {
        ...state.categoryResults[indexParent],
        isLoading: true,
      };
    });
    builder.addCase(
      getChildren.fulfilled,
      (
        state,
        action: PayloadAction<{
          parentId: number;
          data: { results: ICategoryChildren[] };
        }>
      ) => {
        let childrenCategory = action.payload;
        let selectedRubricatorItems = state.selectedCategories;
        let categoryResults = state.categoryResults;
        state.isLoading = false;
        state.isError = false;

        const category = getCategoryById(categoryResults, childrenCategory.parentId);
        if (category) {
          // приводим все категории к одному типу
          let handled = recursionFunction(
            childrenCategory.data.results,
            childrenCategory.parentId,
            category.checked,
            selectedRubricatorItems
          );
          state.categoryResults = recursionFunctionEdit(categoryResults, {
            ...category,
            children: handled,
            isLoading: false,
          });
        }
      }
    );
    builder.addCase(getChildren.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    });
  },
});

export const {
  pushSelectedItem,
  editItemInRubricator,
  setSelectedItem,
  setCategory,
  clearSelectedItem,
  setCheckbox,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
