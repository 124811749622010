import { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CurrencyFormat from 'react-currency-format';
import { Box, Sx } from '@mantine/core';

interface INumberValuesFormatProps {
  value?: number | string | null;
  toFixed?: number;
  sx?: Sx;
}

const NumberValuesFormat: FC<INumberValuesFormatProps> = ({ value, toFixed = 0, sx }) => {
  return (
    <Box display={'inline-block'} sx={[{ whiteSpace: 'nowrap' }, sx]}>
      <CurrencyFormat
        value={Number(value).toFixed(toFixed)}
        displayType={'text'}
        thousandSeparator={','}
        thousandSpacing={'3'}
        style={{ width: '100%' }}
        // renderText={(valueNumber) => {
        //   // @ts-ignore
        //   let newValue = valueNumber.replaceAll(',', ' ');
        //   return <div>{newValue}</div>;
        // }}
      />
    </Box>
  );
};

export default NumberValuesFormat;
