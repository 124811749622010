import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { connectAccount, deleteAccountById, getMarketplaces, updateAccount } from './actions';
import { MarketplaceState } from './types';

const initialState: MarketplaceState = {
  results: [],
  error: null,
  message: null,
  country: '',
  marketplace: '',
  isConnectedAccount: false,
  isUpdatedAccount: false,
  isDeletedAccount: false,
  isLoading: true,
};
const marketplacesSlice = createSlice({
  name: 'marketplaces',
  initialState,
  reducers: {
    getCountry(state, action: PayloadAction<string>) {
      state.country = action.payload;
    },
    getMarketplace(state, action: PayloadAction<string>) {
      state.marketplace = action.payload;
    },
    restoreDefaultState(state) {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    // getMarketplaces
    builder.addCase(getMarketplaces.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(getMarketplaces.fulfilled, (state, action) => {
      state.results = action.payload.results;
      state.isLoading = false;
    });
    builder.addCase(getMarketplaces.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
      state.isLoading = false;
    });

    // connectAccount
    builder.addCase(connectAccount.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(connectAccount.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.isConnectedAccount = !state.isConnectedAccount;
      state.isLoading = false;
    });
    builder.addCase(connectAccount.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
      state.isLoading = false;
    });

    // deleteAccountById
    builder.addCase(deleteAccountById.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteAccountById.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.isDeletedAccount = !state.isDeletedAccount;
    });
    builder.addCase(deleteAccountById.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });

    // updateAccount
    builder.addCase(updateAccount.pending, (state) => {
      state.error = null;
    });
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.isUpdatedAccount = !state.isUpdatedAccount;
    });
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });
  },
});

export const { getCountry, getMarketplace, restoreDefaultState } = marketplacesSlice.actions;

export default marketplacesSlice.reducer;
