import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EXCEEDED_ACCESS, NOT_FOUND_SELLER, SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { getSellerAudit } from '@/store/entities/tools/sellerAudit/actions';
import { SellerAuditStatus } from '@/store/entities/tools/sellerAudit/constants';
import {
  ISellerAudit,
  ISellerAuditCategoryAnalysis,
  ISellerAuditResponse,
  ISellerAuditState,
} from '@/store/entities/tools/sellerAudit/types';

const initialState: ISellerAuditState = {
  isLoading: null,
  isSuccess: null,
  isError: null,
  sellerAudit: null,
  limitedAttempts: null,
  exceededAttempts: false,
  message: null,
};
export const sellerAuditSlice = createSlice({
  name: 'sellerAuditSlice',
  initialState,
  reducers: {
    setSellerAuditStatus(
      state,
      action: PayloadAction<{
        isLoading?: SellerAuditStatus | null;
        isSuccess?: SellerAuditStatus | null;
        isError?: SellerAuditStatus | null;
      }>
    ) {
      if (action.payload.isSuccess !== undefined) {
        state.isSuccess = action.payload.isSuccess;
      }
      if (action.payload.isError !== undefined) {
        state.isError = action.payload.isError;
      }
      if (action.payload.isLoading !== undefined) {
        state.isLoading = action.payload.isLoading;
      }
    },
    resetLimitedAttempts(state) {
      state.limitedAttempts = null;
      state.exceededAttempts = false;
    },
  },
  extraReducers: {
    // get charts
    [getSellerAudit.pending.type]: (state) => {
      state.isSuccess = null;
      state.isLoading = SellerAuditStatus.GET_SELLER_AUDIT;
    },
    [getSellerAudit.fulfilled.type]: (state, action: PayloadAction<ISellerAuditResponse>) => {
      state.isSuccess = SellerAuditStatus.GET_SELLER_AUDIT;
      state.sellerAudit = action.payload.data;
      state.isLoading = null;
      state.limitedAttempts = action.payload.usage_limit;
    },
    [getSellerAudit.rejected.type]: (state, action) => {
      state.isSuccess = null;
      if (EXCEEDED_ACCESS === action.payload.message) {
        state.exceededAttempts = true;
        state.limitedAttempts = action.payload.usage_limit;
      } else if (action.payload.message.includes(NOT_FOUND_SELLER)) {
        state.isError = SellerAuditStatus.NOT_FOUND_SELLER;
        state.message = action.payload.message;
      } else {
        showNotification({ color: 'red', message: SYSTEM_ERROR });
      }
      // showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
      state.isLoading = null;
    },
  },
});
