import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { FlagSelectProps } from './FlagsSelect.types';

import styles from './FlagsSelect.module.scss';

const FlagsSelect: FC<FlagSelectProps> = ({ data, onChange, className, label, autocomplete }) => {
  const [language, setLanguage] = useState('');
  const { i18n } = useTranslation();
  const data1 = useMemo(
    () =>
      data.map((item) =>
        item.id === 'en'
          ? {
              ...item,
              value: 'gb',
            }
          : {
              ...item,
              value: item.id,
            }
      ),
    [data]
  );

  useEffect(() => {
    if (autocomplete) {
      const getCurrentLanguage = data1.find((item) => item.value === i18n.language);

      if (getCurrentLanguage === undefined) {
        setLanguage('GB');
      }

      if (!!getCurrentLanguage) {
        if (getCurrentLanguage.value === 'en') {
          getCurrentLanguage.value = 'gb';
        }
        setLanguage(getCurrentLanguage.value.toLocaleUpperCase());
      }
    } else {
      setLanguage('');
    }
  }, []);

  useEffect(() => {
    if (!!onChange) {
      onChange(language);
    }
  }, [language]);

  useEffect(() => {
    const currentLanguage = data1.find((item) => item.id === i18n.language);
    if (currentLanguage) setLanguage(currentLanguage.value);
  }, [i18n.language]);

  return (
    <div>
      {label && (
        <label htmlFor="flags-select" className={styles.label}>
          {label}
        </label>
      )}

      <Select
        placeholder=" "
        data={data1}
        onChange={(code) => code && setLanguage(code)}
        value={language.toLocaleLowerCase()}
        w={200}
      />
      {/*<ReactFlagsSelect*/}
      {/*  placeholder=" "*/}
      {/*  selected={language}*/}
      {/*  onSelect={(code) => setLanguage(code)}*/}
      {/*  countries={data1.map((item) => item.value.toLocaleUpperCase())}*/}
      {/*  customLabels={data1.reduce(*/}
      {/*    (obj, lang) => ({ ...obj, [lang.value.toLocaleUpperCase()]: lang.label }),*/}
      {/*    {}*/}
      {/*  )}*/}
      {/*  className={clsx(styles.menuFlags, className)}*/}
      {/*  selectButtonClassName={styles.menuFlagsButton}*/}
      {/*  id="flags-select"*/}
      {/*/>*/}
    </div>
  );
};

export default FlagsSelect;
