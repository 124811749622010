import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Group, PasswordInput, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { SignUpUserSchemaLocaleEnum } from '@/api/swaggerApi';

import OAuthButtons from '@/components/OAuthButtons';
import { BREAKPOINTS } from '@/fsd/shared/constants/breakpoints';
import { useAnalyticEventsHook } from '@/hooks/analyticEventsHook/useAnalyticEventsHook';
import { useRegions } from '@/hooks/useRegions';

import { register } from '@/store/entities/auth/actions';
import { resetRegistrationError, resetResult } from '@/store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

export interface FormValues {
  firstName: string;
  secondName: string;
  email: string;
  phoneNumber: string;
  businessType: string;
  regionType: string;
  newslettersAgreement: boolean;
  offerAgreement: boolean;
  password: string;
  password2: string;
  experience: string;
}

const SignUpForm: FC = () => {
  const [dataSend, setDataSend] = useState(false);
  const [isChange, setIsChange] = useState(true);

  const { t, i18n } = useTranslation('APPCON');
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess, isError, message, registrationError } = useAppSelector(
    (store) => store.auth
  );
  const navigate = useNavigate();
  const regions = useRegions();
  const phoneNumberFormat =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  const emailFormat = /^\S+@\S+$/;
  // const unicodeWord = XRegExp('^\\pL+$');
  const { register: registerData, beganTypingFormRegisterGA } = useAnalyticEventsHook();

  const largeScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`);

  // const assetsBusinessType = [
  //   { id: 1, label: t('appcon045-1-13'), name: 'seller' },
  //   { id: 2, label: t('appcon045-1-15'), name: 'agency' },
  //   { id: 3, label: t('appcon045-1-17'), name: 'brand' },
  //   { id: 4, label: t('appcon045-1-19'), name: 'consultant' },
  // ];

  // const experienceType = [
  //   { id: 1, label: t('appcon045-1-22'), name: 'seller' },
  //   { id: 2, label: t('appcon045-1-24'), name: 'agency' },
  //   { id: 3, label: t('appcon045-1-26'), name: 'brand' },
  // ];

  const initialValuesForm = {
    firstName: '',
    secondName: '',
    email: '',
    phoneNumber: '',
    businessType: '',
    regionType: '',
    newslettersAgreement: false,
    offerAgreement: false,
    password: '',
    password2: '',
    experience: '',
  };

  const form = useForm<FormValues>({
    initialValues: initialValuesForm,
    validate: {
      // firstName: (value) => (value.length > 0 ? null : t('appcon045-1A-1')),
      //
      // secondName: (value) => (value.length > 0 ? null : t('appcon045-1A-1')),

      email: (value) =>
        value.length > 0
          ? emailFormat.test(value)
            ? null
            : t('appcon045-3A-15')
          : t('appcon045-3A-13'),
      phoneNumber: (value) =>
        value.length > 0
          ? phoneNumberFormat.test(value)
            ? null
            : t('appcon045-4A-21')
          : t('appcon045-4A-20'),
      // businessType: (value) => (value.length > 0 ? null : t('appcon045-8A-38')),
      password: (value) => (value.length > 0 ? null : t('appcon045-5A-26')),
      // password2: (value, formValues) => password2Validate(formValues.password, value, t),
      // experience: (value) => experienceValidate(value, t),
    },
  });

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        title: t('appcon002--13'),
        message: message,
        color: 'green',
      });
      registerData(document.referrer, form.values.email, form.values.phoneNumber);
      navigate(AppPaths.LOGIN);
    } else if (isError) {
      if (registrationError) {
      } else {
        showNotification({
          message: message,
          color: 'red',
        });
      }
    }
    return () => {
      dispatch(resetResult());
    };
  }, [isSuccess, isError]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    form.clearErrors();
  }, [i18n.language]);

  const handleRegister = (values: FormValues, event: FormEvent) => {
    event.preventDefault();

    // let firstName = values.firstName;
    // let lastName = values.secondName;
    let email = values.email;
    let phoneNumber = values.phoneNumber;
    // let businessType = values.businessType;
    let region = regions.find((item) => item.id === values.regionType.toString())?.name;
    let newslettersAgreement = values.newslettersAgreement;
    let offerAgreement = values.offerAgreement;
    let password = values.password;
    // let password2 = values.password2;

    if (offerAgreement) {
      dispatch(
        register({
          // first_name: firstName,
          // last_name: lastName,
          email,
          // business_type: businessType,
          phone_number: phoneNumber,
          receive_news: newslettersAgreement,
          region: region || 'Indonesia',
          password,
          // password2,
          locale: i18n.language as SignUpUserSchemaLocaleEnum,
        })
      );
    }

    setIsChange(false);
  };

  useEffect(() => {
    if (JSON.stringify(initialValuesForm) !== JSON.stringify(form.values) && !dataSend) {
      const entries = Object.entries(form.values);
      const entriesInitialValues = Object.entries(initialValuesForm);
      let field: string = '';

      for (let i = 0; i < entries.length; i++) {
        if (i >= entries.length || i >= entriesInitialValues.length) {
          break;
        }

        if (entries[i][1] !== entriesInitialValues[i][1]) {
          field = entries[i][0];
          break;
        }
      }

      beganTypingFormRegisterGA(field);
      setDataSend(true);
    }
  }, [form.values]);

  useEffect(() => {
    if (!isChange) {
      dispatch(resetRegistrationError());
      setIsChange(true);
    }
  }, [form.values]);

  return (
    <>
      <form
        id={'form-register'}
        onSubmit={form.onSubmit((values, event) => handleRegister(values, event))}
      >
        <Group position={'center'} mt={largeScreen ? 80 : undefined}>
          <Title order={2} weight={700}>
            {t('appcon045-1-1')}
          </Title>
        </Group>
        {/*<Group position={'apart'} align={'start'} noWrap={!largeScreen}>*/}
        {/*  <TextInput*/}
        {/*    label={t('appcon045-1-2')}*/}
        {/*    {...form.getInputProps('firstName')}*/}
        {/*    error={*/}
        {/*      form.getInputProps('firstName').error ||*/}
        {/*      registrationError?.first_name?.map((item, index) => (*/}
        {/*        <Text key={index + item}>{item}</Text>*/}
        {/*      ))*/}
        {/*    }*/}
        {/*    placeholder={t('appcon045-1-3')}*/}
        {/*    mt={15}*/}
        {/*    size="md"*/}
        {/*    w={'100%'}*/}
        {/*    name={'first-name'}*/}
        {/*  />*/}

        {/*  <TextInput*/}
        {/*    label={t('appcon045-1-4')}*/}
        {/*    {...form.getInputProps('secondName')}*/}
        {/*    error={*/}
        {/*      form.getInputProps('secondName').error ||*/}
        {/*      registrationError?.last_name?.map((item, index) => (*/}
        {/*        <Text key={index + item}>{item}</Text>*/}
        {/*      ))*/}
        {/*    }*/}
        {/*    placeholder={t('appcon045-1-5')}*/}
        {/*    mt={15}*/}
        {/*    size="md"*/}
        {/*    w={'100%'}*/}
        {/*    name={'second-name'}*/}
        {/*  />*/}
        {/*</Group>*/}

        <Group
          position={'apart'}
          align={'start'}
          noWrap={!largeScreen}
          spacing={largeScreen ? 0 : undefined}
        >
          <TextInput
            type="email"
            label={t('appcon045-1-6')}
            placeholder={t('appcon045-1-7')}
            {...form.getInputProps('email')}
            error={
              form.getInputProps('email').error ||
              registrationError?.email?.map((item, index) => (
                <Text key={index + item}>{t(item)}</Text>
              ))
            }
            mt={15}
            size="md"
            w={'100%'}
            name={'email'}
          />

          <TextInput
            type="tel"
            label={t('appcon045-1-10')}
            placeholder={t('appcon045-1-11')}
            {...form.getInputProps('phoneNumber')}
            error={
              form.getInputProps('phoneNumber').error ||
              registrationError?.phone_number?.map((item, index) => (
                <Text key={index + item}>{t(item)}</Text>
              ))
            }
            mt={15}
            size="md"
            w={'100%'}
            name={'phone-number'}
          />
        </Group>

        <Group position={'apart'} align={'start'} noWrap={!largeScreen}>
          <PasswordInput
            label={t('appcon045-1-8')}
            placeholder={t('appcon045-1-9')}
            {...form.getInputProps('password')}
            error={
              form.getInputProps('email').error ||
              registrationError?.password?.map((item, index) => (
                <Text key={index + item}>{t(item)}</Text>
              ))
            }
            size="md"
            mt={15}
            w={'100%'}
            name={'password'}
          />
          {/*<PasswordInput*/}
          {/*  label={t('appcon045-1-8')}*/}
          {/*  placeholder={t('appcon045-1-9')}*/}
          {/*  {...form.getInputProps('password2')}*/}
          {/*  error={*/}
          {/*    form.getInputProps('password2').error ||*/}
          {/*    registrationError?.password2?.map((item, index) => (*/}
          {/*      <Text key={index + item}>{item}</Text>*/}
          {/*    ))*/}
          {/*  }*/}
          {/*  size="md"*/}
          {/*  mt={15}*/}
          {/*  w={'100%'}*/}
          {/*  name={'password2'}*/}
          {/*/>*/}
        </Group>

        {/*<Group position={'apart'} align={'center'} mt={20}></Group>*/}

        {/*<Input.Wrapper*/}
        {/*  label={t('appcon045-7A-36')}*/}
        {/*  {...form.getInputProps('regionType')}*/}
        {/*  styles={{ error: { marginTop: 5 }, label: { fontWeight: 700 } }}*/}
        {/*  className={{ ...form.getInputProps('regionType') }.error ? styles.error : ''}*/}
        {/*  mt={15}*/}
        {/*  size="md"*/}
        {/*>*/}
        {/*  <FlagsSelect*/}
        {/*    data={regions}*/}
        {/*    className={styles.regions}*/}
        {/*    {...form.getInputProps('regionType')}*/}
        {/*  />*/}
        {/*</Input.Wrapper>*/}

        {/*<Select*/}
        {/*  label={*/}
        {/*    <Group spacing={5} align={'center'}>*/}
        {/*      {t('appcon006-1-1')}*/}
        {/*      <InfoCircle size={15} strokeWidth={2} color={'#CED0D4'} />*/}
        {/*    </Group>*/}
        {/*  }*/}
        {/*  mt={15}*/}
        {/*  size="md"*/}
        {/*  radius={8}*/}
        {/*  styles={{ label: { fontWeight: 800 } }}*/}
        {/*  data={*/}
        {/*    assetsBusinessType?.map((item) => ({*/}
        {/*      value: item.name,*/}
        {/*      label: item.label,*/}
        {/*    })) || []*/}
        {/*  }*/}
        {/*  {...form.getInputProps('businessType')}*/}
        {/*  name={'business-type'}*/}
        {/*/>*/}

        {/*<Select*/}
        {/*  label={*/}
        {/*    <Group spacing={5} align={'center'}>*/}
        {/*      {t('appcon006-1-10')}*/}
        {/*      <InfoCircle size={15} strokeWidth={2} color={'#CED0D4'} />*/}
        {/*    </Group>*/}
        {/*  }*/}
        {/*  mt={15}*/}
        {/*  size="md"*/}
        {/*  radius={8}*/}
        {/*  styles={{ label: { fontWeight: 800 } }}*/}
        {/*  data={*/}
        {/*    experienceType?.map((item) => ({*/}
        {/*      value: item.name,*/}
        {/*      label: item.label,*/}
        {/*    })) || []*/}
        {/*  }*/}
        {/*  {...form.getInputProps('experience')}*/}
        {/*/>*/}

        <Checkbox
          label={
            <Text fz={'xs'} weight={500}>
              {t('appcon045-1-28.a')}
            </Text>
          }
          {...form.getInputProps('newslettersAgreement', { type: 'checkbox' })}
          mt={20}
          styles={{ body: { alignItems: 'center' } }}
          size="xs"
        />

        <Checkbox
          label={
            <Text fz={'xs'} weight={500}>
              <Trans
                i18nKey={'appcon045-1-28'}
                t={t}
                components={{
                  'style-link1': (
                    <Text
                      td={'underline'}
                      component={'a'}
                      target={'_blank'}
                      href={AppPaths.PRIVACY_POLICY}
                      color={'#787878'}
                    />
                  ),
                  'style-link2': (
                    <Text
                      td={'underline'}
                      component={'a'}
                      target={'_blank'}
                      href={AppPaths.OFFER}
                      color={'#787878'}
                    />
                  ),
                }}
              />
            </Text>
          }
          {...form.getInputProps('offerAgreement', { type: 'checkbox' })}
          mt={20}
          styles={{ body: { alignItems: 'center' } }}
          size="xs"
        />

        <Button
          type="submit"
          loading={isLoading}
          fullWidth
          mt={20}
          size="md"
          disabled={!form.values.offerAgreement}
        >
          {t('appcon045-1-29')}
        </Button>
        <Group mt={'lg'}>
          <Text size="sm">
            <Trans
              i18nKey={'appcon045-1-30'}
              t={t}
              components={{
                'style-link': (
                  <Text
                    variant="link"
                    size="sm"
                    weight={700}
                    style={{ cursor: 'pointer' }}
                    component={Link}
                    to={AppPaths.LOGIN}
                  />
                ),
              }}
            />
          </Text>
        </Group>
      </form>
      <OAuthButtons />
    </>
  );
};

export default SignUpForm;
