import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Text, Title } from '@mantine/core';

import ContentContainer from '@/ui/ContentContainer/ContentContainer';

import { resetPassword } from '@/store/entities/auth/actions';
import { AuthLoading } from '@/store/entities/auth/constants';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const ProfileChangePassword: FC = () => {
  const { t } = useTranslation('APPCON');
  const { user, authLoading } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();

  const handleSend = () => {
    dispatch(resetPassword({ email: `${user?.email}` }));
  };

  return (
    <ContentContainer>
      <Title order={3} pb={15}>
        {t('appcon036-1C-38')}
      </Title>
      <Text>
        <Trans
          t={t}
          components={{ tag: <b /> }}
          i18nKey={'appcon036-1C-39'}
          values={{ email: user?.email }}
        />
      </Text>
      <Button
        mt={15}
        loading={authLoading === AuthLoading.RESET_PASSWORD_ERROR}
        radius={4}
        variant="filled"
        onClick={handleSend}
      >
        {t('appcon036-1C-40')}
      </Button>
    </ContentContainer>
  );
};

export default ProfileChangePassword;
