import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

import {
  IGetMarginAnalyze,
  IGetMarginAnalyzeTotal,
  IGetMarginAnalyzeTransaction,
  IGetMarginAnalyzeWidgetProfitExpenses,
} from '@/store/entities/tools/internalAnalytics/marginAnalysis/types';

export const getMarginAnalyzeTotal = createAsyncThunk<
  any,
  IGetMarginAnalyzeTotal,
  {
    rejectValue: string;
  }
>('marginAnalysis/getMarginAnalyzeTotal', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(null);
    const { data } = await Api.InternalAnalyticsReports.apiInternalAnalyticsMarginTotalGet(
      options.dateFrom,
      options.dateTo,
      options.connectionIds,
      options.demo,
      options.profitLimit
    );
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getMarginAnalyzeWidgetProfitExpenses = createAsyncThunk<
  any,
  IGetMarginAnalyzeWidgetProfitExpenses,
  {
    rejectValue: string;
  }
>('marginAnalysis/getMarginAnalyzeWidgetProfitExpenses', async (options, { rejectWithValue }) => {
  try {
    const { data } =
      await Api.InternalAnalyticsReports.apiInternalAnalyticsMarginWidgetProfitExpensesGet(
        options.dateFrom,
        options.dateTo,
        options.connectionIds,
        options.demo
      );
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getMarginAnalyze = createAsyncThunk<
  any,
  IGetMarginAnalyze,
  {
    rejectValue: string;
  }
>('marginAnalysis/getMarginAnalyze', async (options, { rejectWithValue }) => {
  try {
    const { data } = await Api.InternalAnalyticsReports.apiInternalAnalyticsMarginGet(
      options.dateFrom,
      options.dateTo,
      options.page,
      options.itemsPerPage,
      options.sortBy as any,
      options.sort,
      options.connectionIds,
      options.demo,
      options.variantIds,
      options.profitLimit,
      options.noCostFilter
    );
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getMarginAnalyzeTransaction = createAsyncThunk<
  any,
  IGetMarginAnalyzeTransaction,
  {
    rejectValue: string;
  }
>('marginAnalysis/getMarginAnalyzeTransaction', async (options, { rejectWithValue }) => {
  try {
    const { data } = await Api.InternalAnalyticsReports.apiInternalAnalyticsMarginTransactionsGet(
      options.dateFrom,
      options.dateTo,
      options.variantId,
      options.page,
      options.itemsPerPage,
      options.connectionIds,
      options.demo
    );
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
