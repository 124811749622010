import { JwtPayload } from '@/types/IProfile';

import jwt_decode from 'jwt-decode';

import { RolesEnum } from '@/fsd/shared/constants/roles';
import { TariffGroup } from '@/fsd/shared/constants/tariffGroup';

export interface IUserInfoToken {
  user_id: string;
  role: RolesEnum;
  parent_id: null | string;
  tariff_group: TariffGroup | null;
  session_id: string;
  subscription_expires_at: null;
  parent_user_role: null | RolesEnum;
  connection_ids: number[];
  tariff_plan: number;
}

export class TokenService {
  /* Save a token in Local Storage
   *
   * @param {string} token
   */

  static storage = localStorage;

  static setToken(token: string) {
    TokenService.storage.setItem('token', token);
  }

  static setRefreshedToken(token: string) {
    TokenService.storage.setItem('refresh-token', token);
  }

  static isRefreshedTokenExists() {
    return TokenService.storage.getItem('refresh-token') !== null;
  }

  /* Remove a token from Local Storage.
   *
   */
  static removeToken() {
    TokenService.storage.removeItem('token');
  }

  static removeRefreshedToken() {
    TokenService.storage.removeItem('refresh-token');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken(): string {
    return TokenService.storage.getItem('token') || '';
  }

  static getRefreshedToken(): string {
    return TokenService.storage.getItem('refresh-token') || '';
  }

  static getRoleUserByToken() {
    const token = this.getToken();
    const decoded = token ? jwt_decode<JwtPayload>(token) : null;
    if (decoded) {
      return decoded.role;
    } else {
      return null;
    }
  }

  static getUserInfoByToken(tokenInput?: string): IUserInfoToken | null {
    const token = tokenInput || this.getToken();
    const decoded = token ? jwt_decode<JwtPayload>(token) : null;
    if (decoded) {
      return decoded as IUserInfoToken;
    } else {
      return null;
    }
  }
}
