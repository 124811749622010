import qs from 'qs';

import { apiParser } from '@/fsd/shared/api';

import {
  IGetNewProductsReportParams,
  INewProductsReportResponse,
} from '@/store/entities/tools/newProductsReport/types';

export const categoriesApi = {
  getNewProductsReport(data: IGetNewProductsReportParams) {
    return apiParser.service.request<INewProductsReportResponse>({
      method: 'get',
      url: '/api/reports/new-products/',
      params: {
        ...data,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
};
