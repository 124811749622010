import { Global } from '@mantine/core';

import GTEestiProDisplayMedium from '@/assets/fonts/GTEestiProDisplayMedium.woff2';
import boldMulish from '@/assets/fonts/Mulish-Bold.woff2';
import mediumMulish from '@/assets/fonts/Mulish-Medium.woff2';
import regularMulish from '@/assets/fonts/Mulish-Regular.woff2';
import semiBoldMulish from '@/assets/fonts/Mulish-SemiBold.woff2';
import light from '@/assets/fonts/noto-sans-v28-cyrillic_latin_vietnamese-300.woff2';
import medium from '@/assets/fonts/noto-sans-v28-cyrillic_latin_vietnamese-500.woff2';
import regular from '@/assets/fonts/noto-sans-v28-cyrillic_latin_vietnamese-regular.woff2';
import bold from '@/assets/fonts/NotoSans-Bold.woff2';
import extraBold from '@/assets/fonts/NotoSans-ExtraBold.woff2';

export function AppFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Noto Sans',
            src: `url('${light}') format("woff2")`,
            fontWeight: 300,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Noto Sans',
            src: `url('${regular}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Noto Sans',
            src: `url('${medium}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Noto Sans',
            src: `url('${bold}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'bold',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Noto Sans',
            src: `url('${extraBold}') format("woff2")`,
            fontWeight: 800,
            fontStyle: 'extra-bold',
          },
        },
        // Mulish
        {
          '@font-face': {
            fontFamily: 'Mulish',
            src: `url('${regularMulish}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'regular',
          },
        },

        {
          '@font-face': {
            fontFamily: 'Mulish',
            src: `url('${mediumMulish}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'medium',
          },
        },

        {
          '@font-face': {
            fontFamily: 'Mulish',
            src: `url('${semiBoldMulish}') format("woff2")`,
            fontWeight: 600,
            fontStyle: 'semi-bold',
          },
        },

        {
          '@font-face': {
            fontFamily: 'Mulish',
            src: `url('${boldMulish}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'bold',
          },
        },

        //GTEestiProDisplay
        {
          '@font-face': {
            fontFamily: 'GT Eesti Pro Display',
            src: `url('${GTEestiProDisplayMedium}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'medium',
          },
        },
      ]}
    />
  );
}
