import { createStyles } from '@mantine/core';

import { BREAKPOINTS } from '@/fsd/shared/constants/breakpoints';

export const useStyles = createStyles(() => ({
  root: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 10,
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20,
  },

  tariffPlanContainer: {
    maxWidth: 500,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  ////

  tariffPlanBox: {
    border: '1px solid #D8D9D9',
    padding: '15px 20px',
    borderRadius: 10,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  mostPopular: {
    lineHeight: 1,
    color: 'white',
    padding: '3px 4px',
    borderRadius: 2,
    backgroundColor: '#7F56D9',
    marginTop: -4,
    marginBottom: -2,
  },

  price: {
    fontSize: 28,
    fontWeight: 700,
    [`@media (max-width: ${BREAKPOINTS.sm - 1}px)`]: {
      fontSize: 23,
    },
    [`@media (max-width: ${BREAKPOINTS.xs - 1}px)`]: {
      fontSize: 18,
    },
  },

  discount: {
    color: '#B2B4B4',

    [`@media (max-width: ${BREAKPOINTS.sm - 1}px)`]: {
      fontSize: 16,
    },
  },

  //////

  paymentSystemBox: {
    // boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    border: '1px solid rgba(178, 180, 180, 0.5)',
    borderRadius: '8px',
    overflow: 'hidden',
  },

  paymentSystemBoxActive: {
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    border: '1px solid #7f56d9',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}));
