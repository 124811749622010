import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FallbackProps } from 'react-error-boundary';
import { Text } from '@mantine/core';

import ContentContainer from '@/ui/ContentContainer/ContentContainer';
import ErrorHeader from '@/ui/Error/ErrorHeader';

const Error = (props: FallbackProps) => {
  return (
    <>
      <ErrorHeader {...props} />
      <ContentContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text>
          There was an error. We are already trying to fix it.
          <br /> Please refresh the page or contact support
        </Text>
        {/*<p>Error info:</p>*/}
        {/*<pre style={{ color: 'red' }}>{props.error}</pre>*/}
      </ContentContainer>
    </>
  );
};

export default Error;
