import { FC, memo, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMetaTags } from 'react-metatags-hook';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { TokenService } from '@/api/TokenService';

import { reset } from '@/store/entities/auth/authSlice';
import { changeLanguage } from '@/store/entities/profile/actions';
import {
  resetLanguageChangeState,
  updateProfileLanguage,
} from '@/store/entities/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

interface Props {
  children: JSX.Element;
  title?: string;
  description?: string;
}

const PrivateRoutes: FC<Props> = ({ children, title, description }) => {
  const token = TokenService.getToken();
  const dispatch = useAppDispatch();
  const { isLoading, isChangePhoneNumber, isChangeEmail } = useAppSelector((state) => state.auth);
  const { isChangeName, language, isErrorChangeLanguage, message } = useAppSelector(
    (state) => state.profile
  );
  const location = useLocation();

  useMetaTags(
    {
      title: title || 'Sellmatica',
      description: description || '',
    },
    [location.pathname]
  );

  useEffect(() => {
    if (token) {
      if (language) {
        dispatch(
          changeLanguage({
            locale: language,
          })
        ).then(() => {
          // dispatch(profile());
        });
        dispatch(updateProfileLanguage(null));
      } else {
        // dispatch(profile());
      }
    }
  }, [language, isChangePhoneNumber, isChangeEmail, isChangeName]);

  useEffect(() => {
    if (isErrorChangeLanguage) {
      showNotification({ message: message, color: 'red' });
      dispatch(resetLanguageChangeState());
    }
  }, [isErrorChangeLanguage]);

  if (isLoading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader variant="dots" />
      </Center>
    );
  }

  if (!token) {
    dispatch(reset());
  }

  return token ? children : <Navigate to={AppPaths.LOGIN} />;
};

export default memo(PrivateRoutes);
