import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-extraneous-dependencies
import Backend from 'i18next-http-backend';

import { SUPPORTED_LANGUAGE } from '@/fsd/shared/constants/languages';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LANGUAGE,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

export const getLocale = () => {
  return i18n.language === 'vn' ? 'vi' : i18n.language === 'cn' ? 'zh-cn' : i18n.language;
};
