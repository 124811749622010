import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { categoriesApi } from '@/api/parser/categoriesApi';

import { Categories } from './types';

export const getCategories = createAsyncThunk<any, Categories, { rejectValue: string }>(
  'categories/getCategories',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await categoriesApi.getCategories(options);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getChildren = createAsyncThunk<any, Categories, { rejectValue: string }>(
  'categories/getChildren',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await categoriesApi.getChildren(options);
      return { data, parentId: options.parent_id };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);
