import React, { FC } from 'react';
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MantineThemeOverride,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { appTheme } from './appTheme';

interface MantineProvidersProps {
  children: React.ReactNode;
  themeParams?: MantineThemeOverride;
}

const MantineProviders: FC<MantineProvidersProps> = ({ children, themeParams }) => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          ...appTheme,
          colorScheme,
          // fontFamily: 'Noto Sans, sans-serif',
          // headings: { fontFamily: 'Noto Sans, sans-serif', fontWeight: 500 },
          colors: {
            brand: [
              '#FFFFFF',
              '#F3EFFC',
              '#E5DDF7',
              '#D9CDF4',
              '#CCBBF0',
              '#BFABEC',
              '#7F56D9',
              '#B29AE8',
              '#A689E5',
              '#9978E1',
            ],
          },
          primaryColor: 'brand',
          ...themeParams,
        }}
      >
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default MantineProviders;
