import { FC } from 'react';

import LayoutAuth from '@/ui/Layouts/LayoutAuth/LayoutAuth';

import ResetPasswordForm from './ResetPasswordForm.tsx/ResetPasswordForm';

import ImageAuth from '@/assets/images/auth/reset-password-man.jpg';

const ResetPassword: FC = () => {
  return (
    <LayoutAuth maw={350} image={ImageAuth}>
      <ResetPasswordForm />
    </LayoutAuth>
  );
};

export default ResetPassword;
