import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';
import { createStyles } from '@mantine/core';

import { TokenService } from '@/api/TokenService';

import { BREAKPOINTS } from '@/fsd/shared/constants/breakpoints';
import { RolesEnum } from '@/fsd/shared/constants/roles';
import { getNavigation } from '@/fsd/shared/lib/router/navigation';

import LayoutNavbarButton from './components/LayoutNavbarButton/LayoutNavbarButton';

export const useStyles = createStyles(() => ({
  navbarMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,

    [`@media (max-width: ${BREAKPOINTS.xl - 1}px)`]: {
      gap: 25,
    },
    [`@media (max-width: ${BREAKPOINTS.lg - 1}px)`]: {
      flexDirection: 'column',
      gap: 25,
      alignItems: 'start',
    },
  },
}));

const LayoutNavbar: FC = () => {
  const { t, i18n } = useTranslation('APPCON');
  const navigation = getNavigation(t, i18n.language);
  const { classes } = useStyles();

  return (
    <Box className={classes.navbarMenu}>
      {navigation.main.map((item) => (
        <LayoutNavbarButton
          title={item.title}
          links={item.links}
          href={item.href}
          key={item.title}
        />
      ))}
      {(TokenService.getRoleUserByToken() === RolesEnum.SUPERUSER ||
        TokenService.getRoleUserByToken() === RolesEnum.ADMIN) && (
        <>
          {navigation.admin.map((item) => (
            <LayoutNavbarButton title={item.title} links={item.links} key={item.title} />
          ))}
        </>
      )}
    </Box>
  );
};

export default LayoutNavbar;
