import { apiParser } from '@/fsd/shared/api';

import { GetLocationsParams, GetLocationsResponse } from '@/store/entities/locations/types';

export const locations = {
  getLocations(options: GetLocationsParams) {
    return apiParser.service.request<GetLocationsResponse>({
      method: 'get',
      url: `/api/reports/seller_locations/`,
      params: {
        ...options,
      },
    });
  },
};
