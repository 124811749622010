import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MarketplaceSelectValue,
  NOT_SELECTED_MARKETPLACE,
} from '@/fsd/shared/constants/marketplaces';

import {
  getGeoAnalysisReport,
  getGeoAnalysisReportForLocation,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/actions';
import { GeoAnalysisStatus } from '@/store/entities/tools/geoAnalysis/geoAnalysis/constants';
import {
  geoAnalysisAdapter,
  geoAnalysisHistoryAdapter,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/geoAnalysisAdapter';
import {
  GeoAnalysisState,
  GetGeoAnalysisReportForLocationResponse,
  GetGeoAnalysisReportResponse,
  IGeoAnalysisReportFilters,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: GeoAnalysisState = {
  geoAnalysis: geoAnalysisAdapter.getInitialState(),
  marketplace: NOT_SELECTED_MARKETPLACE,
  isLoading: null,
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  sorting: {
    sortBy: 'monthly_revenue_sum',
    sortOrder: 'desc',
  },
  filters: {},
  geoAnalysisHistory: geoAnalysisHistoryAdapter.getInitialState(),
  prevPeriodComparison: null,
};
export const geoAnalysisSlice = createSlice({
  name: 'geoAnalysis',
  initialState,
  reducers: {
    setMarketplaceGeoAnalysis: (state, action: PayloadAction<MarketplaceSelectValue>) => {
      state.marketplace = action.payload;
    },
    setPageTableGeoAnalysis: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
    setSizePageTableGeoAnalysis: (state, action: PayloadAction<number>) => {
      state.pagination.size = action.payload;
      state.pagination.page = 1;
    },
    setSortTableGeoAnalysis: (state, action: PayloadAction<ISorting>) => {
      state.sorting = action.payload;
    },
    setFiltersGeoAnalysis: (state, action: PayloadAction<IGeoAnalysisReportFilters>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: {
    // get top sellers
    [getGeoAnalysisReport.pending.type]: (state) => {
      state.isLoading = GeoAnalysisStatus.GET_GEO_ANALYSIS_REPORT;
    },
    [getGeoAnalysisReport.fulfilled.type]: (
      state,
      action: PayloadAction<GetGeoAnalysisReportResponse>
    ) => {
      geoAnalysisAdapter.setAll(state.geoAnalysis, action.payload.data.list);
      state.pagination = {
        total: action.payload.pagination.total_items,
        pages: action.payload.pagination.total_pages,
        page: action.payload.pagination.page,
        size: action.payload.pagination.items_per_page,
      };
      state.isLoading = null;
    },
    [getGeoAnalysisReport.rejected.type]: (state, action) => {
      state.isLoading = null;
    },

    //getGeoAnalysisReportForLocation

    [getGeoAnalysisReportForLocation.pending.type]: (state) => {
      state.isLoading = GeoAnalysisStatus.GET_GEO_ANALYSIS_REPORT_FOR_LOCATION;
    },
    [getGeoAnalysisReportForLocation.fulfilled.type]: (
      state,
      action: PayloadAction<GetGeoAnalysisReportForLocationResponse>
    ) => {
      geoAnalysisHistoryAdapter.setAll(state.geoAnalysisHistory, action.payload.data.history);
      state.prevPeriodComparison = action.payload.data.prev_period_comparison;
      state.isLoading = null;
    },
    [getGeoAnalysisReportForLocation.rejected.type]: (state) => {
      state.isLoading = null;
    },
  },
});
