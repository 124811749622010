import { Dispatch, FC, KeyboardEvent, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, CloseButton, Container, FileButton, Group, Textarea } from '@mantine/core';
import { Eraser, Photo } from 'tabler-icons-react';

interface IProps {
  sendComment: boolean;
  imageFile: File | null;
  valueDescription: string;
  setSendComment: Dispatch<SetStateAction<boolean>>;
  setImageFile: Dispatch<SetStateAction<File | null>>;
  setValueDescription: Dispatch<SetStateAction<string>>;
  isDisabled?: boolean;
}

const TicketsCommentsAdd: FC<IProps> = ({
  imageFile,
  sendComment,
  setImageFile,
  setSendComment,
  valueDescription,
  setValueDescription,
  isDisabled,
}) => {
  const { t } = useTranslation('APPCON');
  const [image, setImage] = useState<string | null>();
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    setImageFile(null);
    setImage(null);
    resetRef.current?.();
  };

  const eraseComment = () => {
    setValueDescription(() => '');
  };

  const handleChooseFile = (file: File) => {
    setImageFile(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // @ts-ignore
      setImage(reader.result);
    };
  };

  const onEnterPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!sendComment) setSendComment(true);
    }
  };

  return (
    <Container
      fluid={true}
      style={{
        minHeight: 100,
        position: 'relative',
        border: '1px solid lightgray',
        borderRadius: 5,
        padding: 10,
        marginTop: 16,
      }}
    >
      <Textarea
        mb={5}
        autosize
        size="md"
        maxRows={5}
        variant="unstyled"
        value={valueDescription}
        style={{ marginBottom: 40, marginRight: 20 }}
        placeholder={t('appcon017-6-26')}
        onChange={(event) => setValueDescription(event.currentTarget.value)}
        onKeyDown={(event) => onEnterPress(event)}
      />
      <Group mt={10} position="right">
        <Container
          style={{
            position: 'absolute',
            padding: 0,
            bottom: 5,
            left: 5,
            backgroundColor: 'transparent',
          }}
        >
          <FileButton resetRef={resetRef} onChange={handleChooseFile} accept="image/png,image/jpeg">
            {(props) => (
              <Button
                style={{
                  padding: '0px 4px',
                  margin: 0,
                }}
                variant="subtle"
                radius="xl"
                size="xs"
                {...props}
              >
                <Photo size={22} />
              </Button>
            )}
          </FileButton>
        </Container>
        {imageFile && (
          <Container
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'left',
              padding: 0,
              bottom: 5,
              marginLeft: 35,
              backgroundColor: 'transparent',
            }}
          >
            <Group>
              <Avatar size="xl" radius="md" src={image} />
              <CloseButton
                style={{ position: 'absolute', bottom: 70, left: 105 }}
                disabled={!imageFile}
                color="red"
                onClick={clearFile}
                title="Close"
                size="sm"
                iconSize={22}
              />
            </Group>
          </Container>
        )}
        <Button
          style={{
            position: 'absolute',
            padding: '0px 4px',
            margin: 0,
            top: 2,
            right: 2,
          }}
          size="xs"
          color="red"
          radius="xl"
          variant="subtle"
          onClick={() => eraseComment()}
        >
          <Eraser size={22} />
        </Button>
        <Container
          style={{
            position: 'absolute',
            padding: 0,
            bottom: 10,
            right: 10,
            backgroundColor: 'transparent',
          }}
        >
          <Button
            disabled={!(valueDescription || image) || isDisabled}
            onClick={() => {
              if (!sendComment) setSendComment(true);
            }}
          >
            {t('appcon040-0A-27')}
          </Button>
        </Container>
      </Group>
    </Container>
  );
};
export default TicketsCommentsAdd;
