import {
  ICategoryAnalysisCharts,
  ICategoryAnalysisChartsGetParams,
  ICategoryAnalysisGetParams,
  ICategoryAnalysisInfo,
  ITopSellersGetParams,
  ITopSellersResponse,
} from '@/types/ICategoryAnalysis';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { categoryAnalysis } from '@/api/parser/categoryAnalysis';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

export const getTopSellers = createAsyncThunk<
  ITopSellersResponse,
  ITopSellersGetParams,
  {
    rejectValue: string;
  }
>('categoryAnalysis/getTopSellers', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await categoryAnalysis.getTopSellers(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getCategoryAnalysis = createAsyncThunk<
  ICategoryAnalysisInfo,
  ICategoryAnalysisGetParams,
  {
    rejectValue: string;
  }
>('categoryAnalysis/getCategoryAnalysis', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await categoryAnalysis.getCategoryAnalysis(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getChartsCategoryAnalysis = createAsyncThunk<
  ICategoryAnalysisCharts,
  ICategoryAnalysisChartsGetParams,
  {
    rejectValue: string;
  }
>('categoryAnalysis/getChartsCategoryAnalysis', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await categoryAnalysis.getChartsCategoryAnalysis(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
