import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR, UNAUTHORIZED_ACCESS } from '@/fsd/shared/constants/errors';

import { TreeCategoriesService } from './tree-categories-service';

import { getCategories } from '@/store/entities/tools/categories/tree-categories/actions';
import { FORBIDDEN_ERROR } from '@/store/entities/tools/categories/tree-categories/constants';
import {
  ISelectedElement,
  ITree,
  SelectedElementItem,
  TreeCategoriesData,
  TreeCategoriesState,
} from '@/store/entities/tools/categories/tree-categories/types';

const initialState: TreeCategoriesState = {
  categoriesTree: {}, // Дерево отображения
  tree: null, // Дерево состояния
  selectedElement: null, // выбранная категорий (для рубрикатора, где можно выбирать только один элемент)
  selectedElements: new Map<number, string>(),
  customError: null,
  isLoading: false,
};
export const treeCategoriesSlice = createSlice({
  name: 'treeCategories',
  initialState,
  reducers: {
    generateOrUpdateStateTree(state) {
      let catsId: number[] = [];
      state.selectedElements?.forEach((_, keys) => catsId.push(keys));
      state.tree = TreeCategoriesService.generateTree(state.categoriesTree, 0, state.tree, catsId);
      TreeCategoriesService.updateCheckedElementsInTree(state.tree, 1);
    },
    generateOrUpdateOneSelectStateTree(state) {
      state.tree = TreeCategoriesService.generateTree(
        state.categoriesTree,
        0,
        state.tree,
        state.selectedElement?.catId ? [state.selectedElement?.catId] : []
      );
    },
    setExpandableStateTree(state, action: PayloadAction<{ id: number; closeExpandable: boolean }>) {
      let newTree = TreeCategoriesService.setExpandableClosed(
        action.payload.id,
        action.payload.closeExpandable,
        state.tree
      );
      if (newTree) state.tree = newTree;
    },

    setLoadingStateTree(state, action: PayloadAction<{ id: number; loading: boolean }>) {
      let newTree = TreeCategoriesService.setLoading(
        action.payload.id,
        action.payload.loading,
        state.tree
      );
      if (newTree) state.tree = newTree;
    },

    // для рубрикатора с возможностью выбрать только одну категорию
    updateOneSelectedElementTreeCategories(state, action: PayloadAction<ISelectedElement | null>) {
      let prevState = state.selectedElement;
      let selectedElement = action.payload;
      let newTree = prevState
        ? TreeCategoriesService.setStateOne(prevState.catId, 'unchecked', state.tree)
        : undefined;

      if (!selectedElement) state.selectedElement = null;

      if (JSON.stringify(prevState) !== JSON.stringify(selectedElement))
        newTree = selectedElement
          ? TreeCategoriesService.setStateOne(
              selectedElement?.catId,
              'checked',
              newTree || state.tree
            )
          : undefined;

      if (newTree) {
        state.tree = newTree;
        if (JSON.stringify(prevState) !== JSON.stringify(selectedElement))
          state.selectedElement = selectedElement;
        else state.selectedElement = null;
      }
    },

    // для рубрикатора с возможностью выбрать несколько категорий
    updateSelectedElementTreeCategories(state, action: PayloadAction<ISelectedElement | null>) {
      let selectedElement = action.payload;

      if (!selectedElement) {
        state.selectedElements = new Map<number, string>();
      } else if (
        TreeCategoriesService.getNodeById(selectedElement.catId, state.tree)?.state === 'unchecked'
      ) {
        // state.selectedElements?.set(selectedElement.catId, selectedElement.name);
        TreeCategoriesService.setState(
          selectedElement?.catId,
          'checked',
          state.tree,
          state.selectedElements
        );
      } else {
        // state.selectedElements?.delete(selectedElement.catId);
        TreeCategoriesService.setState(
          selectedElement?.catId,
          'unchecked',
          state.tree,
          state.selectedElements
        );
      }
    },

    setSelectedElementTreeCategories(state, action: PayloadAction<ISelectedElement | null>) {
      state.selectedElement = action.payload;
    },

    setSelectedElementsTreeCategories(state, action: PayloadAction<SelectedElementItem>) {
      state.selectedElements = action.payload;
    },

    clearTreeCategoriesState(state) {
      state.tree = null;
      // state.selectedElement = null;
      state.categoriesTree = {};
      state.customError = null;
    },
  },
  extraReducers: {
    [getCategories.pending.type]: (state, action) => {
      if (!action.meta.arg.parent_id) {
        state.isLoading = true;
      }
    },
    [getCategories.fulfilled.type]: (
      state,
      action: PayloadAction<{
        parentId: number | undefined;
        results: TreeCategoriesData[];
      }>
    ) => {
      state.isLoading = false;
      let parentId = action.payload.parentId;
      let categoriesData = action.payload.results;
      let tree = state.categoriesTree;
      let result: ITree | null = {};

      // Создаем или дополняем отображаемое дерево
      if (!parentId) {
        result = TreeCategoriesService.dataTreeToStateTreeType(categoriesData);
      } else if (categoriesData[0].children) {
        let v = TreeCategoriesService.dataTreeToStateTreeType(categoriesData[0].children);
        result = v && (TreeCategoriesService.updateElementInTree(tree, parentId, v) || null);
      }

      state.categoriesTree = result || {};

      // Создаем или обновляем дерево состояния
    },
    [getCategories.rejected.type]: (state, action) => {
      state.isLoading = false;
      if (action.payload === UNAUTHORIZED_ACCESS) state.customError = FORBIDDEN_ERROR;
      else showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },
  },
});
