import { apiParser } from '@/fsd/shared/api';

import { ISellerAudit } from '@/store/entities/tools/sellerAudit/types';

const data = {
  advice: {
    business: null,
    categories: 'To enhance marketability in...',
    competitors: 'To enhance marketability in...',
  },
  categories: [
    {
      category: {
        cat_id: 24,
        marketplace: 'Tokopedia',
        name: 'Audio',
      },
      recommendation: {
        max_rank: 22,
        params: {
          average_check: 3,
          model_with_stock_percent: 3,
          revenue_per_seller_avg: 3,
          seller_count: 3,
          sellers_with_sales_percent: 3,
          share_in_revenue_for_top_10_seller: 3,
        },
        rank: 22,
        recommendation: 4,
        score: 25,
      },
      seller_category: {
        revenue: 123456,
        revenue_share: 6.5232,
        sales: 33,
        sales_share: 11.5232,
      },
    },
  ],
  competitors: [
    {
      category: {
        name: 'Wipro Shop',
        revenue: 423423435,
        sales: 2342342,
      },
      comparison: {
        competitor_avg_price: 311.21,
        competitor_market_share: 89,
        seller_avg_price: 212.11,
        seller_market_share: 11,
      },
      seller: {
        basic_name: 'Chris Norman',
        id: 1,
        image_url: 'https://images.tokopedia.net/img/cache/700/product-1/525.jpg',
        listed_age: 25,
        location: 'Jakarta Barat',
        marketplace: 'Tokopedia',
        name: 'Chris Norman',
        rating: 2.3,
      },
    },
  ],
  seller: {
    basic_name: 'Chris Norman',
    id: 1,
    image_url: 'https://images.tokopedia.net/img/cache/700/product-1/525.jpg',
    listed_age: 25,
    location: 'Jakarta Barat',
    marketplace: 'Tokopedia',
    name: 'Chris Norman',
    rating: 2.3,
  },
  seller_aggs: {
    product_sum: 230,
    revenue_sum: 213231231,
    sales_sum: 1112,
  },
};

export const sellerAuditApi = {
  getSellerAuditApi(urlSeller: string, lang: string) {
    return apiParser.service.request<ISellerAudit>({
      method: 'get',
      url: `api/audit/seller/?source=${urlSeller}&lang=${lang}`,
    });
  },
};
