import {
  geoAnalysisAdapter,
  geoAnalysisHistoryAdapter,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/geoAnalysisAdapter';
import { IRootReducer } from '@/store/rootReducer';

export const geoAnalysisAdapterSelectors = geoAnalysisAdapter.getSelectors<IRootReducer>(
  (state) => state.geoAnalysis.geoAnalysis
);

export const geoAnalysisForLocationSelectors = geoAnalysisHistoryAdapter.getSelectors<IRootReducer>(
  (state) => state.geoAnalysis.geoAnalysisHistory
);
