import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';

import {
  AddCommentData,
  AddStatusData,
  AddThemeData,
  AddTicketData,
  AddTicketDataManager,
  ChangeManagerData,
  ChangeStatusData,
  DeleteCommentData,
  ListTicketsData,
  ViewTicketsData,
} from '@/store/entities/ticket/types';

// Изменение списка менеджеров
export const patchManager = createAsyncThunk(
  'ticket/patchManager',
  async (arg: ChangeManagerData, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketTicketIdChangeManagerPatch(arg.ticket_id, {
        manager_id: arg.manager_id,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Отображение списка статусов и их id
export const statusesList = createAsyncThunk('ticket/statusesList', async (arg, thunkAPI) => {
  try {
    const response = await Api.Ticket.apiTicketsListStatusesGet();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response?.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

// Отображение списка тем и их id
export const getThemesList = createAsyncThunk('ticket/themesList', async (arg, thunkAPI) => {
  try {
    const response = await Api.Ticket.apiTicketsListThemesGet();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response?.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

// Отображение списка полей модели "тикет" для сортировки
export const listTicketFields = createAsyncThunk(
  'ticket/ticketFieldsListList',
  async (arg, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsListTicketTableFieldsGet();
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Добавление статуса
export const postStatus = createAsyncThunk(
  'auth/postStatus',
  async (arg: AddStatusData, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsStatusAddStatusPost({
        order: arg.order,
        title: arg.title,
        is_closed: false,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Добавление темы
export const postTheme = createAsyncThunk('auth/postTheme', async (arg: AddThemeData, thunkAPI) => {
  try {
    const response = await Api.Ticket.apiTicketsThemeAddThemePost({ title: arg.title });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

// Создание тикета
export const postTicket = createAsyncThunk(
  'auth/postTicket',
  async (arg: AddTicketData, thunkAPI) => {
    try {
      // const response = await addTicket(arg);
      const response = await Api.Ticket.apiTicketsTicketAddTicketPost({
        theme_id: arg.theme_id,
        file: String(arg.file),
        description: arg.description,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Создание тикета менеджером
export const postTicketManager = createAsyncThunk(
  'auth/postTicketManager',
  async (arg: AddTicketDataManager, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketAddTicketByManagerPost({
        theme_id: arg.theme_id,
        file: String(arg.file),
        description: arg.description,
        user_id: arg.user_id,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
export const listTicketsData = createAsyncThunk(
  'ticket/listTicketsData',
  async (arg: ListTicketsData | undefined, thunkAPI) => {
    try {
      // const response = await getListTickets(arg);
      const response = await Api.Ticket.apiTicketsTicketListTicketsGet(
        arg?.manager_id,
        arg?.user_id,
        arg?.status_id,
        arg?.ticket_rating,
        arg?.from_date || undefined,
        arg?.to_date || undefined,
        arg?.page,
        arg?.items_per_page,
        arg?.sort_by,
        arg?.sort_order
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Отображение всей информации о тикете вместе с комментариями
export const viewTicketsData = createAsyncThunk(
  'ticket/viewTicketData',
  async (arg: ViewTicketsData, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketDetailedViewTicketIdGet(arg.ticket_id);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Изменение списка менеджеров
export const patchStatus = createAsyncThunk(
  'ticket/patchStatus',
  async (arg: ChangeStatusData, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketTicketIdChangeStatusPatch(arg.ticket_id, {
        status_id: arg.status_id,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// // Изменение порядка статуса
// export const patchOrder = createAsyncThunk(
//   'ticket/patchOrder',
//   async (arg: ChangeOrderData, thunkAPI) => {
//     try {
//       const response = await Api.Ticket.apiTicketsStatusStatusIdChangeOrderPatch(arg.status_id, {order: arg.status_id});
//       return response.data;
//     } catch (e) {
//       const error = e as AxiosError;
//       if (error.response?.data) {
//         return thunkAPI.rejectWithValue(error.response.data);
//       } else {
//         return thunkAPI.rejectWithValue(error.response?.statusText);
//       }
//     }
//   }
// );

// Добавить комментарий
export const patchComment = createAsyncThunk(
  'ticket/patchComment',
  async (arg: AddCommentData, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketTicketIdAddCommentPost(arg.ticket_id, {
        content: arg.content,
        file: String(arg.image),
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Удаление комментария
export const deleteComment = createAsyncThunk(
  'ticket/deleteComment',
  async (arg: DeleteCommentData, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(
        arg.ticket_id,
        arg.comment_id
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Список тикетов пользователя
export const listTicketsDataById = createAsyncThunk(
  'ticket/listTicketsDataById',
  async (arg: ListTicketsData | undefined, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketListTicketsGet(
        arg?.manager_id,
        arg?.user_id,
        arg?.status_id,
        arg?.ticket_rating,
        arg?.from_date || undefined,
        arg?.to_date || undefined,
        arg?.page,
        arg?.items_per_page,
        arg?.sort_by,
        arg?.sort_order
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Список закрытых тикетов пользователя
export const listClosedTicketsDataById = createAsyncThunk(
  'ticket/listClosedTicketsDataById',
  async (arg: ListTicketsData | undefined, thunkAPI) => {
    try {
      const response = await Api.Ticket.apiTicketsTicketListTicketsGet(
        arg?.manager_id,
        arg?.user_id,
        arg?.status_id,
        arg?.ticket_rating,
        arg?.from_date || undefined,
        arg?.to_date || undefined,
        arg?.page,
        arg?.items_per_page,
        arg?.sort_by,
        arg?.sort_order
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
