import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from './actionCreators';
import type { AppDispatch, RootState } from './index';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useActions = () => bindActionCreators(ActionCreators, useDispatch());
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
