import {
  ICategoryAnalysisCharts,
  ICategoryAnalysisInfo,
  ITopSellersResponse,
} from '@/types/ICategoryAnalysis';

import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';
import {
  MarketplaceSelectValue,
  NOT_SELECTED_MARKETPLACE,
} from '@/fsd/shared/constants/marketplaces';

import { ISelectedRubricatorItem } from '@/store/entities/tools/categories/types';
import {
  getCategoryAnalysis,
  getChartsCategoryAnalysis,
  getTopSellers,
} from '@/store/entities/tools/categoryAnalysis/actions';
import { CategoryAnalysisErrors } from '@/store/entities/tools/categoryAnalysis/constants';
import { CategoryAnalysisState } from '@/store/entities/tools/categoryAnalysis/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: CategoryAnalysisState = {
  marketplace: NOT_SELECTED_MARKETPLACE,
  selectedCategoryAnalysisCategories: undefined,
  categoryAnalysis: null,
  topSellers: [],
  charts: null,
  isSuccess: false,
  isError: null,
  isLoading: false,
  message: '',
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  sorting: {
    sortBy: 'revenue',
    sortOrder: 'desc',
  },
};
export const categoryAnalysisSlice = createSlice({
  name: 'categoryAnalysis',
  initialState,
  reducers: {
    setCategoryAnalysisMarketplace(state, action: PayloadAction<MarketplaceSelectValue>) {
      state.marketplace = action.payload;
    },
    setSelectedCategoryAnalysisCategories(
      state,
      action: PayloadAction<ISelectedRubricatorItem | undefined>
    ) {
      state.selectedCategoryAnalysisCategories = action.payload;
    },
    setPageSellerTableCategoryAnalysis: (state, action: PayloadAction<{ page: number }>) => {
      state.pagination.page = action.payload.page;
    },
    setSortCategoryAnalysis: (state, action: PayloadAction<ISorting>) => {
      state.sorting = action.payload;
    },
    resetStatusCategoryAnalysis: (state) => {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    // get top sellers
    [getTopSellers.pending.type]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = null;
    },
    [getTopSellers.fulfilled.type]: (state, action: PayloadAction<ITopSellersResponse>) => {
      state.isLoading = false;
      state.topSellers = action.payload.sellers;
      state.pagination = {
        total: action.payload.pagination_data.total_items,
        pages: action.payload.pagination_data.total_pages,
        page: action.payload.pagination_data.page,
        size: action.payload.pagination_data.items_per_page,
      };
    },
    [getTopSellers.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isError = CategoryAnalysisErrors.GET_TOP_SELLERS_ERROR;
      state.isLoading = false;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },

    // get category analysis
    [getCategoryAnalysis.pending.type]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = null;
      state.categoryAnalysis = null;
    },
    [getCategoryAnalysis.fulfilled.type]: (state, action: PayloadAction<ICategoryAnalysisInfo>) => {
      state.isLoading = false;
      state.categoryAnalysis = action.payload;
      state.isSuccess = true;
    },
    [getCategoryAnalysis.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isError = CategoryAnalysisErrors.GET_CATEGORY_ANALYSIS_ERROR;
      state.isLoading = false;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },

    // get charts
    [getChartsCategoryAnalysis.pending.type]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = null;
      state.charts = null;
    },
    [getChartsCategoryAnalysis.fulfilled.type]: (
      state,
      action: PayloadAction<ICategoryAnalysisCharts>
    ) => {
      state.isLoading = false;
      state.charts = action.payload;
    },
    [getChartsCategoryAnalysis.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isLoading = false;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },
  },
});
