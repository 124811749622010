import { createSlice } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import {
  createNotification,
  deleteNotificationById,
  deleteNotifications,
  getNotifications,
  readNotificationById,
  readNotifications,
} from './actions';
import { NotificationsState } from './types';

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
  error: null,
  message: null,
  isCreateNotification: false,
  isDeleteNotification: false,
  page: 1,
  hasNext: true,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    recoveryNotificationsState(state) {
      state.loading = false;
      state.error = null;
      state.message = null;
      state.isCreateNotification = false;
      state.isDeleteNotification = false;
    },
  },
  extraReducers: (builder) => {
    // getNotifications
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.hasNext = action.payload.pagination.has_next;
      state.page = action.payload.pagination.page;
      if (state.page === 1) {
        state.notifications = action.payload.results;
      } else {
        state.notifications = state.notifications.concat(action.payload.results);
      }
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // readNotifications
    builder.addCase(readNotifications.pending, (state) => {
      state.error = null;
    });
    builder.addCase(readNotifications.fulfilled, (state) => {
      state.notifications?.forEach((item) => {
        item.read = true;
      });
    });
    builder.addCase(readNotifications.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });

    // deleteNotifications
    builder.addCase(deleteNotifications.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteNotifications.fulfilled, (state) => {
      state.isDeleteNotification = !state.isDeleteNotification;
    });
    builder.addCase(deleteNotifications.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });

    // readNotificationById
    builder.addCase(readNotificationById.pending, (state) => {
      state.error = null;
    });
    builder.addCase(readNotificationById.fulfilled, (state, action) => {
      if (state.notifications)
        state.notifications.filter((item) => item.id === action.payload.id)[0].read = true;
    });
    builder.addCase(readNotificationById.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });

    // deleteNotificationById
    builder.addCase(deleteNotificationById.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteNotificationById.fulfilled, (state, action) => {
      if (state.notifications)
        state.notifications.splice(
          state.notifications.findIndex((item) => item.id === action.payload.id),
          1
        );
      state.message = action.payload.message || SYSTEM_ERROR;
    });
    builder.addCase(deleteNotificationById.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });

    // createNotification
    builder.addCase(createNotification.pending, (state) => {
      state.error = null;
    });
    builder.addCase(createNotification.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.isCreateNotification = !state.isCreateNotification;
    });
    builder.addCase(createNotification.rejected, (state, action) => {
      state.error = action.payload || SYSTEM_ERROR;
    });
  },
});

export const notificationsActions = {
  getNotifications,
  readNotifications,
  deleteNotifications,
  readNotificationById,
  deleteNotificationById,
  ...notificationsSlice.actions,
};

export default notificationsSlice.reducer;
