import { useEffect } from 'react';
import { Box, Group, Image } from '@mantine/core';

import {
  MakePurchaseRequestPaymentSystemEnum,
  PaymentMethodPaymentSystemEnum,
} from '@/api/swaggerApi';

import StripeImage from '@/assets/images/tariff/stripe.png';
import XenditImage from '@/assets/images/tariff/xendit.png';
import { useStyles } from '@/pages/Profile/ProfileTariff/TariffStyles';
import { useActions, useAppSelector } from '@/store/hooks';

const TariffPaymentSystems = () => {
  const { classes } = useStyles();

  const { paymentSystem } = useAppSelector((state) => state.tariffFilters);
  const { paymentMethods } = useAppSelector((state) => state.tariff);
  const { setPaymentSystem, resetTariffValuesState } = useActions();

  const handlePaymentMethodImage = (value: PaymentMethodPaymentSystemEnum): any => {
    switch (value) {
      case PaymentMethodPaymentSystemEnum.StripePayment:
        return StripeImage;
      case PaymentMethodPaymentSystemEnum.XenditPayment:
        return XenditImage;
    }
  };

  useEffect(() => {
    if (paymentMethods.length > 0) setPaymentSystem(paymentMethods[0].payment_system);
  }, [paymentMethods]);

  const handleSelectPaymentSystem = (ps: PaymentMethodPaymentSystemEnum) => {
    resetTariffValuesState();
    setPaymentSystem(ps);
  };

  return paymentMethods.length > 1 ? (
    <Group mt={'xl'}>
      {paymentMethods.map((method) => (
        <Box
          key={method.payment_system}
          className={
            paymentSystem === method.payment_system
              ? classes.paymentSystemBoxActive
              : classes.paymentSystemBox
          }
          onClick={() => handleSelectPaymentSystem(method.payment_system)}
        >
          <Image src={handlePaymentMethodImage(method.payment_system)} width={100} />
        </Box>
      ))}
    </Group>
  ) : (
    <></>
  );
};

export default TariffPaymentSystems;
