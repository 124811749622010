import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { NotificationsProvider } from '@mantine/notifications';

import Telegram from '@/api/telegramApi/telegram';

import { AppFonts } from '@/utils/AppFonts';
import GoogleTagManager from '@/utils/googleUtils/googleTagManager';

import Error from '@/ui/Error/Error';

import '@/fsd/shared/config/i18n/i18n';

import MantineProviders from './mantine/MantineProviders';

import { store } from '@/store';

interface IProviders {
  /** Content that will be wrapped by providers. */
  readonly children: JSX.Element;
}

GoogleTagManager.initializeGtm();

const logError = async (error: Error, info: { componentStack: string }) => {
  const message = `<b>Error:</b> ${error} <pre>${info.componentStack}</pre>`;
  if (process.env.NODE_ENV === 'production') {
    await Telegram.postSendMessageBot(message);
  }
};

export const Providers: FC<IProviders> = ({ children }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary fallbackRender={Error} onError={logError}>
          <SkeletonTheme baseColor="#f7f7f7" highlightColor="#eeeeee">
            <MantineProviders>
              <AppFonts />

              <NotificationsProvider>{children}</NotificationsProvider>
            </MantineProviders>
          </SkeletonTheme>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
};
