import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Platform } from '@/api/swaggerApi';

import { dateParse, getDefaultDate } from '@/utils/dateFormat';

import { getMarketplaces, getPlatforms } from '@/store/entities/marketplaces/actions';
import { IConnectionsListResponse } from '@/store/entities/marketplaces/types';
import { getABCAnalyzeTotal } from '@/store/entities/tools/internalAnalytics/ABCAnalyze/actions';
import { ITotalABCResponse } from '@/store/entities/tools/internalAnalytics/ABCAnalyze/types';
import {
  brandsFiltersAdapter,
  categoryListFiltersAdapter,
  connectionsFiltersAdapter,
} from '@/store/entities/tools/internalAnalytics/internalAnalyticsFilters/internalAnalyticsFiltersAdapter';
import {
  IABCCategoryFilter,
  IABCFilter,
  IInternalAnalyticsFiltersState,
  IMarginAnalysisFilters,
} from '@/store/entities/tools/internalAnalytics/types';

const initialState: IInternalAnalyticsFiltersState = {
  connections: connectionsFiltersAdapter.getInitialState(),
  brands: brandsFiltersAdapter.getInitialState(),
  categoryList: categoryListFiltersAdapter.getInitialState(),
  commonFilters: {
    selectedConnection: [],
    dateFrom: getDefaultDate().from,
    dateTo: getDefaultDate().to,
    demo: false,
  },
  abcAnalyzeFilters: {
    abcFilter: undefined,
    abcCategoryFilter: undefined,
    variantIds: undefined,
    brandFilter: undefined,
    productCategoryFilter: undefined,
  },
  marginAnalysisFilters: {
    profitLimit: undefined,
    noCostFilter: undefined,
    variantIds: undefined,
  },
  platforms: [],
};
export const internalAnalyticsFiltersSlice = createSlice({
  name: 'internalAnalyticsFilters',
  initialState,
  reducers: {
    setSelectedConnection: (state, action: PayloadAction<number[]>) => {
      state.abcAnalyzeFilters = initialState.abcAnalyzeFilters;
      state.commonFilters = {
        ...initialState.commonFilters,
        selectedConnection: action.payload,
        dateTo: state.commonFilters.dateTo,
        dateFrom: state.commonFilters.dateFrom,
      };
    },
    setDateInternalAnalytics: (state, action: PayloadAction<[Date | null, Date | null]>) => {
      state.commonFilters.dateTo = action.payload[1]
        ? dateParse(action.payload[1], 'yyyy-MM-dd HH:mm:ss')
        : null;
      state.commonFilters.dateFrom = action.payload[0]
        ? dateParse(action.payload[0], 'yyyy-MM-dd HH:mm:ss')
        : null;
    },
    setCategoryListInternalAnalytics: (state, action: PayloadAction<string[] | null>) => {
      state.abcAnalyzeFilters.productCategoryFilter = action.payload || undefined;
    },
    setBrandsInternalAnalytics: (state, action: PayloadAction<string[] | null>) => {
      state.abcAnalyzeFilters.brandFilter = action.payload || undefined;
    },
    setABCFilter: (state, action: PayloadAction<IABCFilter>) => {
      state.abcAnalyzeFilters.abcFilter = action.payload;
    },
    setABCCategoryFilter: (state, action: PayloadAction<IABCCategoryFilter>) => {
      state.abcAnalyzeFilters.abcCategoryFilter = action.payload;
    },
    setVariantIdsAbcAnalyze: (state, action: PayloadAction<string[] | undefined>) => {
      state.abcAnalyzeFilters.variantIds = action.payload;
    },
    setMarginAnalysisFilters: (state, action: PayloadAction<IMarginAnalysisFilters>) => {
      state.marginAnalysisFilters = action.payload;
    },
    setDemoInternalAnalytics: (state, action: PayloadAction<boolean>) => {
      state.commonFilters.demo = action.payload;
    },
  },
  extraReducers: {
    // get top sellers
    [getMarketplaces.pending.type]: () => {},
    [getMarketplaces.fulfilled.type]: (state, action: PayloadAction<IConnectionsListResponse>) => {
      connectionsFiltersAdapter.setAll(state.connections, action.payload.results);
    },
    [getMarketplaces.rejected.type]: () => {
      // showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },

    // getABCAnalyzeTotal
    [getABCAnalyzeTotal.pending.type]: () => {},
    [getABCAnalyzeTotal.fulfilled.type]: (state, action: PayloadAction<ITotalABCResponse>) => {
      categoryListFiltersAdapter.setAll(state.categoryList, action.payload.category_list);
      brandsFiltersAdapter.setAll(state.brands, action.payload.brand_list);
    },
    [getABCAnalyzeTotal.rejected.type]: () => {},

    // getPlatforms
    [getPlatforms.pending.type]: () => {},
    [getPlatforms.fulfilled.type]: (state, action: PayloadAction<Platform[]>) => {
      state.platforms = action.payload;
    },
    [getPlatforms.rejected.type]: () => {},
  },
});
