import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';

import { api } from '@/fsd/shared/api';

import { AccountDetails, Accounts, UpdateAccountData } from './types';

export const getMarketplaces = createAsyncThunk<any, Accounts, { rejectValue: string }>(
  'marketplaces/getMarketplaces',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'get',
        url: `api/connections/list-connection/${options.page}/`,
        params: {
          items_per_page: options.items_per_page,
          user_id: options.user_id,
        },
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const connectAccount = createAsyncThunk<any, AccountDetails, { rejectValue: string }>(
  'marketplaces/connectAccount',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'post',
        url: 'api/connections/request-connection/',
        data: options,
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteAccountById = createAsyncThunk<any, number, { rejectValue: string }>(
  'marketplaces/deleteAccountById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'delete',
        url: `api/connections/delete-connection/${id}/`,
      });
      return { id, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const updateAccount = createAsyncThunk<any, UpdateAccountData, { rejectValue: string }>(
  'marketplaces/updateAccount',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'post',
        url: 'api/connections/update-connection/',
        data: options,
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getPlatforms = createAsyncThunk<any, never, { rejectValue: string }>(
  'marketplaces/getPlatforms',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Api.Connections.apiConnectionsPlatformsGet();
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);
