import { useEffect } from 'react';

import { marketplacesApi } from '@/api/parser/marketplacesApi';

const useGetMarketplaces = () => {
  const handleGetMarketplaces = async () => {
    try {
      const result = await marketplacesApi.getMarketplaces().then((data) => data.data.marketplaces);
      localStorage.setItem('marketplaces', JSON.stringify(result));
    } catch (e) {}
  };

  useEffect(() => {
    handleGetMarketplaces();
  }, []);
};

export default useGetMarketplaces;
