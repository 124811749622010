import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalyticEventsHook } from '@/hooks/analyticEventsHook/useAnalyticEventsHook';

import TariffPlanItem from '@/pages/Profile/ProfileTariff/TariffPlanItem';
import { useStyles } from '@/pages/Profile/ProfileTariff/TariffStyles';
import { useActions, useAppSelector } from '@/store/hooks';

const TariffPaymentList = () => {
  const { t } = useTranslation('APPCON');
  const { classes } = useStyles();
  const { setSelectedTariffValue, setSelectedCurrency } = useActions();
  const { paymentMethods } = useAppSelector((state) => state.tariff);
  const { paymentSystem, selectedTariffValue } = useAppSelector((state) => state.tariffFilters);

  const { selectTariffProfile } = useAnalyticEventsHook();

  const listTariffs = paymentMethods.find(
    (method) => method.payment_system === paymentSystem
  )?.tariff_plans;

  const handleSetSelectedValue = (tariff: string, currency: string) => {
    setSelectedTariffValue(tariff);
    setSelectedCurrency(currency);
    selectTariffProfile(tariff);
  };

  return (
    <div className={classes.tariffPlanContainer}>
      {listTariffs?.map(
        (tariff) =>
          tariff.group === 'pro' && (
            <TariffPlanItem
              prices={tariff.prices || []}
              key={tariff.id}
              days={String(tariff.period) + ' ' + (tariff.period === 1 ? t('day') : t('days'))}
              item={tariff}
              value={selectedTariffValue}
              setValue={handleSetSelectedValue}
            />
          )
      )}
    </div>
  );
};

export default TariffPaymentList;
