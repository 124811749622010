import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Title } from '@mantine/core';

import { languages } from '@/fsd/shared/constants/languages';

import ContentContainer from '@/ui/ContentContainer/ContentContainer';
import FlagsSelect from '@/ui/FlagsSelect/FlagsSelect';

import styles from './Profile.module.scss';

import { changeLanguage } from '@/store/entities/profile/actions';
import { useAppDispatch } from '@/store/hooks';

const ProfileChangeLanguage = () => {
  const { i18n, t } = useTranslation('APPCON');
  const [valueLanguage, setValueLanguage] = useState('');
  const dispatch = useAppDispatch();

  const handleChangeLanguage = () => {
    if (valueLanguage === 'gb') {
      i18n.changeLanguage('en');
      dispatch(
        changeLanguage({
          locale: 'en',
        })
      );
    } else {
      i18n.changeLanguage(valueLanguage?.toLocaleLowerCase());
      dispatch(
        changeLanguage({
          locale: valueLanguage?.toLocaleLowerCase(),
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []);

  return (
    <ContentContainer>
      <Title pb={15} fz={16}>
        {t('appcon036-1D-42')}
      </Title>

      <FlagsSelect
        data={languages}
        onChange={setValueLanguage}
        autocomplete={true}
        className={styles.languages}
      />

      <Button mt={15} radius={4} variant="filled" onClick={() => handleChangeLanguage()}>
        {t('appcon036-1D-43')}
      </Button>
    </ContentContainer>
  );
};

export default ProfileChangeLanguage;
