// eslint-disable-next-line import/no-extraneous-dependencies
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Header } from '@mantine/core';
import { cleanNotifications } from '@mantine/notifications';
import { AppPaths } from 'src/fsd/shared/lib/router';
import { Logout } from 'tabler-icons-react';

import { HEADER_HEIGHT } from '@/fsd/shared/constants/styles';
import LayoutNavbarLogo from '@/fsd/widgets/LayoutNavbar/ui/components/LayoutNavbarLogo/LayoutNavbarLogo';

import SupportOverlay from '@/ui/Layouts/Layout/components/LayoutHeader/components/SupportOverlay/SupportOverlay';

import styles from '@/ui/Layouts/Layout/components/LayoutHeader/LayoutHeader.module.scss';

import { logoutUserAction } from '@/store/entities/auth/actions';
import { logoutUser } from '@/store/entities/auth/authSlice';
import { useAppDispatch } from '@/store/hooks';

const ErrorHeader = (props: FallbackProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('APPCON');

  const handleLogout = async () => {
    props.resetErrorBoundary();
    await dispatch(logoutUserAction());
    dispatch(logoutUser());
    cleanNotifications();
    navigate(AppPaths.LOGIN);
  };

  return (
    <Header className={styles.header} height={HEADER_HEIGHT} py="xs" px={16}>
      <Group position={'apart'} w={'100%'}>
        <LayoutNavbarLogo clickable={false} />
        <Group>
          <SupportOverlay />
          <Button
            onClick={handleLogout}
            variant={'outline'}
            leftIcon={<Logout size={20} color="red" />}
            color={'red'}
          >
            {t('appcon040-0A-34')}
          </Button>
        </Group>
      </Group>
    </Header>
  );
};

export default ErrorHeader;
