import { createSlice } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import {
  createNotice,
  deleteNotice,
  getNotices,
  getNoticesByUrl,
  getNoticesTypes,
  setNoticeActivity,
  updateNotice,
} from './actions';
import { NoticesState } from './types';

const initialState: NoticesState = {
  assetsTypes: null,
  notices: null,
  pagination: null,
  noticesUrl: null,
  noticesByUrl: null,
  assetsLoading: false,
  loading: false,
  updating: false,
  updatingSuccess: false,
  error: false,
  message: null,
};

const noticesSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {
    recoveryNoticesState(state) {
      state.assetsLoading = false;
      state.loading = false;
      state.updating = false;
      state.updatingSuccess = false;
      state.error = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    // getNoticesTypes
    builder.addCase(getNoticesTypes.pending, (state) => {
      state.assetsLoading = true;
    });
    builder.addCase(getNoticesTypes.fulfilled, (state, action) => {
      state.assetsLoading = false;
      state.assetsTypes = action.payload.results;
    });
    builder.addCase(getNoticesTypes.rejected, (state, action) => {
      state.assetsLoading = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // getNotices
    builder.addCase(getNotices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotices.fulfilled, (state, action) => {
      state.loading = false;
      state.notices = action.payload.results;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getNotices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // createNotice
    builder.addCase(createNotice.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(createNotice.fulfilled, (state, action) => {
      state.updating = false;
      state.updatingSuccess = true;
      state.message = action.payload.message;
    });
    builder.addCase(createNotice.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // updateNotice
    builder.addCase(updateNotice.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(updateNotice.fulfilled, (state, action) => {
      state.updating = false;
      state.updatingSuccess = true;
      state.message = action.payload.message;
    });
    builder.addCase(updateNotice.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // deleteNotice
    builder.addCase(deleteNotice.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(deleteNotice.fulfilled, (state, action) => {
      state.updating = false;
      state.updatingSuccess = true;
      state.message = action.payload.message;
    });
    builder.addCase(deleteNotice.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // setNoticeActivity
    builder.addCase(setNoticeActivity.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(setNoticeActivity.fulfilled, (state, action) => {
      state.updating = false;
      state.updatingSuccess = true;
      state.message = action.payload.message;
      if (state.notices)
        state.notices.filter((item) => item.id === action.payload.id)[0].active =
          action.payload.active;
    });
    builder.addCase(setNoticeActivity.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || SYSTEM_ERROR;
    });

    // getNoticesByUrl
    builder.addCase(getNoticesByUrl.fulfilled, (state, action) => {
      state.noticesUrl = action.payload.url_page;
      state.noticesByUrl = action.payload.results;
    });
  },
});

export const noticesActions = {
  getNoticesTypes,
  getNotices,
  createNotice,
  updateNotice,
  deleteNotice,
  setNoticeActivity,
  getNoticesByUrl,
  ...noticesSlice.actions,
};

export default noticesSlice.reducer;
