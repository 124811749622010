import { FC, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { TokenService } from '@/api/TokenService';

import { RolesEnum } from '@/fsd/shared/constants/roles';

import { reset } from '@/store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

interface Props {
  children: JSX.Element;
}

const AdminRoutes: FC<Props> = ({ children }) => {
  const token = TokenService.getToken();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      // dispatch(profile());
    }
  }, []);

  useEffect(() => {
    if (
      TokenService.getRoleUserByToken() !== RolesEnum.SUPERUSER &&
      TokenService.getRoleUserByToken() !== RolesEnum.ADMIN
    ) {
      navigate(AppPaths.HOME);
    }
  }, []);

  if (isLoading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader variant="dots" />
      </Center>
    );
  }

  if (!token) {
    dispatch(reset());
  }

  return token ? children : <Navigate to={AppPaths.LOGIN} />;
};

export default AdminRoutes;
