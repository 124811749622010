import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MarginResponse,
  MarginTotalResponse,
  MarginTransactionsResponse,
  MarginWidgetProfitExpensesResponse,
} from '@/api/swaggerApi';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import {
  getMarginAnalyze,
  getMarginAnalyzeTotal,
  getMarginAnalyzeTransaction,
  getMarginAnalyzeWidgetProfitExpenses,
} from '@/store/entities/tools/internalAnalytics/marginAnalysis/actions';
import { MarginAnalysisConst } from '@/store/entities/tools/internalAnalytics/marginAnalysis/constants';
import { MarginAnalysisStore } from '@/store/entities/tools/internalAnalytics/marginAnalysis/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: MarginAnalysisStore = {
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  paginationTransaction: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  marginTotal: undefined,
  marginWidgetProfitExpenses: undefined,
  sorting: {
    sortBy: 'am_s',
    sortOrder: 'desc',
  },
  isLoading: null,
  marginProducts: [],
  transactions: [],
};
export const marginAnalysisSlice = createSlice({
  name: 'marginAnalysis',
  initialState,
  reducers: {
    setSortingMarginAnalysis: (state, action: PayloadAction<ISorting>) => {
      state.sorting = action.payload;
    },
    setPageMarginAnalysisTable: (state, action: PayloadAction<{ page: number }>) => {
      state.pagination.page = action.payload.page;
    },
    setPageMarginAnalysisTransactionTable: (state, action: PayloadAction<{ page: number }>) => {
      state.paginationTransaction.page = action.payload.page;
    },
  },
  extraReducers: {
    // get top sellers
    [getMarginAnalyzeTotal.pending.type]: () => {
      // state.loadingState = ABCAnalyzeConst.GET_ABC_ANALYZE_TOTAL;
    },
    [getMarginAnalyzeTotal.fulfilled.type]: (state, action: PayloadAction<MarginTotalResponse>) => {
      state.marginTotal = action.payload.data;
    },
    [getMarginAnalyzeTotal.rejected.type]: (state, action) => {
      showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
    },

    // get top sellers
    [getMarginAnalyzeWidgetProfitExpenses.pending.type]: () => {
      // state.loadingState = ABCAnalyzeConst.GET_ABC_ANALYZE_TOTAL;
    },
    [getMarginAnalyzeWidgetProfitExpenses.fulfilled.type]: (
      state,
      action: PayloadAction<MarginWidgetProfitExpensesResponse>
    ) => {
      state.marginWidgetProfitExpenses = action.payload.data;
    },
    [getMarginAnalyzeWidgetProfitExpenses.rejected.type]: (state, action) => {
      showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
    },

    //getMarginAnalyze
    [getMarginAnalyze.pending.type]: (state) => {
      state.isLoading = MarginAnalysisConst.GET_MARGIN_ANALYSIS;
    },
    [getMarginAnalyze.fulfilled.type]: (state, action: PayloadAction<MarginResponse>) => {
      if (action.payload.status === 'success') {
        state.isLoading = null;
        state.marginProducts = action.payload.data;
        state.pagination = {
          total: action.payload.pagination?.total_items || 0,
          pages: Math.ceil(
            (action.payload.pagination?.total_items || 0) /
              (action.payload.pagination?.items_per_page || 0)
          ),
          page: action.payload.pagination?.page || 0,
          size: action.payload.pagination?.items_per_page || 0,
        };
      }
    },
    [getMarginAnalyze.rejected.type]: (state, action) => {
      state.isLoading = null;
      showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
    },

    //getMarginAnalyzeTransaction
    [getMarginAnalyzeTransaction.pending.type]: (state) => {
      state.isLoading = MarginAnalysisConst.GET_MARGIN_ANALYSIS_TRANSACTION;
    },
    [getMarginAnalyzeTransaction.fulfilled.type]: (
      state,
      action: PayloadAction<MarginTransactionsResponse>
    ) => {
      if (action.payload.status === 'success') {
        state.isLoading = null;
        state.transactions = action.payload.data;
        state.paginationTransaction = {
          total: action.payload.pagination?.total_items || 0,
          pages: Math.ceil(
            (action.payload.pagination?.total_items || 0) /
              (action.payload.pagination?.items_per_page || 0)
          ),
          page: action.payload.pagination?.page || 0,
          size: action.payload.pagination?.items_per_page || 0,
        };
      }
    },
    [getMarginAnalyzeTransaction.rejected.type]: (state, action) => {
      state.isLoading = null;
      showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
    },
  },
});
