import { createSlice } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { ITelegram } from '../../types/ITelegram';

import {
  deleteTelegramAccount,
  getQRTelegram,
  getTelegramAccounts,
  getUrlTelegram,
  setTelegramNotification,
} from './actions';

export interface TelegramState {
  isLoading: boolean;
  isLoadingTelegramAccounts: boolean;
  isSuccess: boolean;
  isSuccessTelegramAccounts: boolean;
  isError: boolean;
  isErrorTelegramAccounts: boolean;
  urlTelegram: undefined | string;
  message: string;
  notificationEnable: boolean;
  telegramAccounts: null | ITelegram[];
  telegramQR: string | null;
  isSuccessSwitchNotification: boolean;
  isErrorSwitchNotification: boolean;
  isErrorDeleteAccount: boolean;
  isSuccessDeleteAccount: boolean;
}

const initialState: TelegramState = {
  isLoading: false,
  isLoadingTelegramAccounts: false,
  isSuccess: false,
  isSuccessTelegramAccounts: false,
  isError: false,
  isErrorTelegramAccounts: false,
  urlTelegram: undefined,
  message: '',
  notificationEnable: false,
  telegramAccounts: null,
  telegramQR: null,
  isSuccessSwitchNotification: false,
  isErrorSwitchNotification: false,
  isErrorDeleteAccount: false,
  isSuccessDeleteAccount: false,
};

export const telegramSlice = createSlice({
  name: 'telegram',
  initialState,
  reducers: {
    resetTelegramState: () => initialState,
    switchStatusDefault: (state) => {
      state.isSuccessSwitchNotification = false;
      state.isErrorSwitchNotification = false;
    },
  },
  extraReducers: {
    // получение ссылки на телеграмм канал
    [getUrlTelegram.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUrlTelegram.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.urlTelegram = action.payload.message;
      state.isError = false;
      state.isSuccess = true;
      state.message = 'Успех';
    },
    [getUrlTelegram.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // получение qr кода
    [getQRTelegram.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getQRTelegram.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.telegramQR = action.payload.message;
      state.isError = false;
      state.isSuccess = true;
      state.message = 'Успех';
    },
    [getQRTelegram.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // включить/выключить уведомления в телеграмме
    [setTelegramNotification.fulfilled.type]: (state, action) => {
      state.message = action.payload.message;
      state.isSuccessSwitchNotification = true;
      state.isErrorSwitchNotification = false;
    },
    [setTelegramNotification.rejected.type]: (state, action) => {
      state.message = action.payload.message || SYSTEM_ERROR;
      state.isSuccessSwitchNotification = false;
      state.isErrorSwitchNotification = true;
    },
    // получить телеграмм аккаунты
    [getTelegramAccounts.pending.type]: (state) => {
      state.isLoadingTelegramAccounts = true;
      state.isSuccessTelegramAccounts = false;
      state.isErrorTelegramAccounts = false;
    },
    [getTelegramAccounts.fulfilled.type]: (state, action) => {
      state.isLoadingTelegramAccounts = false;
      state.isSuccessTelegramAccounts = true;
      state.isErrorTelegramAccounts = false;
      state.telegramAccounts = action.payload.length === 0 ? null : action.payload;
      if (state.telegramAccounts) {
        state.notificationEnable = state.telegramAccounts[0].notifications;
      }
    },
    [getTelegramAccounts.rejected.type]: (state, action) => {
      state.isLoadingTelegramAccounts = false;
      state.isErrorTelegramAccounts = true;
      state.isSuccessTelegramAccounts = false;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // удаление телеграмм аккаунта
    [deleteTelegramAccount.fulfilled.type]: (state, action) => {
      state.isErrorDeleteAccount = false;
      state.isSuccessDeleteAccount = true;
      state.message = action.payload.message;
    },
    [deleteTelegramAccount.rejected.type]: (state, action) => {
      state.isErrorDeleteAccount = true;
      state.isSuccessDeleteAccount = false;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
  },
});

export const { resetTelegramState, switchStatusDefault } = telegramSlice.actions;
export default telegramSlice.reducer;
