import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { trendAnalysisApi } from '@/api/parser/trendAnalysis';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

import {
  IGetReportsCategoriesRecommendation,
  IGetReportsCategoriesRecommendationResponse,
} from '@/store/entities/tools/trendAnalysis/types';

export const getCategoriesListWithRecommendationData = createAsyncThunk<
  IGetReportsCategoriesRecommendationResponse,
  IGetReportsCategoriesRecommendation,
  {
    rejectValue: string;
  }
>('trendAnalysis/getTopSellers', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await trendAnalysisApi.getReportsCategoriesRecommendation(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
