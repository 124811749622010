import { createEntityAdapter } from '@reduxjs/toolkit';

import { IMarketplace } from '@/store/entities/marketplaces/types';
import { ICategoryList } from '@/store/entities/tools/internalAnalytics/ABCAnalyze/types';

export const connectionsFiltersAdapter = createEntityAdapter<IMarketplace>({
  selectId: (connections) => connections.id,
});

export const brandsFiltersAdapter = createEntityAdapter<string>({
  selectId: (brand) => brand,
});

export const categoryListFiltersAdapter = createEntityAdapter<ICategoryList>({
  selectId: (brand) => brand.category_id,
});
