import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BackgroundImage, Box, Title } from '@mantine/core';

import styles from './AuthImage.module.scss';

interface IAuthImageProps {
  image: string;
}

const AuthImage: FC<IAuthImageProps> = ({ image }) => {
  const { t } = useTranslation('APPCON');

  return (
    <Box w={'100%'} h={'100%'} pos={'relative'}>
      <BackgroundImage
        w={'100%'}
        h={'100%'}
        src={image}
        sx={{ filter: 'brightness(40%)' }}
      ></BackgroundImage>
      <Box className={styles.textBox}>
        <Box ml={60} mb={100}>
          <Title className={styles.title2}>
            <Trans
              i18nKey={'appcon002-1-1'}
              t={t}
              components={{
                style1: <Title className={styles.title} />,
              }}
            />
          </Title>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthImage;
