import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BinaryResponse, ListPlans, PaymentMethod, Subscription } from '@/api/swaggerApi';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { getPaymentMethods, getSubscriptionMe, getTariffPlans, postBuyTariff } from './actions';

import { LOADING } from '@/store/entities/tariff/constants';
import { TariffState } from '@/store/entities/tariff/types';

const initialState: TariffState = {
  isLoading: null,
  isSuccess: false,
  isError: false,
  message: '',
  listTariffs: [],
  paymentMethods: [],
  currentTariffPlan: null,
  buyTariffLink: null,
};

export const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    resetTariffState: (state) => {
      state.isLoading = null;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    resetBuyTariffLink: (state) => {
      state.buyTariffLink = null;
    },
  },

  extraReducers: {
    //Список тарифных планов
    [getTariffPlans.fulfilled.type]: (state, action: PayloadAction<ListPlans>) => {
      state.isLoading = null;
      state.listTariffs = action.payload.results || [];
    },
    [getTariffPlans.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },

    // Купить план
    [postBuyTariff.pending.type]: (state) => {
      state.isLoading = LOADING.BUY_PLAN;
    },
    [postBuyTariff.fulfilled.type]: (state, action: PayloadAction<BinaryResponse>) => {
      state.isLoading = null;
      state.buyTariffLink = action.payload.message || null;
    },
    [postBuyTariff.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      showNotification({ color: 'red', message: action.payload.message || SYSTEM_ERROR });
    },

    // // Получить свой план
    [getSubscriptionMe.fulfilled.type]: (state, action: PayloadAction<Subscription>) => {
      state.isLoading = null;
      state.currentTariffPlan = action.payload;
    },
    [getSubscriptionMe.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isError = true;
      // state.message = action.payload.message || SYSTEM_ERROR;
      // showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },

    //
    [getPaymentMethods.fulfilled.type]: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.isLoading = null;
      state.paymentMethods = action.payload;
    },
    [getPaymentMethods.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },
  },
});
