import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR, UNAUTHORIZED_ACCESS } from '@/fsd/shared/constants/errors';
import {
  MarketplaceSelectValue,
  NOT_SELECTED_MARKETPLACE,
} from '@/fsd/shared/constants/marketplaces';

import { FORBIDDEN_RESOURCES } from '@/store/entities/tools/products/constants';
import { getCategoriesListWithRecommendationData } from '@/store/entities/tools/trendAnalysis/actions';
import {
  IGetReportsCategoriesRecommendationResponse,
  TrendAnalysisState,
} from '@/store/entities/tools/trendAnalysis/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: TrendAnalysisState = {
  trends: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  marketplace: NOT_SELECTED_MARKETPLACE,
  sorting: undefined,
  cutomError: null,
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  filters: {
    categoryId: null,
  },
};
export const trendAnalysisSlice = createSlice({
  name: 'trendAnalysis',
  initialState,
  reducers: {
    setFilterCategoryTrendAnalysis(state, action: PayloadAction<number | null>) {
      state.filters.categoryId = action.payload;
    },
    setTrendAnalysisMarketplace(state, action: PayloadAction<MarketplaceSelectValue>) {
      state.marketplace = action.payload;
    },
    setPageSellerTableTrendAnalysis: (state, action: PayloadAction<{ page: number }>) => {
      state.pagination.page = action.payload.page;
    },
    setSizeSellerTableTrendAnalysis: (state, action: PayloadAction<{ size: number }>) => {
      state.pagination.size = action.payload.size;
      state.pagination.page = 1;
    },
    setSortTrendAnalysis: (state, action: PayloadAction<ISorting | undefined>) => {
      state.sorting = action.payload;
    },
  },
  extraReducers: {
    // get top sellers
    [getCategoriesListWithRecommendationData.pending.type]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [getCategoriesListWithRecommendationData.fulfilled.type]: (
      state,
      action: PayloadAction<IGetReportsCategoriesRecommendationResponse>
    ) => {
      state.isLoading = false;
      state.trends = action.payload.categories;
      state.pagination = {
        total: action.payload.pagination_data.total_items,
        pages: action.payload.pagination_data.total_pages,
        page: action.payload.pagination_data.page,
        size: action.payload.pagination_data.items_per_page,
      };
    },
    [getCategoriesListWithRecommendationData.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.isLoading = false;
      if (action.payload === UNAUTHORIZED_ACCESS) state.cutomError = FORBIDDEN_RESOURCES;
      else showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },
  },
});
