import { FC, Suspense, useLayoutEffect } from 'react';

import { api } from '@/fsd/shared/api';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import useGetMarketplaces from '@/hooks/useGetMarketplaces';

import { AppRouter } from './router';

import './styles/index.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const App: FC = () => {
  useGetMarketplaces();

  useLayoutEffect(() => {
    api.init();
  }, []);
  return (
    <div className={classNames('app', {}, ['light'])}>
      <Suspense fallback={''}>
        <AppRouter />
      </Suspense>
    </div>
  );
};

export default App;
