import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, Title, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';
import Pagination from 'src/ui/navigation/Pagination/Pagination';
import { ExclamationMark } from 'tabler-icons-react';

import { getLocale } from '@/fsd/shared/config/i18n/i18n';
import { useRegions } from '@/hooks/useRegions';

import ContentContainer from '@/ui/ContentContainer/ContentContainer';
import { Mdash } from '@/ui/Mdash/Mdash';
import TableM from '@/ui/Tables/TableM/TableM';

import 'dayjs/locale/vi';
import 'dayjs/locale/en';
import 'dayjs/locale/id';
import 'dayjs/locale/th';
import 'dayjs/locale/my';
import 'dayjs/locale/zh-cn';

import ProfileEditModal from './ProfileInfoChange';

import styles from './Profile.module.scss';

import {
  deactivateAllSessionsExceptOne,
  getSessions,
  profile,
} from '@/store/entities/auth/actions';
import { resetDefaultState } from '@/store/entities/auth/authSlice';
import { resetProfileState } from '@/store/entities/profile/profileSlice';
import { useActions, useAppDispatch, useAppSelector } from '@/store/hooks';

const ProfileInfo: FC = () => {
  const { t } = useTranslation('APPCON');
  const dispatch = useAppDispatch();
  const {
    user,
    sessions,
    itemsPerPage,
    totalItems,
    page,
    message,
    isDeactivate,
    isError,
    isSuccess,
    changePhoneNumberError,
    changeEmailError,
  } = useAppSelector((store) => store.auth);
  const { currentTariffPlan } = useAppSelector((state) => state.tariff);
  const {
    isSuccess: isSuccessUpdateProfile,
    isError: isErrorUpdateProfile,
    message: updateProfileMessage,
    formError,
    isLoading,
  } = useAppSelector((store) => store.profile);
  const [currentPage, setCurrentPage] = useState(1);
  const [opened, setOpened] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const regions = useRegions();
  const { getSubscriptionMe } = useActions();

  const fetchSessions = () => {
    dispatch(
      getSessions({ page: currentPage, items_per_page: 5, is_active: true, user_id: user?.id })
    );
    getSubscriptionMe();
  };

  useEffect(() => {
    fetchSessions();
  }, [currentPage, isDeactivate]);

  useEffect(() => {
    dispatch(profile());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: message,
        color: 'green',
      });
      dispatch(resetDefaultState());
    } else if (isError) {
      if (changePhoneNumberError) {
        Object.values(changePhoneNumberError).forEach((value) => {
          value?.forEach((error) => {
            showNotification({
              title: message,
              message: error,
              color: 'red',
              styles: () => ({
                description: { color: 'black' },
              }),
            });
          });
        });
      }
      if (changeEmailError) {
        Object.values(changeEmailError).forEach((value) => {
          value?.forEach((error) => {
            showNotification({
              title: message,
              message: error,
              color: 'red',
              styles: () => ({
                description: { color: 'black' },
              }),
            });
          });
        });
      }
      if (changeEmailError.new_email === null && changePhoneNumberError._schema === null) {
        showNotification({ color: 'red', message: message });
      }
      dispatch(resetDefaultState());
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isSuccessUpdateProfile) {
      showNotification({
        message: updateProfileMessage,
        color: 'green',
      });
      dispatch(profile());
      dispatch(resetProfileState());
    } else if (isErrorUpdateProfile) {
      if (formError) {
        Object.values(formError).forEach((value) => {
          value?.forEach((error) => {
            showNotification({
              title: updateProfileMessage,
              message: error,
              color: 'red',
              styles: () => ({
                description: { color: 'black' },
              }),
            });
          });
        });
      } else {
        showNotification({
          message: updateProfileMessage,
          color: 'red',
        });
      }
      dispatch(resetProfileState());
    }
  }, [isSuccessUpdateProfile, isErrorUpdateProfile]);

  const columns = [
    { id: 'date', text: t('appcon036-1A-15') },
    { id: 'username', text: t('appcon036-1A-16') },
    { id: 'IP', text: t('appcon036-1A-17') },
    { id: 'country', text: t('appcon036-1A-18') },
  ];

  const sessionsData = sessions?.map((session) => ({
    date: dayjs(session.created_at).format('MMM DD, YYYY, h:mm:ss A'),
    username: user?.first_name,
    IP: session.user_ip,
    country: session.last_log_data.country_code,
  }));

  const getCellNodeByKey = (key: string, value: any) => {
    switch (key) {
      default:
        return value;
    }
  };

  const getRowData = (row: any) => {
    const rowData: any = {};
    Object.keys(row).forEach((key) => {
      rowData[key] = getCellNodeByKey(key, row[key]);
    });
    return rowData;
  };

  const data = sessionsData?.map((row: any) => getRowData(row));

  const deactivateSessions = () => {
    dispatch(deactivateAllSessionsExceptOne(''));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpened(true);
    const { name } = (event?.currentTarget as HTMLButtonElement).dataset;

    switch (name) {
      case 'name':
        setSelectedLabel(name);
        break;
      case 'phone':
        setSelectedLabel(name);
        break;
      case 'email':
        setSelectedLabel(name);
        break;
    }
  };

  let username =
    user?.first_name && user?.last_name ? `${user?.first_name} ${user?.last_name}` : <Mdash />;

  return (
    <ContentContainer>
      <Title pb={15} order={4}>
        {t('appcon036-1A-16')}: {username}{' '}
        <Button
          variant="subtle"
          style={{
            backgroundColor: 'transparent',
            padding: 0,
          }}
          onClick={handleClick}
          data-name={'name'}
        >
          ({t('appcon036-1A-3')})
        </Button>
      </Title>
      <Text pb={15}>
        {t('appcon036-1A-4')}: {dayjs(user?.created_at).format('DD MMM YYYY')}
      </Text>
      <Text pb={15}>
        {t('appcon036-1A-5')}{' '}
        <Button
          variant="subtle"
          style={{
            backgroundColor: 'transparent',
            padding: 0,
          }}
          onClick={handleClick}
          data-name={'phone'}
        >
          ({t('appcon036-1A-6')})
        </Button>
        : {user?.phone_number}
      </Text>
      <Text
        pb={15}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {t('appcon036-1A-7')}{' '}
        <Button
          variant="subtle"
          style={{
            backgroundColor: 'transparent',
            padding: 0,
            marginLeft: 5,
          }}
          onClick={handleClick}
          data-name={'email'}
        >
          ({t('appcon036-1A-8')})
        </Button>
        :{' '}
        {user?.new_email ? (
          <>
            <ExclamationMark size={22} color={'red'} />
            <Tooltip label={user?.new_email}>
              <span>{user?.email}</span>
            </Tooltip>
          </>
        ) : (
          user?.email
        )}
      </Text>
      <Text pb={15}>
        {t('appcon036-1A-9')}:{' '}
        {
          <b>
            {currentTariffPlan?.tariff_plan ? (
              currentTariffPlan?.tariff_plan.description
            ) : (
              <Mdash />
            )}
          </b>
        }
      </Text>

      <Text pb={15}>
        {t('The tariff is valid until')}:{' '}
        {
          <b>
            {currentTariffPlan?.tariff_plan ? (
              dayjs(currentTariffPlan?.finish_date).locale(getLocale()).format('MMM DD, YYYY')
            ) : (
              <Mdash />
            )}
          </b>
        }
      </Text>

      <Text pb={15}>
        {t('appcon036-1A-11')}: {regions.find((item) => item.name === user?.region)?.label}
        {/*<Button*/}
        {/*  variant="subtle"*/}
        {/*  style={{*/}
        {/*    backgroundColor: 'transparent',*/}
        {/*    padding: 0,*/}
        {/*  }}*/}
        {/*  onClick={handleClick}*/}
        {/*  data-name={'region'}*/}
        {/*>*/}
        {/*  ({t('appcon036-1A-3')})*/}
        {/*</Button>*/}
      </Text>
      <Box mt={15} className={styles.sessions}>
        <Text className={styles.title}>{t('appcon036-1A-14')}</Text>
        <Button onClick={deactivateSessions}>{t('appcon036-1A-19')}</Button>
      </Box>
      <Box mt={15}>
        <TableM tableInstance={{ columns, data }} />
      </Box>
      <ProfileEditModal
        opened={opened}
        onClose={() => setOpened(false)}
        inputLabel={selectedLabel}
        userData={user}
      />
      <Pagination
        disable={!!isLoading}
        total={totalItems}
        limit={itemsPerPage}
        currentPage={page}
        onChange={setCurrentPage}
      />
    </ContentContainer>
  );
};

export default ProfileInfo;
