import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, ScrollArea } from '@mantine/core';

import { LogoutButton } from '@/fsd/features/logout';
import { SelectLanguage } from '@/fsd/features/selectLanguage';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import { getNavigation } from '@/fsd/shared/lib/router';
import { AccordionMenu } from '@/fsd/shared/ui/AccordionMenu';
import { Bell } from '@/fsd/shared/ui/Bell';
import { Logo } from '@/fsd/shared/ui/Logo';

import NotificationsPopover from '@/ui/Layouts/Layout/components/LayoutHeader/components/NotificationsPopover/NotificationsPopover';

import cls from './NavbarDrawer.module.scss';

interface NavbarDrawerProps {
  className?: string;
  opened: boolean;
  setOpened: (open: boolean) => void;
}

const HEIGHT_HEADER_DRAWER = 55;

export const NavbarDrawer = ({ className, opened, setOpened }: NavbarDrawerProps) => {
  const { t, i18n } = useTranslation('APPCON');
  const { main, profile, web, admin, dashboard } = getNavigation(t, i18n.language);

  const menu = useMemo(
    () => [...dashboard, ...main, ...web, ...profile, ...admin],
    [profile, main, web, admin, dashboard]
  );

  return (
    <Drawer
      styles={() => ({
        title: { width: '100%', marginRight: 0 },
        body: { height: `calc(100% - ${HEIGHT_HEADER_DRAWER}px)` },
        drawer: { padding: '16px 8px 16px 16px!important' },
      })}
      classNames={{ body: cls.DrawerBody }}
      lockScroll={true}
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <div className={cls.headerDrawer}>
          <Logo />
          <div className={cls.headerDrawerRight}>
            <SelectLanguage
              autocomplete={true}
              labelStyle={{ fontWeight: 500 }}
              width={null}
              mobile={true}
            />

            <NotificationsPopover bell={<Bell />} />
          </div>
        </div>
      }
      size={340}
      padding="md"
      position={'right'}
    >
      <Box className={classNames(cls.NavbarDrawer, {}, [className])}>
        {/*{dashboard.map((item) => (*/}
        {/*  <UnstyledButton className={cls.btnBacklight}>*/}
        {/*    <NavLink name={item.title} href={item.href} />*/}
        {/*  </UnstyledButton>*/}
        {/*))}*/}
        <AccordionMenu items={menu} />
        <div className={cls.logoutBtn}>
          <LogoutButton />
        </div>
      </Box>
    </Drawer>
  );
};
