import React, { lazy } from 'react';
import { AppPaths } from 'src/fsd/shared/lib/router';

const HomePage = lazy(() => import('@/pages/Home/Home'));
const Clients = lazy(() => import('@/pages/Clients/Clients'));
const Telegram = lazy(() => import('@/pages/Telegram/Telegram'));
const Support = lazy(() => import('@/pages/Support/Support'));
const Help = lazy(() => import('@/pages/Help/Help'));
const Users = lazy(() => import('@/pages/Users/Users'));
const Content = lazy(() => import('@/pages/Content/Content'));
const Notifications = lazy(() => import('@/pages/Notifications/Notifications'));
const Tickets = lazy(() => import('@/pages/Tickets/Tickets'));
const TicketDetail = lazy(
  () => import('@/pages/Tickets/TicketsTable/TicketsActions/TicketDetailModal/TicketDetail')
);
const Analytics = lazy(() => import('@/pages/Tickets/TicketsControls/Analytics/Analytics'));
const Settings = lazy(() => import('@/pages/Settings/Settings'));
// const ResetPassword = lazy(() => import('@/pages/ResetPassword/ResetPassword'));
// const SignUp = lazy(() => import('@/pages/SignUp/SignUp'));
const ConfirmEmail = lazy(() => import('@/pages/ConfirmEmail/ConfirmEmail'));
const ConfirmPassword = lazy(() => import('@/pages/ConfirmPassword/ConfirmPassword'));
const NewPassword = lazy(() => import('@/pages/NewPassword/NewPassword'));
const NotFound = lazy(() => import('@/pages/NotFound/NotFound'));
// const Tools = lazy(() => import('@/pages/Tools/Tools'));
const ExternalAnalysis = lazy(() => import('@/pages/Tools/ExternalAnalysis/ExternalAnalysis'));
const InternalAnalysis = lazy(() => import('@/pages/Tools/InternalAnalysis/InternalAnalysis'));
const ProductAnalysis = lazy(() => import('@/pages/Tools/ProductAnalysis/ProductAnalysis'));
const ProductCard = lazy(() => import('@/pages/Tools/ProductAnalysis/ProductCard/ProductCard'));
const CategoryAnalysis = lazy(() => import('@/pages/Tools/CategoryAnalysis/CategoryAnalysis'));
const CompetitorTracking = lazy(
  () => import('@/pages/Tools/CompetitorTracking/CompetitorTracking')
);
const ProductsAnalyzer = lazy(() => import('@/pages/Tools/ProductsAnalyzer/ProductsAnalyzer'));
const ProfitAnalysis = lazy(() => import('@/pages/Tools/ProfitAnalysis&Insights/ProfitAnalysis'));
const SearchRankTracking = lazy(
  () => import('@/pages/Tools/SearchRankTracking/SearchRankTracking')
);
const FavoriteProducts = lazy(() => import('@/pages/Tools/FavoriteProducts/FavoriteProducts'));
const Seller = lazy(() => import('@/pages/Tools/Seller/Seller'));
const Connections = lazy(() => import('@/pages/Connections/Connections'));
const Offer = lazy(() => import('@/pages/Offer/Offer'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy/PrivacyPolicy'));
const Payment = lazy(() => import('@/pages/Payment/Payment'));
const ConfirmChangedEmail = lazy(() => import('@/pages/ConfirmChangedEmail/ConfirmChangedEmail'));
const PaymentSucces = lazy(() => import('@/pages/Payment/PaymentSuccess'));
const PaymentFail = lazy(() => import('@/pages/Payment/PaymentFail'));
const TrendAnalysis = lazy(() => import('@/pages/Tools/TrendAnalysis/TrendAnalysis'));
const NewProductsReport = lazy(() => import('@/pages/Tools/NewProductsReport/NewProductsReport'));
const MarginAnalysis = lazy(() => import('@/pages/Tools/MarginAnalysis/MarginAnalysis'));
const FacebookDeletion = lazy(() => import('@/pages/FacebookDeletion/FacebookDeletion'));
const SellerAudit = lazy(() => import('@/pages/Tools/SellerAudit/SellerAudit'));
const GeoAnalysis = lazy(() => import('@/pages/Tools/GeoAnalysis/GeoAnalysis'));

import { Profile } from '@/fsd/pages/Profile';

import OAuth from '@/pages/OAuth/OAuth';
import ResetPassword from '@/pages/ResetPassword/ResetPassword';
import SignIn from '@/pages/SignIn/SignIn';
import SignUp from '@/pages/SignUp/SignUp';

export interface IRoute {
  path: string;
  component: JSX.Element;
  titleTag?: string;
  descriptionTag?: string;
}

export const routes: IRoute[] = [
  { path: AppPaths.RESET + '/:token/', component: <ConfirmPassword /> },
  { path: AppPaths.CONFIRM + '/:token/', component: <ConfirmEmail /> },
  { path: AppPaths.NEW_PASSWORD, component: <NewPassword /> },
  { path: AppPaths.NOT_FOUND, component: <NotFound /> },
  { path: AppPaths.OFFER, component: <Offer /> },
  { path: AppPaths.PRIVACY_POLICY, component: <PrivacyPolicy /> },
  { path: AppPaths.CONFIRM_CHANGE_EMAIL + '/:token/', component: <ConfirmChangedEmail /> },
  { path: AppPaths.FACEBOOK_DELETION, component: <FacebookDeletion /> },
];

export const publicRoutes = (t: any): IRoute[] => [
  { path: AppPaths.LOGIN, component: <SignIn /> },
  {
    path: AppPaths.REGISTER,
    component: <SignUp />,
    titleTag: t('Sellmatica - Sign up'),
    descriptionTag: t('Sellmatica - Sign up now'),
  },
  { path: AppPaths.RESET, component: <ResetPassword /> },
  { path: AppPaths.OAUTH, component: <OAuth /> },
];

export const privateRoutes = (t: any): IRoute[] => [
  {
    path: AppPaths.HOME,
    component: <HomePage />,
    titleTag: t('Explore Analysis Tools: Free 30-Day Trial for Revenue Boost!'),
    descriptionTag: t(
      "Unlock business insights with Sellmatica's Analysis Tools. Optimize profit margins and maximize revenue during your free 30-day trial. Start now!"
    ),
  },
  { path: AppPaths.CLIENTS + '/*', component: <Clients /> },
  { path: AppPaths.PROFILE, component: <Profile /> },
  { path: AppPaths.TELEGRAM, component: <Telegram /> },
  {
    path: AppPaths.SUPPORT,
    component: <Support />,
    titleTag: t('Support - Sellmatica Analytics App'),
    descriptionTag: t(
      'Encounter issues? Visit Sellmatica’s support page for fast, reliable assistance. Get solutions to your queries and continue optimizing your business.'
    ),
  },
  { path: AppPaths.HELP + '/*', component: <Help /> },
  { path: AppPaths.ADMIN_TICKET_DETAIL, component: <TicketDetail /> },
  // { path: AppPaths.TOOLS + '/*', components: <Tools /> },
  {
    path: AppPaths.EXTERNAL_ANALYSIS + '/*',
    component: <ExternalAnalysis />,
    titleTag: t('Top External Analysis Tools for Marketplace - Sellmatica'),
    descriptionTag: t(
      "Unleash market potential with Sellmatica's external analysis tools. Delve into trends, competition, and product insights to dominate your niche."
    ),
  },
  {
    path: AppPaths.INTERNAL_ANALYSIS + '/*',
    component: <InternalAnalysis />,
    titleTag: t('Store Business Analytics Tools: Sellmatica Internal Analytics'),
    descriptionTag: t(
      "Utilize Sellmatica's business analytics tools to maximize profitability and optimize product listings. Discover how to transform your market approach!"
    ),
  },
  {
    path: AppPaths.PRODUCT_ANALYSIS,
    component: <ProductAnalysis />,
    titleTag: t('Best Marketplace Product Analysis 2024 - Sellmatica App'),
    descriptionTag: t(
      'Efficiently navigate product analysis with Sellmatica. Select your marketplace, refine categories, and unleash precise market insights today!'
    ),
  },
  { path: AppPaths.PRODUCT_CARD + '/:valueMarketplace/:id', component: <ProductCard /> },
  { path: AppPaths.SELLER + '/:valueMarketplace/:id', component: <Seller /> },
  {
    path: AppPaths.CATEGORY + '/*',
    component: <CategoryAnalysis />,
    titleTag: t('Find The Right Product Category To Sell - Start Now! - Sellmatica'),
    descriptionTag: t(
      "Enhance your market strategy with Sellmatica's category analysis tools. Dive deep into product categories, select marketplaces, and refine research."
    ),
  },
  { path: AppPaths.COMPETITOR_TRACKING + '/*', component: <CompetitorTracking /> },
  { path: AppPaths.PRODUCTS_ANALYZER + '/*', component: <ProductsAnalyzer /> },
  {
    path: AppPaths.PROFIT_ANALYSIS + '/*',
    component: <ProfitAnalysis />,
    titleTag: t('Maximize Earnings with Our Business Profitability Analysis!'),
    descriptionTag: t(
      "Enhance your business's financial health with Sellmatica's Business Profitability Analysis. Gain insights, optimize costs, and boost profits effectively."
    ),
  },
  { path: AppPaths.SEARCH_RANK_TRACKING + '/*', component: <SearchRankTracking /> },
  {
    path: AppPaths.FAVORITE + '/*',
    component: <FavoriteProducts />,
    titleTag: t('Product Research Analysis with Favorite Listings! - Sellmatica'),
    descriptionTag: t(
      'Efficiently manage your favorite products on Sellmatica. Start saving essential items today to enhance your future product research endeavors.'
    ),
  },
  {
    path: AppPaths.TREND_ANALYSIS + '/*',
    component: <TrendAnalysis />,
    titleTag: t('Advance Your Sales with Strategic Market Research Analysis!'),
    descriptionTag: t(
      'Unlock the potential of market research with Sellmatica. Choose marketplaces, select indicators, and gain strategic insights to boost your revenue.'
    ),
  },
  {
    path: AppPaths.CONNECTIONS + '/*',
    component: <Connections />,
    titleTag: t('Connections - Sellmatica Internal Analytics'),
    descriptionTag: t(
      'Access and manage your active connections on Sellmatica. Track updates, add new marketplace links, and ensure all data sources are accurately synchronized.'
    ),
  },
  { path: AppPaths.PAYMENT + '/*', component: <Payment /> },
  { path: AppPaths.SUCCESS_PAYMENT + '/*', component: <PaymentSucces /> },
  { path: AppPaths.CANCEL_PAYMENT + '/*', component: <PaymentFail /> },
  { path: AppPaths.MARGIN_ANALYSIS, component: <MarginAnalysis /> },
  {
    path: AppPaths.NEW_PRODUCTS_REPORT + '/*',
    component: <NewProductsReport />,
    titleTag: t('Analyze Latest Products with Sellmatica Trend Analysis!'),
    descriptionTag: t(
      "Explore trending products with Sellmatica's trend analysis tool. Select marketplaces and categories, and stay ahead with the latest product insights."
    ),
  },
  {
    path: AppPaths.SELLER_AUDIT,
    component: <SellerAudit />,
  },
  {
    path: AppPaths.GEO_ANALYSIS,
    component: <GeoAnalysis />,
  },
];

export const adminRoutes: IRoute[] = [
  { path: AppPaths.ADMIN_USERS, component: <Users /> },
  { path: AppPaths.ADMIN_CONTENT + '/*', component: <Content /> },
  { path: AppPaths.ADMIN_NOTIFICATIONS + '/*', component: <Notifications /> },
  { path: AppPaths.ADMIN_TICKETS, component: <Tickets /> },
  { path: AppPaths.ADMIN_TICKET_DETAIL, component: <TicketDetail /> },
  { path: AppPaths.ADMIN_ANALYTICS, component: <Analytics /> },
  { path: AppPaths.ADMIN_SETTINGS + '/*', component: <Settings /> },
];
