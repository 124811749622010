import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UNAUTHORIZED_ACCESS } from '@/fsd/shared/constants/errors';

import { getNewProductsReport } from '@/store/entities/tools/newProductsReport/newProductsReport/actions';
import {
  INewProductsReportResponse,
  NewProductsReportState,
} from '@/store/entities/tools/newProductsReport/types';

const initialState: NewProductsReportState = {
  newProductsReport: [],
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  isLoading: false,
  errorCustom: null,
  isSuccess: false,
};

export const newProductsReportSlice = createSlice({
  name: 'newProductsReport',
  initialState,
  reducers: {
    // clearTreeCategoriesState(state) {
    //   state.tree = null;
    //   // state.selectedElement = null;
    //   state.categoriesTree = {};
    // },
    setPageNewProductsReport(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload;
    },
    resetStatusNewProductsReport(state) {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [getNewProductsReport.pending.type]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    },

    [getNewProductsReport.fulfilled.type]: (
      state,
      action: PayloadAction<INewProductsReportResponse>
    ) => {
      state.newProductsReport = action.payload.products;
      state.pagination = {
        total: action.payload.pagination_data.total_items,
        page: action.payload.pagination_data.page,
        size: action.payload.pagination_data.items_per_page,
        pages: action.payload.pagination_data.total_pages,
      };
      state.isSuccess = true;
      state.isLoading = false;
    },

    [getNewProductsReport.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      if (action.payload === UNAUTHORIZED_ACCESS) state.errorCustom = UNAUTHORIZED_ACCESS;
    },
  },
});
