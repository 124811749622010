import { CSSProperties, FC } from 'react';
import { Table as MTable } from '@mantine/core';

import { useStyles } from './TableStyles';

export interface IColumn {
  id: string;
  text: string;
  style?: CSSProperties;
}

interface ITableM {
  tableInstance: {
    columns: Array<IColumn>;
    data: Array<any> | undefined;
    onRowClick?: (row: any) => void;
  };
}

const TableM: FC<ITableM> = ({ tableInstance }) => {
  const { classes } = useStyles();

  const { columns, data, onRowClick } = tableInstance;

  return (
    <div className={classes.tableWrapper}>
      <MTable
        className={classes.table}
        verticalSpacing={16}
        horizontalSpacing={16}
        highlightOnHover={!!onRowClick}
      >
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.id} style={column.style}>
                {column.text}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex} onClick={onRowClick ? () => onRowClick(row) : undefined}>
              {columns.map((column) => (
                <td key={column.id}>{row[column.id]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </MTable>
    </div>
  );
};

export default TableM;
