import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppShell, Box, Grid, MantineProvider } from '@mantine/core';

import { SelectLanguage } from '@/fsd/features/selectLanguage';
import { languages } from '@/fsd/shared/constants/languages';
import { useAnalyticEventsHook } from '@/hooks/analyticEventsHook/useAnalyticEventsHook';
import useHandleLanguageReferrer from '@/hooks/useHandleLanguageReferrer/useHandleLanguageReferrer';

import AuthImage from '@/ui/Layouts/LayoutAuth/components/AuthImage/AuthImage';
import { themeLayoutAuth } from '@/ui/Layouts/LayoutAuth/theme';

import cls from '@/ui/Layouts/LayoutAuth/LayoutAuth.module.scss';

import { getOAuthClient } from '@/store/entities/auth/actions';
import { updateProfileLanguage } from '@/store/entities/profile/profileSlice';
import { useAppDispatch } from '@/store/hooks';

interface ILayoutAuthProps {
  children: ReactNode;
  maw?: number;
  image: any;
}

const LayoutAuth: FC<ILayoutAuthProps> = ({ children, maw = 500, image }) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  useAnalyticEventsHook(true);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng.toLocaleLowerCase());
    dispatch(updateProfileLanguage(lng.toLocaleLowerCase()));
  };

  const languageChanged = useHandleLanguageReferrer();

  useEffect(() => {
    dispatch(getOAuthClient());
  }, []);

  return (
    <AppShell padding={0} className={cls.LayoutAuth}>
      {languageChanged && (
        <Grid gutter={0} mih="100%">
          <Grid.Col md={6} className={cls.authImage}>
            <AuthImage image={image} />
          </Grid.Col>
          <Grid.Col md={6}>
            <MantineProvider theme={themeLayoutAuth} inherit={true}>
              <Box className={cls.formCol}>
                <Box className={cls.headerForm}>
                  <SelectLanguage autocomplete={true} />
                </Box>
                <Box w="100%" maw={maw} mx="auto">
                  {children}
                </Box>
              </Box>
            </MantineProvider>
          </Grid.Col>
        </Grid>
      )}
    </AppShell>
  );
};

export default LayoutAuth;
