import { ReactNode } from 'react';
import { HoverCard } from '@mantine/core';

import { classNames } from '../../lib/classNames/classNames';

import cls from './DropdownMenu.module.scss';

interface DropdownMenuProps {
  className?: string;
  label?: ReactNode;
  children?: ReactNode;
}

export const DropdownMenu = ({ className, label, children }: DropdownMenuProps) => {
  return (
    <div className={classNames(cls.DropdownMenu, {}, [className])}>
      <HoverCard
        shadow="md"
        styles={() => ({
          dropdown: {
            padding: '15px',
            paddingTop: 20,
            paddingRight: 40,
            gap: 20,
            maxWidth: 400,
            width: '100%',
          },
        })}
        openDelay={200}
      >
        <HoverCard.Target>{label}</HoverCard.Target>

        {children && (
          <HoverCard.Dropdown>
            <div className={classNames(cls.dropdownHoverItems, {}, [])}>{children}</div>
          </HoverCard.Dropdown>
        )}
      </HoverCard>
    </div>
  );
};
