import { ReactNode } from 'react';
import { NavLink as NavLinkRouterDom } from 'react-router-dom';

import { classNames } from '@/fsd/shared/lib/classNames/classNames';

import cls from './NavLink.module.scss';

interface NavLinkProps {
  className?: string;
  name: string;
  href: string | undefined;
  icon?: ReactNode;
  externalLink?: boolean;
}

export const NavLink = ({ className, name, href, icon, externalLink }: NavLinkProps) => {
  if (!href) {
    return (
      <div className={classNames(className, {}, [])}>
        <div className={classNames(cls.navLink, {}, [])}>
          <span>
            {icon && <div className={cls.navLinkIcon}>{icon}</div>}
            {name}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(className, {}, [])}>
      {!externalLink ? (
        <NavLinkRouterDom
          to={href || '#'}
          className={({ isActive }: any) =>
            classNames(cls.navLink, { [cls.activeNavLink]: isActive }, [])
          }
        >
          <span>
            {icon && <div className={cls.navLinkIcon}>{icon}</div>}
            <div>{name}</div>
          </span>
        </NavLinkRouterDom>
      ) : (
        <a href={href} target={'_blank'} className={cls.navLink}>
          <span>
            {icon && <div className={cls.navLinkIcon}>{icon}</div>}
            <div>{name}</div>
          </span>
        </a>
      )}
    </div>
  );
};
