import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';

import Layout from '@/ui/Layouts/Layout/Layout';

import ProfileChangeLanguage from '@/pages/Profile/ProfileChangeLanguage';
import ProfileChangePassword from '@/pages/Profile/ProfileChangePassword';
import ProfileInfo from '@/pages/Profile/ProfileInfo';
import TariffPlans from '@/pages/Profile/ProfileTariff/TariffPlans';

export const Profile = () => {
  const { t } = useTranslation('APPCON');

  const navigate = useNavigate();
  const { profileTabValue } = useParams();

  const tabConfig = [
    {
      value: 'info',
      label: t('appcon036-1A-1'),
      content: <ProfileInfo />,
    },
    {
      value: 'tariff',
      label: t('appcon036-1B-20'),
      content: <TariffPlans />,
    },
    {
      value: 'change-password',
      label: t('appcon036-1C-38'),
      content: <ProfileChangePassword />,
    },
    {
      value: 'language',
      label: t('appcon036-1D-41'),
      content: <ProfileChangeLanguage />,
    },
  ];

  return (
    <Layout>
      <Tabs value={profileTabValue} onTabChange={(value) => navigate(`/profile/${value}`)}>
        <Tabs.List>
          {tabConfig.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value} fz={16}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabConfig.map((tab) => (
          <Tabs.Panel key={tab.value} value={tab.value} pt="xs">
            <div>{tab.content}</div>
          </Tabs.Panel>
        ))}
      </Tabs>
    </Layout>
  );
};
