import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentMethodPaymentSystemEnum } from '@/api/swaggerApi';

import { TariffFiltersState } from '@/store/entities/tariff/types';

const initialState: TariffFiltersState = {
  paymentSystem: PaymentMethodPaymentSystemEnum.StripePayment,
  selectedTariffValue: 'plan30days',
  selectedCurrency: '',
};

export const tariffFiltersSlice = createSlice({
  name: 'tariffFilters',
  initialState,
  reducers: {
    setPaymentSystem: (state, action: PayloadAction<PaymentMethodPaymentSystemEnum>) => {
      state.paymentSystem = action.payload;
    },
    setSelectedTariffValue: (state, action: PayloadAction<string>) => {
      state.selectedTariffValue = action.payload;
    },
    setSelectedCurrency: (state, action: PayloadAction<string>) => {
      state.selectedCurrency = action.payload;
    },
    resetTariffValuesState: () => initialState,
  },

  extraReducers: {
    //Список тарифных планов
  },
});
