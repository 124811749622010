import React from 'react';

import { classNames } from '@/fsd/shared/lib/classNames/classNames';

import cls from './Bell.module.scss';

import { ReactComponent as BellIcon } from '@/assets/icons/bell.svg';
import { ReactComponent as BellCircleIcon } from '@/assets/icons/bellCircle.svg';

interface BellProps {
  className?: string;
  countNotif?: number;
}

export const Bell = ({ className, countNotif }: BellProps) => {
  return !countNotif ? (
    <div className={classNames(cls.Bell, {}, [className])}>
      {/*<img width={'100%'} src={BellIcon} alt="" />*/}
      <BellIcon width={'100%'} height={'100%'} />
    </div>
  ) : (
    <div className={classNames(cls.Bell, {}, [className])}>
      <BellCircleIcon width={'100%'} height={'100%'} />
    </div>
  );
};
