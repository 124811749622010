import { useMediaQuery } from '@mantine/hooks';

import { BREAKPOINTS } from '@/fsd/shared/constants/breakpoints';

export const useGetScreenSize = () => {
  const xlScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`);
  const lgScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`);
  const mdScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`);
  const smScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`);
  const xsScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xs}px)`);

  return { xlScreen, lgScreen, mdScreen, smScreen, xsScreen };
};
