import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { geoAnalysis } from '@/api/parser/geoAnalysis';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

import {
  GetGeoAnalysisReportForLocationParams,
  GetGeoAnalysisReportForLocationResponse,
  GetGeoAnalysisReportParams,
  GetGeoAnalysisReportResponse,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/types';

export const getGeoAnalysisReport = createAsyncThunk<
  GetGeoAnalysisReportResponse,
  GetGeoAnalysisReportParams,
  {
    rejectValue: string;
  }
>('geoAnalysis/getGeoAnalysisReport', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await geoAnalysis.getGeoAnalysisReport(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getGeoAnalysisReportForLocation = createAsyncThunk<
  GetGeoAnalysisReportForLocationResponse,
  GetGeoAnalysisReportForLocationParams,
  {
    rejectValue: string;
  }
>('geoAnalysis/getGeoAnalysisReportForLocation', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await geoAnalysis.getGeoAnalysisReportForLocation(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
