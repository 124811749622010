import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { sellerAuditApi } from '@/api/parser/sellerAuditApi';

import { ISellerAudit } from '@/store/entities/tools/sellerAudit/types';

export const getSellerAudit = createAsyncThunk<
  ISellerAudit,
  { sellerLink: string; lang: string },
  {
    rejectValue: string;
  }
>('sellerAudit/getSellerAudit', async (options, { rejectWithValue }) => {
  try {
    // setCurrentCurrencyByMarketplace(options.marketplace);
    const argNew = (): string => {
      switch (options.lang) {
        case 'my':
          return 'ms';
        case 'cn':
          return 'zh';
        case 'vn':
          return 'vi';
        default:
          return options.lang;
      }
    };
    const { data } = await sellerAuditApi.getSellerAuditApi(options.sellerLink, argNew());
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data || statusText);
  }
});
