import { FC } from 'react';
import { ActionIcon, Group, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';

import { dateToDDMMYYYY } from '@/utils/helpers';

import styles from './NotificationsItem.module.scss';

interface INotificationsItem {
  id: number;
  date: string;
  title: string;
  text?: string;
  read: boolean;
  onClickRead: (id: number) => void;
  onClickDelete: (id: number) => void;
}

const NotificationsItem: FC<INotificationsItem> = ({
  id,
  date,
  title,
  text,
  read,
  onClickRead,
  onClickDelete,
}) => {
  return (
    <>
      <Group position="apart">
        <Text color="gray" size="xs">
          {dateToDDMMYYYY(date)}
        </Text>
        {!read && (
          <ActionIcon
            className={styles.badgeBtn}
            radius="xl"
            size={16}
            onClick={() => onClickRead(id)}
          >
            <span className={styles.badge}></span>
          </ActionIcon>
        )}
        <ActionIcon
          className={styles.removeBtn}
          radius="xl"
          size={20}
          onClick={() => onClickDelete(id)}
        >
          <Trash />
        </ActionIcon>
      </Group>
      <Text weight={500}>{title}</Text>
      {text && <Text size="sm">{text}</Text>}
    </>
  );
};

export default NotificationsItem;
