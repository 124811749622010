import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Sessions } from 'src/store/types/ISession';

const HOST = process.env.REACT_APP_HOST;

import { Api } from '@/api/apiService';
import { userTokens } from '@/api/parser/userTokens';
import {
  InlineObject,
  SignInUserSchema,
  SignUpUserSchema,
  SignUpUserSchemaLocaleEnum,
} from '@/api/swaggerApi';

import { getLocaleForApiParam } from '@/utils/helpers';

import { api } from '@/fsd/shared/api';

export const profile = createAsyncThunk('auth/profile', async (arg, thunkAPI) => {
  try {
    const response = await Api.Auth.apiAuthProfileGet();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export interface RegisterSchema {
  email: string;
  phone_number: string;
  receive_news: boolean;
  region?: string;
  password: string;
  locale: SignUpUserSchemaLocaleEnum;
}

export const register = createAsyncThunk('auth/register', async (arg: RegisterSchema, thunkAPI) => {
  try {
    const locale = getLocaleForApiParam(arg.locale);
    const response = await api.service.request({
      method: 'post',
      url: `api/auth/register/`,
      data: {
        ...arg,
        locale: locale as SignUpUserSchemaLocaleEnum,
        host: HOST,
      },
    });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const login = createAsyncThunk('auth/login', async (arg: SignInUserSchema, thunkAPI) => {
  try {
    const response = await Api.Auth.apiAuthLoginPost({ email: arg.email, password: arg.password });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (arg: InlineObject, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthResetPost({ email: arg.email, host: HOST });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

interface IConfirmEmailByTokenArg {
  token: string;
}

export const confirmEmailByToken = createAsyncThunk(
  'auth/confirmEmailByToken',
  async (arg: IConfirmEmailByTokenArg, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthConfirmTokenGet(arg.token);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export interface ConfirmTokenForChangePasswordArg {
  token: string;
}

export const confirmTokenForChangePassword = createAsyncThunk(
  'auth/confirmTokenForChangePassword',
  async (arg: ConfirmTokenForChangePasswordArg, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthResetTokenGet(arg.token);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

interface ISetNewPasswordArg {
  token: string | null;
  password1: string;
  password2: string;
}

export const setNewPassword = createAsyncThunk(
  'auth/setNewPassword',
  async (arg: ISetNewPasswordArg, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthResetTokenPost(arg.token || '', {
        password1: arg.password1,
        password2: arg.password2,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const logoutUserAction = createAsyncThunk('auth/logout', async (arg, thunkAPI) => {
  try {
    const response = await Api.Auth.apiAuthLogoutGet();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const getSessions = createAsyncThunk('auth/getSessions', async (arg: Sessions, thunkAPI) => {
  try {
    const response = await Api.Auth.apiAuthListSessionPageGet(
      arg.page,
      arg.user_id,
      arg.is_active,
      arg.items_per_page
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const deactivateAllSessionsExceptOne = createAsyncThunk(
  'auth/deactivateAllSessionsExceptOne',
  async (arg: string, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthSessionDeactivateSessionIdPatch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const changePhoneNumber = createAsyncThunk(
  'auth/changePhoneNumber',
  async (arg: string, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthChangePhoneNumberPost({ new_phone_number: arg });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const changeEmail = createAsyncThunk('auth/changeEmail', async (arg: string, thunkAPI) => {
  try {
    const response = await Api.Auth.apiAuthChangeEmailPost({ new_email: arg, host: HOST });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export interface IConfirmChangedEmailByTokenArg {
  token: string;
}

export const ConfirmChangedEmailByToken = createAsyncThunk(
  'auth/ConfirmChangedEmailByToken',
  async (arg: IConfirmChangedEmailByTokenArg, thunkAPI) => {
    try {
      const response = await Api.Auth.apiAuthConfirmChangeEmailTokenGet(arg.token);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getOAuthClient = createAsyncThunk('auth/getOAuthClient', async (arg, thunkAPI) => {
  try {
    const response = await Api.OAuth.apiAuthOauthClientsGet();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const authUserWithOAuth = createAsyncThunk(
  'auth/authUserWithOAuth',
  async (arg: { clientName: string }, thunkAPI) => {
    try {
      const response = await Api.OAuth.apiAuthOauthClientNameGet(arg.clientName);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getUserUsageLimitTokens = createAsyncThunk(
  'auth/getUserUsageLimitTokens',
  async (arg: { endpointId: number }, thunkAPI) => {
    try {
      const response = await userTokens.getUserUsageLimitTokens({ endpoint_id: arg.endpointId });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
