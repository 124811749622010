import { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMetaTags } from 'react-metatags-hook';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { TokenService } from '@/api/TokenService';

interface Props {
  children: JSX.Element;
  description?: string;
  title?: string;
}

const PublicRoutes: FC<Props> = ({ children, title, description }) => {
  const token = TokenService.getToken();

  const location = useLocation();

  useMetaTags(
    {
      title: title || 'Sellmatica',
      description: description || '',
    },
    [location.pathname]
  );

  return !token ? children : <Navigate to={AppPaths.HOME} />;
};

export default PublicRoutes;
