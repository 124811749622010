import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { getLocations } from '@/store/entities/locations/actions';
import { LocationsStatus } from '@/store/entities/locations/const';
import { GetLocationsResponse, LocationsState } from '@/store/entities/locations/types';

const initialState: LocationsState = {
  isError: null,
  isLoading: null,
  isSuccess: null,
  locations: [],
  pagination: null,
};
export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    // setPlatformConnections(state, action: PayloadAction<string>) {
    //   state.selectedPlatform = action.payload;
    // },
    // setRegionConnections(state, action: PayloadAction<string>) {
    //   state.selectedRegion = action.payload;
    // },
    resetConnectionsState: () => initialState,
  },
  extraReducers: {
    [getLocations.pending.type]: (state) => {
      state.isError = null;
      state.isSuccess = null;
      state.isLoading = LocationsStatus.GET_LOCATIONS;
    },
    [getLocations.fulfilled.type]: (state, action: PayloadAction<GetLocationsResponse>) => {
      state.isError = null;
      state.isSuccess = LocationsStatus.GET_LOCATIONS;
      state.isLoading = null;
      if (action.payload.pagination_data.page === 1) {
        state.locations = action.payload.locations.map((item) => item.location);
      } else {
        state.locations = [
          ...state.locations,
          ...action.payload.locations.map((item) => item.location),
        ];
      }
      state.pagination = action.payload.pagination_data;
    },
    [getLocations.rejected.type]: (state, action) => {
      state.isError = LocationsStatus.GET_LOCATIONS;
      state.isSuccess = null;
      state.isLoading = null;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },
  },
});
