import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { locations } from '@/api/parser/locations';

import { GetLocationsParams, GetLocationsResponse } from '@/store/entities/locations/types';

export const getLocations = createAsyncThunk<
  GetLocationsResponse,
  GetLocationsParams,
  {
    rejectValue: string;
  }
>('locations/getLocations', async (options, { rejectWithValue }) => {
  try {
    const { data } = await locations.getLocations(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
