import {
  newProductsReportActions,
  newProductsReportReducer,
} from '@/store/entities/tools/newProductsReport/newProductsReport';
import {
  newProductsReportFiltersActions,
  newProductsReportFiltersReducer,
} from '@/store/entities/tools/newProductsReport/newProductsReportFilters';

export const newProductsReportCombineReducer = {
  newProductsReport: newProductsReportReducer,
  newProductsReportFilters: newProductsReportFiltersReducer,
};

export const newProductsReportActionsCombine = {
  ...newProductsReportActions,
  ...newProductsReportFiltersActions,
};
