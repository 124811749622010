import { combineReducers } from 'redux';
import { internalAnalyticsCombineReducer } from 'src/store/entities/tools/internalAnalytics';

import authReducer from './entities/auth/authSlice';
import marketplacesReducer from './entities/marketplaces';
import noticesReducer from './entities/notices';
import notificationsReducer from './entities/notifications';
import profileReducer from './entities/profile/profileSlice';
import telegramReducer from './entities/telegram/telegramSlice';
import ticketReducer from './entities/ticket/ticketSlice';
import categoriesReducer from './entities/tools/categories';
import productsReducer from './entities/tools/products';
import sellerReducer from './entities/tools/seller';

import { connectionsReducer } from '@/store/entities/connections';
import { locationsReducer } from '@/store/entities/locations';
import { tariffCombineReducer } from '@/store/entities/tariff';
import { treeCategoriesReducer } from '@/store/entities/tools/categories/tree-categories';
import { categoryAnalysisReducer } from '@/store/entities/tools/categoryAnalysis';
import { geoAnalysisCombineReducer } from '@/store/entities/tools/geoAnalysis';
import { newProductsReportCombineReducer } from '@/store/entities/tools/newProductsReport';
import { sellerAuditReducer } from '@/store/entities/tools/sellerAudit';
import { trendAnalysisReducer } from '@/store/entities/tools/trendAnalysis';
import { usersCombineReducer } from '@/store/entities/users';

// ROOT REDUCER EXAMPLE

// 1. Import your reducers from entities

// import cartReducer from './entities/cart/reducers';

// 2. Define reducers into common object
const rootReducer = combineReducers({
  auth: authReducer,
  telegram: telegramReducer,
  notices: noticesReducer,
  notifications: notificationsReducer,
  ticket: ticketReducer,
  profile: profileReducer,
  marketplaces: marketplacesReducer,
  products: productsReducer,
  categories: categoriesReducer,
  seller: sellerReducer,
  categoryAnalysis: categoryAnalysisReducer,
  treeCategories: treeCategoriesReducer,
  trendAnalysis: trendAnalysisReducer,
  sellerAudit: sellerAuditReducer,
  connections: connectionsReducer,
  locations: locationsReducer,
  ...newProductsReportCombineReducer,
  ...usersCombineReducer,
  ...tariffCombineReducer,
  ...internalAnalyticsCombineReducer,
  ...geoAnalysisCombineReducer,
});

export default rootReducer;
export type IRootReducer = ReturnType<typeof rootReducer>;
