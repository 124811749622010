import {
  brandsFiltersAdapter,
  categoryListFiltersAdapter,
  connectionsFiltersAdapter,
} from '@/store/entities/tools/internalAnalytics/internalAnalyticsFilters/internalAnalyticsFiltersAdapter';
import { IRootReducer } from '@/store/rootReducer';

export const connectionsAdapterSelectors = connectionsFiltersAdapter.getSelectors<IRootReducer>(
  (state) => state.internalAnalyticsFilters.connections
);

export const brandsAdapterSelectors = brandsFiltersAdapter.getSelectors<IRootReducer>(
  (state) => state.internalAnalyticsFilters.brands
);

export const categoryListAdapterSelectors = categoryListFiltersAdapter.getSelectors<IRootReducer>(
  (state) => state.internalAnalyticsFilters.categoryList
);
