import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '@/fsd/shared/api';

import {
  CreateNoticeData,
  GetNoticesByUrlData,
  GetNoticesParams,
  NoticesState,
  SetNoticeActivityData,
  UpdateNoticeData,
} from './types';

export const getNoticesTypes = createAsyncThunk<any, void, { rejectValue: string }>(
  'notices/getNoticesTypes',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'get',
        url: 'api/notices/type_notice/list-by-get/',
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getNotices = createAsyncThunk<any, GetNoticesParams, { rejectValue: string }>(
  'notices/getNotices',
  async (params: GetNoticesParams, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'get',
        url: 'api/notices/notice/list-by-get/',
        params: {
          page: params.page,
          items_per_page: params.items_per_page,
        },
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const createNotice = createAsyncThunk<any, CreateNoticeData, { rejectValue: string }>(
  'notices/createNotice',
  async (formData, { getState, dispatch, rejectWithValue }) => {
    try {
      const {
        notices: { pagination },
      } = getState() as { notices: NoticesState };

      const { data } = await api.service.request({
        method: 'post',
        url: 'api/notices/notice/add-notice/',
        data: formData,
      });

      dispatch(getNotices({ page: pagination?.page, items_per_page: pagination?.items_per_page }));

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const updateNotice = createAsyncThunk<any, UpdateNoticeData, { rejectValue: string }>(
  'notices/updateNotice',
  async (formData, { getState, dispatch, rejectWithValue }) => {
    try {
      const {
        notices: { pagination },
      } = getState() as { notices: NoticesState };

      const { data } = await api.service.request({
        method: 'post',
        url: 'api/notices/notice/update-notice/',
        data: formData,
      });
      dispatch(getNotices({ page: pagination?.page, items_per_page: pagination?.items_per_page }));

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteNotice = createAsyncThunk<any, number, { rejectValue: string }>(
  'notices/deleteNotice',
  async (id: number, { getState, dispatch, rejectWithValue }) => {
    try {
      const {
        notices: { pagination },
      } = getState() as { notices: NoticesState };
      const page =
        pagination &&
        pagination.prev_page !== null &&
        !pagination.has_next &&
        pagination.total_items % pagination.items_per_page === 1
          ? pagination.prev_page
          : pagination?.page;

      const { data } = await api.service.request({
        method: 'delete',
        url: `api/notices/notice/delete-notice/${id}/`,
      });
      dispatch(getNotices({ page, items_per_page: pagination?.items_per_page }));

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const setNoticeActivity = createAsyncThunk<
  any,
  SetNoticeActivityData,
  { rejectValue: string }
>('notices/setNoticeActivity', async (formData, { rejectWithValue }) => {
  try {
    const { data } = await api.service.request({
      method: 'patch',
      url: 'api/notices/notice/set-status/',
      data: formData,
    });
    return { ...formData, ...data };
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getNoticesByUrl = createAsyncThunk<any, GetNoticesByUrlData, { rejectValue: string }>(
  'notices/getNoticesByUrl',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await api.service.request({
        method: 'post',
        url: 'api/notices/notice/info/',
        data: formData,
      });
      return { ...formData, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);
