import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR, UNAUTHORIZED_ACCESS } from '@/fsd/shared/constants/errors';
import {
  MarketplaceSelectValue,
  NOT_SELECTED_MARKETPLACE,
} from '@/fsd/shared/constants/marketplaces';

import {
  createFavoriteProduct,
  deleteFavoriteProduct,
  getExtermum,
  getFavoriteProducts,
  getProductCard,
  getProducts,
  getSellerLocations,
  getSellers,
} from './actions';
import { FiltersDetail, ProductsState } from './types';

import { ISelectedRubricatorItem } from '@/store/entities/tools/categories/types';
import {
  CREATE_FAVORITE_PRODUCT,
  defaultFilterParameterValues,
  DELETE_FAVORITE_PRODUCT,
  FORBIDDEN_RESOURCES,
  GET_FAVORITE_PRODUCTS,
  GET_PRODUCTS,
  SORT_BY_DEFAULT,
  SORT_ORDER_DEFAULT,
} from '@/store/entities/tools/products/constants';

const initialState: ProductsState = {
  marketplace: NOT_SELECTED_MARKETPLACE,
  selectedProductCategories: [],
  results: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  isLoadingCustom: null,
  isSuccessCustom: null,
  isErrorCustom: null,
  message: null,
  page: 1,
  totalItems: 0,
  itemsPerPage: 0,
  extremumValues: null,
  sellers: [],
  sellerLocations: [],
  hasNext: true,
  currentProductCard: null,
  favoriteProducts: [],

  filterParameters: defaultFilterParameterValues,
  filters: defaultFilterParameterValues,
  sortBy: SORT_BY_DEFAULT,
  sortOrder: SORT_ORDER_DEFAULT,
  updateFilters: null,

  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
};
const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setCustomFlags(
      state,
      action: PayloadAction<{
        isLoadingCustom?: string | null;
        isSuccessCustom?: string | null;
        isErrorCustom?: string | null;
      }>
    ) {
      if (action.payload.isSuccessCustom !== undefined) {
        state.isSuccessCustom = action.payload.isSuccessCustom;
      }
      if (action.payload.isErrorCustom !== undefined) {
        state.isErrorCustom = action.payload.isErrorCustom;
      }
      if (action.payload.isLoadingCustom !== undefined) {
        state.isLoadingCustom = action.payload.isLoadingCustom;
      }
    },

    clearProductCard(state) {
      state.currentProductCard = null;
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = null;
    },

    clearProducts(state) {
      state.results = [];
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = null;
    },

    setFilterParameters(state, action: PayloadAction<FiltersDetail>) {
      state.filterParameters = action.payload;
    },
    setFilters(state, action: PayloadAction<FiltersDetail>) {
      state.filters = action.payload;
    },

    setSortBy(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },
    setSortOrder(state, action: PayloadAction<string>) {
      state.sortOrder = action.payload;
    },
    resetChangeMarketplace(state) {
      state.pagination = {
        total: 0,
        page: 1,
        size: 10,
        pages: 0,
      };

      state.message = null;
      state.page = 1;
      state.totalItems = 0;
      state.itemsPerPage = 0;
      state.extremumValues = null;
      state.sellers = [];
      state.sellerLocations = [];
      state.hasNext = true;
      state.currentProductCard = null;
      state.favoriteProducts = [];
      state.results = [];

      state.filterParameters = defaultFilterParameterValues;
      state.updateFilters = !state.updateFilters;
      state.sortBy = SORT_BY_DEFAULT;
      state.sortOrder = SORT_ORDER_DEFAULT;
    },
    setUpdateFilters(state) {
      state.updateFilters = null;
    },
    setMarketplace(state, action: PayloadAction<MarketplaceSelectValue>) {
      state.marketplace = action.payload;
    },
    setSelectedProductCategories(state, action: PayloadAction<ISelectedRubricatorItem[]>) {
      state.selectedProductCategories = action.payload;
    },
    resetStatusFavoriteProducts(state) {
      state.isSuccess = false;
    },
    setPageProductAnalysisTable(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload;
    },
    setPageSizeProductAnalysisTable(
      state,
      action: PayloadAction<{
        pageSize: number;
        resetPage?: boolean;
      }>
    ) {
      state.pagination.size = action.payload.pageSize;
      state.pagination.page = action.payload.resetPage === false ? state.pagination.page : 1;
    },
  },
  extraReducers: (builder) => {
    // getProducts
    builder.addCase(getProducts.pending, (state) => {
      state.isLoadingCustom = GET_PRODUCTS;
      state.isSuccess = false;
      state.isSuccessCustom = null;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoadingCustom = null;
      state.isSuccessCustom = GET_PRODUCTS;
      state.isError = false;
      state.isSuccess = true;
      state.results = action.payload.results;
      // state.page = action.payload.pagination.page;
      // state.totalItems = action.payload.pagination.total_items;
      // state.itemsPerPage = action.payload.pagination.items_per_page;
      state.pagination = {
        total: action.payload.pagination.total_items,
        pages: action.payload.pagination.total_pages,
        page: action.payload.pagination.page,
        size: action.payload.pagination.items_per_page,
      };
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isLoadingCustom = null;
      state.isError = true;
      state.isSuccess = false;
      state.results = [];
      if (action.payload === UNAUTHORIZED_ACCESS) state.isErrorCustom = FORBIDDEN_RESOURCES;
      else showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
      state.message = action.payload || SYSTEM_ERROR;
      state.page = 1;
      state.totalItems = 0;
      state.itemsPerPage = 0;
    });

    // getExtermum
    builder.addCase(getExtermum.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExtermum.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.extremumValues = action.payload;
    });
    builder.addCase(getExtermum.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    });

    // getSellers
    builder.addCase(getSellers.pending, (state) => {
      state.isError = false;
    });
    builder.addCase(getSellers.fulfilled, (state, action) => {
      state.isError = false;
      state.page = action.payload.pagination_data.page;
      state.hasNext = action.payload.pagination_data.has_next;
      if (state.page === 1) {
        state.sellers = action.payload.sellers;
      } else {
        state.sellers = state.sellers.concat(action.payload.sellers);
      }
    });
    builder.addCase(getSellers.rejected, (state, action) => {
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    });

    //  getSellerLocations
    builder.addCase(getSellerLocations.pending, (state) => {
      state.isError = false;
    });
    builder.addCase(getSellerLocations.fulfilled, (state, action) => {
      state.isError = false;
      state.page = action.payload.pagination_data.page;
      state.hasNext = action.payload.pagination_data.has_next;
      if (state.page === 1) {
        state.sellerLocations = action.payload.locations;
      } else {
        state.sellerLocations = state.sellerLocations.concat(action.payload.locations);
      }
    });
    builder.addCase(getSellerLocations.rejected, (state, action) => {
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    });

    //  getProductCard
    builder.addCase(getProductCard.pending, (state) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(getProductCard.fulfilled, (state, action) => {
      state.currentProductCard = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(getProductCard.rejected, (state, action) => {
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
      state.isLoading = false;
      if (action.payload === UNAUTHORIZED_ACCESS) state.isErrorCustom = FORBIDDEN_RESOURCES;
    });

    // createFavoriteProduct
    builder.addCase(createFavoriteProduct.pending, (state, action) => {
      state.isErrorCustom = null;
      state.isLoadingCustom = CREATE_FAVORITE_PRODUCT;
      if (state.currentProductCard) {
        state.currentProductCard.is_favorite = true;
      }
      if (state.results.length !== 0) {
        state.results = state.results.map((result) => {
          if (result.id === action.meta.arg.product_id) return { ...result, is_favorite: true };
          else return result;
        });
      }
    });
    builder.addCase(createFavoriteProduct.fulfilled, (state, action) => {
      state.isLoadingCustom = null;
      state.isSuccessCustom = CREATE_FAVORITE_PRODUCT;
      if (state.currentProductCard) {
        state.currentProductCard.is_favorite = true;
      }
      if (state.results.length !== 0) {
        state.results = state.results.map((result) => {
          if (result.id === action.meta.arg.product_id) return { ...result, is_favorite: true };
          else return result;
        });
      }
    });
    builder.addCase(createFavoriteProduct.rejected, (state, action) => {
      state.isErrorCustom = CREATE_FAVORITE_PRODUCT;
      state.message = action.payload || SYSTEM_ERROR;
      state.isLoadingCustom = null;
      if (state.currentProductCard) {
        state.currentProductCard.is_favorite = false;
      }
      if (state.results.length !== 0) {
        state.results = state.results.map((result) => {
          if (result.id === action.meta.arg.product_id) return { ...result, is_favorite: false };
          else return result;
        });
      }
    });

    // deleteFavoriteProduct
    builder.addCase(deleteFavoriteProduct.pending, (state, action) => {
      state.isErrorCustom = null;
      state.isLoadingCustom = DELETE_FAVORITE_PRODUCT;
      if (state.currentProductCard) {
        state.currentProductCard.is_favorite = false;
      }
      if (state.results.length !== 0) {
        state.results = state.results.map((result) => {
          if (result.id === action.meta.arg.product_id) return { ...result, is_favorite: false };
          else return result;
        });
      }
    });
    builder.addCase(deleteFavoriteProduct.fulfilled, (state, action) => {
      state.isLoadingCustom = null;
      state.isSuccessCustom = DELETE_FAVORITE_PRODUCT;
      if (state.currentProductCard) {
        state.currentProductCard.is_favorite = false;
      }
      if (state.results.length !== 0) {
        state.results = state.results.map((result) => {
          if (result.id === action.meta.arg.product_id) return { ...result, is_favorite: false };
          else return result;
        });
      }
    });
    builder.addCase(deleteFavoriteProduct.rejected, (state, action) => {
      state.isErrorCustom = DELETE_FAVORITE_PRODUCT;
      state.message = action.payload || SYSTEM_ERROR;
      state.isLoadingCustom = null;
      if (state.currentProductCard) {
        state.currentProductCard.is_favorite = true;
      }
      if (state.results.length !== 0) {
        state.results = state.results.map((result) => {
          if (result.id === action.meta.arg.product_id) return { ...result, is_favorite: true };
          else return result;
        });
      }
    });

    // getFavoriteProducts
    builder.addCase(getFavoriteProducts.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getFavoriteProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.totalItems = action.payload.pagination_data.total_items;
      state.itemsPerPage = action.payload.pagination_data.items_per_page;
      state.favoriteProducts = action.payload.products.map((item) => item.root_product);
      state.isSuccessCustom = GET_FAVORITE_PRODUCTS;

      if (state.results.length !== 0) {
        let favProdId = state.favoriteProducts.map((item) => item.id);
        state.results = state.results.map((product) =>
          favProdId.includes(product.id)
            ? {
                ...product,
                is_favorite: true,
              }
            : product
        );
      }
    });
    builder.addCase(getFavoriteProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    });
  },
});

export const {
  clearProductCard,
  clearProducts,
  setFilterParameters,
  setPageProductAnalysisTable,
  setSortBy,
  setSortOrder,
  resetChangeMarketplace,
  setMarketplace,
  setSelectedProductCategories,
  setUpdateFilters,
  setFilters,
  setCustomFlags,
  resetStatusFavoriteProducts,
  setPageSizeProductAnalysisTable,
} = productsSlice.actions;

export default productsSlice.reducer;
