import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ActionIcon, Button, Divider, Group, Loader, Popover, Text, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Bell } from 'tabler-icons-react';

import NotificationsItem from '../NotificationsItem/NotificationsItem';

import styles from './NotificationsPopover.module.scss';

import { useActions, useAppSelector } from '@/store/hooks';

const NotificationsPopover: FC<{ bell: ReactNode }> = ({ bell }) => {
  const { t } = useTranslation('APPCON');

  const {
    notifications,
    loading,
    error,
    message,
    isCreateNotification,
    isDeleteNotification,
    hasNext,
  } = useAppSelector((store) => store.notifications);

  const {
    getNotifications,
    readNotifications,
    deleteNotifications,
    readNotificationById,
    deleteNotificationById,
    recoveryNotificationsState,
  } = useActions();

  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = () => {
    setCurrentPage(currentPage + 1);

    setTimeout(() => {
      if (hasNext) {
        getNotifications({ page: currentPage, limit: 5 });
      }
    }, 1000);
  };

  useEffect(() => {
    if (isDeleteNotification || isCreateNotification) {
      getNotifications({ page: 1, limit: 5 });
      setCurrentPage(2);
    }
  }, [isDeleteNotification, isCreateNotification]);

  useEffect(() => {
    getNotifications({ page: currentPage, limit: 5 });
    setCurrentPage(currentPage + 1);
  }, []);

  useEffect(() => {
    if (message) {
      showNotification({ message: message, color: 'green' });
      recoveryNotificationsState();
    }
    if (error) {
      showNotification({ message: error, color: 'red' });
      recoveryNotificationsState();
    }
  }, [error, message]);

  return (
    <>
      <Popover width={400} position="bottom" shadow="md" offset={10}>
        <Popover.Target>
          <ActionIcon radius="xl" size={28} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
            {bell}
            {!!notifications?.filter((item) => !item.read)?.length && (
              <span className={styles.badge}></span>
            )}
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown className={styles.dropdown}>
          <Group className={styles.header} position="apart" mb={30}>
            <Title order={4}>{`${t('appcon040-0A-28')}`}</Title>

            {notifications?.length > 0 && (
              <Group spacing="xs">
                <Button
                  className={styles.removeBtn}
                  variant="subtle"
                  color="gray"
                  size="xs"
                  compact
                  onClick={() => deleteNotifications({ user_notification_ids: [] })}
                >
                  {t('Delete all')}
                </Button>
                <Button variant="subtle" color="gray" size="xs" compact onClick={readNotifications}>
                  {t('Read all')}
                </Button>
              </Group>
            )}
          </Group>

          {loading && <Loader variant="dots" />}
          {!notifications?.length ? (
            <Text>{t('appcon040-0A-29')}</Text>
          ) : (
            <InfiniteScroll
              height={450}
              className={styles.content}
              dataLength={notifications.length}
              next={fetchData}
              hasMore={hasNext}
              loader={<Loader variant="dots" />}
            >
              {notifications?.map((item, index) => (
                <React.Fragment key={index}>
                  <NotificationsItem
                    id={item.id}
                    date={item.notification.sent_at}
                    title={item.notification.title}
                    text={item.notification.body}
                    read={item.read}
                    onClickRead={readNotificationById}
                    onClickDelete={deleteNotificationById}
                  />
                  {index !== notifications.length - 1 && <Divider my="lg" color="#bfcedb" />}
                </React.Fragment>
              ))}
            </InfiniteScroll>
          )}
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
