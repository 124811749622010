/* tslint:disable */
/* eslint-disable */
/**
 * Sellmatica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface APINotificationBinary
 */
export interface APINotificationBinary {
  /**
   *
   * @type {string}
   * @memberof APINotificationBinary
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof APINotificationBinary
   */
  result?: boolean;
  /**
   *
   * @type {string}
   * @memberof APINotificationBinary
   */
  user_id?: string;
  /**
   *
   * @type {number}
   * @memberof APINotificationBinary
   */
  user_notification_id?: number;
}
/**
 *
 * @export
 * @interface APINotificationSent
 */
export interface APINotificationSent {
  /**
   *
   * @type {Array<APINotificationBinary>}
   * @memberof APINotificationSent
   */
  failures?: Array<APINotificationBinary>;
  /**
   *
   * @type {string}
   * @memberof APINotificationSent
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof APINotificationSent
   */
  result?: boolean;
  /**
   *
   * @type {Array<APINotificationBinary>}
   * @memberof APINotificationSent
   */
  success?: Array<APINotificationBinary>;
}
/**
 *
 * @export
 * @interface APINotificationUpdated
 */
export interface APINotificationUpdated {
  /**
   *
   * @type {Array<APINotificationBinary>}
   * @memberof APINotificationUpdated
   */
  failures?: Array<APINotificationBinary>;
  /**
   *
   * @type {string}
   * @memberof APINotificationUpdated
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof APINotificationUpdated
   */
  result?: boolean;
  /**
   *
   * @type {Array<APINotificationBinary>}
   * @memberof APINotificationUpdated
   */
  success?: Array<APINotificationBinary>;
}
/**
 *
 * @export
 * @interface AbcAnalyze
 */
export interface AbcAnalyze {
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  brand_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  category_id?: string;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  client_uuid?: string;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  cost_s?: number;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  currency?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AbcAnalyze
   */
  extra_supply?: boolean;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  full_count?: number;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  image?: string | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  margin?: number;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  margin_category?: string;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  marketplace_id?: number;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  mp_account_id?: number;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  mp_product_info_id?: string;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof AbcAnalyze
   */
  product_codes?: { [key: string]: Array<string> };
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  profitability?: number;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  sales?: number | null;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyze
   */
  sales_category?: string;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  sales_qty?: number | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyze
   */
  turnover?: number;
}
/**
 *
 * @export
 * @interface AbcAnalyzeCategoryItem
 */
export interface AbcAnalyzeCategoryItem {
  /**
   *
   * @type {string}
   * @memberof AbcAnalyzeCategoryItem
   */
  category_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyzeCategoryItem
   */
  category_name?: string | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeCategoryItem
   */
  product_count?: number | null;
}
/**
 *
 * @export
 * @interface AbcAnalyzeResponse
 */
export interface AbcAnalyzeResponse {
  /**
   * User\'s connection IDs. Default: all user connections.
   * @type {Array<number>}
   * @memberof AbcAnalyzeResponse
   */
  connection_ids?: Array<number> | null;
  /**
   *
   * @type {Array<AbcAnalyze>}
   * @memberof AbcAnalyzeResponse
   */
  data?: Array<AbcAnalyze>;
  /**
   *
   * @type {Pagination}
   * @memberof AbcAnalyzeResponse
   */
  pagination?: Pagination;
  /**
   *
   * @type {any}
   * @memberof AbcAnalyzeResponse
   */
  status?: any;
}
/**
 *
 * @export
 * @interface AbcAnalyzeTotal
 */
export interface AbcAnalyzeTotal {
  /**
   *
   * @type {string}
   * @memberof AbcAnalyzeTotal
   */
  currency?: string | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  margin?: number | null;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyzeTotal
   */
  margin_category?: string | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  margin_qty?: number | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  margin_qty_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  margin_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  sales?: number | null;
  /**
   *
   * @type {string}
   * @memberof AbcAnalyzeTotal
   */
  sales_category?: string | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  sales_qty?: number | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  sales_qty_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof AbcAnalyzeTotal
   */
  sales_rate?: number | null;
}
/**
 *
 * @export
 * @interface AbcAnalyzeTotalData
 */
export interface AbcAnalyzeTotalData {
  /**
   *
   * @type {AbcAnalyzeTotal}
   * @memberof AbcAnalyzeTotalData
   */
  A?: AbcAnalyzeTotal;
  /**
   *
   * @type {AbcAnalyzeTotal}
   * @memberof AbcAnalyzeTotalData
   */
  B?: AbcAnalyzeTotal;
  /**
   *
   * @type {AbcAnalyzeTotal}
   * @memberof AbcAnalyzeTotalData
   */
  C?: AbcAnalyzeTotal;
  /**
   *
   * @type {Array<string>}
   * @memberof AbcAnalyzeTotalData
   */
  brand_list?: Array<string> | null;
  /**
   *
   * @type {Array<AbcAnalyzeCategoryItem>}
   * @memberof AbcAnalyzeTotalData
   */
  category_list?: Array<AbcAnalyzeCategoryItem> | null;
}
/**
 *
 * @export
 * @interface AbcAnalyzeTotalResponse
 */
export interface AbcAnalyzeTotalResponse {
  /**
   * User\'s connection IDs. Default: all user connections.
   * @type {Array<number>}
   * @memberof AbcAnalyzeTotalResponse
   */
  connection_ids?: Array<number> | null;
  /**
   *
   * @type {AbcAnalyzeTotalData}
   * @memberof AbcAnalyzeTotalResponse
   */
  data?: AbcAnalyzeTotalData;
  /**
   *
   * @type {any}
   * @memberof AbcAnalyzeTotalResponse
   */
  status?: any;
}
/**
 *
 * @export
 * @interface AddConnectionRequest
 */
export interface AddConnectionRequest {
  /**
   *
   * @type {string}
   * @memberof AddConnectionRequest
   */
  account_name?: string;
  /**
   *
   * @type {any}
   * @memberof AddConnectionRequest
   */
  country: any;
  /**
   *
   * @type {object}
   * @memberof AddConnectionRequest
   */
  credentials?: object;
  /**
   *
   * @type {number}
   * @memberof AddConnectionRequest
   */
  marketplace_id: number;
}
/**
 *
 * @export
 * @interface AddConnectionResponse
 */
export interface AddConnectionResponse {
  /**
   *
   * @type {string}
   * @memberof AddConnectionResponse
   */
  account_name?: string;
  /**
   *
   * @type {any}
   * @memberof AddConnectionResponse
   */
  country: any;
  /**
   *
   * @type {string}
   * @memberof AddConnectionResponse
   */
  created_at?: string | null;
  /**
   *
   * @type {object}
   * @memberof AddConnectionResponse
   */
  credentials?: object;
  /**
   *
   * @type {number}
   * @memberof AddConnectionResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AddConnectionResponse
   */
  marketplace?: AddConnectionResponseMarketplaceEnum;
  /**
   *
   * @type {number}
   * @memberof AddConnectionResponse
   */
  marketplace_id: number;
  /**
   *
   * @type {string}
   * @memberof AddConnectionResponse
   */
  status?: AddConnectionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AddConnectionResponse
   */
  updated_at?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum AddConnectionResponseMarketplaceEnum {
  Laz = 'laz',
  Sho = 'sho',
  Tok = 'tok',
  Demo = 'demo',
}
/**
 * @export
 * @enum {string}
 */
export enum AddConnectionResponseStatusEnum {
  Active = 'active',
  NotActive = 'not_active',
  Deleted = 'deleted',
}

/**
 *
 * @export
 * @interface AddTariffPlanRequest
 */
export interface AddTariffPlanRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof AddTariffPlanRequest
   */
  additional_data?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof AddTariffPlanRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AddTariffPlanRequest
   */
  group: string;
  /**
   *
   * @type {number}
   * @memberof AddTariffPlanRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof AddTariffPlanRequest
   */
  period?: number | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof AddTariffPlanRequest
   */
  restrictions: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof AddTariffPlanRequest
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof AddTariffPlanRequest
   */
  title: string;
}
/**
 *
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
  /**
   *
   * @type {string}
   * @memberof AuthToken
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof AuthToken
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof AuthToken
   */
  refresh_token?: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthToken
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface BasicReferralParamsSchema
 */
export interface BasicReferralParamsSchema {
  /**
   *
   * @type {string}
   * @memberof BasicReferralParamsSchema
   */
  param1: string;
  /**
   *
   * @type {string}
   * @memberof BasicReferralParamsSchema
   */
  param2: string;
}
/**
 *
 * @export
 * @interface BasicReferralProgramParamsSchema
 */
export interface BasicReferralProgramParamsSchema {
  /**
   *
   * @type {BasicReferralParamsSchema}
   * @memberof BasicReferralProgramParamsSchema
   */
  referral_params: BasicReferralParamsSchema;
  /**
   *
   * @type {BasicReferrerParamsSchema}
   * @memberof BasicReferralProgramParamsSchema
   */
  referrer_params: BasicReferrerParamsSchema;
}
/**
 *
 * @export
 * @interface BasicReferralProgramTypeSchema
 */
export interface BasicReferralProgramTypeSchema {
  /**
   *
   * @type {BasicReferralProgramParamsSchema}
   * @memberof BasicReferralProgramTypeSchema
   */
  params?: BasicReferralProgramParamsSchema;
  /**
   *
   * @type {string}
   * @memberof BasicReferralProgramTypeSchema
   */
  type?: string;
}
/**
 *
 * @export
 * @interface BasicReferrerParamsSchema
 */
export interface BasicReferrerParamsSchema {
  /**
   *
   * @type {string}
   * @memberof BasicReferrerParamsSchema
   */
  param1: string;
  /**
   *
   * @type {string}
   * @memberof BasicReferrerParamsSchema
   */
  param2: string;
}
/**
 *
 * @export
 * @interface BinaryResponse
 */
export interface BinaryResponse {
  /**
   *
   * @type {string}
   * @memberof BinaryResponse
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof BinaryResponse
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface BrandListResponse
 */
export interface BrandListResponse {
  /**
   *
   * @type {string}
   * @memberof BrandListResponse
   */
  message?: string;
  /**
   *
   * @type {Pagination}
   * @memberof BrandListResponse
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<MPDictBrand>}
   * @memberof BrandListResponse
   */
  results?: Array<MPDictBrand>;
}
/**
 *
 * @export
 * @interface CallbackProviderSchema
 */
export interface CallbackProviderSchema {
  /**
   *
   * @type {string}
   * @memberof CallbackProviderSchema
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CallbackProviderSchema
   */
  provider_name: string;
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {number}
   * @memberof Category
   */
  id?: number;
}
/**
 *
 * @export
 * @interface CategoryList
 */
export interface CategoryList {
  /**
   *
   * @type {Array<CategorySchema>}
   * @memberof CategoryList
   */
  categories?: Array<CategorySchema>;
  /**
   *
   * @type {boolean}
   * @memberof CategoryList
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CategoryList
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof CategoryList
   */
  items_per_page?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryList
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoryList
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryList
   */
  prev_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoryList
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface CategoryListResponse
 */
export interface CategoryListResponse {
  /**
   *
   * @type {string}
   * @memberof CategoryListResponse
   */
  message?: string;
  /**
   *
   * @type {Pagination}
   * @memberof CategoryListResponse
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<MPDictCategory>}
   * @memberof CategoryListResponse
   */
  results?: Array<MPDictCategory>;
}
/**
 *
 * @export
 * @interface CategorySchema
 */
export interface CategorySchema {
  /**
   *
   * @type {number}
   * @memberof CategorySchema
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CategorySchema
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ChangeEmailSchema
 */
export interface ChangeEmailSchema {
  /**
   *
   * @type {string}
   * @memberof ChangeEmailSchema
   */
  host?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeEmailSchema
   */
  new_email: string;
}
/**
 *
 * @export
 * @interface ChangePhoneNumberSchema
 */
export interface ChangePhoneNumberSchema {
  /**
   *
   * @type {string}
   * @memberof ChangePhoneNumberSchema
   */
  new_phone_number: string;
}
/**
 *
 * @export
 * @interface CloseTickets
 */
export interface CloseTickets {
  /**
   *
   * @type {number}
   * @memberof CloseTickets
   */
  close_status_id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CloseTickets
   */
  selected_tickets: Array<number>;
}
/**
 *
 * @export
 * @interface CommentList
 */
export interface CommentList {
  /**
   *
   * @type {Array<CommentSchema>}
   * @memberof CommentList
   */
  comments?: Array<CommentSchema>;
  /**
   *
   * @type {boolean}
   * @memberof CommentList
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommentList
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommentList
   */
  items_per_page?: number;
  /**
   *
   * @type {number}
   * @memberof CommentList
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof CommentList
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof CommentList
   */
  prev_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof CommentList
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface CommentSchema
 */
export interface CommentSchema {
  /**
   *
   * @type {string}
   * @memberof CommentSchema
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CommentSchema
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof CommentSchema
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CommentSchema
   */
  news_id: number;
  /**
   *
   * @type {string}
   * @memberof CommentSchema
   */
  updated_at?: string;
  /**
   *
   * @type {User}
   * @memberof CommentSchema
   */
  user_data?: User;
}
/**
 *
 * @export
 * @interface CommonReferralProgramTypeSchema
 */
export interface CommonReferralProgramTypeSchema {
  /**
   *
   * @type {object}
   * @memberof CommonReferralProgramTypeSchema
   */
  params?: object;
  /**
   *
   * @type {string}
   * @memberof CommonReferralProgramTypeSchema
   */
  program_type?: string;
}
/**
 *
 * @export
 * @interface Connection
 */
export interface Connection {
  /**
   *
   * @type {string}
   * @memberof Connection
   */
  account_name?: string;
  /**
   *
   * @type {any}
   * @memberof Connection
   */
  country: any;
  /**
   *
   * @type {string}
   * @memberof Connection
   */
  created_at?: string | null;
  /**
   *
   * @type {object}
   * @memberof Connection
   */
  credentials?: object;
  /**
   *
   * @type {number}
   * @memberof Connection
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Connection
   */
  marketplace?: ConnectionMarketplaceEnum;
  /**
   *
   * @type {number}
   * @memberof Connection
   */
  marketplace_id: number;
  /**
   *
   * @type {string}
   * @memberof Connection
   */
  status?: ConnectionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Connection
   */
  updated_at?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ConnectionMarketplaceEnum {
  Laz = 'laz',
  Sho = 'sho',
  Tok = 'tok',
  Demo = 'demo',
}
/**
 * @export
 * @enum {string}
 */
export enum ConnectionStatusEnum {
  Active = 'active',
  NotActive = 'not_active',
  Deleted = 'deleted',
}

/**
 *
 * @export
 * @interface ConnectionsPagination
 */
export interface ConnectionsPagination {
  /**
   *
   * @type {boolean}
   * @memberof ConnectionsPagination
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ConnectionsPagination
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof ConnectionsPagination
   */
  items_per_page?: number;
  /**
   *
   * @type {string}
   * @memberof ConnectionsPagination
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof ConnectionsPagination
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof ConnectionsPagination
   */
  page?: number;
  /**
   *
   * @type {Pagination}
   * @memberof ConnectionsPagination
   */
  pagination?: Pagination;
  /**
   *
   * @type {number}
   * @memberof ConnectionsPagination
   */
  prev_page?: number | null;
  /**
   *
   * @type {Array<Connection>}
   * @memberof ConnectionsPagination
   */
  results?: Array<Connection>;
  /**
   *
   * @type {number}
   * @memberof ConnectionsPagination
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface ConnectionsSchema
 */
export interface ConnectionsSchema {
  /**
   *
   * @type {string}
   * @memberof ConnectionsSchema
   */
  account_name?: string;
  /**
   *
   * @type {string}
   * @memberof ConnectionsSchema
   */
  client_id: string;
  /**
   *
   * @type {string}
   * @memberof ConnectionsSchema
   */
  client_secret: string;
  /**
   *
   * @type {any}
   * @memberof ConnectionsSchema
   */
  country?: any;
  /**
   *
   * @type {string}
   * @memberof ConnectionsSchema
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof ConnectionsSchema
   */
  id?: number;
  /**
   *
   * @type {any}
   * @memberof ConnectionsSchema
   */
  marketplace?: any;
  /**
   *
   * @type {any}
   * @memberof ConnectionsSchema
   */
  status?: any;
  /**
   *
   * @type {string}
   * @memberof ConnectionsSchema
   */
  updated_at?: string;
}
/**
 *
 * @export
 * @interface CountUsers
 */
export interface CountUsers {
  /**
   *
   * @type {string}
   * @memberof CountUsers
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof CountUsers
   */
  result?: number;
}
/**
 *
 * @export
 * @interface CreateCategorySchema
 */
export interface CreateCategorySchema {
  /**
   *
   * @type {string}
   * @memberof CreateCategorySchema
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateNewsSchema
 */
export interface CreateNewsSchema {
  /**
   *
   * @type {boolean}
   * @memberof CreateNewsSchema
   */
  activity?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNewsSchema
   */
  available_roles?: Array<string>;
  /**
   *
   * @type {Array<Category>}
   * @memberof CreateNewsSchema
   */
  categories?: Array<Category>;
  /**
   *
   * @type {boolean}
   * @memberof CreateNewsSchema
   */
  comment_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateNewsSchema
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewsSchema
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewsSchema
   */
  description: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNewsSchema
   */
  external_resources?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNewsSchema
   */
  resources?: Array<string>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof CreateNewsSchema
   */
  tags?: Array<Tag>;
  /**
   *
   * @type {string}
   * @memberof CreateNewsSchema
   */
  title: string;
}
/**
 *
 * @export
 * @interface CreateSessionSchema
 */
export interface CreateSessionSchema {
  /**
   *
   * @type {string}
   * @memberof CreateSessionSchema
   */
  session_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateSessionSchema
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateSessionSchema
   */
  user_ip: string | null;
}
/**
 *
 * @export
 * @interface CreateTagSchema
 */
export interface CreateTagSchema {
  /**
   *
   * @type {string}
   * @memberof CreateTagSchema
   */
  name?: string;
}
/**
 *
 * @export
 * @interface CreateTicket
 */
export interface CreateTicket {
  /**
   *
   * @type {string}
   * @memberof CreateTicket
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicket
   */
  file: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateTicket
   */
  theme_id: number | null;
}
/**
 *
 * @export
 * @interface CreateTicketByManager
 */
export interface CreateTicketByManager {
  /**
   *
   * @type {string}
   * @memberof CreateTicketByManager
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketByManager
   */
  file: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateTicketByManager
   */
  theme_id: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateTicketByManager
   */
  user_id: string | null;
}
/**
 *
 * @export
 * @interface CreateTicketComment
 */
export interface CreateTicketComment {
  /**
   *
   * @type {string}
   * @memberof CreateTicketComment
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketComment
   */
  file?: string | null;
}
/**
 *
 * @export
 * @interface CreateTicketStatus
 */
export interface CreateTicketStatus {
  /**
   *
   * @type {boolean}
   * @memberof CreateTicketStatus
   */
  is_closed: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CreateTicketStatus
   */
  order: number;
  /**
   *
   * @type {string}
   * @memberof CreateTicketStatus
   */
  title: string;
}
/**
 *
 * @export
 * @interface CreateTicketTheme
 */
export interface CreateTicketTheme {
  /**
   *
   * @type {string}
   * @memberof CreateTicketTheme
   */
  title: string;
}
/**
 *
 * @export
 * @interface CreateUserSchema
 */
export interface CreateUserSchema {
  /**
   *
   * @type {any}
   * @memberof CreateUserSchema
   */
  business_type?: any;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  locale?: CreateUserSchemaLocaleEnum;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  phone_number?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateUserSchema
   */
  receive_news?: boolean;
  /**
   *
   * @type {any}
   * @memberof CreateUserSchema
   */
  region?: any;
  /**
   *
   * @type {string}
   * @memberof CreateUserSchema
   */
  role?: CreateUserSchemaRoleEnum;
  /**
   *
   * @type {any}
   * @memberof CreateUserSchema
   */
  sales_experience?: any;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateUserSchemaLocaleEnum {
  En = 'en',
  Id = 'id',
  Vi = 'vi',
  Th = 'th',
  Ms = 'ms',
  Zh = 'zh',
  Ru = 'ru',
}
/**
 * @export
 * @enum {string}
 */
export enum CreateUserSchemaRoleEnum {
  Employee = 'employee',
  User = 'user',
  Admin = 'admin',
  SupportManager = 'support_manager',
}

/**
 *
 * @export
 * @interface DeactivateSessionSchema
 */
export interface DeactivateSessionSchema {
  /**
   *
   * @type {string}
   * @memberof DeactivateSessionSchema
   */
  session_id?: string | null;
}
/**
 *
 * @export
 * @interface EditManagerTicket
 */
export interface EditManagerTicket {
  /**
   *
   * @type {string}
   * @memberof EditManagerTicket
   */
  manager_id: string | null;
}
/**
 *
 * @export
 * @interface EditOrderStatus
 */
export interface EditOrderStatus {
  /**
   *
   * @type {number}
   * @memberof EditOrderStatus
   */
  order: number;
}
/**
 *
 * @export
 * @interface EditStatusTicket
 */
export interface EditStatusTicket {
  /**
   *
   * @type {number}
   * @memberof EditStatusTicket
   */
  status_id: number | null;
}
/**
 *
 * @export
 * @interface EditTariffPlanRequest
 */
export interface EditTariffPlanRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof EditTariffPlanRequest
   */
  additional_data?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof EditTariffPlanRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof EditTariffPlanRequest
   */
  group: string;
  /**
   *
   * @type {number}
   * @memberof EditTariffPlanRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof EditTariffPlanRequest
   */
  period?: number | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof EditTariffPlanRequest
   */
  restrictions: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof EditTariffPlanRequest
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof EditTariffPlanRequest
   */
  title: string;
}
/**
 *
 * @export
 * @interface EmailLimitResponse
 */
export interface EmailLimitResponse {
  /**
   *
   * @type {string}
   * @memberof EmailLimitResponse
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof EmailLimitResponse
   */
  result?: boolean;
  /**
   * Wait until request again.
   * @type {number}
   * @memberof EmailLimitResponse
   */
  wait_sec?: number;
}
/**
 *
 * @export
 * @interface FacebookDeletionResponse
 */
export interface FacebookDeletionResponse {
  /**
   *
   * @type {string}
   * @memberof FacebookDeletionResponse
   */
  confirmation_code: string;
  /**
   *
   * @type {string}
   * @memberof FacebookDeletionResponse
   */
  url: string;
}
/**
 *
 * @export
 * @interface FacebookDeletionStatusResponse
 */
export interface FacebookDeletionStatusResponse {
  /**
   *
   * @type {string}
   * @memberof FacebookDeletionStatusResponse
   */
  confirmation_code: string;
  /**
   *
   * @type {boolean}
   * @memberof FacebookDeletionStatusResponse
   */
  is_deleted: boolean;
  /**
   *
   * @type {string}
   * @memberof FacebookDeletionStatusResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface FilterCodesByParamsSchema
 */
export interface FilterCodesByParamsSchema {
  /**
   *
   * @type {Array<number>}
   * @memberof FilterCodesByParamsSchema
   */
  program_ids?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof FilterCodesByParamsSchema
   */
  user_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface FilterConnectionSchema
 */
export interface FilterConnectionSchema {
  /**
   *
   * @type {any}
   * @memberof FilterConnectionSchema
   */
  country?: any | null;
  /**
   *
   * @type {number}
   * @memberof FilterConnectionSchema
   */
  items_per_page?: number | null;
  /**
   *
   * @type {Array<any>}
   * @memberof FilterConnectionSchema
   */
  marketplace?: Array<any>;
  /**
   *
   * @type {string}
   * @memberof FilterConnectionSchema
   */
  user_id?: string | null;
}
/**
 *
 * @export
 * @interface FilterDatesSchema
 */
export interface FilterDatesSchema {
  /**
   *
   * @type {string}
   * @memberof FilterDatesSchema
   */
  date_from?: string | null;
  /**
   *
   * @type {string}
   * @memberof FilterDatesSchema
   */
  date_to?: string | null;
}
/**
 *
 * @export
 * @interface FilterSessionSchema
 */
export interface FilterSessionSchema {
  /**
   *
   * @type {boolean}
   * @memberof FilterSessionSchema
   */
  is_active?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof FilterSessionSchema
   */
  items_per_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof FilterSessionSchema
   */
  limit?: number | null;
  /**
   *
   * @type {string}
   * @memberof FilterSessionSchema
   */
  user_id?: string | null;
}
/**
 *
 * @export
 * @interface FormError
 */
export interface FormError {
  /**
   *
   * @type {object}
   * @memberof FormError
   */
  form_error?: object;
  /**
   *
   * @type {string}
   * @memberof FormError
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormError
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface GetCategorySchema
 */
export interface GetCategorySchema {
  /**
   *
   * @type {number}
   * @memberof GetCategorySchema
   */
  category_id?: number;
}
/**
 *
 * @export
 * @interface GetCommentSchema
 */
export interface GetCommentSchema {
  /**
   *
   * @type {number}
   * @memberof GetCommentSchema
   */
  comment_id: number;
}
/**
 *
 * @export
 * @interface GetNewsForUpdateAndDeleteSchema
 */
export interface GetNewsForUpdateAndDeleteSchema {
  /**
   *
   * @type {number}
   * @memberof GetNewsForUpdateAndDeleteSchema
   */
  news_id: number;
}
/**
 *
 * @export
 * @interface GetNewsSchema
 */
export interface GetNewsSchema {
  /**
   *
   * @type {number}
   * @memberof GetNewsSchema
   */
  news_id: number;
}
/**
 *
 * @export
 * @interface GetProviderSchema
 */
export interface GetProviderSchema {
  /**
   *
   * @type {string}
   * @memberof GetProviderSchema
   */
  provider_name: string;
}
/**
 *
 * @export
 * @interface GetQueryParameters
 */
export interface GetQueryParameters {
  /**
   * if present, only the notifications that were created after this date are in the result
   * @type {string}
   * @memberof GetQueryParameters
   */
  created_at_after?: string;
  /**
   *
   * @type {number}
   * @memberof GetQueryParameters
   */
  limit?: number | null;
  /**
   *
   * @type {number}
   * @memberof GetQueryParameters
   */
  page?: number;
}
/**
 *
 * @export
 * @interface GetRoleSchema
 */
export interface GetRoleSchema {
  /**
   *
   * @type {string}
   * @memberof GetRoleSchema
   */
  name?: string;
}
/**
 *
 * @export
 * @interface GetTagSchema
 */
export interface GetTagSchema {
  /**
   *
   * @type {number}
   * @memberof GetTagSchema
   */
  id?: number;
}
/**
 *
 * @export
 * @interface GetUserSchema
 */
export interface GetUserSchema {
  /**
   *
   * @type {string}
   * @memberof GetUserSchema
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   * user\'s email
   * @type {string}
   * @memberof InlineObject
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  host?: string;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   * User\'s new password
   * @type {string}
   * @memberof InlineObject1
   */
  password1?: string;
  /**
   * Confirm user\'s new password
   * @type {string}
   * @memberof InlineObject1
   */
  password2?: string;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  name?: string;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  content?: string;
}
/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
  /**
   *
   * @type {string}
   * @memberof InlineObject4
   */
  owner_id?: string;
  /**
   *
   * @type {number}
   * @memberof InlineObject4
   */
  referral_program_id: number;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse200
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  refresh_token?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2001
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  refresh_token?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2002
   */
  result?: boolean;
}
/**
 * Message about the result of the token update
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  refresh_token?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2003
   */
  result?: boolean;
}
/**
 * Notification of registration results
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2004
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2004
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2004
   */
  refresh_token?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2004
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   * listener\'s response
   * @type {object}
   * @memberof InlineResponse2005
   */
  response?: object;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   * сообщение с сервера!
   * @type {string}
   * @memberof InlineResponse2006
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2006
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
  /**
   * сообщение с сервера!
   * @type {string}
   * @memberof InlineResponse2007
   */
  message?: string;
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2008
   */
  auth_token?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2008
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse2008
   */
  refresh_token?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2008
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse401
 */
export interface InlineResponse401 {
  /**
   * A message from the server
   * @type {string}
   * @memberof InlineResponse401
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse401
   */
  result?: boolean;
}
/**
 * The requested resource was not found
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse404
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse404
   */
  result?: boolean;
}
/**
 * The input data format is not JSON
 * @export
 * @interface InlineResponse415
 */
export interface InlineResponse415 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse415
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse415
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface InternalEmailInput
 */
export interface InternalEmailInput {
  /**
   *
   * @type {string}
   * @memberof InternalEmailInput
   */
  secret: string;
  /**
   *
   * @type {string}
   * @memberof InternalEmailInput
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof InternalEmailInput
   */
  text: string;
}
/**
 *
 * @export
 * @interface ListPlans
 */
export interface ListPlans {
  /**
   *
   * @type {string}
   * @memberof ListPlans
   */
  message?: string;
  /**
   *
   * @type {Array<TariffPlan>}
   * @memberof ListPlans
   */
  results?: Array<TariffPlan>;
}
/**
 *
 * @export
 * @interface ListReferralCodes
 */
export interface ListReferralCodes {
  /**
   *
   * @type {ReferralPaginate}
   * @memberof ListReferralCodes
   */
  pagination?: ReferralPaginate;
  /**
   *
   * @type {Array<ReferralCodeModel>}
   * @memberof ListReferralCodes
   */
  results?: Array<ReferralCodeModel>;
  /**
   *
   * @type {ReferralCodeSort}
   * @memberof ListReferralCodes
   */
  sorting?: ReferralCodeSort;
}
/**
 *
 * @export
 * @interface ListReferralProgramModelSchema
 */
export interface ListReferralProgramModelSchema {
  /**
   *
   * @type {ReferralPaginate}
   * @memberof ListReferralProgramModelSchema
   */
  pagination?: ReferralPaginate;
  /**
   *
   * @type {Array<ReferrerProgramModel>}
   * @memberof ListReferralProgramModelSchema
   */
  results?: Array<ReferrerProgramModel>;
  /**
   *
   * @type {ReferralProgramSortSchema}
   * @memberof ListReferralProgramModelSchema
   */
  sorting?: ReferralProgramSortSchema;
}
/**
 *
 * @export
 * @interface ListStatus
 */
export interface ListStatus {
  /**
   *
   * @type {Array<TicketStatus1>}
   * @memberof ListStatus
   */
  detailed_results?: Array<TicketStatus1>;
  /**
   *
   * @type {string}
   * @memberof ListStatus
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListStatus
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface ListTheme
 */
export interface ListTheme {
  /**
   *
   * @type {Array<TicketTheme1>}
   * @memberof ListTheme
   */
  detailed_results?: Array<TicketTheme1>;
  /**
   *
   * @type {string}
   * @memberof ListTheme
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListTheme
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface ListTicketFields
 */
export interface ListTicketFields {
  /**
   *
   * @type {Array<string>}
   * @memberof ListTicketFields
   */
  detailed_results?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ListTicketFields
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListTicketFields
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface LoginHistory
 */
export interface LoginHistory {
  /**
   *
   * @type {string}
   * @memberof LoginHistory
   */
  browser?: string | null;
  /**
   *
   * @type {string}
   * @memberof LoginHistory
   */
  country_code?: string | null;
  /**
   *
   * @type {string}
   * @memberof LoginHistory
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof LoginHistory
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof LoginHistory
   */
  os_name?: string | null;
  /**
   *
   * @type {number}
   * @memberof LoginHistory
   */
  session?: number;
  /**
   *
   * @type {string}
   * @memberof LoginHistory
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof LoginHistory
   */
  user_ip?: string | null;
}
/**
 *
 * @export
 * @interface LoginHistoryPagination
 */
export interface LoginHistoryPagination {
  /**
   *
   * @type {boolean}
   * @memberof LoginHistoryPagination
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LoginHistoryPagination
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof LoginHistoryPagination
   */
  items_per_page?: number;
  /**
   *
   * @type {string}
   * @memberof LoginHistoryPagination
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof LoginHistoryPagination
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof LoginHistoryPagination
   */
  page?: number;
  /**
   *
   * @type {Pagination}
   * @memberof LoginHistoryPagination
   */
  pagination?: Pagination;
  /**
   *
   * @type {number}
   * @memberof LoginHistoryPagination
   */
  prev_page?: number | null;
  /**
   *
   * @type {Array<LoginHistory>}
   * @memberof LoginHistoryPagination
   */
  results?: Array<LoginHistory>;
  /**
   *
   * @type {number}
   * @memberof LoginHistoryPagination
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface MPDictBrand
 */
export interface MPDictBrand {
  /**
   *
   * @type {string}
   * @memberof MPDictBrand
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MPDictBrand
   */
  slug: string;
}
/**
 *
 * @export
 * @interface MPDictCategory
 */
export interface MPDictCategory {
  /**
   *
   * @type {string}
   * @memberof MPDictCategory
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof MPDictCategory
   */
  marketplace_id: number;
  /**
   *
   * @type {string}
   * @memberof MPDictCategory
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MPDictCategory
   */
  parent_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof MPDictCategory
   */
  parsed_date?: string;
}
/**
 *
 * @export
 * @interface MakePurchaseRequest
 */
export interface MakePurchaseRequest {
  /**
   *
   * @type {any}
   * @memberof MakePurchaseRequest
   */
  currency?: any;
  /**
   *
   * @type {string}
   * @memberof MakePurchaseRequest
   */
  payment_system: MakePurchaseRequestPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof MakePurchaseRequest
   */
  slug: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MakePurchaseRequestPaymentSystemEnum {
  StripePayment = 'stripe_payment',
  XenditPayment = 'xendit_payment',
}

/**
 *
 * @export
 * @interface MarginDataSchema
 */
export interface MarginDataSchema {
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  account_id?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  adserv_s?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  am_s?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  as_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  com_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  com_s?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  cost_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  cost_s?: number;
  /**
   *
   * @type {string}
   * @memberof MarginDataSchema
   */
  currency?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarginDataSchema
   */
  has_cost?: string;
  /**
   *
   * @type {string}
   * @memberof MarginDataSchema
   */
  image?: string | null;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  logist_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  logist_s?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  marketplace_id?: number;
  /**
   *
   * @type {string}
   * @memberof MarginDataSchema
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof MarginDataSchema
   */
  product_codes?: { [key: string]: Array<string> };
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  profit?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  profit_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  qty?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  return_logist_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  return_logist_s?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  row_count?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  tax_rate?: number;
  /**
   *
   * @type {number}
   * @memberof MarginDataSchema
   */
  tax_value?: number;
  /**
   *
   * @type {string}
   * @memberof MarginDataSchema
   */
  variant_id?: string;
}
/**
 *
 * @export
 * @interface MarginResponse
 */
export interface MarginResponse {
  /**
   * User\'s connection IDs. Default: all user connections.
   * @type {Array<number>}
   * @memberof MarginResponse
   */
  connection_ids?: Array<number> | null;
  /**
   *
   * @type {Array<MarginDataSchema>}
   * @memberof MarginResponse
   */
  data?: Array<MarginDataSchema>;
  /**
   *
   * @type {Pagination}
   * @memberof MarginResponse
   */
  pagination?: Pagination;
  /**
   *
   * @type {any}
   * @memberof MarginResponse
   */
  status?: any;
}
/**
 *
 * @export
 * @interface MarginSearchResponse
 */
export interface MarginSearchResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof MarginSearchResponse
   */
  variant_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface MarginTotalData
 */
export interface MarginTotalData {
  /**
   *
   * @type {string}
   * @memberof MarginTotalData
   */
  currency?: string | null;
  /**
   *
   * @type {number}
   * @memberof MarginTotalData
   */
  low_profitability_qty?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTotalData
   */
  negative_profitability_amount?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTotalData
   */
  negative_profitability_qty?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTotalData
   */
  product_no_cost?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTotalData
   */
  profit_limit?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTotalData
   */
  tax_value?: number | null;
}
/**
 *
 * @export
 * @interface MarginTotalResponse
 */
export interface MarginTotalResponse {
  /**
   * User\'s connection IDs. Default: all user connections.
   * @type {Array<number>}
   * @memberof MarginTotalResponse
   */
  connection_ids?: Array<number> | null;
  /**
   *
   * @type {MarginTotalData}
   * @memberof MarginTotalResponse
   */
  data?: MarginTotalData;
  /**
   *
   * @type {any}
   * @memberof MarginTotalResponse
   */
  status?: any;
}
/**
 *
 * @export
 * @interface MarginTransactionsData
 */
export interface MarginTransactionsData {
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  commissions?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  cost?: number | null;
  /**
   *
   * @type {string}
   * @memberof MarginTransactionsData
   */
  currency?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarginTransactionsData
   */
  delivery_region?: string | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  logistic?: number | null;
  /**
   *
   * @type {string}
   * @memberof MarginTransactionsData
   */
  mp_order_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarginTransactionsData
   */
  order_date?: string | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  profit?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  qty?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  returns?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  row_count?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  sales?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  services?: number | null;
  /**
   *
   * @type {string}
   * @memberof MarginTransactionsData
   */
  status?: string | null;
  /**
   *
   * @type {number}
   * @memberof MarginTransactionsData
   */
  tax?: number | null;
  /**
   *
   * @type {string}
   * @memberof MarginTransactionsData
   */
  warehouse_from?: string | null;
}
/**
 *
 * @export
 * @interface MarginTransactionsResponse
 */
export interface MarginTransactionsResponse {
  /**
   * User\'s connection IDs. Default: all user connections.
   * @type {Array<number>}
   * @memberof MarginTransactionsResponse
   */
  connection_ids?: Array<number> | null;
  /**
   *
   * @type {Array<MarginTransactionsData>}
   * @memberof MarginTransactionsResponse
   */
  data?: Array<MarginTransactionsData>;
  /**
   *
   * @type {Pagination}
   * @memberof MarginTransactionsResponse
   */
  pagination?: Pagination;
  /**
   *
   * @type {any}
   * @memberof MarginTransactionsResponse
   */
  status?: any;
}
/**
 *
 * @export
 * @interface MarginWidgetProfitExpensesData
 */
export interface MarginWidgetProfitExpensesData {
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  commissions_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  commissions_value?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  cost_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  cost_value?: number | null;
  /**
   *
   * @type {string}
   * @memberof MarginWidgetProfitExpensesData
   */
  currency?: string | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  logistic_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  logistic_value?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  profit_diff?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  profit_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  profit_rate_diff?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  profit_value?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  returns_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  returns_value?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  sales_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  sales_value?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  services_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  services_value?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  tax_rate?: number | null;
  /**
   *
   * @type {number}
   * @memberof MarginWidgetProfitExpensesData
   */
  tax_value?: number | null;
}
/**
 *
 * @export
 * @interface MarginWidgetProfitExpensesResponse
 */
export interface MarginWidgetProfitExpensesResponse {
  /**
   * User\'s connection IDs. Default: all user connections.
   * @type {Array<number>}
   * @memberof MarginWidgetProfitExpensesResponse
   */
  connection_ids?: Array<number> | null;
  /**
   *
   * @type {MarginWidgetProfitExpensesData}
   * @memberof MarginWidgetProfitExpensesResponse
   */
  data?: MarginWidgetProfitExpensesData;
  /**
   *
   * @type {any}
   * @memberof MarginWidgetProfitExpensesResponse
   */
  status?: any;
}
/**
 *
 * @export
 * @interface MultipleResults
 */
export interface MultipleResults {
  /**
   *
   * @type {Array<SingleResult>}
   * @memberof MultipleResults
   */
  detailed_results?: Array<SingleResult>;
  /**
   *
   * @type {string}
   * @memberof MultipleResults
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof MultipleResults
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface News
 */
export interface News {
  /**
   *
   * @type {boolean}
   * @memberof News
   */
  activity?: boolean;
  /**
   *
   * @type {Array<CategorySchema>}
   * @memberof News
   */
  category?: Array<CategorySchema> | null;
  /**
   *
   * @type {boolean}
   * @memberof News
   */
  comment_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof News
   */
  content: string;
  /**
   *
   * @type {number}
   * @memberof News
   */
  count_comment?: number;
  /**
   *
   * @type {number}
   * @memberof News
   */
  count_disliked?: number;
  /**
   *
   * @type {number}
   * @memberof News
   */
  count_liked?: number;
  /**
   *
   * @type {string}
   * @memberof News
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof News
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof News
   */
  is_liked?: boolean;
  /**
   *
   * @type {number}
   * @memberof News
   */
  popularity?: number;
  /**
   *
   * @type {Array<ResourceSchema>}
   * @memberof News
   */
  resources?: Array<ResourceSchema> | null;
  /**
   *
   * @type {Array<TagSchema>}
   * @memberof News
   */
  tag?: Array<TagSchema> | null;
  /**
   *
   * @type {string}
   * @memberof News
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  updated_at?: string;
  /**
   *
   * @type {User}
   * @memberof News
   */
  user_data?: User;
}
/**
 *
 * @export
 * @interface NewsList
 */
export interface NewsList {
  /**
   *
   * @type {boolean}
   * @memberof NewsList
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewsList
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof NewsList
   */
  items_per_page?: number;
  /**
   *
   * @type {Array<News>}
   * @memberof NewsList
   */
  news?: Array<News>;
  /**
   *
   * @type {number}
   * @memberof NewsList
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof NewsList
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof NewsList
   */
  prev_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof NewsList
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface NewsSchema
 */
export interface NewsSchema {
  /**
   *
   * @type {boolean}
   * @memberof NewsSchema
   */
  activity?: boolean;
  /**
   *
   * @type {string}
   * @memberof NewsSchema
   */
  available?: string;
  /**
   *
   * @type {Array<CategorySchema>}
   * @memberof NewsSchema
   */
  category?: Array<CategorySchema> | null;
  /**
   *
   * @type {boolean}
   * @memberof NewsSchema
   */
  comment_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof NewsSchema
   */
  content: string;
  /**
   *
   * @type {number}
   * @memberof NewsSchema
   */
  count_comment?: number;
  /**
   *
   * @type {number}
   * @memberof NewsSchema
   */
  count_disliked?: number;
  /**
   *
   * @type {number}
   * @memberof NewsSchema
   */
  count_liked?: number;
  /**
   *
   * @type {string}
   * @memberof NewsSchema
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof NewsSchema
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof NewsSchema
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof NewsSchema
   */
  is_liked?: boolean;
  /**
   *
   * @type {number}
   * @memberof NewsSchema
   */
  popularity?: number;
  /**
   *
   * @type {Array<ResourceSchema>}
   * @memberof NewsSchema
   */
  resources?: Array<ResourceSchema> | null;
  /**
   *
   * @type {Array<TagSchema>}
   * @memberof NewsSchema
   */
  tag?: Array<TagSchema> | null;
  /**
   *
   * @type {string}
   * @memberof NewsSchema
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NewsSchema
   */
  updated_at?: string;
  /**
   *
   * @type {User}
   * @memberof NewsSchema
   */
  user_data?: User;
}
/**
 *
 * @export
 * @interface Notice
 */
export interface Notice {
  /**
   *
   * @type {boolean}
   * @memberof Notice
   */
  active?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Notice
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof Notice
   */
  created_at?: string | null;
  /**
   *
   * @type {number}
   * @memberof Notice
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Notice
   */
  media_file?: string | null;
  /**
   *
   * @type {string}
   * @memberof Notice
   */
  publisher?: string | null;
  /**
   *
   * @type {number}
   * @memberof Notice
   */
  type_notice?: number | null;
  /**
   *
   * @type {string}
   * @memberof Notice
   */
  updated_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof Notice
   */
  url_page: string;
}
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  body?: string | null;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  sent_at?: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  type?: NotificationTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationTypeEnum {
  Text = 'text',
  Error = 'error',
  Clue = 'clue',
}

/**
 *
 * @export
 * @interface NotificationGetting
 */
export interface NotificationGetting {
  /**
   *
   * @type {number}
   * @memberof NotificationGetting
   */
  id?: number;
  /**
   *
   * @type {Notification}
   * @memberof NotificationGetting
   */
  notification?: Notification;
  /**
   *
   * @type {boolean}
   * @memberof NotificationGetting
   */
  read?: boolean;
}
/**
 *
 * @export
 * @interface NotificationGroupSending
 */
export interface NotificationGroupSending {
  /**
   *
   * @type {string}
   * @memberof NotificationGroupSending
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationGroupSending
   */
  group: NotificationGroupSendingGroupEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationGroupSending
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationGroupSending
   */
  type?: NotificationGroupSendingTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationGroupSendingGroupEnum {
  SupportManager = 'support_manager',
  Employee = 'employee',
  User = 'user',
  Superuser = 'superuser',
  Admin = 'admin',
}
/**
 * @export
 * @enum {string}
 */
export enum NotificationGroupSendingTypeEnum {
  Text = 'text',
  Error = 'error',
  Clue = 'clue',
}

/**
 *
 * @export
 * @interface NotificationListGet
 */
export interface NotificationListGet {
  /**
   *
   * @type {string}
   * @memberof NotificationListGet
   */
  message?: string;
  /**
   *
   * @type {Pagination}
   * @memberof NotificationListGet
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<NotificationGetting>}
   * @memberof NotificationListGet
   */
  results?: Array<NotificationGetting>;
}
/**
 *
 * @export
 * @interface NotificationListUpdate
 */
export interface NotificationListUpdate {
  /**
   *
   * @type {Array<number>}
   * @memberof NotificationListUpdate
   */
  user_notification_ids: Array<number>;
}
/**
 *
 * @export
 * @interface NotificationSchema
 */
export interface NotificationSchema {
  /**
   *
   * @type {string}
   * @memberof NotificationSchema
   */
  body?: string | null;
  /**
   *
   * @type {number}
   * @memberof NotificationSchema
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationSchema
   */
  sent_at?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationSchema
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof NotificationSchema
   */
  type?: NotificationSchemaTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationSchemaTypeEnum {
  Text = 'text',
  Error = 'error',
  Clue = 'clue',
}

/**
 *
 * @export
 * @interface NotificationSending
 */
export interface NotificationSending {
  /**
   *
   * @type {string}
   * @memberof NotificationSending
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationSending
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationSending
   */
  type?: NotificationSendingTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationSending
   */
  users_id: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationSendingTypeEnum {
  Text = 'text',
  Error = 'error',
  Clue = 'clue',
}

/**
 *
 * @export
 * @interface OAuthClient
 */
export interface OAuthClient {
  /**
   *
   * @type {boolean}
   * @memberof OAuthClient
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof OAuthClient
   */
  display_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OAuthClient
   */
  image_svg?: string | null;
  /**
   *
   * @type {string}
   * @memberof OAuthClient
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OAuthClient
   */
  priority: number;
}
/**
 *
 * @export
 * @interface OAuthLink
 */
export interface OAuthLink {
  /**
   *
   * @type {string}
   * @memberof OAuthLink
   */
  redirect_url: string;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {boolean}
   * @memberof Pagination
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Pagination
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  items_per_page?: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  prev_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface PaginationNotice
 */
export interface PaginationNotice {
  /**
   *
   * @type {string}
   * @memberof PaginationNotice
   */
  message?: string;
  /**
   *
   * @type {Pagination}
   * @memberof PaginationNotice
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<Notice>}
   * @memberof PaginationNotice
   */
  results?: Array<Notice>;
}
/**
 *
 * @export
 * @interface PaginationTicket
 */
export interface PaginationTicket {
  /**
   *
   * @type {string}
   * @memberof PaginationTicket
   */
  message?: string;
  /**
   *
   * @type {Pagination}
   * @memberof PaginationTicket
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<Ticket>}
   * @memberof PaginationTicket
   */
  results?: Array<Ticket>;
  /**
   *
   * @type {Sorting}
   * @memberof PaginationTicket
   */
  sorting?: Sorting;
}
/**
 *
 * @export
 * @interface PaginationUsers
 */
export interface PaginationUsers {
  /**
   *
   * @type {string}
   * @memberof PaginationUsers
   */
  message?: string;
  /**
   *
   * @type {Pagination}
   * @memberof PaginationUsers
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<UserSchema>}
   * @memberof PaginationUsers
   */
  results?: Array<UserSchema>;
  /**
   *
   * @type {Sorting}
   * @memberof PaginationUsers
   */
  sorting?: Sorting;
}
/**
 *
 * @export
 * @interface PayloadTokenSchema
 */
export interface PayloadTokenSchema {
  /**
   *
   * @type {Array<number>}
   * @memberof PayloadTokenSchema
   */
  connection_ids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  parent_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  parent_user_role?: string | null;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  session_id: string;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  subscription_expires_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  tariff_group?: string | null;
  /**
   *
   * @type {number}
   * @memberof PayloadTokenSchema
   */
  tariff_plan?: number | null;
  /**
   *
   * @type {string}
   * @memberof PayloadTokenSchema
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  payment_system: PaymentMethodPaymentSystemEnum;
  /**
   *
   * @type {Array<TariffPlanWithPrices>}
   * @memberof PaymentMethod
   */
  tariff_plans?: Array<TariffPlanWithPrices>;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentMethodPaymentSystemEnum {
  StripePayment = 'stripe_payment',
  XenditPayment = 'xendit_payment',
}

/**
 *
 * @export
 * @interface Platform
 */
export interface Platform {
  /**
   *
   * @type {boolean}
   * @memberof Platform
   */
  add_connection_active?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof Platform
   */
  countries?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof Platform
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Platform
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Platform
   */
  required_credentials?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof Platform
   */
  slug: string;
}
/**
 *
 * @export
 * @interface ProfileUser
 */
export interface ProfileUser {
  /**
   *
   * @type {any}
   * @memberof ProfileUser
   */
  business_type?: any;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUser
   */
  confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUser
   */
  email_confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUser
   */
  is_deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUser
   */
  is_email_change?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUser
   */
  is_test_user?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  last_login_date?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  locale?: ProfileUserLocaleEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  new_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  phone_number?: string | null;
  /**
   *
   * @type {any}
   * @memberof ProfileUser
   */
  region?: any;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  sex?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfileUser
   */
  status?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  temp_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  url_avatar?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileUser
   */
  username: string;
  /**
   *
   * @type {VkUserSchema}
   * @memberof ProfileUser
   */
  vk_user_data?: VkUserSchema;
  /**
   *
   * @type {YandexUserSchema}
   * @memberof ProfileUser
   */
  yandex_user_data?: YandexUserSchema;
}

/**
 * @export
 * @enum {string}
 */
export enum ProfileUserLocaleEnum {
  En = 'en',
  Id = 'id',
  Vi = 'vi',
  Th = 'th',
  Ms = 'ms',
  Zh = 'zh',
  Ru = 'ru',
}

/**
 *
 * @export
 * @interface ProvidersSchema
 */
export interface ProvidersSchema {
  /**
   *
   * @type {string}
   * @memberof ProvidersSchema
   */
  oauth_location?: string;
  /**
   *
   * @type {string}
   * @memberof ProvidersSchema
   */
  provider_name?: string;
}
/**
 *
 * @export
 * @interface ReferralCodeModel
 */
export interface ReferralCodeModel {
  /**
   *
   * @type {string}
   * @memberof ReferralCodeModel
   */
  created_at?: string | null;
  /**
   *          Class-level method representing the \'created_by\' as a mapped property.
   * @type {string}
   * @memberof ReferralCodeModel
   */
  created_by: string;
  /**
   * Adds the deleted_at field to the model.
   * @type {string}
   * @memberof ReferralCodeModel
   */
  deleted_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferralCodeModel
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof ReferralCodeModel
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof ReferralCodeModel
   */
  owner_id: string;
  /**
   *
   * @type {number}
   * @memberof ReferralCodeModel
   */
  referral_program_id: number;
  /**
   *
   * @type {string}
   * @memberof ReferralCodeModel
   */
  updated_at?: string | null;
  /**
   *
   * @type {any}
   * @memberof ReferralCodeModel
   */
  users_invited?: any;
  /**
   *
   * @type {string}
   * @memberof ReferralCodeModel
   */
  valid_from?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferralCodeModel
   */
  valid_to?: string | null;
}
/**
 *
 * @export
 * @interface ReferralCodeSort
 */
export interface ReferralCodeSort {
  /**
   *
   * @type {string}
   * @memberof ReferralCodeSort
   */
  sort_by?: ReferralCodeSortSortByEnum;
  /**
   *
   * @type {string}
   * @memberof ReferralCodeSort
   */
  sort_order?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ReferralCodeSortSortByEnum {
  ProgramId = 'program_id',
  UserId = 'user_id',
  CreatedAt = 'created_at',
  ValidTo = 'valid_to',
}

/**
 *
 * @export
 * @interface ReferralDateTimeSchema
 */
export interface ReferralDateTimeSchema {
  /**
   *
   * @type {string}
   * @memberof ReferralDateTimeSchema
   */
  date_from?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferralDateTimeSchema
   */
  date_to?: string | null;
}
/**
 *
 * @export
 * @interface ReferralPaginate
 */
export interface ReferralPaginate {
  /**
   *
   * @type {boolean}
   * @memberof ReferralPaginate
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReferralPaginate
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof ReferralPaginate
   */
  items_per_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReferralPaginate
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReferralPaginate
   */
  page?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReferralPaginate
   */
  prev_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReferralPaginate
   */
  total_items?: number;
  /**
   *
   * @type {number}
   * @memberof ReferralPaginate
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface ReferralProgramSortSchema
 */
export interface ReferralProgramSortSchema {
  /**
   *
   * @type {string}
   * @memberof ReferralProgramSortSchema
   */
  sort_by?: ReferralProgramSortSchemaSortByEnum;
  /**
   *
   * @type {string}
   * @memberof ReferralProgramSortSchema
   */
  sort_order?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ReferralProgramSortSchemaSortByEnum {
  ProgramType = 'program_type',
  CreatedAt = 'created_at',
  ValidFrom = 'valid_from',
  ValidTo = 'valid_to',
}

/**
 *
 * @export
 * @interface ReferrerProgramModel
 */
export interface ReferrerProgramModel {
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  created_at?: string | null;
  /**
   * Adds the deleted_at field to the model.
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  deleted_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof ReferrerProgramModel
   */
  id?: number;
  /**
   *
   * @type {object}
   * @memberof ReferrerProgramModel
   */
  params?: object;
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  program_type: ReferrerProgramModelProgramTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  updated_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  valid_from?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReferrerProgramModel
   */
  valid_to?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ReferrerProgramModelProgramTypeEnum {
  Basicreferralprogramtype = 'basicreferralprogramtype',
  Employeeprogramtype = 'employeeprogramtype',
}

/**
 *
 * @export
 * @interface RefreshTokenSchema
 */
export interface RefreshTokenSchema {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenSchema
   */
  refresh_token?: string;
}
/**
 *
 * @export
 * @interface RepeatSendMail
 */
export interface RepeatSendMail {
  /**
   *
   * @type {string}
   * @memberof RepeatSendMail
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RepeatSendMail
   */
  host?: string;
}
/**
 *
 * @export
 * @interface ResourceSchema
 */
export interface ResourceSchema {
  /**
   *
   * @type {string}
   * @memberof ResourceSchema
   */
  location_resource: string;
  /**
   *
   * @type {string}
   * @memberof ResourceSchema
   */
  s3_url: string;
  /**
   *
   * @type {string}
   * @memberof ResourceSchema
   */
  status_resource: string;
}
/**
 *
 * @export
 * @interface RestoreUserSchema
 */
export interface RestoreUserSchema {
  /**
   *
   * @type {string}
   * @memberof RestoreUserSchema
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface SelectedTickets
 */
export interface SelectedTickets {
  /**
   *
   * @type {Array<number>}
   * @memberof SelectedTickets
   */
  selected_tickets: Array<number>;
}
/**
 *
 * @export
 * @interface SessionSchema
 */
export interface SessionSchema {
  /**
   *
   * @type {string}
   * @memberof SessionSchema
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof SessionSchema
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof SessionSchema
   */
  is_active: boolean;
  /**
   *
   * @type {any}
   * @memberof SessionSchema
   */
  last_log_data?: any;
  /**
   *
   * @type {string}
   * @memberof SessionSchema
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof SessionSchema
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof SessionSchema
   */
  user_ip: string | null;
}
/**
 *
 * @export
 * @interface SignInUserSchema
 */
export interface SignInUserSchema {
  /**
   *
   * @type {string}
   * @memberof SignInUserSchema
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SignInUserSchema
   */
  password: string;
}
/**
 *
 * @export
 * @interface SignUpUserSchema
 */
export interface SignUpUserSchema {
  /**
   *
   * @type {any}
   * @memberof SignUpUserSchema
   */
  business_type?: any;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  host?: string;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  locale?: SignUpUserSchemaLocaleEnum;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  phone_number: string;
  /**
   *
   * @type {boolean}
   * @memberof SignUpUserSchema
   */
  receive_news?: boolean;
  /**
   *
   * @type {string}
   * @memberof SignUpUserSchema
   */
  referral_code?: string;
  /**
   *
   * @type {any}
   * @memberof SignUpUserSchema
   */
  region?: any;
  /**
   *
   * @type {any}
   * @memberof SignUpUserSchema
   */
  sales_experience?: any;
}

/**
 * @export
 * @enum {string}
 */
export enum SignUpUserSchemaLocaleEnum {
  En = 'en',
  Id = 'id',
  Vi = 'vi',
  Th = 'th',
  Ms = 'ms',
  Zh = 'zh',
  Ru = 'ru',
}

/**
 *
 * @export
 * @interface SingleResult
 */
export interface SingleResult {
  /**
   *
   * @type {number}
   * @memberof SingleResult
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SingleResult
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof SingleResult
   */
  result?: boolean;
}
/**
 *
 * @export
 * @interface Sorting
 */
export interface Sorting {
  /**
   * the field by which the selection is sorted
   * @type {string}
   * @memberof Sorting
   */
  sort_by?: string;
  /**
   * sorting direction: asc или desc
   * @type {string}
   * @memberof Sorting
   */
  sort_order?: string;
}
/**
 *
 * @export
 * @interface StatusSchema
 */
export interface StatusSchema {
  /**
   *
   * @type {boolean}
   * @memberof StatusSchema
   */
  status: boolean;
  /**
   *
   * @type {string}
   * @memberof StatusSchema
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {boolean}
   * @memberof Subscription
   */
  auto_renewal?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  finish_date?: string | null;
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  start_date?: string | null;
  /**
   *
   * @type {TariffPlan}
   * @memberof Subscription
   */
  tariff_plan?: TariffPlan;
}
/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   *
   * @type {number}
   * @memberof Tag
   */
  id?: number;
}
/**
 *
 * @export
 * @interface TagList
 */
export interface TagList {
  /**
   *
   * @type {boolean}
   * @memberof TagList
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TagList
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof TagList
   */
  items_per_page?: number;
  /**
   *
   * @type {number}
   * @memberof TagList
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagList
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof TagList
   */
  prev_page?: number | null;
  /**
   *
   * @type {Array<TagSchema>}
   * @memberof TagList
   */
  tags?: Array<TagSchema>;
  /**
   *
   * @type {number}
   * @memberof TagList
   */
  total_items?: number;
}
/**
 *
 * @export
 * @interface TagSchema
 */
export interface TagSchema {
  /**
   *
   * @type {number}
   * @memberof TagSchema
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TagSchema
   */
  name?: string;
}
/**
 *
 * @export
 * @interface TariffPlan
 */
export interface TariffPlan {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TariffPlan
   */
  additional_data?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof TariffPlan
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof TariffPlan
   */
  group: string;
  /**
   *
   * @type {number}
   * @memberof TariffPlan
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof TariffPlan
   */
  period?: number | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TariffPlan
   */
  restrictions: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof TariffPlan
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof TariffPlan
   */
  title: string;
}
/**
 *
 * @export
 * @interface TariffPlanPrice
 */
export interface TariffPlanPrice {
  /**
   *
   * @type {number}
   * @memberof TariffPlanPrice
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof TariffPlanPrice
   */
  amount_before_discount?: number;
  /**
   *
   * @type {any}
   * @memberof TariffPlanPrice
   */
  currency?: any;
  /**
   *
   * @type {number}
   * @memberof TariffPlanPrice
   */
  discount_percent?: number;
  /**
   *
   * @type {number}
   * @memberof TariffPlanPrice
   */
  id?: number;
}
/**
 *
 * @export
 * @interface TariffPlanWithPrices
 */
export interface TariffPlanWithPrices {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TariffPlanWithPrices
   */
  additional_data?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof TariffPlanWithPrices
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof TariffPlanWithPrices
   */
  group: string;
  /**
   *
   * @type {number}
   * @memberof TariffPlanWithPrices
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof TariffPlanWithPrices
   */
  period?: number | null;
  /**
   *
   * @type {Array<TariffPlanPrice>}
   * @memberof TariffPlanWithPrices
   */
  prices?: Array<TariffPlanPrice>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TariffPlanWithPrices
   */
  restrictions: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof TariffPlanWithPrices
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof TariffPlanWithPrices
   */
  title: string;
}
/**
 *
 * @export
 * @interface TelegramSchema
 */
export interface TelegramSchema {
  /**
   *
   * @type {string}
   * @memberof TelegramSchema
   */
  chat_id?: string;
  /**
   *
   * @type {number}
   * @memberof TelegramSchema
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TelegramSchema
   */
  join_date?: string;
  /**
   *
   * @type {string}
   * @memberof TelegramSchema
   */
  nickname: string;
  /**
   *
   * @type {boolean}
   * @memberof TelegramSchema
   */
  notifications?: boolean;
  /**
   *
   * @type {string}
   * @memberof TelegramSchema
   */
  tgrm_token?: string;
  /**
   *
   * @type {string}
   * @memberof TelegramSchema
   */
  user_id?: string;
}
/**
 *
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  changed_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  closed_date?: string | null;
  /**
   *
   * @type {Array<TicketComment>}
   * @memberof Ticket
   */
  comments?: Array<TicketComment>;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  created_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  file?: string | null;
  /**
   *
   * @type {number}
   * @memberof Ticket
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  last_view_date?: string | null;
  /**
   *
   * @type {number}
   * @memberof Ticket
   */
  last_view_user_id?: number;
  /**
   *
   * @type {User}
   * @memberof Ticket
   */
  manager_data?: User;
  /**
   *
   * @type {TicketStatus}
   * @memberof Ticket
   */
  status?: TicketStatus;
  /**
   *
   * @type {TicketTheme}
   * @memberof Ticket
   */
  theme?: TicketTheme;
  /**
   *
   * @type {number}
   * @memberof Ticket
   */
  ticket_rating?: number | null;
  /**
   *
   * @type {User}
   * @memberof Ticket
   */
  user_data?: User;
}
/**
 *
 * @export
 * @interface TicketComment
 */
export interface TicketComment {
  /**
   *
   * @type {User1}
   * @memberof TicketComment
   */
  commenter_data?: User1;
  /**
   *
   * @type {string}
   * @memberof TicketComment
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof TicketComment
   */
  created_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof TicketComment
   */
  file?: string | null;
  /**
   *
   * @type {number}
   * @memberof TicketComment
   */
  id?: number;
}
/**
 *
 * @export
 * @interface TicketStatus
 */
export interface TicketStatus {
  /**
   *
   * @type {number}
   * @memberof TicketStatus
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof TicketStatus
   */
  is_closed?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof TicketStatus
   */
  order: number;
  /**
   *
   * @type {string}
   * @memberof TicketStatus
   */
  title: string;
}
/**
 *
 * @export
 * @interface TicketStatus1
 */
export interface TicketStatus1 {
  /**
   *
   * @type {number}
   * @memberof TicketStatus1
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof TicketStatus1
   */
  is_closed?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof TicketStatus1
   */
  order: number;
  /**
   *
   * @type {string}
   * @memberof TicketStatus1
   */
  title: string;
}
/**
 *
 * @export
 * @interface TicketTheme
 */
export interface TicketTheme {
  /**
   *
   * @type {number}
   * @memberof TicketTheme
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TicketTheme
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TicketTheme1
 */
export interface TicketTheme1 {
  /**
   *
   * @type {number}
   * @memberof TicketTheme1
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TicketTheme1
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TypeNotice
 */
export interface TypeNotice {
  /**
   *
   * @type {number}
   * @memberof TypeNotice
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TypeNotice
   */
  name?: string | null;
}
/**
 *
 * @export
 * @interface UpdateConnectionRequest
 */
export interface UpdateConnectionRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateConnectionRequest
   */
  account_name?: string;
  /**
   *
   * @type {object}
   * @memberof UpdateConnectionRequest
   */
  credentials?: object;
  /**
   *
   * @type {number}
   * @memberof UpdateConnectionRequest
   */
  id?: number;
}
/**
 *
 * @export
 * @interface UpdateConnectionResponse
 */
export interface UpdateConnectionResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateConnectionResponse
   */
  account_name?: string;
  /**
   *
   * @type {any}
   * @memberof UpdateConnectionResponse
   */
  country: any;
  /**
   *
   * @type {string}
   * @memberof UpdateConnectionResponse
   */
  created_at?: string | null;
  /**
   *
   * @type {object}
   * @memberof UpdateConnectionResponse
   */
  credentials?: object;
  /**
   *
   * @type {number}
   * @memberof UpdateConnectionResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateConnectionResponse
   */
  marketplace?: UpdateConnectionResponseMarketplaceEnum;
  /**
   *
   * @type {number}
   * @memberof UpdateConnectionResponse
   */
  marketplace_id: number;
  /**
   *
   * @type {string}
   * @memberof UpdateConnectionResponse
   */
  status?: UpdateConnectionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateConnectionResponse
   */
  updated_at?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateConnectionResponseMarketplaceEnum {
  Laz = 'laz',
  Sho = 'sho',
  Tok = 'tok',
  Demo = 'demo',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateConnectionResponseStatusEnum {
  Active = 'active',
  NotActive = 'not_active',
  Deleted = 'deleted',
}

/**
 *
 * @export
 * @interface UpdateNewsSchema
 */
export interface UpdateNewsSchema {
  /**
   *
   * @type {boolean}
   * @memberof UpdateNewsSchema
   */
  activity?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateNewsSchema
   */
  available_roles?: Array<string>;
  /**
   *
   * @type {Array<Category>}
   * @memberof UpdateNewsSchema
   */
  categories?: Array<Category>;
  /**
   *
   * @type {boolean}
   * @memberof UpdateNewsSchema
   */
  comment_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateNewsSchema
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateNewsSchema
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateNewsSchema
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateNewsSchema
   */
  external_resources?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdateNewsSchema
   */
  news_id?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateNewsSchema
   */
  resources?: Array<string>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof UpdateNewsSchema
   */
  tags?: Array<Tag>;
  /**
   *
   * @type {string}
   * @memberof UpdateNewsSchema
   */
  title?: string;
}
/**
 *
 * @export
 * @interface UpdateReferralProgramValidPeriod
 */
export interface UpdateReferralProgramValidPeriod {
  /**
   *
   * @type {string}
   * @memberof UpdateReferralProgramValidPeriod
   */
  valid_from?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateReferralProgramValidPeriod
   */
  valid_to?: string | null;
}
/**
 *
 * @export
 * @interface UpdateUserProfileSchema
 */
export interface UpdateUserProfileSchema {
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  date_of_birth?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  locale?: UpdateUserProfileSchemaLocaleEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  sex?: UpdateUserProfileSchemaSexEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileSchema
   */
  username?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUserProfileSchemaLocaleEnum {
  En = 'en',
  Id = 'id',
  Vi = 'vi',
  Th = 'th',
  Ms = 'ms',
  Zh = 'zh',
  Ru = 'ru',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateUserProfileSchemaSexEnum {
  Male = 'male',
  Female = 'female',
}

/**
 *
 * @export
 * @interface UpdateUserSchema
 */
export interface UpdateUserSchema {
  /**
   *
   * @type {any}
   * @memberof UpdateUserSchema
   */
  business_type?: any;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserSchema
   */
  confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSchema
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserSchema
   */
  email_confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSchema
   */
  first_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserSchema
   */
  is_test_user?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSchema
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSchema
   */
  phone_number?: string;
  /**
   *
   * @type {any}
   * @memberof UpdateUserSchema
   */
  region?: any;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSchema
   */
  role?: UpdateUserSchemaRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateUserSchema
   */
  user_id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUserSchemaRoleEnum {
  Employee = 'employee',
  User = 'user',
  SupportManager = 'support_manager',
  Admin = 'admin',
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  username: string;
}
/**
 *
 * @export
 * @interface User1
 */
export interface User1 {
  /**
   *
   * @type {string}
   * @memberof User1
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof User1
   */
  username: string;
}
/**
 *
 * @export
 * @interface User2
 */
export interface User2 {
  /**
   *
   * @type {any}
   * @memberof User2
   */
  business_type?: any;
  /**
   *
   * @type {boolean}
   * @memberof User2
   */
  confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof User2
   */
  email_confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof User2
   */
  is_email_change?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User2
   */
  is_test_user?: boolean;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  last_login_date?: string;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  locale?: User2LocaleEnum;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  new_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  phone_number?: string;
  /**
   *
   * @type {any}
   * @memberof User2
   */
  region?: any;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  role?: string;
  /**
   *
   * @type {boolean}
   * @memberof User2
   */
  status?: boolean;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  temp_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof User2
   */
  username: string;
}

/**
 * @export
 * @enum {string}
 */
export enum User2LocaleEnum {
  En = 'en',
  Id = 'id',
  Vi = 'vi',
  Th = 'th',
  Ms = 'ms',
  Zh = 'zh',
  Ru = 'ru',
}

/**
 *
 * @export
 * @interface UserList
 */
export interface UserList {
  /**
   *
   * @type {boolean}
   * @memberof UserList
   */
  has_next?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserList
   */
  has_prev?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  items_per_page?: number;
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  next_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  prev_page?: number | null;
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  total_items?: number;
  /**
   *
   * @type {Array<User2>}
   * @memberof UserList
   */
  users?: Array<User2>;
}
/**
 *
 * @export
 * @interface UserNotificationSchema
 */
export interface UserNotificationSchema {
  /**
   *
   * @type {string}
   * @memberof UserNotificationSchema
   */
  deleted_at?: string;
  /**
   *
   * @type {number}
   * @memberof UserNotificationSchema
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UserNotificationSchema
   */
  notification_id?: number;
  /**
   *
   * @type {string}
   * @memberof UserNotificationSchema
   */
  read_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserNotificationSchema
   */
  user_id?: string;
}
/**
 *
 * @export
 * @interface UserParamSchema
 */
export interface UserParamSchema {
  /**
   *
   * @type {string}
   * @memberof UserParamSchema
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserParamSchema
   */
  is_registration?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserParamSchema
   */
  items_per_page?: number;
  /**
   *
   * @type {number}
   * @memberof UserParamSchema
   */
  page: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UserParamSchema
   */
  role?: Array<UserParamSchemaRoleEnum>;
  /**
   *
   * @type {string}
   * @memberof UserParamSchema
   */
  sort_by?: UserParamSchemaSortByEnum;
  /**
   *
   * @type {string}
   * @memberof UserParamSchema
   */
  sort_type?: UserParamSchemaSortTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserParamSchema
   */
  status?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserParamSchema
   */
  username?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserParamSchemaRoleEnum {
  Employee = 'employee',
  SupportManager = 'support_manager',
  User = 'user',
  Superuser = 'superuser',
  Admin = 'admin',
}
/**
 * @export
 * @enum {string}
 */
export enum UserParamSchemaSortByEnum {
  Id = 'id',
  Email = 'email',
  Username = 'username',
  Role = 'role',
  Status = 'status',
  PhoneNumber = 'phone_number',
  TempEmail = 'temp_email',
  NewEmail = 'new_email',
  IsEmailChange = 'is_email_change',
  EmailConfirmed = 'email_confirmed',
  Confirmed = 'confirmed',
}
/**
 * @export
 * @enum {string}
 */
export enum UserParamSchemaSortTypeEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 *
 * @export
 * @interface UserSchema
 */
export interface UserSchema {
  /**
   *
   * @type {any}
   * @memberof UserSchema
   */
  business_type?: any;
  /**
   *
   * @type {boolean}
   * @memberof UserSchema
   */
  confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSchema
   */
  email_confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSchema
   */
  is_deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSchema
   */
  is_email_change?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSchema
   */
  is_test_user?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  last_login_date?: string;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  locale?: UserSchemaLocaleEnum;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  new_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  phone_number?: string;
  /**
   *
   * @type {any}
   * @memberof UserSchema
   */
  region?: any;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  role?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSchema
   */
  status?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  temp_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof UserSchema
   */
  username: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserSchemaLocaleEnum {
  En = 'en',
  Id = 'id',
  Vi = 'vi',
  Th = 'th',
  Ms = 'ms',
  Zh = 'zh',
  Ru = 'ru',
}

/**
 *
 * @export
 * @interface ValidateNotificationId
 */
export interface ValidateNotificationId {
  /**
   *
   * @type {number}
   * @memberof ValidateNotificationId
   */
  id: number;
}
/**
 *
 * @export
 * @interface VkUserSchema
 */
export interface VkUserSchema {
  /**
   *
   * @type {string}
   * @memberof VkUserSchema
   */
  first_name?: string;
  /**
   *
   * @type {number}
   * @memberof VkUserSchema
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof VkUserSchema
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface YandexUserSchema
 */
export interface YandexUserSchema {
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  default_email?: string;
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  display_name?: string;
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  first_name?: string;
  /**
   *
   * @type {number}
   * @memberof YandexUserSchema
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  real_name?: string;
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  sex?: string;
  /**
   *
   * @type {string}
   * @memberof YandexUserSchema
   */
  username?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получение токена, если пользователь авторизовался через сервис (Яндекс, ВК)
     * @param {string} providerName
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    apiAuthCallbackProviderNameGet: async (
      providerName: string,
      code?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'providerName' is not null or undefined
      assertParamExists('apiAuthCallbackProviderNameGet', 'providerName', providerName);
      const localVarPath = `/api/auth/callback/{provider_name}/`.replace(
        `{${'provider_name'}}`,
        encodeURIComponent(String(providerName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sending a link to change the email to the user\'s email
     * @param {ChangeEmailSchema} [changeEmailSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthChangeEmailPost: async (
      changeEmailSchema?: ChangeEmailSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/change_email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changeEmailSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change user\'s phone number
     * @param {ChangePhoneNumberSchema} [changePhoneNumberSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthChangePhoneNumberPost: async (
      changePhoneNumberSchema?: ChangePhoneNumberSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/change_phone_number/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePhoneNumberSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirmation of the user\'s email change by token.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthConfirmChangeEmailTokenGet: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('apiAuthConfirmChangeEmailTokenGet', 'token', token);
      const localVarPath = `/api/auth/confirm_change_email/{token}/`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resending the link to the email to confirm it.
     * @param {RepeatSendMail} [repeatSendMail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthConfirmPost: async (
      repeatSendMail?: RepeatSendMail,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/confirm/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        repeatSendMail,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirmation of the user\'s email by token
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthConfirmTokenGet: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('apiAuthConfirmTokenGet', 'token', token);
      const localVarPath = `/api/auth/confirm/{token}/`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting a list of sessions (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {boolean} [isActive]
     * @param {number} [itemsPerPage] number of elements on page
     * @param {number} [limit] total number of elements, cancels pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthListSessionPageGet: async (
      page: number,
      userId?: string,
      isActive?: boolean,
      itemsPerPage?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiAuthListSessionPageGet', 'page', page);
      const localVarPath = `/api/auth/list-session/{page}/`.replace(
        `{${'page'}}`,
        encodeURIComponent(String(page))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary User authorization
     * @param {SignInUserSchema} [signInUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLoginPost: async (
      signInUserSchema?: SignInUserSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/login/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        signInUserSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLogoutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/logout/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary User\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/profile/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение ссылок для авторизации через сервисы
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    apiAuthProvidersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/providers/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting a new authorization token
     * @param {RefreshTokenSchema} [refreshTokenSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRefreshPost: async (
      refreshTokenSchema?: RefreshTokenSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/refresh/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshTokenSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Required fields are `email`, `phone_number`, `password`.
     * @summary User registration
     * @param {SignUpUserSchema} [signUpUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterPost: async (
      signUpUserSchema?: SignUpUserSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/register/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        signUpUserSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sending a link to change the password to the user\'s email
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetPost: async (
      inlineObject?: InlineObject,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/reset/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verifying the validity of the token for changing the password
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetTokenGet: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('apiAuthResetTokenGet', 'token', token);
      const localVarPath = `/api/auth/reset/{token}/`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changing a user\'s password by token
     * @param {string} token
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetTokenPost: async (
      token: string,
      inlineObject1?: InlineObject1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('apiAuthResetTokenPost', 'token', token);
      const localVarPath = `/api/auth/reset/{token}/`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivating sessions specific or all active, except the current one
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthSessionDeactivateSessionIdPatch: async (
      sessionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists('apiAuthSessionDeactivateSessionIdPatch', 'sessionId', sessionId);
      const localVarPath = `/api/auth/session-deactivate/{session_id}/`.replace(
        `{${'session_id'}}`,
        encodeURIComponent(String(sessionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отключение стороннего аккаунта
     * @param {string} providerName
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    apiAuthUnlinkProviderProviderNameGet: async (
      providerName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'providerName' is not null or undefined
      assertParamExists('apiAuthUnlinkProviderProviderNameGet', 'providerName', providerName);
      const localVarPath = `/api/auth/unlink_provider/{provider_name}/`.replace(
        `{${'provider_name'}}`,
        encodeURIComponent(String(providerName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Получение токена, если пользователь авторизовался через сервис (Яндекс, ВК)
     * @param {string} providerName
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async apiAuthCallbackProviderNameGet(
      providerName: string,
      code?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthCallbackProviderNameGet(
        providerName,
        code,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Sending a link to change the email to the user\'s email
     * @param {ChangeEmailSchema} [changeEmailSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthChangeEmailPost(
      changeEmailSchema?: ChangeEmailSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthChangeEmailPost(
        changeEmailSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Change user\'s phone number
     * @param {ChangePhoneNumberSchema} [changePhoneNumberSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthChangePhoneNumberPost(
      changePhoneNumberSchema?: ChangePhoneNumberSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthChangePhoneNumberPost(
        changePhoneNumberSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Confirmation of the user\'s email change by token.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthConfirmChangeEmailTokenGet(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthConfirmChangeEmailTokenGet(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Resending the link to the email to confirm it.
     * @param {RepeatSendMail} [repeatSendMail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthConfirmPost(
      repeatSendMail?: RepeatSendMail,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthConfirmPost(
        repeatSendMail,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Confirmation of the user\'s email by token
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthConfirmTokenGet(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthConfirmTokenGet(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting a list of sessions (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {boolean} [isActive]
     * @param {number} [itemsPerPage] number of elements on page
     * @param {number} [limit] total number of elements, cancels pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthListSessionPageGet(
      page: number,
      userId?: string,
      isActive?: boolean,
      itemsPerPage?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionsPagination>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthListSessionPageGet(
        page,
        userId,
        isActive,
        itemsPerPage,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary User authorization
     * @param {SignInUserSchema} [signInUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthLoginPost(
      signInUserSchema?: SignInUserSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLoginPost(
        signInUserSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthLogoutGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLogoutGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary User\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthProfileGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProfileGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение ссылок для авторизации через сервисы
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async apiAuthProvidersGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProvidersSchema>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProvidersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting a new authorization token
     * @param {RefreshTokenSchema} [refreshTokenSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRefreshPost(
      refreshTokenSchema?: RefreshTokenSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRefreshPost(
        refreshTokenSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Required fields are `email`, `phone_number`, `password`.
     * @summary User registration
     * @param {SignUpUserSchema} [signUpUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRegisterPost(
      signUpUserSchema?: SignUpUserSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRegisterPost(
        signUpUserSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Sending a link to change the password to the user\'s email
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthResetPost(
      inlineObject?: InlineObject,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetPost(
        inlineObject,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Verifying the validity of the token for changing the password
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthResetTokenGet(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetTokenGet(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Changing a user\'s password by token
     * @param {string} token
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthResetTokenPost(
      token: string,
      inlineObject1?: InlineObject1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetTokenPost(
        token,
        inlineObject1,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deactivating sessions specific or all active, except the current one
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthSessionDeactivateSessionIdPatch(
      sessionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAuthSessionDeactivateSessionIdPatch(sessionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отключение стороннего аккаунта
     * @param {string} providerName
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async apiAuthUnlinkProviderProviderNameGet(
      providerName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAuthUnlinkProviderProviderNameGet(providerName, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @summary Получение токена, если пользователь авторизовался через сервис (Яндекс, ВК)
     * @param {string} providerName
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    apiAuthCallbackProviderNameGet(
      providerName: string,
      code?: string,
      options?: any
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .apiAuthCallbackProviderNameGet(providerName, code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sending a link to change the email to the user\'s email
     * @param {ChangeEmailSchema} [changeEmailSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthChangeEmailPost(
      changeEmailSchema?: ChangeEmailSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthChangeEmailPost(changeEmailSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Change user\'s phone number
     * @param {ChangePhoneNumberSchema} [changePhoneNumberSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthChangePhoneNumberPost(
      changePhoneNumberSchema?: ChangePhoneNumberSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthChangePhoneNumberPost(changePhoneNumberSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Confirmation of the user\'s email change by token.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthConfirmChangeEmailTokenGet(token: string, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthConfirmChangeEmailTokenGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resending the link to the email to confirm it.
     * @param {RepeatSendMail} [repeatSendMail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthConfirmPost(
      repeatSendMail?: RepeatSendMail,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthConfirmPost(repeatSendMail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Confirmation of the user\'s email by token
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthConfirmTokenGet(token: string, options?: any): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .apiAuthConfirmTokenGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting a list of sessions (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {boolean} [isActive]
     * @param {number} [itemsPerPage] number of elements on page
     * @param {number} [limit] total number of elements, cancels pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthListSessionPageGet(
      page: number,
      userId?: string,
      isActive?: boolean,
      itemsPerPage?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<ConnectionsPagination> {
      return localVarFp
        .apiAuthListSessionPageGet(page, userId, isActive, itemsPerPage, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary User authorization
     * @param {SignInUserSchema} [signInUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLoginPost(
      signInUserSchema?: SignInUserSchema,
      options?: any
    ): AxiosPromise<InlineResponse2002> {
      return localVarFp
        .apiAuthLoginPost(signInUserSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLogoutGet(options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp.apiAuthLogoutGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary User\'s profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthProfileGet(options?: any): AxiosPromise<UserSchema> {
      return localVarFp.apiAuthProfileGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение ссылок для авторизации через сервисы
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    apiAuthProvidersGet(options?: any): AxiosPromise<Array<ProvidersSchema>> {
      return localVarFp.apiAuthProvidersGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting a new authorization token
     * @param {RefreshTokenSchema} [refreshTokenSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRefreshPost(
      refreshTokenSchema?: RefreshTokenSchema,
      options?: any
    ): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .apiAuthRefreshPost(refreshTokenSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Required fields are `email`, `phone_number`, `password`.
     * @summary User registration
     * @param {SignUpUserSchema} [signUpUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterPost(
      signUpUserSchema?: SignUpUserSchema,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiAuthRegisterPost(signUpUserSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sending a link to change the password to the user\'s email
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetPost(inlineObject?: InlineObject, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthResetPost(inlineObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verifying the validity of the token for changing the password
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetTokenGet(token: string, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthResetTokenGet(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changing a user\'s password by token
     * @param {string} token
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetTokenPost(
      token: string,
      inlineObject1?: InlineObject1,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthResetTokenPost(token, inlineObject1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivating sessions specific or all active, except the current one
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthSessionDeactivateSessionIdPatch(
      sessionId: string,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthSessionDeactivateSessionIdPatch(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отключение стороннего аккаунта
     * @param {string} providerName
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    apiAuthUnlinkProviderProviderNameGet(
      providerName: string,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiAuthUnlinkProviderProviderNameGet(providerName, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary Получение токена, если пользователь авторизовался через сервис (Яндекс, ВК)
   * @param {string} providerName
   * @param {string} [code]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthCallbackProviderNameGet(
    providerName: string,
    code?: string,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiAuthCallbackProviderNameGet(providerName, code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sending a link to change the email to the user\'s email
   * @param {ChangeEmailSchema} [changeEmailSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthChangeEmailPost(
    changeEmailSchema?: ChangeEmailSchema,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiAuthChangeEmailPost(changeEmailSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Change user\'s phone number
   * @param {ChangePhoneNumberSchema} [changePhoneNumberSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthChangePhoneNumberPost(
    changePhoneNumberSchema?: ChangePhoneNumberSchema,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiAuthChangePhoneNumberPost(changePhoneNumberSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirmation of the user\'s email change by token.
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthConfirmChangeEmailTokenGet(token: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthConfirmChangeEmailTokenGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resending the link to the email to confirm it.
   * @param {RepeatSendMail} [repeatSendMail]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthConfirmPost(repeatSendMail?: RepeatSendMail, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthConfirmPost(repeatSendMail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirmation of the user\'s email by token
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthConfirmTokenGet(token: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthConfirmTokenGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting a list of sessions (filtered or not)
   * @param {number} page
   * @param {string} [userId]
   * @param {boolean} [isActive]
   * @param {number} [itemsPerPage] number of elements on page
   * @param {number} [limit] total number of elements, cancels pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthListSessionPageGet(
    page: number,
    userId?: string,
    isActive?: boolean,
    itemsPerPage?: number,
    limit?: number,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiAuthListSessionPageGet(page, userId, isActive, itemsPerPage, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary User authorization
   * @param {SignInUserSchema} [signInUserSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthLoginPost(signInUserSchema?: SignInUserSchema, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthLoginPost(signInUserSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthLogoutGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthLogoutGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary User\'s profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthProfileGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthProfileGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение ссылок для авторизации через сервисы
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthProvidersGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthProvidersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting a new authorization token
   * @param {RefreshTokenSchema} [refreshTokenSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthRefreshPost(refreshTokenSchema?: RefreshTokenSchema, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthRefreshPost(refreshTokenSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Required fields are `email`, `phone_number`, `password`.
   * @summary User registration
   * @param {SignUpUserSchema} [signUpUserSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthRegisterPost(signUpUserSchema?: SignUpUserSchema, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthRegisterPost(signUpUserSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sending a link to change the password to the user\'s email
   * @param {InlineObject} [inlineObject]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthResetPost(inlineObject?: InlineObject, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthResetPost(inlineObject, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verifying the validity of the token for changing the password
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthResetTokenGet(token: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthResetTokenGet(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changing a user\'s password by token
   * @param {string} token
   * @param {InlineObject1} [inlineObject1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthResetTokenPost(
    token: string,
    inlineObject1?: InlineObject1,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiAuthResetTokenPost(token, inlineObject1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivating sessions specific or all active, except the current one
   * @param {string} sessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthSessionDeactivateSessionIdPatch(sessionId: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthSessionDeactivateSessionIdPatch(sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отключение стороннего аккаунта
   * @param {string} providerName
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiAuthUnlinkProviderProviderNameGet(providerName: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiAuthUnlinkProviderProviderNameGet(providerName, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Accepts events from payment systems.
     * @summary Payment systems event listener.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingStripePaymentListenerPost: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/stripe-payment-listener/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Buy a subscription.
     * @param {MakePurchaseRequest} makePurchaseRequest Tariff Plan slug identifier to buy and payment system to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingSubscriptionsBuyPost: async (
      makePurchaseRequest: MakePurchaseRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'makePurchaseRequest' is not null or undefined
      assertParamExists(
        'apiBillingSubscriptionsBuyPost',
        'makePurchaseRequest',
        makePurchaseRequest
      );
      const localVarPath = `/api/billing/subscriptions/buy/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        makePurchaseRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get active subscription for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingSubscriptionsMeGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/subscriptions/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Currently available payment methods.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingSubscriptionsPaymentMethodsGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/subscriptions/payment-methods/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List tariff plans
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {'period'} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingTariffPlansGet: async (
      sortOrder?: 'asc' | 'desc',
      sortBy?: 'period',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/tariff-plans/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add tariff plan
     * @param {AddTariffPlanRequest} addTariffPlanRequest tariff plan data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingTariffPlansPost: async (
      addTariffPlanRequest: AddTariffPlanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'addTariffPlanRequest' is not null or undefined
      assertParamExists('apiBillingTariffPlansPost', 'addTariffPlanRequest', addTariffPlanRequest);
      const localVarPath = `/api/billing/tariff-plans/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addTariffPlanRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update tariff plan
     * @param {number} tariffPlanId tariff plan id
     * @param {EditTariffPlanRequest} editTariffPlanRequest tariff plan data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingTariffPlansTariffPlanIdPatch: async (
      tariffPlanId: number,
      editTariffPlanRequest: EditTariffPlanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tariffPlanId' is not null or undefined
      assertParamExists('apiBillingTariffPlansTariffPlanIdPatch', 'tariffPlanId', tariffPlanId);
      // verify required parameter 'editTariffPlanRequest' is not null or undefined
      assertParamExists(
        'apiBillingTariffPlansTariffPlanIdPatch',
        'editTariffPlanRequest',
        editTariffPlanRequest
      );
      const localVarPath = `/api/billing/tariff-plans/{tariff_plan_id}/`.replace(
        `{${'tariff_plan_id'}}`,
        encodeURIComponent(String(tariffPlanId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editTariffPlanRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Accepts events from payment systems.
     * @summary Payment systems event listener.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingXenditPaymentListenerPost: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/xendit-payment-listener/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration);
  return {
    /**
     * Accepts events from payment systems.
     * @summary Payment systems event listener.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingStripePaymentListenerPost(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingStripePaymentListenerPost(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Buy a subscription.
     * @param {MakePurchaseRequest} makePurchaseRequest Tariff Plan slug identifier to buy and payment system to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingSubscriptionsBuyPost(
      makePurchaseRequest: MakePurchaseRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingSubscriptionsBuyPost(
        makePurchaseRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get active subscription for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingSubscriptionsMeGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingSubscriptionsMeGet(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Currently available payment methods.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingSubscriptionsPaymentMethodsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentMethod>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiBillingSubscriptionsPaymentMethodsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List tariff plans
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {'period'} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingTariffPlansGet(
      sortOrder?: 'asc' | 'desc',
      sortBy?: 'period',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPlans>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingTariffPlansGet(
        sortOrder,
        sortBy,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Add tariff plan
     * @param {AddTariffPlanRequest} addTariffPlanRequest tariff plan data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingTariffPlansPost(
      addTariffPlanRequest: AddTariffPlanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingTariffPlansPost(
        addTariffPlanRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update tariff plan
     * @param {number} tariffPlanId tariff plan id
     * @param {EditTariffPlanRequest} editTariffPlanRequest tariff plan data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingTariffPlansTariffPlanIdPatch(
      tariffPlanId: number,
      editTariffPlanRequest: EditTariffPlanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiBillingTariffPlansTariffPlanIdPatch(
          tariffPlanId,
          editTariffPlanRequest,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Accepts events from payment systems.
     * @summary Payment systems event listener.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBillingXenditPaymentListenerPost(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingXenditPaymentListenerPost(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BillingApiFp(configuration);
  return {
    /**
     * Accepts events from payment systems.
     * @summary Payment systems event listener.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingStripePaymentListenerPost(options?: any): AxiosPromise<InlineResponse2005> {
      return localVarFp
        .apiBillingStripePaymentListenerPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Buy a subscription.
     * @param {MakePurchaseRequest} makePurchaseRequest Tariff Plan slug identifier to buy and payment system to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingSubscriptionsBuyPost(
      makePurchaseRequest: MakePurchaseRequest,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiBillingSubscriptionsBuyPost(makePurchaseRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get active subscription for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingSubscriptionsMeGet(options?: any): AxiosPromise<Subscription> {
      return localVarFp
        .apiBillingSubscriptionsMeGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Currently available payment methods.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingSubscriptionsPaymentMethodsGet(options?: any): AxiosPromise<Array<PaymentMethod>> {
      return localVarFp
        .apiBillingSubscriptionsPaymentMethodsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List tariff plans
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {'period'} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingTariffPlansGet(
      sortOrder?: 'asc' | 'desc',
      sortBy?: 'period',
      options?: any
    ): AxiosPromise<ListPlans> {
      return localVarFp
        .apiBillingTariffPlansGet(sortOrder, sortBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add tariff plan
     * @param {AddTariffPlanRequest} addTariffPlanRequest tariff plan data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingTariffPlansPost(
      addTariffPlanRequest: AddTariffPlanRequest,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiBillingTariffPlansPost(addTariffPlanRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update tariff plan
     * @param {number} tariffPlanId tariff plan id
     * @param {EditTariffPlanRequest} editTariffPlanRequest tariff plan data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingTariffPlansTariffPlanIdPatch(
      tariffPlanId: number,
      editTariffPlanRequest: EditTariffPlanRequest,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiBillingTariffPlansTariffPlanIdPatch(tariffPlanId, editTariffPlanRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Accepts events from payment systems.
     * @summary Payment systems event listener.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBillingXenditPaymentListenerPost(options?: any): AxiosPromise<InlineResponse2005> {
      return localVarFp
        .apiBillingXenditPaymentListenerPost(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
  /**
   * Accepts events from payment systems.
   * @summary Payment systems event listener.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingStripePaymentListenerPost(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .apiBillingStripePaymentListenerPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Buy a subscription.
   * @param {MakePurchaseRequest} makePurchaseRequest Tariff Plan slug identifier to buy and payment system to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingSubscriptionsBuyPost(
    makePurchaseRequest: MakePurchaseRequest,
    options?: AxiosRequestConfig
  ) {
    return BillingApiFp(this.configuration)
      .apiBillingSubscriptionsBuyPost(makePurchaseRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get active subscription for current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingSubscriptionsMeGet(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .apiBillingSubscriptionsMeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Currently available payment methods.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingSubscriptionsPaymentMethodsGet(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .apiBillingSubscriptionsPaymentMethodsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List tariff plans
   * @param {'asc' | 'desc'} [sortOrder]
   * @param {'period'} [sortBy]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingTariffPlansGet(
    sortOrder?: 'asc' | 'desc',
    sortBy?: 'period',
    options?: AxiosRequestConfig
  ) {
    return BillingApiFp(this.configuration)
      .apiBillingTariffPlansGet(sortOrder, sortBy, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add tariff plan
   * @param {AddTariffPlanRequest} addTariffPlanRequest tariff plan data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingTariffPlansPost(
    addTariffPlanRequest: AddTariffPlanRequest,
    options?: AxiosRequestConfig
  ) {
    return BillingApiFp(this.configuration)
      .apiBillingTariffPlansPost(addTariffPlanRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update tariff plan
   * @param {number} tariffPlanId tariff plan id
   * @param {EditTariffPlanRequest} editTariffPlanRequest tariff plan data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingTariffPlansTariffPlanIdPatch(
    tariffPlanId: number,
    editTariffPlanRequest: EditTariffPlanRequest,
    options?: AxiosRequestConfig
  ) {
    return BillingApiFp(this.configuration)
      .apiBillingTariffPlansTariffPlanIdPatch(tariffPlanId, editTariffPlanRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Accepts events from payment systems.
   * @summary Payment systems event listener.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public apiBillingXenditPaymentListenerPost(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .apiBillingXenditPaymentListenerPost(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ConnectionsApi - axios parameter creator
 * @export
 */
export const ConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add a connection to a marketplace account
     * @param {AddConnectionRequest} [addConnectionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsAddConnectionPost: async (
      addConnectionRequest?: AddConnectionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/connections/add-connection/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addConnectionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a connection to a marketplace account
     * @param {number} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsDeleteConnectionConnectionIdDelete: async (
      connectionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists(
        'apiConnectionsDeleteConnectionConnectionIdDelete',
        'connectionId',
        connectionId
      );
      const localVarPath = `/api/connections/delete-connection/{connection_id}/`.replace(
        `{${'connection_id'}}`,
        encodeURIComponent(String(connectionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting a list of connections (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {'Lazada' | 'Shopee' | 'Tokopedia'} [marketplace] marketplace name
     * @param {string} [country] country name
     * @param {number} [itemsPerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsListConnectionPageGet: async (
      page: number,
      userId?: string,
      marketplace?: 'Lazada' | 'Shopee' | 'Tokopedia',
      country?: string,
      itemsPerPage?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiConnectionsListConnectionPageGet', 'page', page);
      const localVarPath = `/api/connections/list-connection/{page}/`.replace(
        `{${'page'}}`,
        encodeURIComponent(String(page))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      if (marketplace !== undefined) {
        localVarQueryParameter['marketplace'] = marketplace;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get platforms list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsPlatformsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/connections/platforms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request a connection to a marketplace account (sends email).
     * @param {ConnectionsSchema} [connectionsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsRequestConnectionPost: async (
      connectionsSchema?: ConnectionsSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/connections/request-connection/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        connectionsSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a connection to a marketplace account
     * @param {UpdateConnectionRequest} [updateConnectionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsUpdateConnectionPost: async (
      updateConnectionRequest?: UpdateConnectionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/connections/update-connection/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateConnectionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConnectionsApi - functional programming interface
 * @export
 */
export const ConnectionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConnectionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add a connection to a marketplace account
     * @param {AddConnectionRequest} [addConnectionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConnectionsAddConnectionPost(
      addConnectionRequest?: AddConnectionRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddConnectionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionsAddConnectionPost(
        addConnectionRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a connection to a marketplace account
     * @param {number} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConnectionsDeleteConnectionConnectionIdDelete(
      connectionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiConnectionsDeleteConnectionConnectionIdDelete(
          connectionId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting a list of connections (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {'Lazada' | 'Shopee' | 'Tokopedia'} [marketplace] marketplace name
     * @param {string} [country] country name
     * @param {number} [itemsPerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConnectionsListConnectionPageGet(
      page: number,
      userId?: string,
      marketplace?: 'Lazada' | 'Shopee' | 'Tokopedia',
      country?: string,
      itemsPerPage?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionsPagination>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionsListConnectionPageGet(
        page,
        userId,
        marketplace,
        country,
        itemsPerPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get platforms list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConnectionsPlatformsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Platform>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionsPlatformsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Request a connection to a marketplace account (sends email).
     * @param {ConnectionsSchema} [connectionsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConnectionsRequestConnectionPost(
      connectionsSchema?: ConnectionsSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddConnectionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionsRequestConnectionPost(
        connectionsSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a connection to a marketplace account
     * @param {UpdateConnectionRequest} [updateConnectionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiConnectionsUpdateConnectionPost(
      updateConnectionRequest?: UpdateConnectionRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateConnectionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionsUpdateConnectionPost(
        updateConnectionRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ConnectionsApi - factory interface
 * @export
 */
export const ConnectionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ConnectionsApiFp(configuration);
  return {
    /**
     *
     * @summary Add a connection to a marketplace account
     * @param {AddConnectionRequest} [addConnectionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsAddConnectionPost(
      addConnectionRequest?: AddConnectionRequest,
      options?: any
    ): AxiosPromise<AddConnectionResponse> {
      return localVarFp
        .apiConnectionsAddConnectionPost(addConnectionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a connection to a marketplace account
     * @param {number} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsDeleteConnectionConnectionIdDelete(
      connectionId: number,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiConnectionsDeleteConnectionConnectionIdDelete(connectionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting a list of connections (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {'Lazada' | 'Shopee' | 'Tokopedia'} [marketplace] marketplace name
     * @param {string} [country] country name
     * @param {number} [itemsPerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsListConnectionPageGet(
      page: number,
      userId?: string,
      marketplace?: 'Lazada' | 'Shopee' | 'Tokopedia',
      country?: string,
      itemsPerPage?: number,
      options?: any
    ): AxiosPromise<ConnectionsPagination> {
      return localVarFp
        .apiConnectionsListConnectionPageGet(
          page,
          userId,
          marketplace,
          country,
          itemsPerPage,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get platforms list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsPlatformsGet(options?: any): AxiosPromise<Array<Platform>> {
      return localVarFp
        .apiConnectionsPlatformsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Request a connection to a marketplace account (sends email).
     * @param {ConnectionsSchema} [connectionsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsRequestConnectionPost(
      connectionsSchema?: ConnectionsSchema,
      options?: any
    ): AxiosPromise<AddConnectionResponse> {
      return localVarFp
        .apiConnectionsRequestConnectionPost(connectionsSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a connection to a marketplace account
     * @param {UpdateConnectionRequest} [updateConnectionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiConnectionsUpdateConnectionPost(
      updateConnectionRequest?: UpdateConnectionRequest,
      options?: any
    ): AxiosPromise<UpdateConnectionResponse> {
      return localVarFp
        .apiConnectionsUpdateConnectionPost(updateConnectionRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ConnectionsApi - object-oriented interface
 * @export
 * @class ConnectionsApi
 * @extends {BaseAPI}
 */
export class ConnectionsApi extends BaseAPI {
  /**
   *
   * @summary Add a connection to a marketplace account
   * @param {AddConnectionRequest} [addConnectionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public apiConnectionsAddConnectionPost(
    addConnectionRequest?: AddConnectionRequest,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .apiConnectionsAddConnectionPost(addConnectionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a connection to a marketplace account
   * @param {number} connectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public apiConnectionsDeleteConnectionConnectionIdDelete(
    connectionId: number,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .apiConnectionsDeleteConnectionConnectionIdDelete(connectionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting a list of connections (filtered or not)
   * @param {number} page
   * @param {string} [userId]
   * @param {'Lazada' | 'Shopee' | 'Tokopedia'} [marketplace] marketplace name
   * @param {string} [country] country name
   * @param {number} [itemsPerPage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public apiConnectionsListConnectionPageGet(
    page: number,
    userId?: string,
    marketplace?: 'Lazada' | 'Shopee' | 'Tokopedia',
    country?: string,
    itemsPerPage?: number,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .apiConnectionsListConnectionPageGet(
        page,
        userId,
        marketplace,
        country,
        itemsPerPage,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get platforms list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public apiConnectionsPlatformsGet(options?: AxiosRequestConfig) {
    return ConnectionsApiFp(this.configuration)
      .apiConnectionsPlatformsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Request a connection to a marketplace account (sends email).
   * @param {ConnectionsSchema} [connectionsSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public apiConnectionsRequestConnectionPost(
    connectionsSchema?: ConnectionsSchema,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .apiConnectionsRequestConnectionPost(connectionsSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a connection to a marketplace account
   * @param {UpdateConnectionRequest} [updateConnectionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public apiConnectionsUpdateConnectionPost(
    updateConnectionRequest?: UpdateConnectionRequest,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .apiConnectionsUpdateConnectionPost(updateConnectionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Sends an email on the internal email address.
     * @param {InternalEmailInput} internalEmailInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEmailInternalSendPost: async (
      internalEmailInput: InternalEmailInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'internalEmailInput' is not null or undefined
      assertParamExists('apiEmailInternalSendPost', 'internalEmailInput', internalEmailInput);
      const localVarPath = `/api/email/internal/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        internalEmailInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Sends an email on the internal email address.
     * @param {InternalEmailInput} internalEmailInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEmailInternalSendPost(
      internalEmailInput: InternalEmailInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailInternalSendPost(
        internalEmailInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EmailApiFp(configuration);
  return {
    /**
     *
     * @summary Sends an email on the internal email address.
     * @param {InternalEmailInput} internalEmailInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEmailInternalSendPost(
      internalEmailInput: InternalEmailInput,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiEmailInternalSendPost(internalEmailInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
  /**
   *
   * @summary Sends an email on the internal email address.
   * @param {InternalEmailInput} internalEmailInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public apiEmailInternalSendPost(
    internalEmailInput: InternalEmailInput,
    options?: AxiosRequestConfig
  ) {
    return EmailApiFp(this.configuration)
      .apiEmailInternalSendPost(internalEmailInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InternalAnalyticsApi - axios parameter creator
 * @export
 */
export const InternalAnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get brand list
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsBrandsGet: async (
      page?: number,
      itemsPerPage?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/internal-analytics/brands`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get categories list for a marketplace
     * @param {number} marketplaceId Marketplace ID.
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsCategoriesGet: async (
      marketplaceId: number,
      page?: number,
      itemsPerPage?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'marketplaceId' is not null or undefined
      assertParamExists('apiInternalAnalyticsCategoriesGet', 'marketplaceId', marketplaceId);
      const localVarPath = `/api/internal-analytics/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (marketplaceId !== undefined) {
        localVarQueryParameter['marketplace_id'] = marketplaceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Margin search - search product MD5 by actual ID.
     * @param {string} q Actual product variant ID (not MD5).
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginSearchGet: async (
      q: string,
      connectionIds?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'q' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginSearchGet', 'q', q);
      const localVarPath = `/api/internal-analytics/margin/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InternalAnalyticsApi - functional programming interface
 * @export
 */
export const InternalAnalyticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InternalAnalyticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get brand list
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsBrandsGet(
      page?: number,
      itemsPerPage?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternalAnalyticsBrandsGet(
        page,
        itemsPerPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get categories list for a marketplace
     * @param {number} marketplaceId Marketplace ID.
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsCategoriesGet(
      marketplaceId: number,
      page?: number,
      itemsPerPage?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternalAnalyticsCategoriesGet(
        marketplaceId,
        page,
        itemsPerPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Margin search - search product MD5 by actual ID.
     * @param {string} q Actual product variant ID (not MD5).
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsMarginSearchGet(
      q: string,
      connectionIds?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarginSearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternalAnalyticsMarginSearchGet(
        q,
        connectionIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InternalAnalyticsApi - factory interface
 * @export
 */
export const InternalAnalyticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InternalAnalyticsApiFp(configuration);
  return {
    /**
     *
     * @summary Get brand list
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsBrandsGet(
      page?: number,
      itemsPerPage?: number,
      options?: any
    ): AxiosPromise<BrandListResponse> {
      return localVarFp
        .apiInternalAnalyticsBrandsGet(page, itemsPerPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get categories list for a marketplace
     * @param {number} marketplaceId Marketplace ID.
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsCategoriesGet(
      marketplaceId: number,
      page?: number,
      itemsPerPage?: number,
      options?: any
    ): AxiosPromise<CategoryListResponse> {
      return localVarFp
        .apiInternalAnalyticsCategoriesGet(marketplaceId, page, itemsPerPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Margin search - search product MD5 by actual ID.
     * @param {string} q Actual product variant ID (not MD5).
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginSearchGet(
      q: string,
      connectionIds?: Array<number>,
      options?: any
    ): AxiosPromise<MarginSearchResponse> {
      return localVarFp
        .apiInternalAnalyticsMarginSearchGet(q, connectionIds, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InternalAnalyticsApi - object-oriented interface
 * @export
 * @class InternalAnalyticsApi
 * @extends {BaseAPI}
 */
export class InternalAnalyticsApi extends BaseAPI {
  /**
   *
   * @summary Get brand list
   * @param {number} [page] Number of a page to request.
   * @param {number} [itemsPerPage] Number of items per page to request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsApi
   */
  public apiInternalAnalyticsBrandsGet(
    page?: number,
    itemsPerPage?: number,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsApiFp(this.configuration)
      .apiInternalAnalyticsBrandsGet(page, itemsPerPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get categories list for a marketplace
   * @param {number} marketplaceId Marketplace ID.
   * @param {number} [page] Number of a page to request.
   * @param {number} [itemsPerPage] Number of items per page to request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsApi
   */
  public apiInternalAnalyticsCategoriesGet(
    marketplaceId: number,
    page?: number,
    itemsPerPage?: number,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsApiFp(this.configuration)
      .apiInternalAnalyticsCategoriesGet(marketplaceId, page, itemsPerPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Margin search - search product MD5 by actual ID.
   * @param {string} q Actual product variant ID (not MD5).
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsApi
   */
  public apiInternalAnalyticsMarginSearchGet(
    q: string,
    connectionIds?: Array<number>,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsApiFp(this.configuration)
      .apiInternalAnalyticsMarginSearchGet(q, connectionIds, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InternalAnalyticsReportsApi - axios parameter creator
 * @export
 */
export const InternalAnalyticsReportsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Reports abc analyze
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {'sales' | 'margin' | 'turnover'} [sortBy] The field to sort by.
     * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [brandFilter] Brand names for filtering
     * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
     * @param {Array<string>} [variantIds] Variant IDs for filtering.
     * @param {'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c'} [abcCategoryFilter] Type of ABC filtering. You must specify **abc_category** filter when using filter **abc_category_filter** with values: &#x60;sales_category&#x60;, &#x60;margin_category&#x60;.
     * @param {'A' | 'B' | 'C'} [abcCategory] ABC category to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsAbcAnalyzeGet: async (
      dateFrom: string,
      dateTo: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?: 'sales' | 'margin' | 'turnover',
      sortOrder?: 'asc' | 'desc',
      connectionIds?: Array<number>,
      demo?: boolean,
      brandFilter?: Array<string>,
      productCategoryFilter?: Array<string>,
      variantIds?: Array<string>,
      abcCategoryFilter?: 'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c',
      abcCategory?: 'A' | 'B' | 'C',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dateFrom' is not null or undefined
      assertParamExists('apiInternalAnalyticsAbcAnalyzeGet', 'dateFrom', dateFrom);
      // verify required parameter 'dateTo' is not null or undefined
      assertParamExists('apiInternalAnalyticsAbcAnalyzeGet', 'dateTo', dateTo);
      const localVarPath = `/api/internal-analytics/abc/analyze`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (demo !== undefined) {
        localVarQueryParameter['demo'] = demo;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (brandFilter) {
        localVarQueryParameter['brand_filter'] = brandFilter;
      }

      if (productCategoryFilter) {
        localVarQueryParameter['product_category_filter'] = productCategoryFilter;
      }

      if (variantIds) {
        localVarQueryParameter['variant_ids'] = variantIds;
      }

      if (abcCategoryFilter !== undefined) {
        localVarQueryParameter['abc_category_filter'] = abcCategoryFilter;
      }

      if (abcCategory !== undefined) {
        localVarQueryParameter['abc_category'] = abcCategory;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reports abc analyze total
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [brandFilter] Brand names for filtering
     * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsAbcAnalyzeTotalGet: async (
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      brandFilter?: Array<string>,
      productCategoryFilter?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dateFrom' is not null or undefined
      assertParamExists('apiInternalAnalyticsAbcAnalyzeTotalGet', 'dateFrom', dateFrom);
      // verify required parameter 'dateTo' is not null or undefined
      assertParamExists('apiInternalAnalyticsAbcAnalyzeTotalGet', 'dateTo', dateTo);
      const localVarPath = `/api/internal-analytics/abc/analyze/total`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (demo !== undefined) {
        localVarQueryParameter['demo'] = demo;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (brandFilter) {
        localVarQueryParameter['brand_filter'] = brandFilter;
      }

      if (productCategoryFilter) {
        localVarQueryParameter['product_category_filter'] = productCategoryFilter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Margin analyze
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {'am_s' | 'qty' | 'com_s' | 'com_rate' | 'logist_s' | 'logist_rate' | 'return_logist_s' | 'return_logist_rate' | 'adserv_s' | 'as_rate' | 'tax_value' | 'tax_rate' | 'cost_s' | 'cost_rate' | 'profit' | 'profit_rate'} [sortBy] The field to sort by.
     * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [variantIds] Variant IDs for filtering.
     * @param {number} [profitLimit] Profitability threshold.
     * @param {'no_cost' | 'low_prof' | 'negative_prof'} [noCostFilter] No cost filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginGet: async (
      dateFrom: string,
      dateTo: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?:
        | 'am_s'
        | 'qty'
        | 'com_s'
        | 'com_rate'
        | 'logist_s'
        | 'logist_rate'
        | 'return_logist_s'
        | 'return_logist_rate'
        | 'adserv_s'
        | 'as_rate'
        | 'tax_value'
        | 'tax_rate'
        | 'cost_s'
        | 'cost_rate'
        | 'profit'
        | 'profit_rate',
      sortOrder?: 'asc' | 'desc',
      connectionIds?: Array<number>,
      demo?: boolean,
      variantIds?: Array<string>,
      profitLimit?: number,
      noCostFilter?: 'no_cost' | 'low_prof' | 'negative_prof',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dateFrom' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginGet', 'dateFrom', dateFrom);
      // verify required parameter 'dateTo' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginGet', 'dateTo', dateTo);
      const localVarPath = `/api/internal-analytics/margin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (demo !== undefined) {
        localVarQueryParameter['demo'] = demo;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (variantIds) {
        localVarQueryParameter['variant_ids'] = variantIds;
      }

      if (profitLimit !== undefined) {
        localVarQueryParameter['profit_limit'] = profitLimit;
      }

      if (noCostFilter !== undefined) {
        localVarQueryParameter['no_cost_filter'] = noCostFilter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Margin total report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {number} [profitLimit] Profitability threshold.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginTotalGet: async (
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      profitLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dateFrom' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginTotalGet', 'dateFrom', dateFrom);
      // verify required parameter 'dateTo' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginTotalGet', 'dateTo', dateTo);
      const localVarPath = `/api/internal-analytics/margin/total`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (demo !== undefined) {
        localVarQueryParameter['demo'] = demo;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (profitLimit !== undefined) {
        localVarQueryParameter['profit_limit'] = profitLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Margin transactions report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {string} variantId Product variant ID.
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginTransactionsGet: async (
      dateFrom: string,
      dateTo: string,
      variantId: string,
      page?: number,
      itemsPerPage?: number,
      connectionIds?: Array<number>,
      demo?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dateFrom' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginTransactionsGet', 'dateFrom', dateFrom);
      // verify required parameter 'dateTo' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginTransactionsGet', 'dateTo', dateTo);
      // verify required parameter 'variantId' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginTransactionsGet', 'variantId', variantId);
      const localVarPath = `/api/internal-analytics/margin/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (demo !== undefined) {
        localVarQueryParameter['demo'] = demo;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (variantId !== undefined) {
        localVarQueryParameter['variant_id'] = variantId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Margin widget profit expenses report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginWidgetProfitExpensesGet: async (
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dateFrom' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginWidgetProfitExpensesGet', 'dateFrom', dateFrom);
      // verify required parameter 'dateTo' is not null or undefined
      assertParamExists('apiInternalAnalyticsMarginWidgetProfitExpensesGet', 'dateTo', dateTo);
      const localVarPath = `/api/internal-analytics/margin/widget-profit-expenses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (connectionIds) {
        localVarQueryParameter['connection_ids'] = connectionIds;
      }

      if (demo !== undefined) {
        localVarQueryParameter['demo'] = demo;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InternalAnalyticsReportsApi - functional programming interface
 * @export
 */
export const InternalAnalyticsReportsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InternalAnalyticsReportsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Reports abc analyze
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {'sales' | 'margin' | 'turnover'} [sortBy] The field to sort by.
     * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [brandFilter] Brand names for filtering
     * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
     * @param {Array<string>} [variantIds] Variant IDs for filtering.
     * @param {'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c'} [abcCategoryFilter] Type of ABC filtering. You must specify **abc_category** filter when using filter **abc_category_filter** with values: &#x60;sales_category&#x60;, &#x60;margin_category&#x60;.
     * @param {'A' | 'B' | 'C'} [abcCategory] ABC category to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsAbcAnalyzeGet(
      dateFrom: string,
      dateTo: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?: 'sales' | 'margin' | 'turnover',
      sortOrder?: 'asc' | 'desc',
      connectionIds?: Array<number>,
      demo?: boolean,
      brandFilter?: Array<string>,
      productCategoryFilter?: Array<string>,
      variantIds?: Array<string>,
      abcCategoryFilter?: 'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c',
      abcCategory?: 'A' | 'B' | 'C',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbcAnalyzeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternalAnalyticsAbcAnalyzeGet(
        dateFrom,
        dateTo,
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        connectionIds,
        demo,
        brandFilter,
        productCategoryFilter,
        variantIds,
        abcCategoryFilter,
        abcCategory,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Reports abc analyze total
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [brandFilter] Brand names for filtering
     * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsAbcAnalyzeTotalGet(
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      brandFilter?: Array<string>,
      productCategoryFilter?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbcAnalyzeTotalResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiInternalAnalyticsAbcAnalyzeTotalGet(
          dateFrom,
          dateTo,
          connectionIds,
          demo,
          brandFilter,
          productCategoryFilter,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Margin analyze
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {'am_s' | 'qty' | 'com_s' | 'com_rate' | 'logist_s' | 'logist_rate' | 'return_logist_s' | 'return_logist_rate' | 'adserv_s' | 'as_rate' | 'tax_value' | 'tax_rate' | 'cost_s' | 'cost_rate' | 'profit' | 'profit_rate'} [sortBy] The field to sort by.
     * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [variantIds] Variant IDs for filtering.
     * @param {number} [profitLimit] Profitability threshold.
     * @param {'no_cost' | 'low_prof' | 'negative_prof'} [noCostFilter] No cost filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsMarginGet(
      dateFrom: string,
      dateTo: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?:
        | 'am_s'
        | 'qty'
        | 'com_s'
        | 'com_rate'
        | 'logist_s'
        | 'logist_rate'
        | 'return_logist_s'
        | 'return_logist_rate'
        | 'adserv_s'
        | 'as_rate'
        | 'tax_value'
        | 'tax_rate'
        | 'cost_s'
        | 'cost_rate'
        | 'profit'
        | 'profit_rate',
      sortOrder?: 'asc' | 'desc',
      connectionIds?: Array<number>,
      demo?: boolean,
      variantIds?: Array<string>,
      profitLimit?: number,
      noCostFilter?: 'no_cost' | 'low_prof' | 'negative_prof',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternalAnalyticsMarginGet(
        dateFrom,
        dateTo,
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        connectionIds,
        demo,
        variantIds,
        profitLimit,
        noCostFilter,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Margin total report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {number} [profitLimit] Profitability threshold.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsMarginTotalGet(
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      profitLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarginTotalResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiInternalAnalyticsMarginTotalGet(
        dateFrom,
        dateTo,
        connectionIds,
        demo,
        profitLimit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Margin transactions report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {string} variantId Product variant ID.
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsMarginTransactionsGet(
      dateFrom: string,
      dateTo: string,
      variantId: string,
      page?: number,
      itemsPerPage?: number,
      connectionIds?: Array<number>,
      demo?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarginTransactionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiInternalAnalyticsMarginTransactionsGet(
          dateFrom,
          dateTo,
          variantId,
          page,
          itemsPerPage,
          connectionIds,
          demo,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Margin widget profit expenses report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInternalAnalyticsMarginWidgetProfitExpensesGet(
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarginWidgetProfitExpensesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiInternalAnalyticsMarginWidgetProfitExpensesGet(
          dateFrom,
          dateTo,
          connectionIds,
          demo,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InternalAnalyticsReportsApi - factory interface
 * @export
 */
export const InternalAnalyticsReportsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InternalAnalyticsReportsApiFp(configuration);
  return {
    /**
     *
     * @summary Reports abc analyze
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {'sales' | 'margin' | 'turnover'} [sortBy] The field to sort by.
     * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [brandFilter] Brand names for filtering
     * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
     * @param {Array<string>} [variantIds] Variant IDs for filtering.
     * @param {'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c'} [abcCategoryFilter] Type of ABC filtering. You must specify **abc_category** filter when using filter **abc_category_filter** with values: &#x60;sales_category&#x60;, &#x60;margin_category&#x60;.
     * @param {'A' | 'B' | 'C'} [abcCategory] ABC category to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsAbcAnalyzeGet(
      dateFrom: string,
      dateTo: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?: 'sales' | 'margin' | 'turnover',
      sortOrder?: 'asc' | 'desc',
      connectionIds?: Array<number>,
      demo?: boolean,
      brandFilter?: Array<string>,
      productCategoryFilter?: Array<string>,
      variantIds?: Array<string>,
      abcCategoryFilter?: 'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c',
      abcCategory?: 'A' | 'B' | 'C',
      options?: any
    ): AxiosPromise<AbcAnalyzeResponse> {
      return localVarFp
        .apiInternalAnalyticsAbcAnalyzeGet(
          dateFrom,
          dateTo,
          page,
          itemsPerPage,
          sortBy,
          sortOrder,
          connectionIds,
          demo,
          brandFilter,
          productCategoryFilter,
          variantIds,
          abcCategoryFilter,
          abcCategory,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reports abc analyze total
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [brandFilter] Brand names for filtering
     * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsAbcAnalyzeTotalGet(
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      brandFilter?: Array<string>,
      productCategoryFilter?: Array<string>,
      options?: any
    ): AxiosPromise<AbcAnalyzeTotalResponse> {
      return localVarFp
        .apiInternalAnalyticsAbcAnalyzeTotalGet(
          dateFrom,
          dateTo,
          connectionIds,
          demo,
          brandFilter,
          productCategoryFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Margin analyze
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {'am_s' | 'qty' | 'com_s' | 'com_rate' | 'logist_s' | 'logist_rate' | 'return_logist_s' | 'return_logist_rate' | 'adserv_s' | 'as_rate' | 'tax_value' | 'tax_rate' | 'cost_s' | 'cost_rate' | 'profit' | 'profit_rate'} [sortBy] The field to sort by.
     * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {Array<string>} [variantIds] Variant IDs for filtering.
     * @param {number} [profitLimit] Profitability threshold.
     * @param {'no_cost' | 'low_prof' | 'negative_prof'} [noCostFilter] No cost filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginGet(
      dateFrom: string,
      dateTo: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?:
        | 'am_s'
        | 'qty'
        | 'com_s'
        | 'com_rate'
        | 'logist_s'
        | 'logist_rate'
        | 'return_logist_s'
        | 'return_logist_rate'
        | 'adserv_s'
        | 'as_rate'
        | 'tax_value'
        | 'tax_rate'
        | 'cost_s'
        | 'cost_rate'
        | 'profit'
        | 'profit_rate',
      sortOrder?: 'asc' | 'desc',
      connectionIds?: Array<number>,
      demo?: boolean,
      variantIds?: Array<string>,
      profitLimit?: number,
      noCostFilter?: 'no_cost' | 'low_prof' | 'negative_prof',
      options?: any
    ): AxiosPromise<MarginResponse> {
      return localVarFp
        .apiInternalAnalyticsMarginGet(
          dateFrom,
          dateTo,
          page,
          itemsPerPage,
          sortBy,
          sortOrder,
          connectionIds,
          demo,
          variantIds,
          profitLimit,
          noCostFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Margin total report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {number} [profitLimit] Profitability threshold.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginTotalGet(
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      profitLimit?: number,
      options?: any
    ): AxiosPromise<MarginTotalResponse> {
      return localVarFp
        .apiInternalAnalyticsMarginTotalGet(
          dateFrom,
          dateTo,
          connectionIds,
          demo,
          profitLimit,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Margin transactions report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {string} variantId Product variant ID.
     * @param {number} [page] Number of a page to request.
     * @param {number} [itemsPerPage] Number of items per page to request.
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginTransactionsGet(
      dateFrom: string,
      dateTo: string,
      variantId: string,
      page?: number,
      itemsPerPage?: number,
      connectionIds?: Array<number>,
      demo?: boolean,
      options?: any
    ): AxiosPromise<MarginTransactionsResponse> {
      return localVarFp
        .apiInternalAnalyticsMarginTransactionsGet(
          dateFrom,
          dateTo,
          variantId,
          page,
          itemsPerPage,
          connectionIds,
          demo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Margin widget profit expenses report
     * @param {string} dateFrom
     * @param {string} dateTo
     * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
     * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInternalAnalyticsMarginWidgetProfitExpensesGet(
      dateFrom: string,
      dateTo: string,
      connectionIds?: Array<number>,
      demo?: boolean,
      options?: any
    ): AxiosPromise<MarginWidgetProfitExpensesResponse> {
      return localVarFp
        .apiInternalAnalyticsMarginWidgetProfitExpensesGet(
          dateFrom,
          dateTo,
          connectionIds,
          demo,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InternalAnalyticsReportsApi - object-oriented interface
 * @export
 * @class InternalAnalyticsReportsApi
 * @extends {BaseAPI}
 */
export class InternalAnalyticsReportsApi extends BaseAPI {
  /**
   *
   * @summary Reports abc analyze
   * @param {string} dateFrom
   * @param {string} dateTo
   * @param {number} [page] Number of a page to request.
   * @param {number} [itemsPerPage] Number of items per page to request.
   * @param {'sales' | 'margin' | 'turnover'} [sortBy] The field to sort by.
   * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
   * @param {Array<string>} [brandFilter] Brand names for filtering
   * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
   * @param {Array<string>} [variantIds] Variant IDs for filtering.
   * @param {'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c'} [abcCategoryFilter] Type of ABC filtering. You must specify **abc_category** filter when using filter **abc_category_filter** with values: &#x60;sales_category&#x60;, &#x60;margin_category&#x60;.
   * @param {'A' | 'B' | 'C'} [abcCategory] ABC category to filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsReportsApi
   */
  public apiInternalAnalyticsAbcAnalyzeGet(
    dateFrom: string,
    dateTo: string,
    page?: number,
    itemsPerPage?: number,
    sortBy?: 'sales' | 'margin' | 'turnover',
    sortOrder?: 'asc' | 'desc',
    connectionIds?: Array<number>,
    demo?: boolean,
    brandFilter?: Array<string>,
    productCategoryFilter?: Array<string>,
    variantIds?: Array<string>,
    abcCategoryFilter?: 'sales_category' | 'margin_category' | 'skew_category' | 'zero_sales_c',
    abcCategory?: 'A' | 'B' | 'C',
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsReportsApiFp(this.configuration)
      .apiInternalAnalyticsAbcAnalyzeGet(
        dateFrom,
        dateTo,
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        connectionIds,
        demo,
        brandFilter,
        productCategoryFilter,
        variantIds,
        abcCategoryFilter,
        abcCategory,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reports abc analyze total
   * @param {string} dateFrom
   * @param {string} dateTo
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
   * @param {Array<string>} [brandFilter] Brand names for filtering
   * @param {Array<string>} [productCategoryFilter] Product category IDs for filtering
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsReportsApi
   */
  public apiInternalAnalyticsAbcAnalyzeTotalGet(
    dateFrom: string,
    dateTo: string,
    connectionIds?: Array<number>,
    demo?: boolean,
    brandFilter?: Array<string>,
    productCategoryFilter?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsReportsApiFp(this.configuration)
      .apiInternalAnalyticsAbcAnalyzeTotalGet(
        dateFrom,
        dateTo,
        connectionIds,
        demo,
        brandFilter,
        productCategoryFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Margin analyze
   * @param {string} dateFrom
   * @param {string} dateTo
   * @param {number} [page] Number of a page to request.
   * @param {number} [itemsPerPage] Number of items per page to request.
   * @param {'am_s' | 'qty' | 'com_s' | 'com_rate' | 'logist_s' | 'logist_rate' | 'return_logist_s' | 'return_logist_rate' | 'adserv_s' | 'as_rate' | 'tax_value' | 'tax_rate' | 'cost_s' | 'cost_rate' | 'profit' | 'profit_rate'} [sortBy] The field to sort by.
   * @param {'asc' | 'desc'} [sortOrder] The sorting order is indicated by \&#39;asc\&#39; for lowest to highest and \&#39;desc\&#39; for highest to lowest.
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
   * @param {Array<string>} [variantIds] Variant IDs for filtering.
   * @param {number} [profitLimit] Profitability threshold.
   * @param {'no_cost' | 'low_prof' | 'negative_prof'} [noCostFilter] No cost filter.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsReportsApi
   */
  public apiInternalAnalyticsMarginGet(
    dateFrom: string,
    dateTo: string,
    page?: number,
    itemsPerPage?: number,
    sortBy?:
      | 'am_s'
      | 'qty'
      | 'com_s'
      | 'com_rate'
      | 'logist_s'
      | 'logist_rate'
      | 'return_logist_s'
      | 'return_logist_rate'
      | 'adserv_s'
      | 'as_rate'
      | 'tax_value'
      | 'tax_rate'
      | 'cost_s'
      | 'cost_rate'
      | 'profit'
      | 'profit_rate',
    sortOrder?: 'asc' | 'desc',
    connectionIds?: Array<number>,
    demo?: boolean,
    variantIds?: Array<string>,
    profitLimit?: number,
    noCostFilter?: 'no_cost' | 'low_prof' | 'negative_prof',
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsReportsApiFp(this.configuration)
      .apiInternalAnalyticsMarginGet(
        dateFrom,
        dateTo,
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        connectionIds,
        demo,
        variantIds,
        profitLimit,
        noCostFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Margin total report
   * @param {string} dateFrom
   * @param {string} dateTo
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
   * @param {number} [profitLimit] Profitability threshold.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsReportsApi
   */
  public apiInternalAnalyticsMarginTotalGet(
    dateFrom: string,
    dateTo: string,
    connectionIds?: Array<number>,
    demo?: boolean,
    profitLimit?: number,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsReportsApiFp(this.configuration)
      .apiInternalAnalyticsMarginTotalGet(
        dateFrom,
        dateTo,
        connectionIds,
        demo,
        profitLimit,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Margin transactions report
   * @param {string} dateFrom
   * @param {string} dateTo
   * @param {string} variantId Product variant ID.
   * @param {number} [page] Number of a page to request.
   * @param {number} [itemsPerPage] Number of items per page to request.
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsReportsApi
   */
  public apiInternalAnalyticsMarginTransactionsGet(
    dateFrom: string,
    dateTo: string,
    variantId: string,
    page?: number,
    itemsPerPage?: number,
    connectionIds?: Array<number>,
    demo?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsReportsApiFp(this.configuration)
      .apiInternalAnalyticsMarginTransactionsGet(
        dateFrom,
        dateTo,
        variantId,
        page,
        itemsPerPage,
        connectionIds,
        demo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Margin widget profit expenses report
   * @param {string} dateFrom
   * @param {string} dateTo
   * @param {Array<number>} [connectionIds] User\&#39;s connection IDs. Default: all user connections.
   * @param {boolean} [demo] Use only demo data. &#x60;connection_ids&#x60; ignored.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalAnalyticsReportsApi
   */
  public apiInternalAnalyticsMarginWidgetProfitExpensesGet(
    dateFrom: string,
    dateTo: string,
    connectionIds?: Array<number>,
    demo?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InternalAnalyticsReportsApiFp(this.configuration)
      .apiInternalAnalyticsMarginWidgetProfitExpensesGet(
        dateFrom,
        dateTo,
        connectionIds,
        demo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LoginHistoryApi - axios parameter creator
 * @export
 */
export const LoginHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Getting a list of login logs (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [country]
     * @param {string} [userIp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLoggingListLogsPageGet: async (
      page: number,
      userId?: string,
      fromDate?: string,
      toDate?: string,
      country?: string,
      userIp?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiLoggingListLogsPageGet', 'page', page);
      const localVarPath = `/api/logging/list-logs/{page}/`.replace(
        `{${'page'}}`,
        encodeURIComponent(String(page))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter['from_date'] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter['to_date'] = toDate;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      if (userIp !== undefined) {
        localVarQueryParameter['user_ip'] = userIp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LoginHistoryApi - functional programming interface
 * @export
 */
export const LoginHistoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LoginHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Getting a list of login logs (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [country]
     * @param {string} [userIp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLoggingListLogsPageGet(
      page: number,
      userId?: string,
      fromDate?: string,
      toDate?: string,
      country?: string,
      userIp?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginHistoryPagination>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoggingListLogsPageGet(
        page,
        userId,
        fromDate,
        toDate,
        country,
        userIp,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LoginHistoryApi - factory interface
 * @export
 */
export const LoginHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LoginHistoryApiFp(configuration);
  return {
    /**
     *
     * @summary Getting a list of login logs (filtered or not)
     * @param {number} page
     * @param {string} [userId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [country]
     * @param {string} [userIp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLoggingListLogsPageGet(
      page: number,
      userId?: string,
      fromDate?: string,
      toDate?: string,
      country?: string,
      userIp?: string,
      options?: any
    ): AxiosPromise<LoginHistoryPagination> {
      return localVarFp
        .apiLoggingListLogsPageGet(page, userId, fromDate, toDate, country, userIp, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LoginHistoryApi - object-oriented interface
 * @export
 * @class LoginHistoryApi
 * @extends {BaseAPI}
 */
export class LoginHistoryApi extends BaseAPI {
  /**
   *
   * @summary Getting a list of login logs (filtered or not)
   * @param {number} page
   * @param {string} [userId]
   * @param {string} [fromDate]
   * @param {string} [toDate]
   * @param {string} [country]
   * @param {string} [userIp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginHistoryApi
   */
  public apiLoggingListLogsPageGet(
    page: number,
    userId?: string,
    fromDate?: string,
    toDate?: string,
    country?: string,
    userIp?: string,
    options?: AxiosRequestConfig
  ) {
    return LoginHistoryApiFp(this.configuration)
      .apiLoggingListLogsPageGet(page, userId, fromDate, toDate, country, userIp, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Удаление категории
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryCategoryIdDelete: async (
      categoryId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('apiNewsCategoryCategoryIdDelete', 'categoryId', categoryId);
      const localVarPath = `/api/news/category/{category_id}/`.replace(
        `{${'category_id'}}`,
        encodeURIComponent(String(categoryId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление категории
     * @param {number} categoryId
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryCategoryIdPost: async (
      categoryId: number,
      inlineObject2?: InlineObject2,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('apiNewsCategoryCategoryIdPost', 'categoryId', categoryId);
      const localVarPath = `/api/news/category/{category_id}/`.replace(
        `{${'category_id'}}`,
        encodeURIComponent(String(categoryId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject2,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение списка категорий новостей
     * @param {number} page
     * @param {string} [name]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryGet: async (
      page: number,
      name?: string,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiNewsCategoryGet', 'page', page);
      const localVarPath = `/api/news/category/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление категории
     * @param {CreateCategorySchema} [createCategorySchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryPost: async (
      createCategorySchema?: CreateCategorySchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/news/category/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCategorySchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление комментариев
     * @param {number} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentCommentIdDelete: async (
      commentId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists('apiNewsCommentCommentIdDelete', 'commentId', commentId);
      const localVarPath = `/api/news/comment/{comment_id}/`.replace(
        `{${'comment_id'}}`,
        encodeURIComponent(String(commentId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение комментариев
     * @param {number} commentId
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentCommentIdPost: async (
      commentId: number,
      inlineObject3?: InlineObject3,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists('apiNewsCommentCommentIdPost', 'commentId', commentId);
      const localVarPath = `/api/news/comment/{comment_id}/`.replace(
        `{${'comment_id'}}`,
        encodeURIComponent(String(commentId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject3,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение списка комментариев
     * @param {number} newsId
     * @param {number} page
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentGet: async (
      newsId: number,
      page: number,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newsId' is not null or undefined
      assertParamExists('apiNewsCommentGet', 'newsId', newsId);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiNewsCommentGet', 'page', page);
      const localVarPath = `/api/news/comment/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (newsId !== undefined) {
        localVarQueryParameter['news_id'] = newsId;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление комментария
     * @param {CommentSchema} [commentSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentPost: async (
      commentSchema?: CommentSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/news/comment/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        commentSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение всех новостей
     * @param {number} page
     * @param {string} [toDate]
     * @param {Array<number>} [createdBy]
     * @param {boolean} [isLiked]
     * @param {number} [itemsPerPage]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {Array<number>} [categories]
     * @param {boolean} [activity]
     * @param {string} [fromDate]
     * @param {Array<number>} [tags]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsGet: async (
      page: number,
      toDate?: string,
      createdBy?: Array<number>,
      isLiked?: boolean,
      itemsPerPage?: number,
      sortOrder?: string,
      sortBy?: string,
      categories?: Array<number>,
      activity?: boolean,
      fromDate?: string,
      tags?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiNewsGet', 'page', page);
      const localVarPath = `/api/news/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (toDate !== undefined) {
        localVarQueryParameter['to_date'] =
          (toDate as any) instanceof Date ? (toDate as any).toISOString().substr(0, 10) : toDate;
      }

      if (createdBy) {
        localVarQueryParameter['created_by'] = createdBy;
      }

      if (isLiked !== undefined) {
        localVarQueryParameter['is_liked'] = isLiked;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (categories) {
        localVarQueryParameter['categories'] = categories;
      }

      if (activity !== undefined) {
        localVarQueryParameter['activity'] = activity;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter['from_date'] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString().substr(0, 10)
            : fromDate;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление новости
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdDelete: async (
      newsId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newsId' is not null or undefined
      assertParamExists('apiNewsNewsIdDelete', 'newsId', newsId);
      const localVarPath = `/api/news/{news_id}/`.replace(
        `{${'news_id'}}`,
        encodeURIComponent(String(newsId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение данных по одной новости
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdGet: async (
      newsId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newsId' is not null or undefined
      assertParamExists('apiNewsNewsIdGet', 'newsId', newsId);
      const localVarPath = `/api/news/{news_id}/`.replace(
        `{${'news_id'}}`,
        encodeURIComponent(String(newsId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение поля is_liked новости
     * @param {number} newsId
     * @param {boolean} [isLiked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdPatch: async (
      newsId: number,
      isLiked?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newsId' is not null or undefined
      assertParamExists('apiNewsNewsIdPatch', 'newsId', newsId);
      const localVarPath = `/api/news/{news_id}/`.replace(
        `{${'news_id'}}`,
        encodeURIComponent(String(newsId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isLiked !== undefined) {
        localVarQueryParameter['is_liked'] = isLiked;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение данных новости
     * @param {number} newsId
     * @param {UpdateNewsSchema} [updateNewsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdPost: async (
      newsId: number,
      updateNewsSchema?: UpdateNewsSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newsId' is not null or undefined
      assertParamExists('apiNewsNewsIdPost', 'newsId', newsId);
      const localVarPath = `/api/news/{news_id}/`.replace(
        `{${'news_id'}}`,
        encodeURIComponent(String(newsId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNewsSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Скачать медиа новости
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsMediaFilenameGet: async (
      filename: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'filename' is not null or undefined
      assertParamExists('apiNewsNewsMediaFilenameGet', 'filename', filename);
      const localVarPath = `/api/news/news_media/{filename}/`.replace(
        `{${'filename'}}`,
        encodeURIComponent(String(filename))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление новости
     * @param {CreateNewsSchema} [createNewsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsPost: async (
      createNewsSchema?: CreateNewsSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/news/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createNewsSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение списка тегов
     * @param {number} page
     * @param {string} [tagName]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsGet: async (
      page: number,
      tagName?: string,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiNewsTagsGet', 'page', page);
      const localVarPath = `/api/news/tags/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (tagName !== undefined) {
        localVarQueryParameter['tag_name'] = tagName;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление тега
     * @param {CreateTagSchema} [createTagSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsPost: async (
      createTagSchema?: CreateTagSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/news/tags/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTagSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление тега
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsTagIdDelete: async (
      tagId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('apiNewsTagsTagIdDelete', 'tagId', tagId);
      const localVarPath = `/api/news/tags/{tag_id}/`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение тега
     * @param {number} tagId
     * @param {CreateTagSchema} [createTagSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsTagIdPost: async (
      tagId: number,
      createTagSchema?: CreateTagSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('apiNewsTagsTagIdPost', 'tagId', tagId);
      const localVarPath = `/api/news/tags/{tag_id}/`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTagSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Удаление категории
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCategoryCategoryIdDelete(
      categoryId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCategoryCategoryIdDelete(
        categoryId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление категории
     * @param {number} categoryId
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCategoryCategoryIdPost(
      categoryId: number,
      inlineObject2?: InlineObject2,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCategoryCategoryIdPost(
        categoryId,
        inlineObject2,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение списка категорий новостей
     * @param {number} page
     * @param {string} [name]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCategoryGet(
      page: number,
      name?: string,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCategoryGet(
        page,
        name,
        sortOrder,
        sortBy,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление категории
     * @param {CreateCategorySchema} [createCategorySchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCategoryPost(
      createCategorySchema?: CreateCategorySchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCategoryPost(
        createCategorySchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление комментариев
     * @param {number} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCommentCommentIdDelete(
      commentId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCommentCommentIdDelete(
        commentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение комментариев
     * @param {number} commentId
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCommentCommentIdPost(
      commentId: number,
      inlineObject3?: InlineObject3,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCommentCommentIdPost(
        commentId,
        inlineObject3,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение списка комментариев
     * @param {number} newsId
     * @param {number} page
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCommentGet(
      newsId: number,
      page: number,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCommentGet(
        newsId,
        page,
        sortOrder,
        sortBy,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление комментария
     * @param {CommentSchema} [commentSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsCommentPost(
      commentSchema?: CommentSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsCommentPost(
        commentSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение всех новостей
     * @param {number} page
     * @param {string} [toDate]
     * @param {Array<number>} [createdBy]
     * @param {boolean} [isLiked]
     * @param {number} [itemsPerPage]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {Array<number>} [categories]
     * @param {boolean} [activity]
     * @param {string} [fromDate]
     * @param {Array<number>} [tags]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsGet(
      page: number,
      toDate?: string,
      createdBy?: Array<number>,
      isLiked?: boolean,
      itemsPerPage?: number,
      sortOrder?: string,
      sortBy?: string,
      categories?: Array<number>,
      activity?: boolean,
      fromDate?: string,
      tags?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsGet(
        page,
        toDate,
        createdBy,
        isLiked,
        itemsPerPage,
        sortOrder,
        sortBy,
        categories,
        activity,
        fromDate,
        tags,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление новости
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsNewsIdDelete(
      newsId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsNewsIdDelete(
        newsId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение данных по одной новости
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsNewsIdGet(
      newsId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSchema>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsNewsIdGet(newsId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение поля is_liked новости
     * @param {number} newsId
     * @param {boolean} [isLiked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsNewsIdPatch(
      newsId: number,
      isLiked?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsNewsIdPatch(
        newsId,
        isLiked,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение данных новости
     * @param {number} newsId
     * @param {UpdateNewsSchema} [updateNewsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsNewsIdPost(
      newsId: number,
      updateNewsSchema?: UpdateNewsSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsNewsIdPost(
        newsId,
        updateNewsSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Скачать медиа новости
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsNewsMediaFilenameGet(
      filename: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsNewsMediaFilenameGet(
        filename,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление новости
     * @param {CreateNewsSchema} [createNewsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsPost(
      createNewsSchema?: CreateNewsSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPost(
        createNewsSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение списка тегов
     * @param {number} page
     * @param {string} [tagName]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsTagsGet(
      page: number,
      tagName?: string,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsTagsGet(
        page,
        tagName,
        sortOrder,
        sortBy,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление тега
     * @param {CreateTagSchema} [createTagSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsTagsPost(
      createTagSchema?: CreateTagSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsTagsPost(
        createTagSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление тега
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsTagsTagIdDelete(
      tagId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsTagsTagIdDelete(
        tagId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение тега
     * @param {number} tagId
     * @param {CreateTagSchema} [createTagSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNewsTagsTagIdPost(
      tagId: number,
      createTagSchema?: CreateTagSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsTagsTagIdPost(
        tagId,
        createTagSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NewsApiFp(configuration);
  return {
    /**
     *
     * @summary Удаление категории
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryCategoryIdDelete(
      categoryId: number,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsCategoryCategoryIdDelete(categoryId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление категории
     * @param {number} categoryId
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryCategoryIdPost(
      categoryId: number,
      inlineObject2?: InlineObject2,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsCategoryCategoryIdPost(categoryId, inlineObject2, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение списка категорий новостей
     * @param {number} page
     * @param {string} [name]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryGet(
      page: number,
      name?: string,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options?: any
    ): AxiosPromise<CategoryList> {
      return localVarFp
        .apiNewsCategoryGet(page, name, sortOrder, sortBy, perPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление категории
     * @param {CreateCategorySchema} [createCategorySchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCategoryPost(
      createCategorySchema?: CreateCategorySchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsCategoryPost(createCategorySchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление комментариев
     * @param {number} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentCommentIdDelete(commentId: number, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsCommentCommentIdDelete(commentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение комментариев
     * @param {number} commentId
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentCommentIdPost(
      commentId: number,
      inlineObject3?: InlineObject3,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsCommentCommentIdPost(commentId, inlineObject3, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение списка комментариев
     * @param {number} newsId
     * @param {number} page
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentGet(
      newsId: number,
      page: number,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options?: any
    ): AxiosPromise<CommentList> {
      return localVarFp
        .apiNewsCommentGet(newsId, page, sortOrder, sortBy, perPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление комментария
     * @param {CommentSchema} [commentSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsCommentPost(commentSchema?: CommentSchema, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsCommentPost(commentSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение всех новостей
     * @param {number} page
     * @param {string} [toDate]
     * @param {Array<number>} [createdBy]
     * @param {boolean} [isLiked]
     * @param {number} [itemsPerPage]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {Array<number>} [categories]
     * @param {boolean} [activity]
     * @param {string} [fromDate]
     * @param {Array<number>} [tags]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsGet(
      page: number,
      toDate?: string,
      createdBy?: Array<number>,
      isLiked?: boolean,
      itemsPerPage?: number,
      sortOrder?: string,
      sortBy?: string,
      categories?: Array<number>,
      activity?: boolean,
      fromDate?: string,
      tags?: Array<number>,
      options?: any
    ): AxiosPromise<NewsList> {
      return localVarFp
        .apiNewsGet(
          page,
          toDate,
          createdBy,
          isLiked,
          itemsPerPage,
          sortOrder,
          sortBy,
          categories,
          activity,
          fromDate,
          tags,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление новости
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdDelete(newsId: number, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsNewsIdDelete(newsId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение данных по одной новости
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdGet(newsId: number, options?: any): AxiosPromise<NewsSchema> {
      return localVarFp
        .apiNewsNewsIdGet(newsId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение поля is_liked новости
     * @param {number} newsId
     * @param {boolean} [isLiked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdPatch(
      newsId: number,
      isLiked?: boolean,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsNewsIdPatch(newsId, isLiked, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение данных новости
     * @param {number} newsId
     * @param {UpdateNewsSchema} [updateNewsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsIdPost(
      newsId: number,
      updateNewsSchema?: UpdateNewsSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsNewsIdPost(newsId, updateNewsSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Скачать медиа новости
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsNewsMediaFilenameGet(filename: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiNewsNewsMediaFilenameGet(filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление новости
     * @param {CreateNewsSchema} [createNewsSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsPost(createNewsSchema?: CreateNewsSchema, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsPost(createNewsSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение списка тегов
     * @param {number} page
     * @param {string} [tagName]
     * @param {string} [sortOrder] sorting direction: asc или desc
     * @param {string} [sortBy] the field by which the selection is sorted
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsGet(
      page: number,
      tagName?: string,
      sortOrder?: string,
      sortBy?: string,
      perPage?: number,
      options?: any
    ): AxiosPromise<TagList> {
      return localVarFp
        .apiNewsTagsGet(page, tagName, sortOrder, sortBy, perPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление тега
     * @param {CreateTagSchema} [createTagSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsPost(
      createTagSchema?: CreateTagSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsTagsPost(createTagSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление тега
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsTagIdDelete(tagId: number, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsTagsTagIdDelete(tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение тега
     * @param {number} tagId
     * @param {CreateTagSchema} [createTagSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNewsTagsTagIdPost(
      tagId: number,
      createTagSchema?: CreateTagSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNewsTagsTagIdPost(tagId, createTagSchema, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
  /**
   *
   * @summary Удаление категории
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCategoryCategoryIdDelete(categoryId: number, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsCategoryCategoryIdDelete(categoryId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление категории
   * @param {number} categoryId
   * @param {InlineObject2} [inlineObject2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCategoryCategoryIdPost(
    categoryId: number,
    inlineObject2?: InlineObject2,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsCategoryCategoryIdPost(categoryId, inlineObject2, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение списка категорий новостей
   * @param {number} page
   * @param {string} [name]
   * @param {string} [sortOrder] sorting direction: asc или desc
   * @param {string} [sortBy] the field by which the selection is sorted
   * @param {number} [perPage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCategoryGet(
    page: number,
    name?: string,
    sortOrder?: string,
    sortBy?: string,
    perPage?: number,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsCategoryGet(page, name, sortOrder, sortBy, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление категории
   * @param {CreateCategorySchema} [createCategorySchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCategoryPost(
    createCategorySchema?: CreateCategorySchema,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsCategoryPost(createCategorySchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление комментариев
   * @param {number} commentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCommentCommentIdDelete(commentId: number, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsCommentCommentIdDelete(commentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение комментариев
   * @param {number} commentId
   * @param {InlineObject3} [inlineObject3]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCommentCommentIdPost(
    commentId: number,
    inlineObject3?: InlineObject3,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsCommentCommentIdPost(commentId, inlineObject3, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение списка комментариев
   * @param {number} newsId
   * @param {number} page
   * @param {string} [sortOrder] sorting direction: asc или desc
   * @param {string} [sortBy] the field by which the selection is sorted
   * @param {number} [perPage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCommentGet(
    newsId: number,
    page: number,
    sortOrder?: string,
    sortBy?: string,
    perPage?: number,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsCommentGet(newsId, page, sortOrder, sortBy, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление комментария
   * @param {CommentSchema} [commentSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsCommentPost(commentSchema?: CommentSchema, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsCommentPost(commentSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение всех новостей
   * @param {number} page
   * @param {string} [toDate]
   * @param {Array<number>} [createdBy]
   * @param {boolean} [isLiked]
   * @param {number} [itemsPerPage]
   * @param {string} [sortOrder] sorting direction: asc или desc
   * @param {string} [sortBy] the field by which the selection is sorted
   * @param {Array<number>} [categories]
   * @param {boolean} [activity]
   * @param {string} [fromDate]
   * @param {Array<number>} [tags]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsGet(
    page: number,
    toDate?: string,
    createdBy?: Array<number>,
    isLiked?: boolean,
    itemsPerPage?: number,
    sortOrder?: string,
    sortBy?: string,
    categories?: Array<number>,
    activity?: boolean,
    fromDate?: string,
    tags?: Array<number>,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsGet(
        page,
        toDate,
        createdBy,
        isLiked,
        itemsPerPage,
        sortOrder,
        sortBy,
        categories,
        activity,
        fromDate,
        tags,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление новости
   * @param {number} newsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsNewsIdDelete(newsId: number, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsNewsIdDelete(newsId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение данных по одной новости
   * @param {number} newsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsNewsIdGet(newsId: number, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsNewsIdGet(newsId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение поля is_liked новости
   * @param {number} newsId
   * @param {boolean} [isLiked]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsNewsIdPatch(newsId: number, isLiked?: boolean, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsNewsIdPatch(newsId, isLiked, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение данных новости
   * @param {number} newsId
   * @param {UpdateNewsSchema} [updateNewsSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsNewsIdPost(
    newsId: number,
    updateNewsSchema?: UpdateNewsSchema,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsNewsIdPost(newsId, updateNewsSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Скачать медиа новости
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsNewsMediaFilenameGet(filename: string, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsNewsMediaFilenameGet(filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление новости
   * @param {CreateNewsSchema} [createNewsSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsPost(createNewsSchema?: CreateNewsSchema, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsPost(createNewsSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение списка тегов
   * @param {number} page
   * @param {string} [tagName]
   * @param {string} [sortOrder] sorting direction: asc или desc
   * @param {string} [sortBy] the field by which the selection is sorted
   * @param {number} [perPage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsTagsGet(
    page: number,
    tagName?: string,
    sortOrder?: string,
    sortBy?: string,
    perPage?: number,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsTagsGet(page, tagName, sortOrder, sortBy, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление тега
   * @param {CreateTagSchema} [createTagSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsTagsPost(createTagSchema?: CreateTagSchema, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsTagsPost(createTagSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление тега
   * @param {number} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsTagsTagIdDelete(tagId: number, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .apiNewsTagsTagIdDelete(tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение тега
   * @param {number} tagId
   * @param {CreateTagSchema} [createTagSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public apiNewsTagsTagIdPost(
    tagId: number,
    createTagSchema?: CreateTagSchema,
    options?: AxiosRequestConfig
  ) {
    return NewsApiFp(this.configuration)
      .apiNewsTagsTagIdPost(tagId, createTagSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NoticesApi - axios parameter creator
 * @export
 */
export const NoticesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Добавление уведомления
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeAddNoticePost: async (
      notice?: Notice,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/notice/add-notice/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notice,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление уведомления
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeDeleteNoticeNoticeIdDelete: async (
      noticeId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('apiNoticesNoticeDeleteNoticeNoticeIdDelete', 'noticeId', noticeId);
      const localVarPath = `/api/notices/notice/delete-notice/{notice_id}/`.replace(
        `{${'notice_id'}}`,
        encodeURIComponent(String(noticeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение уведомлений по url
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeInfoPost: async (
      notice?: Notice,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/notice/info/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notice,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение уведомлений
     * @param {number} [page]
     * @param {number} [itemsPerPage] Количество на странице
     * @param {string} [sortBy] Поле для сортировки, по умолчанию &#x3D; created_at (url_page,updated_at)
     * @param {string} [sortOrder] Сортировка в порядке возрастания - asc, убывания - desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeListByGetGet: async (
      page?: number,
      itemsPerPage?: number,
      sortBy?: string,
      sortOrder?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/notice/list-by-get/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Включить/отключить уведомление
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeSetStatusPatch: async (
      notice?: Notice,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/notice/set-status/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notice,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение уведомления
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeUpdateNoticePost: async (
      notice?: Notice,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/notice/update-notice/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notice,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление типа уведомления
     * @param {TypeNotice} [typeNotice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeAddTypeNoticePost: async (
      typeNotice?: TypeNotice,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/type_notice/add-type-notice/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        typeNotice,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление типа уведомления
     * @param {number} typeNoticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete: async (
      typeNoticeId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'typeNoticeId' is not null or undefined
      assertParamExists(
        'apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete',
        'typeNoticeId',
        typeNoticeId
      );
      const localVarPath = `/api/notices/type_notice/delete-type-notice/{type_notice_id}/`.replace(
        `{${'type_notice_id'}}`,
        encodeURIComponent(String(typeNoticeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение типов уведомлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeListByGetGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/type_notice/list-by-get/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение типа уведомления
     * @param {TypeNotice} [typeNotice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeUpdateTypeNoticePost: async (
      typeNotice?: TypeNotice,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notices/type_notice/update-type-notice/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        typeNotice,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NoticesApi - functional programming interface
 * @export
 */
export const NoticesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NoticesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Добавление уведомления
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesNoticeAddNoticePost(
      notice?: Notice,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticesNoticeAddNoticePost(
        notice,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление уведомления
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesNoticeDeleteNoticeNoticeIdDelete(
      noticeId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiNoticesNoticeDeleteNoticeNoticeIdDelete(
          noticeId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение уведомлений по url
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesNoticeInfoPost(
      notice?: Notice,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notice>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticesNoticeInfoPost(
        notice,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение уведомлений
     * @param {number} [page]
     * @param {number} [itemsPerPage] Количество на странице
     * @param {string} [sortBy] Поле для сортировки, по умолчанию &#x3D; created_at (url_page,updated_at)
     * @param {string} [sortOrder] Сортировка в порядке возрастания - asc, убывания - desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesNoticeListByGetGet(
      page?: number,
      itemsPerPage?: number,
      sortBy?: string,
      sortOrder?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationNotice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticesNoticeListByGetGet(
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Включить/отключить уведомление
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesNoticeSetStatusPatch(
      notice?: Notice,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticesNoticeSetStatusPatch(
        notice,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение уведомления
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesNoticeUpdateNoticePost(
      notice?: Notice,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticesNoticeUpdateNoticePost(
        notice,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление типа уведомления
     * @param {TypeNotice} [typeNotice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesTypeNoticeAddTypeNoticePost(
      typeNotice?: TypeNotice,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiNoticesTypeNoticeAddTypeNoticePost(typeNotice, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление типа уведомления
     * @param {number} typeNoticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete(
      typeNoticeId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete(
          typeNoticeId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получение типов уведомлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesTypeNoticeListByGetGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypeNotice>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoticesTypeNoticeListByGetGet(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение типа уведомления
     * @param {TypeNotice} [typeNotice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNoticesTypeNoticeUpdateTypeNoticePost(
      typeNotice?: TypeNotice,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiNoticesTypeNoticeUpdateTypeNoticePost(
          typeNotice,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NoticesApi - factory interface
 * @export
 */
export const NoticesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NoticesApiFp(configuration);
  return {
    /**
     *
     * @summary Добавление уведомления
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeAddNoticePost(notice?: Notice, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNoticesNoticeAddNoticePost(notice, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление уведомления
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeDeleteNoticeNoticeIdDelete(
      noticeId: number,
      options?: any
    ): AxiosPromise<InlineResponse2006> {
      return localVarFp
        .apiNoticesNoticeDeleteNoticeNoticeIdDelete(noticeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение уведомлений по url
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeInfoPost(notice?: Notice, options?: any): AxiosPromise<Array<Notice>> {
      return localVarFp
        .apiNoticesNoticeInfoPost(notice, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение уведомлений
     * @param {number} [page]
     * @param {number} [itemsPerPage] Количество на странице
     * @param {string} [sortBy] Поле для сортировки, по умолчанию &#x3D; created_at (url_page,updated_at)
     * @param {string} [sortOrder] Сортировка в порядке возрастания - asc, убывания - desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeListByGetGet(
      page?: number,
      itemsPerPage?: number,
      sortBy?: string,
      sortOrder?: string,
      options?: any
    ): AxiosPromise<PaginationNotice> {
      return localVarFp
        .apiNoticesNoticeListByGetGet(page, itemsPerPage, sortBy, sortOrder, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Включить/отключить уведомление
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeSetStatusPatch(notice?: Notice, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNoticesNoticeSetStatusPatch(notice, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение уведомления
     * @param {Notice} [notice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesNoticeUpdateNoticePost(notice?: Notice, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNoticesNoticeUpdateNoticePost(notice, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление типа уведомления
     * @param {TypeNotice} [typeNotice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeAddTypeNoticePost(
      typeNotice?: TypeNotice,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNoticesTypeNoticeAddTypeNoticePost(typeNotice, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление типа уведомления
     * @param {number} typeNoticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete(
      typeNoticeId: number,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete(typeNoticeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение типов уведомлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeListByGetGet(options?: any): AxiosPromise<Array<TypeNotice>> {
      return localVarFp
        .apiNoticesTypeNoticeListByGetGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение типа уведомления
     * @param {TypeNotice} [typeNotice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNoticesTypeNoticeUpdateTypeNoticePost(
      typeNotice?: TypeNotice,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNoticesTypeNoticeUpdateTypeNoticePost(typeNotice, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NoticesApi - object-oriented interface
 * @export
 * @class NoticesApi
 * @extends {BaseAPI}
 */
export class NoticesApi extends BaseAPI {
  /**
   *
   * @summary Добавление уведомления
   * @param {Notice} [notice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesNoticeAddNoticePost(notice?: Notice, options?: AxiosRequestConfig) {
    return NoticesApiFp(this.configuration)
      .apiNoticesNoticeAddNoticePost(notice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление уведомления
   * @param {number} noticeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesNoticeDeleteNoticeNoticeIdDelete(
    noticeId: number,
    options?: AxiosRequestConfig
  ) {
    return NoticesApiFp(this.configuration)
      .apiNoticesNoticeDeleteNoticeNoticeIdDelete(noticeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение уведомлений по url
   * @param {Notice} [notice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesNoticeInfoPost(notice?: Notice, options?: AxiosRequestConfig) {
    return NoticesApiFp(this.configuration)
      .apiNoticesNoticeInfoPost(notice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение уведомлений
   * @param {number} [page]
   * @param {number} [itemsPerPage] Количество на странице
   * @param {string} [sortBy] Поле для сортировки, по умолчанию &#x3D; created_at (url_page,updated_at)
   * @param {string} [sortOrder] Сортировка в порядке возрастания - asc, убывания - desc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesNoticeListByGetGet(
    page?: number,
    itemsPerPage?: number,
    sortBy?: string,
    sortOrder?: string,
    options?: AxiosRequestConfig
  ) {
    return NoticesApiFp(this.configuration)
      .apiNoticesNoticeListByGetGet(page, itemsPerPage, sortBy, sortOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Включить/отключить уведомление
   * @param {Notice} [notice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesNoticeSetStatusPatch(notice?: Notice, options?: AxiosRequestConfig) {
    return NoticesApiFp(this.configuration)
      .apiNoticesNoticeSetStatusPatch(notice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение уведомления
   * @param {Notice} [notice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesNoticeUpdateNoticePost(notice?: Notice, options?: AxiosRequestConfig) {
    return NoticesApiFp(this.configuration)
      .apiNoticesNoticeUpdateNoticePost(notice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление типа уведомления
   * @param {TypeNotice} [typeNotice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesTypeNoticeAddTypeNoticePost(
    typeNotice?: TypeNotice,
    options?: AxiosRequestConfig
  ) {
    return NoticesApiFp(this.configuration)
      .apiNoticesTypeNoticeAddTypeNoticePost(typeNotice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление типа уведомления
   * @param {number} typeNoticeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete(
    typeNoticeId: number,
    options?: AxiosRequestConfig
  ) {
    return NoticesApiFp(this.configuration)
      .apiNoticesTypeNoticeDeleteTypeNoticeTypeNoticeIdDelete(typeNoticeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение типов уведомлений
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesTypeNoticeListByGetGet(options?: AxiosRequestConfig) {
    return NoticesApiFp(this.configuration)
      .apiNoticesTypeNoticeListByGetGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение типа уведомления
   * @param {TypeNotice} [typeNotice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoticesApi
   */
  public apiNoticesTypeNoticeUpdateTypeNoticePost(
    typeNotice?: TypeNotice,
    options?: AxiosRequestConfig
  ) {
    return NoticesApiFp(this.configuration)
      .apiNoticesTypeNoticeUpdateTypeNoticePost(typeNotice, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete all the notifications in the requested user notifications list part.
     * @param {NotificationListUpdate} [notificationListUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsDelete: async (
      notificationListUpdate?: NotificationListUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationListUpdate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Method returns a requested page of user\'s notifications list.
     * @summary Get the requested part of user notifications list
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [createdAtAfter] if present, only the notifications that were created after this date are in the result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsGet: async (
      limit?: number,
      page?: number,
      createdAtAfter?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (createdAtAfter !== undefined) {
        localVarQueryParameter['created_at_after'] =
          (createdAtAfter as any) instanceof Date
            ? (createdAtAfter as any).toISOString()
            : createdAtAfter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send notification to all users from group.
     * @param {NotificationGroupSending} [notificationGroupSending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsGroupsPost: async (
      notificationGroupSending?: NotificationGroupSending,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/groups/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationGroupSending,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete the notifications
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsIdDelete: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('apiNotificationsIdDelete', 'id', id);
      const localVarPath = `/api/notifications/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Read the notification
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsIdPatch: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('apiNotificationsIdPatch', 'id', id);
      const localVarPath = `/api/notifications/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Read all the messages in the requested user notifications list part.
     * @param {NotificationListUpdate} [notificationListUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsPatch: async (
      notificationListUpdate?: NotificationListUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationListUpdate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send notification to the listed users.
     * @param {NotificationSending} [notificationSending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsPost: async (
      notificationSending?: NotificationSending,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'Application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationSending,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete all the notifications in the requested user notifications list part.
     * @param {NotificationListUpdate} [notificationListUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsDelete(
      notificationListUpdate?: NotificationListUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APINotificationUpdated>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsDelete(
        notificationListUpdate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Method returns a requested page of user\'s notifications list.
     * @summary Get the requested part of user notifications list
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [createdAtAfter] if present, only the notifications that were created after this date are in the result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsGet(
      limit?: number,
      page?: number,
      createdAtAfter?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationListGet>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsGet(
        limit,
        page,
        createdAtAfter,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Send notification to all users from group.
     * @param {NotificationGroupSending} [notificationGroupSending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsGroupsPost(
      notificationGroupSending?: NotificationGroupSending,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APINotificationSent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsGroupsPost(
        notificationGroupSending,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete the notifications
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsIdDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsIdDelete(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read the notification
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsIdPatch(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsIdPatch(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read all the messages in the requested user notifications list part.
     * @param {NotificationListUpdate} [notificationListUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsPatch(
      notificationListUpdate?: NotificationListUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APINotificationUpdated>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsPatch(
        notificationListUpdate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Send notification to the listed users.
     * @param {NotificationSending} [notificationSending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNotificationsPost(
      notificationSending?: NotificationSending,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APINotificationSent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsPost(
        notificationSending,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete all the notifications in the requested user notifications list part.
     * @param {NotificationListUpdate} [notificationListUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsDelete(
      notificationListUpdate?: NotificationListUpdate,
      options?: any
    ): AxiosPromise<APINotificationUpdated> {
      return localVarFp
        .apiNotificationsDelete(notificationListUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Method returns a requested page of user\'s notifications list.
     * @summary Get the requested part of user notifications list
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [createdAtAfter] if present, only the notifications that were created after this date are in the result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsGet(
      limit?: number,
      page?: number,
      createdAtAfter?: string,
      options?: any
    ): AxiosPromise<NotificationListGet> {
      return localVarFp
        .apiNotificationsGet(limit, page, createdAtAfter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send notification to all users from group.
     * @param {NotificationGroupSending} [notificationGroupSending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsGroupsPost(
      notificationGroupSending?: NotificationGroupSending,
      options?: any
    ): AxiosPromise<APINotificationSent> {
      return localVarFp
        .apiNotificationsGroupsPost(notificationGroupSending, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete the notifications
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsIdDelete(id: number, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNotificationsIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read the notification
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsIdPatch(id: number, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiNotificationsIdPatch(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read all the messages in the requested user notifications list part.
     * @param {NotificationListUpdate} [notificationListUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsPatch(
      notificationListUpdate?: NotificationListUpdate,
      options?: any
    ): AxiosPromise<APINotificationUpdated> {
      return localVarFp
        .apiNotificationsPatch(notificationListUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send notification to the listed users.
     * @param {NotificationSending} [notificationSending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNotificationsPost(
      notificationSending?: NotificationSending,
      options?: any
    ): AxiosPromise<APINotificationSent> {
      return localVarFp
        .apiNotificationsPost(notificationSending, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   *
   * @summary Delete all the notifications in the requested user notifications list part.
   * @param {NotificationListUpdate} [notificationListUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsDelete(
    notificationListUpdate?: NotificationListUpdate,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsDelete(notificationListUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Method returns a requested page of user\'s notifications list.
   * @summary Get the requested part of user notifications list
   * @param {number} [limit]
   * @param {number} [page]
   * @param {string} [createdAtAfter] if present, only the notifications that were created after this date are in the result
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsGet(
    limit?: number,
    page?: number,
    createdAtAfter?: string,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsGet(limit, page, createdAtAfter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send notification to all users from group.
   * @param {NotificationGroupSending} [notificationGroupSending]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsGroupsPost(
    notificationGroupSending?: NotificationGroupSending,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsGroupsPost(notificationGroupSending, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete the notifications
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsIdDelete(id: number, options?: AxiosRequestConfig) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsIdDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read the notification
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsIdPatch(id: number, options?: AxiosRequestConfig) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsIdPatch(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read all the messages in the requested user notifications list part.
   * @param {NotificationListUpdate} [notificationListUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsPatch(
    notificationListUpdate?: NotificationListUpdate,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsPatch(notificationListUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send notification to the listed users.
   * @param {NotificationSending} [notificationSending]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public apiNotificationsPost(
    notificationSending?: NotificationSending,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .apiNotificationsPost(notificationSending, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Authorizes a user with OAuth.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientNameGet: async (
      clientName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientName' is not null or undefined
      assertParamExists('apiAuthOauthClientNameGet', 'clientName', clientName);
      const localVarPath = `/api/auth/oauth/{client_name}/`.replace(
        `{${'client_name'}}`,
        encodeURIComponent(String(clientName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates or logins a user with OAuth. Adds `auth_token` and `refresh_token` to the Cookie if auth was successful. Adds `auth_error` with an error message if auth is failed. Then redirects on a URI from `config.oauth.redirect_after_callback_url`. Also provides client app name in `client_app_name`.
     * @summary Callback for OAuth authorization.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsClientNameCallbackGet: async (
      clientName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientName' is not null or undefined
      assertParamExists('apiAuthOauthClientsClientNameCallbackGet', 'clientName', clientName);
      const localVarPath = `/api/auth/oauth/clients/{client_name}/callback`.replace(
        `{${'client_name'}}`,
        encodeURIComponent(String(clientName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List OAuth client apps.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/oauth/clients/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Authorizes a user with OAuth.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthOauthClientNameGet(
      clientName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthLink>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthOauthClientNameGet(
        clientName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creates or logins a user with OAuth. Adds `auth_token` and `refresh_token` to the Cookie if auth was successful. Adds `auth_error` with an error message if auth is failed. Then redirects on a URI from `config.oauth.redirect_after_callback_url`. Also provides client app name in `client_app_name`.
     * @summary Callback for OAuth authorization.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthOauthClientsClientNameCallbackGet(
      clientName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAuthOauthClientsClientNameCallbackGet(
          clientName,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List OAuth client apps.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthOauthClientsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OAuthClient>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthOauthClientsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OAuthApiFp(configuration);
  return {
    /**
     *
     * @summary Authorizes a user with OAuth.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientNameGet(clientName: string, options?: any): AxiosPromise<OAuthLink> {
      return localVarFp
        .apiAuthOauthClientNameGet(clientName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates or logins a user with OAuth. Adds `auth_token` and `refresh_token` to the Cookie if auth was successful. Adds `auth_error` with an error message if auth is failed. Then redirects on a URI from `config.oauth.redirect_after_callback_url`. Also provides client app name in `client_app_name`.
     * @summary Callback for OAuth authorization.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsClientNameCallbackGet(
      clientName: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiAuthOauthClientsClientNameCallbackGet(clientName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List OAuth client apps.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsGet(options?: any): AxiosPromise<Array<OAuthClient>> {
      return localVarFp.apiAuthOauthClientsGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
  /**
   *
   * @summary Authorizes a user with OAuth.
   * @param {string} clientName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthApi
   */
  public apiAuthOauthClientNameGet(clientName: string, options?: AxiosRequestConfig) {
    return OAuthApiFp(this.configuration)
      .apiAuthOauthClientNameGet(clientName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates or logins a user with OAuth. Adds `auth_token` and `refresh_token` to the Cookie if auth was successful. Adds `auth_error` with an error message if auth is failed. Then redirects on a URI from `config.oauth.redirect_after_callback_url`. Also provides client app name in `client_app_name`.
   * @summary Callback for OAuth authorization.
   * @param {string} clientName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthApi
   */
  public apiAuthOauthClientsClientNameCallbackGet(
    clientName: string,
    options?: AxiosRequestConfig
  ) {
    return OAuthApiFp(this.configuration)
      .apiAuthOauthClientsClientNameCallbackGet(clientName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List OAuth client apps.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthApi
   */
  public apiAuthOauthClientsGet(options?: AxiosRequestConfig) {
    return OAuthApiFp(this.configuration)
      .apiAuthOauthClientsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OAuthFacebookApi - axios parameter creator
 * @export
 */
export const OAuthFacebookApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Deletes user\'s facebook data and facebook OAuth user.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsClientNameFacebookDeletionPost: async (
      clientName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientName' is not null or undefined
      assertParamExists(
        'apiAuthOauthClientsClientNameFacebookDeletionPost',
        'clientName',
        clientName
      );
      const localVarPath = `/api/auth/oauth/clients/{client_name}/facebook-deletion`.replace(
        `{${'client_name'}}`,
        encodeURIComponent(String(clientName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets user\'s facebook data deletion status.
     * @param {string} clientName
     * @param {string} confirmationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsClientNameFacebookDeletionStatusGet: async (
      clientName: string,
      confirmationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientName' is not null or undefined
      assertParamExists(
        'apiAuthOauthClientsClientNameFacebookDeletionStatusGet',
        'clientName',
        clientName
      );
      // verify required parameter 'confirmationCode' is not null or undefined
      assertParamExists(
        'apiAuthOauthClientsClientNameFacebookDeletionStatusGet',
        'confirmationCode',
        confirmationCode
      );
      const localVarPath = `/api/auth/oauth/clients/{client_name}/facebook-deletion-status`.replace(
        `{${'client_name'}}`,
        encodeURIComponent(String(clientName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (confirmationCode !== undefined) {
        localVarQueryParameter['confirmation_code'] = confirmationCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OAuthFacebookApi - functional programming interface
 * @export
 */
export const OAuthFacebookApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OAuthFacebookApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Deletes user\'s facebook data and facebook OAuth user.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthOauthClientsClientNameFacebookDeletionPost(
      clientName: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookDeletionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAuthOauthClientsClientNameFacebookDeletionPost(
          clientName,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets user\'s facebook data deletion status.
     * @param {string} clientName
     * @param {string} confirmationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthOauthClientsClientNameFacebookDeletionStatusGet(
      clientName: string,
      confirmationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookDeletionStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAuthOauthClientsClientNameFacebookDeletionStatusGet(
          clientName,
          confirmationCode,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OAuthFacebookApi - factory interface
 * @export
 */
export const OAuthFacebookApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OAuthFacebookApiFp(configuration);
  return {
    /**
     *
     * @summary Deletes user\'s facebook data and facebook OAuth user.
     * @param {string} clientName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsClientNameFacebookDeletionPost(
      clientName: string,
      options?: any
    ): AxiosPromise<FacebookDeletionResponse> {
      return localVarFp
        .apiAuthOauthClientsClientNameFacebookDeletionPost(clientName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gets user\'s facebook data deletion status.
     * @param {string} clientName
     * @param {string} confirmationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthOauthClientsClientNameFacebookDeletionStatusGet(
      clientName: string,
      confirmationCode: string,
      options?: any
    ): AxiosPromise<FacebookDeletionStatusResponse> {
      return localVarFp
        .apiAuthOauthClientsClientNameFacebookDeletionStatusGet(
          clientName,
          confirmationCode,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OAuthFacebookApi - object-oriented interface
 * @export
 * @class OAuthFacebookApi
 * @extends {BaseAPI}
 */
export class OAuthFacebookApi extends BaseAPI {
  /**
   *
   * @summary Deletes user\'s facebook data and facebook OAuth user.
   * @param {string} clientName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthFacebookApi
   */
  public apiAuthOauthClientsClientNameFacebookDeletionPost(
    clientName: string,
    options?: AxiosRequestConfig
  ) {
    return OAuthFacebookApiFp(this.configuration)
      .apiAuthOauthClientsClientNameFacebookDeletionPost(clientName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets user\'s facebook data deletion status.
   * @param {string} clientName
   * @param {string} confirmationCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthFacebookApi
   */
  public apiAuthOauthClientsClientNameFacebookDeletionStatusGet(
    clientName: string,
    confirmationCode: string,
    options?: AxiosRequestConfig
  ) {
    return OAuthFacebookApiFp(this.configuration)
      .apiAuthOauthClientsClientNameFacebookDeletionStatusGet(clientName, confirmationCode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReferralProgramCodesApi - axios parameter creator
 * @export
 */
export const ReferralProgramCodesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get information about specific referral code
     * @summary Get information about specific referral code
     * @param {string} code The referral code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsCodesCodeGet: async (
      code: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('apiProgramsCodesCodeGet', 'code', code);
      const localVarPath = `/api/programs/codes/{code}/`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List referral codes.
     * @summary List referral codes.
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {Array<string>} [userIds] Filter by users
     * @param {Array<number>} [programIds] Filter by programs
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {'program_id' | 'user_id' | 'created_at' | 'valid_to'} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsCodesGet: async (
      page?: number,
      perPage?: number,
      userIds?: Array<string>,
      programIds?: Array<number>,
      dateFrom?: string,
      dateTo?: string,
      sortBy?: 'program_id' | 'user_id' | 'created_at' | 'valid_to',
      sortOrder?: 'asc' | 'desc',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/programs/codes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage;
      }

      if (userIds) {
        localVarQueryParameter['user_ids'] = userIds;
      }

      if (programIds) {
        localVarQueryParameter['program_ids'] = programIds;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['date_from'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['date_to'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Generate a referral code
     * @summary Generate a referral code
     * @param {InlineObject4} inlineObject4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsCodesPost: async (
      inlineObject4: InlineObject4,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'inlineObject4' is not null or undefined
      assertParamExists('apiProgramsCodesPost', 'inlineObject4', inlineObject4);
      const localVarPath = `/api/programs/codes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject4,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReferralProgramCodesApi - functional programming interface
 * @export
 */
export const ReferralProgramCodesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReferralProgramCodesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get information about specific referral code
     * @summary Get information about specific referral code
     * @param {string} code The referral code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsCodesCodeGet(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralCodeModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsCodesCodeGet(
        code,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * List referral codes.
     * @summary List referral codes.
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {Array<string>} [userIds] Filter by users
     * @param {Array<number>} [programIds] Filter by programs
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {'program_id' | 'user_id' | 'created_at' | 'valid_to'} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsCodesGet(
      page?: number,
      perPage?: number,
      userIds?: Array<string>,
      programIds?: Array<number>,
      dateFrom?: string,
      dateTo?: string,
      sortBy?: 'program_id' | 'user_id' | 'created_at' | 'valid_to',
      sortOrder?: 'asc' | 'desc',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReferralCodes>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsCodesGet(
        page,
        perPage,
        userIds,
        programIds,
        dateFrom,
        dateTo,
        sortBy,
        sortOrder,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Generate a referral code
     * @summary Generate a referral code
     * @param {InlineObject4} inlineObject4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsCodesPost(
      inlineObject4: InlineObject4,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsCodesPost(
        inlineObject4,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReferralProgramCodesApi - factory interface
 * @export
 */
export const ReferralProgramCodesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ReferralProgramCodesApiFp(configuration);
  return {
    /**
     * Get information about specific referral code
     * @summary Get information about specific referral code
     * @param {string} code The referral code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsCodesCodeGet(code: string, options?: any): AxiosPromise<ReferralCodeModel> {
      return localVarFp
        .apiProgramsCodesCodeGet(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List referral codes.
     * @summary List referral codes.
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {Array<string>} [userIds] Filter by users
     * @param {Array<number>} [programIds] Filter by programs
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {'program_id' | 'user_id' | 'created_at' | 'valid_to'} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsCodesGet(
      page?: number,
      perPage?: number,
      userIds?: Array<string>,
      programIds?: Array<number>,
      dateFrom?: string,
      dateTo?: string,
      sortBy?: 'program_id' | 'user_id' | 'created_at' | 'valid_to',
      sortOrder?: 'asc' | 'desc',
      options?: any
    ): AxiosPromise<ListReferralCodes> {
      return localVarFp
        .apiProgramsCodesGet(
          page,
          perPage,
          userIds,
          programIds,
          dateFrom,
          dateTo,
          sortBy,
          sortOrder,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Generate a referral code
     * @summary Generate a referral code
     * @param {InlineObject4} inlineObject4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsCodesPost(
      inlineObject4: InlineObject4,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiProgramsCodesPost(inlineObject4, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReferralProgramCodesApi - object-oriented interface
 * @export
 * @class ReferralProgramCodesApi
 * @extends {BaseAPI}
 */
export class ReferralProgramCodesApi extends BaseAPI {
  /**
   * Get information about specific referral code
   * @summary Get information about specific referral code
   * @param {string} code The referral code.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramCodesApi
   */
  public apiProgramsCodesCodeGet(code: string, options?: AxiosRequestConfig) {
    return ReferralProgramCodesApiFp(this.configuration)
      .apiProgramsCodesCodeGet(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List referral codes.
   * @summary List referral codes.
   * @param {number} [page]
   * @param {number} [perPage]
   * @param {Array<string>} [userIds] Filter by users
   * @param {Array<number>} [programIds] Filter by programs
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {'program_id' | 'user_id' | 'created_at' | 'valid_to'} [sortBy]
   * @param {'asc' | 'desc'} [sortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramCodesApi
   */
  public apiProgramsCodesGet(
    page?: number,
    perPage?: number,
    userIds?: Array<string>,
    programIds?: Array<number>,
    dateFrom?: string,
    dateTo?: string,
    sortBy?: 'program_id' | 'user_id' | 'created_at' | 'valid_to',
    sortOrder?: 'asc' | 'desc',
    options?: AxiosRequestConfig
  ) {
    return ReferralProgramCodesApiFp(this.configuration)
      .apiProgramsCodesGet(
        page,
        perPage,
        userIds,
        programIds,
        dateFrom,
        dateTo,
        sortBy,
        sortOrder,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Generate a referral code
   * @summary Generate a referral code
   * @param {InlineObject4} inlineObject4
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramCodesApi
   */
  public apiProgramsCodesPost(inlineObject4: InlineObject4, options?: AxiosRequestConfig) {
    return ReferralProgramCodesApiFp(this.configuration)
      .apiProgramsCodesPost(inlineObject4, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReferralProgramTypesApi - axios parameter creator
 * @export
 */
export const ReferralProgramTypesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * List of available referral program types
     * @summary List of available referral program types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/programs/types/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReferralProgramTypesApi - functional programming interface
 * @export
 */
export const ReferralProgramTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReferralProgramTypesApiAxiosParamCreator(configuration);
  return {
    /**
     * List of available referral program types
     * @summary List of available referral program types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsTypesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsTypesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReferralProgramTypesApi - factory interface
 * @export
 */
export const ReferralProgramTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ReferralProgramTypesApiFp(configuration);
  return {
    /**
     * List of available referral program types
     * @summary List of available referral program types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsTypesGet(options?: any): AxiosPromise<object> {
      return localVarFp.apiProgramsTypesGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReferralProgramTypesApi - object-oriented interface
 * @export
 * @class ReferralProgramTypesApi
 * @extends {BaseAPI}
 */
export class ReferralProgramTypesApi extends BaseAPI {
  /**
   * List of available referral program types
   * @summary List of available referral program types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramTypesApi
   */
  public apiProgramsTypesGet(options?: AxiosRequestConfig) {
    return ReferralProgramTypesApiFp(this.configuration)
      .apiProgramsTypesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReferralProgramsApi - axios parameter creator
 * @export
 */
export const ReferralProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * List created referral programs
     * @summary List created referral programs
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {Array<'basicreferralprogramtype'>} [programType] Filter by referral program type
     * @param {boolean} [currentlyValid]
     * @param {string} [createdAtAfter]
     * @param {'program_type' | 'created_at' | 'valid_from' | 'valid_to'} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsGet: async (
      page?: number,
      perPage?: number,
      programType?: Array<'basicreferralprogramtype'>,
      currentlyValid?: boolean,
      createdAtAfter?: string,
      sortBy?: 'program_type' | 'created_at' | 'valid_from' | 'valid_to',
      sortOrder?: 'asc' | 'desc',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/programs/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage;
      }

      if (programType) {
        localVarQueryParameter['program_type'] = programType;
      }

      if (currentlyValid !== undefined) {
        localVarQueryParameter['currently_valid'] = currentlyValid;
      }

      if (createdAtAfter !== undefined) {
        localVarQueryParameter['created_at_after'] =
          (createdAtAfter as any) instanceof Date
            ? (createdAtAfter as any).toISOString()
            : createdAtAfter;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create new referral program
     * @summary Create new referral program
     * @param {ReferrerProgramModel} referrerProgramModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsPost: async (
      referrerProgramModel: ReferrerProgramModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'referrerProgramModel' is not null or undefined
      assertParamExists('apiProgramsPost', 'referrerProgramModel', referrerProgramModel);
      const localVarPath = `/api/programs/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        referrerProgramModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete referral program
     * @summary Delete referral program
     * @param {number} programId Referral program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdDelete: async (
      programId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'programId' is not null or undefined
      assertParamExists('apiProgramsProgramIdDelete', 'programId', programId);
      const localVarPath = `/api/programs/{program_id}/`.replace(
        `{${'program_id'}}`,
        encodeURIComponent(String(programId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get referral program data
     * @summary Get referral program data
     * @param {number} programId Referral program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdGet: async (
      programId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'programId' is not null or undefined
      assertParamExists('apiProgramsProgramIdGet', 'programId', programId);
      const localVarPath = `/api/programs/{program_id}/`.replace(
        `{${'program_id'}}`,
        encodeURIComponent(String(programId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update referral program data
     * @summary Update referral program data
     * @param {number} programId Referral program ID
     * @param {ReferrerProgramModel} [referrerProgramModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdPut: async (
      programId: number,
      referrerProgramModel?: ReferrerProgramModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'programId' is not null or undefined
      assertParamExists('apiProgramsProgramIdPut', 'programId', programId);
      const localVarPath = `/api/programs/{program_id}/`.replace(
        `{${'program_id'}}`,
        encodeURIComponent(String(programId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        referrerProgramModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the referral program valid period
     * @summary Update the referral program valid period
     * @param {number} programId Referral program ID
     * @param {UpdateReferralProgramValidPeriod} updateReferralProgramValidPeriod Referral program valid period data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdValidPeriodPatch: async (
      programId: number,
      updateReferralProgramValidPeriod: UpdateReferralProgramValidPeriod,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'programId' is not null or undefined
      assertParamExists('apiProgramsProgramIdValidPeriodPatch', 'programId', programId);
      // verify required parameter 'updateReferralProgramValidPeriod' is not null or undefined
      assertParamExists(
        'apiProgramsProgramIdValidPeriodPatch',
        'updateReferralProgramValidPeriod',
        updateReferralProgramValidPeriod
      );
      const localVarPath = `/api/programs/{program_id}/valid-period/`.replace(
        `{${'program_id'}}`,
        encodeURIComponent(String(programId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateReferralProgramValidPeriod,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReferralProgramsApi - functional programming interface
 * @export
 */
export const ReferralProgramsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReferralProgramsApiAxiosParamCreator(configuration);
  return {
    /**
     * List created referral programs
     * @summary List created referral programs
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {Array<'basicreferralprogramtype'>} [programType] Filter by referral program type
     * @param {boolean} [currentlyValid]
     * @param {string} [createdAtAfter]
     * @param {'program_type' | 'created_at' | 'valid_from' | 'valid_to'} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsGet(
      page?: number,
      perPage?: number,
      programType?: Array<'basicreferralprogramtype'>,
      currentlyValid?: boolean,
      createdAtAfter?: string,
      sortBy?: 'program_type' | 'created_at' | 'valid_from' | 'valid_to',
      sortOrder?: 'asc' | 'desc',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReferralProgramModelSchema>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsGet(
        page,
        perPage,
        programType,
        currentlyValid,
        createdAtAfter,
        sortBy,
        sortOrder,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create new referral program
     * @summary Create new referral program
     * @param {ReferrerProgramModel} referrerProgramModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsPost(
      referrerProgramModel: ReferrerProgramModel,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsPost(
        referrerProgramModel,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete referral program
     * @summary Delete referral program
     * @param {number} programId Referral program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsProgramIdDelete(
      programId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsProgramIdDelete(
        programId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get referral program data
     * @summary Get referral program data
     * @param {number} programId Referral program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsProgramIdGet(
      programId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferrerProgramModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsProgramIdGet(
        programId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update referral program data
     * @summary Update referral program data
     * @param {number} programId Referral program ID
     * @param {ReferrerProgramModel} [referrerProgramModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsProgramIdPut(
      programId: number,
      referrerProgramModel?: ReferrerProgramModel,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProgramsProgramIdPut(
        programId,
        referrerProgramModel,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update the referral program valid period
     * @summary Update the referral program valid period
     * @param {number} programId Referral program ID
     * @param {UpdateReferralProgramValidPeriod} updateReferralProgramValidPeriod Referral program valid period data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProgramsProgramIdValidPeriodPatch(
      programId: number,
      updateReferralProgramValidPeriod: UpdateReferralProgramValidPeriod,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiProgramsProgramIdValidPeriodPatch(
          programId,
          updateReferralProgramValidPeriod,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReferralProgramsApi - factory interface
 * @export
 */
export const ReferralProgramsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ReferralProgramsApiFp(configuration);
  return {
    /**
     * List created referral programs
     * @summary List created referral programs
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {Array<'basicreferralprogramtype'>} [programType] Filter by referral program type
     * @param {boolean} [currentlyValid]
     * @param {string} [createdAtAfter]
     * @param {'program_type' | 'created_at' | 'valid_from' | 'valid_to'} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsGet(
      page?: number,
      perPage?: number,
      programType?: Array<'basicreferralprogramtype'>,
      currentlyValid?: boolean,
      createdAtAfter?: string,
      sortBy?: 'program_type' | 'created_at' | 'valid_from' | 'valid_to',
      sortOrder?: 'asc' | 'desc',
      options?: any
    ): AxiosPromise<ListReferralProgramModelSchema> {
      return localVarFp
        .apiProgramsGet(
          page,
          perPage,
          programType,
          currentlyValid,
          createdAtAfter,
          sortBy,
          sortOrder,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Create new referral program
     * @summary Create new referral program
     * @param {ReferrerProgramModel} referrerProgramModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsPost(
      referrerProgramModel: ReferrerProgramModel,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiProgramsPost(referrerProgramModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete referral program
     * @summary Delete referral program
     * @param {number} programId Referral program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdDelete(programId: number, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiProgramsProgramIdDelete(programId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get referral program data
     * @summary Get referral program data
     * @param {number} programId Referral program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdGet(programId: number, options?: any): AxiosPromise<ReferrerProgramModel> {
      return localVarFp
        .apiProgramsProgramIdGet(programId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update referral program data
     * @summary Update referral program data
     * @param {number} programId Referral program ID
     * @param {ReferrerProgramModel} [referrerProgramModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdPut(
      programId: number,
      referrerProgramModel?: ReferrerProgramModel,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiProgramsProgramIdPut(programId, referrerProgramModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the referral program valid period
     * @summary Update the referral program valid period
     * @param {number} programId Referral program ID
     * @param {UpdateReferralProgramValidPeriod} updateReferralProgramValidPeriod Referral program valid period data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProgramsProgramIdValidPeriodPatch(
      programId: number,
      updateReferralProgramValidPeriod: UpdateReferralProgramValidPeriod,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiProgramsProgramIdValidPeriodPatch(programId, updateReferralProgramValidPeriod, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReferralProgramsApi - object-oriented interface
 * @export
 * @class ReferralProgramsApi
 * @extends {BaseAPI}
 */
export class ReferralProgramsApi extends BaseAPI {
  /**
   * List created referral programs
   * @summary List created referral programs
   * @param {number} [page]
   * @param {number} [perPage]
   * @param {Array<'basicreferralprogramtype'>} [programType] Filter by referral program type
   * @param {boolean} [currentlyValid]
   * @param {string} [createdAtAfter]
   * @param {'program_type' | 'created_at' | 'valid_from' | 'valid_to'} [sortBy]
   * @param {'asc' | 'desc'} [sortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramsApi
   */
  public apiProgramsGet(
    page?: number,
    perPage?: number,
    programType?: Array<'basicreferralprogramtype'>,
    currentlyValid?: boolean,
    createdAtAfter?: string,
    sortBy?: 'program_type' | 'created_at' | 'valid_from' | 'valid_to',
    sortOrder?: 'asc' | 'desc',
    options?: AxiosRequestConfig
  ) {
    return ReferralProgramsApiFp(this.configuration)
      .apiProgramsGet(
        page,
        perPage,
        programType,
        currentlyValid,
        createdAtAfter,
        sortBy,
        sortOrder,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create new referral program
   * @summary Create new referral program
   * @param {ReferrerProgramModel} referrerProgramModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramsApi
   */
  public apiProgramsPost(referrerProgramModel: ReferrerProgramModel, options?: AxiosRequestConfig) {
    return ReferralProgramsApiFp(this.configuration)
      .apiProgramsPost(referrerProgramModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete referral program
   * @summary Delete referral program
   * @param {number} programId Referral program ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramsApi
   */
  public apiProgramsProgramIdDelete(programId: number, options?: AxiosRequestConfig) {
    return ReferralProgramsApiFp(this.configuration)
      .apiProgramsProgramIdDelete(programId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get referral program data
   * @summary Get referral program data
   * @param {number} programId Referral program ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramsApi
   */
  public apiProgramsProgramIdGet(programId: number, options?: AxiosRequestConfig) {
    return ReferralProgramsApiFp(this.configuration)
      .apiProgramsProgramIdGet(programId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update referral program data
   * @summary Update referral program data
   * @param {number} programId Referral program ID
   * @param {ReferrerProgramModel} [referrerProgramModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramsApi
   */
  public apiProgramsProgramIdPut(
    programId: number,
    referrerProgramModel?: ReferrerProgramModel,
    options?: AxiosRequestConfig
  ) {
    return ReferralProgramsApiFp(this.configuration)
      .apiProgramsProgramIdPut(programId, referrerProgramModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the referral program valid period
   * @summary Update the referral program valid period
   * @param {number} programId Referral program ID
   * @param {UpdateReferralProgramValidPeriod} updateReferralProgramValidPeriod Referral program valid period data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralProgramsApi
   */
  public apiProgramsProgramIdValidPeriodPatch(
    programId: number,
    updateReferralProgramValidPeriod: UpdateReferralProgramValidPeriod,
    options?: AxiosRequestConfig
  ) {
    return ReferralProgramsApiFp(this.configuration)
      .apiProgramsProgramIdValidPeriodPatch(programId, updateReferralProgramValidPeriod, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Counting the number of registered users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStatisticsCountUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/statistics/count_users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a list of users with filtering
     * @param {number} [page] page number
     * @param {number} [itemsPerPage] items per page
     * @param {string} [filterBy] selecting a filter field
     * @param {string} [fromDate] start date
     * @param {string} [toDate] end date
     * @param {string} [sortBy] Sorting field
     * @param {string} [sortOrder] The order of data output
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStatisticsListUsersGet: async (
      page?: number,
      itemsPerPage?: number,
      filterBy?: string,
      fromDate?: string,
      toDate?: string,
      sortBy?: string,
      sortOrder?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/statistics/list_users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (filterBy !== undefined) {
        localVarQueryParameter['filter_by'] = filterBy;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter['from_date'] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter['to_date'] = toDate;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Counting the number of registered users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiStatisticsCountUsersGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountUsers>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsCountUsersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a list of users with filtering
     * @param {number} [page] page number
     * @param {number} [itemsPerPage] items per page
     * @param {string} [filterBy] selecting a filter field
     * @param {string} [fromDate] start date
     * @param {string} [toDate] end date
     * @param {string} [sortBy] Sorting field
     * @param {string} [sortOrder] The order of data output
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiStatisticsListUsersGet(
      page?: number,
      itemsPerPage?: number,
      filterBy?: string,
      fromDate?: string,
      toDate?: string,
      sortBy?: string,
      sortOrder?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationUsers>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsListUsersGet(
        page,
        itemsPerPage,
        filterBy,
        fromDate,
        toDate,
        sortBy,
        sortOrder,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StatisticsApiFp(configuration);
  return {
    /**
     *
     * @summary Counting the number of registered users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStatisticsCountUsersGet(options?: any): AxiosPromise<CountUsers> {
      return localVarFp
        .apiStatisticsCountUsersGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a list of users with filtering
     * @param {number} [page] page number
     * @param {number} [itemsPerPage] items per page
     * @param {string} [filterBy] selecting a filter field
     * @param {string} [fromDate] start date
     * @param {string} [toDate] end date
     * @param {string} [sortBy] Sorting field
     * @param {string} [sortOrder] The order of data output
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStatisticsListUsersGet(
      page?: number,
      itemsPerPage?: number,
      filterBy?: string,
      fromDate?: string,
      toDate?: string,
      sortBy?: string,
      sortOrder?: string,
      options?: any
    ): AxiosPromise<PaginationUsers> {
      return localVarFp
        .apiStatisticsListUsersGet(
          page,
          itemsPerPage,
          filterBy,
          fromDate,
          toDate,
          sortBy,
          sortOrder,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
  /**
   *
   * @summary Counting the number of registered users.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public apiStatisticsCountUsersGet(options?: AxiosRequestConfig) {
    return StatisticsApiFp(this.configuration)
      .apiStatisticsCountUsersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a list of users with filtering
   * @param {number} [page] page number
   * @param {number} [itemsPerPage] items per page
   * @param {string} [filterBy] selecting a filter field
   * @param {string} [fromDate] start date
   * @param {string} [toDate] end date
   * @param {string} [sortBy] Sorting field
   * @param {string} [sortOrder] The order of data output
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public apiStatisticsListUsersGet(
    page?: number,
    itemsPerPage?: number,
    filterBy?: string,
    fromDate?: string,
    toDate?: string,
    sortBy?: string,
    sortOrder?: string,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .apiStatisticsListUsersGet(
        page,
        itemsPerPage,
        filterBy,
        fromDate,
        toDate,
        sortBy,
        sortOrder,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TelegramApi - axios parameter creator
 * @export
 */
export const TelegramApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Telegram account deleting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramAccountDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/telegram/account/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary View connected telegram accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramAccountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/telegram/account/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Turn on/ turn off the notification from telegram bot
     * @param {boolean} [setType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramNotificationsPatch: async (
      setType?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/telegram/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (setType !== undefined) {
        localVarQueryParameter['set_type'] = setType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting qr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramQrGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/telegram/qr/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creating token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramTokenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/telegram/token/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramUrlGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/telegram/url/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TelegramApi - functional programming interface
 * @export
 */
export const TelegramApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TelegramApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Telegram account deleting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTelegramAccountDelete(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelegramAccountDelete(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary View connected telegram accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTelegramAccountGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelegramSchema>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelegramAccountGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Turn on/ turn off the notification from telegram bot
     * @param {boolean} [setType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTelegramNotificationsPatch(
      setType?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelegramNotificationsPatch(
        setType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting qr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTelegramQrGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelegramQrGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creating token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTelegramTokenPost(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelegramTokenPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTelegramUrlGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelegramUrlGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TelegramApi - factory interface
 * @export
 */
export const TelegramApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TelegramApiFp(configuration);
  return {
    /**
     *
     * @summary Telegram account deleting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramAccountDelete(options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTelegramAccountDelete(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary View connected telegram accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramAccountGet(options?: any): AxiosPromise<Array<TelegramSchema>> {
      return localVarFp.apiTelegramAccountGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Turn on/ turn off the notification from telegram bot
     * @param {boolean} [setType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramNotificationsPatch(setType?: boolean, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTelegramNotificationsPatch(setType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting qr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramQrGet(options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp.apiTelegramQrGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creating token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramTokenPost(options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp.apiTelegramTokenPost(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTelegramUrlGet(options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp.apiTelegramUrlGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TelegramApi - object-oriented interface
 * @export
 * @class TelegramApi
 * @extends {BaseAPI}
 */
export class TelegramApi extends BaseAPI {
  /**
   *
   * @summary Telegram account deleting
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelegramApi
   */
  public apiTelegramAccountDelete(options?: AxiosRequestConfig) {
    return TelegramApiFp(this.configuration)
      .apiTelegramAccountDelete(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary View connected telegram accounts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelegramApi
   */
  public apiTelegramAccountGet(options?: AxiosRequestConfig) {
    return TelegramApiFp(this.configuration)
      .apiTelegramAccountGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Turn on/ turn off the notification from telegram bot
   * @param {boolean} [setType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelegramApi
   */
  public apiTelegramNotificationsPatch(setType?: boolean, options?: AxiosRequestConfig) {
    return TelegramApiFp(this.configuration)
      .apiTelegramNotificationsPatch(setType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting qr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelegramApi
   */
  public apiTelegramQrGet(options?: AxiosRequestConfig) {
    return TelegramApiFp(this.configuration)
      .apiTelegramQrGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creating token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelegramApi
   */
  public apiTelegramTokenPost(options?: AxiosRequestConfig) {
    return TelegramApiFp(this.configuration)
      .apiTelegramTokenPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelegramApi
   */
  public apiTelegramUrlGet(options?: AxiosRequestConfig) {
    return TelegramApiFp(this.configuration)
      .apiTelegramUrlGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TicketApi - axios parameter creator
 * @export
 */
export const TicketApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Отображение списка статусов и их id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsListStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/tickets/list-statuses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отображение списка тем и их id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsListThemesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/tickets/list-themes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отображение списка полей модели тикет для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsListTicketTableFieldsGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tickets/list-ticket-table-fields`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление статуса
     * @param {CreateTicketStatus} createTicketStatus Данные статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusAddStatusPost: async (
      createTicketStatus: CreateTicketStatus,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTicketStatus' is not null or undefined
      assertParamExists('apiTicketsStatusAddStatusPost', 'createTicketStatus', createTicketStatus);
      const localVarPath = `/api/tickets/status/add-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicketStatus,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение порядка статуса
     * @param {number} statusId id статуса для изменения
     * @param {EditOrderStatus} editOrderStatus модель статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusStatusIdChangeOrderPatch: async (
      statusId: number,
      editOrderStatus: EditOrderStatus,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'statusId' is not null or undefined
      assertParamExists('apiTicketsStatusStatusIdChangeOrderPatch', 'statusId', statusId);
      // verify required parameter 'editOrderStatus' is not null or undefined
      assertParamExists(
        'apiTicketsStatusStatusIdChangeOrderPatch',
        'editOrderStatus',
        editOrderStatus
      );
      const localVarPath = `/api/tickets/status/{status_id}/change-order`.replace(
        `{${'status_id'}}`,
        encodeURIComponent(String(statusId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editOrderStatus,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление статуса
     * @param {number} statusId id статуса для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusStatusIdDeleteStatusDelete: async (
      statusId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'statusId' is not null or undefined
      assertParamExists('apiTicketsStatusStatusIdDeleteStatusDelete', 'statusId', statusId);
      const localVarPath = `/api/tickets/status/{status_id}/delete-status`.replace(
        `{${'status_id'}}`,
        encodeURIComponent(String(statusId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение статуса
     * @param {number} statusId id статуса для изменения
     * @param {CreateTicketStatus} createTicketStatus Данные статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusStatusIdUpdateStatusPut: async (
      statusId: number,
      createTicketStatus: CreateTicketStatus,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'statusId' is not null or undefined
      assertParamExists('apiTicketsStatusStatusIdUpdateStatusPut', 'statusId', statusId);
      // verify required parameter 'createTicketStatus' is not null or undefined
      assertParamExists(
        'apiTicketsStatusStatusIdUpdateStatusPut',
        'createTicketStatus',
        createTicketStatus
      );
      const localVarPath = `/api/tickets/status/{status_id}/update-status`.replace(
        `{${'status_id'}}`,
        encodeURIComponent(String(statusId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicketStatus,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление темы
     * @param {CreateTicketTheme} createTicketTheme схема темы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsThemeAddThemePost: async (
      createTicketTheme: CreateTicketTheme,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTicketTheme' is not null or undefined
      assertParamExists('apiTicketsThemeAddThemePost', 'createTicketTheme', createTicketTheme);
      const localVarPath = `/api/tickets/theme/add-theme`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicketTheme,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление темы
     * @param {number} themeId id темы для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsThemeThemeIdDeleteThemeDelete: async (
      themeId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'themeId' is not null or undefined
      assertParamExists('apiTicketsThemeThemeIdDeleteThemeDelete', 'themeId', themeId);
      const localVarPath = `/api/tickets/theme/{theme_id}/delete-theme`.replace(
        `{${'theme_id'}}`,
        encodeURIComponent(String(themeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение темы
     * @param {number} themeId id темы для изменения
     * @param {CreateTicketTheme} createTicketTheme схема темы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsThemeThemeIdUpdateThemePut: async (
      themeId: number,
      createTicketTheme: CreateTicketTheme,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'themeId' is not null or undefined
      assertParamExists('apiTicketsThemeThemeIdUpdateThemePut', 'themeId', themeId);
      // verify required parameter 'createTicketTheme' is not null or undefined
      assertParamExists(
        'apiTicketsThemeThemeIdUpdateThemePut',
        'createTicketTheme',
        createTicketTheme
      );
      const localVarPath = `/api/tickets/theme/{theme_id}/update-theme`.replace(
        `{${'theme_id'}}`,
        encodeURIComponent(String(themeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicketTheme,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание тикета
     * @param {CreateTicketByManager} createTicketByManager Данные для создания тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketAddTicketByManagerPost: async (
      createTicketByManager: CreateTicketByManager,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTicketByManager' is not null or undefined
      assertParamExists(
        'apiTicketsTicketAddTicketByManagerPost',
        'createTicketByManager',
        createTicketByManager
      );
      const localVarPath = `/api/tickets/ticket/add-ticket-by-manager`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicketByManager,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание тикета
     * @param {CreateTicket} createTicket Данные для создания тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketAddTicketPost: async (
      createTicket: CreateTicket,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTicket' is not null or undefined
      assertParamExists('apiTicketsTicketAddTicketPost', 'createTicket', createTicket);
      const localVarPath = `/api/tickets/ticket/add-ticket`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicket,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Закрытие одного или нескольких тикетов
     * @param {CloseTickets} closeTickets список тикетов на закрытие
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketCloseTicketsPost: async (
      closeTickets: CloseTickets,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'closeTickets' is not null or undefined
      assertParamExists('apiTicketsTicketCloseTicketsPost', 'closeTickets', closeTickets);
      const localVarPath = `/api/tickets/ticket/close-tickets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        closeTickets,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление одного или нескольких тикетов
     * @param {SelectedTickets} selectedTickets список тикетов на удаление
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketDeleteTicketsPost: async (
      selectedTickets: SelectedTickets,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'selectedTickets' is not null or undefined
      assertParamExists('apiTicketsTicketDeleteTicketsPost', 'selectedTickets', selectedTickets);
      const localVarPath = `/api/tickets/ticket/delete-tickets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        selectedTickets,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отображение всей информации о тикете вместе с комментариями
     * @param {number} ticketId id тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketDetailedViewTicketIdGet: async (
      ticketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketId' is not null or undefined
      assertParamExists('apiTicketsTicketDetailedViewTicketIdGet', 'ticketId', ticketId);
      const localVarPath = `/api/tickets/ticket/detailed-view/{ticket_id}`.replace(
        `{${'ticket_id'}}`,
        encodeURIComponent(String(ticketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
     * @param {string} [managerId] id пользователя, назначенного менеджером к тикету
     * @param {string} [userId] id пользователя, создавшего тикет
     * @param {number} [statusId] id статуса
     * @param {number} [ticketRating] рейтинг
     * @param {string} [fromDate] начальная дата
     * @param {string} [toDate] конечная дата
     * @param {number} [page] Номер страницы
     * @param {number} [itemsPerPage] Количество объектов на странице, default&#x3D;10
     * @param {string} [sortBy] Поле для сортировки, default&#x3D;Ticket.created_date
     * @param {string} [sortOrder] Отобразить в порядке возрастания - asc, убывания - desc, default&#x3D;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketListTicketsGet: async (
      managerId?: string,
      userId?: string,
      statusId?: number,
      ticketRating?: number,
      fromDate?: string,
      toDate?: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?: string,
      sortOrder?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tickets/ticket/list-tickets/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (managerId !== undefined) {
        localVarQueryParameter['manager_id'] = managerId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      if (statusId !== undefined) {
        localVarQueryParameter['status_id'] = statusId;
      }

      if (ticketRating !== undefined) {
        localVarQueryParameter['ticket_rating'] = ticketRating;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter['from_date'] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter['to_date'] = toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавление комментария
     * @param {number} ticketId id тикета
     * @param {CreateTicketComment} createTicketComment Содержание комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdAddCommentPost: async (
      ticketId: number,
      createTicketComment: CreateTicketComment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketId' is not null or undefined
      assertParamExists('apiTicketsTicketTicketIdAddCommentPost', 'ticketId', ticketId);
      // verify required parameter 'createTicketComment' is not null or undefined
      assertParamExists(
        'apiTicketsTicketTicketIdAddCommentPost',
        'createTicketComment',
        createTicketComment
      );
      const localVarPath = `/api/tickets/ticket/{ticket_id}/add-comment`.replace(
        `{${'ticket_id'}}`,
        encodeURIComponent(String(ticketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTicketComment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение менеджера тикета
     * @param {number} ticketId id тикета для изменения
     * @param {EditManagerTicket} editManagerTicket модель тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdChangeManagerPatch: async (
      ticketId: number,
      editManagerTicket: EditManagerTicket,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketId' is not null or undefined
      assertParamExists('apiTicketsTicketTicketIdChangeManagerPatch', 'ticketId', ticketId);
      // verify required parameter 'editManagerTicket' is not null or undefined
      assertParamExists(
        'apiTicketsTicketTicketIdChangeManagerPatch',
        'editManagerTicket',
        editManagerTicket
      );
      const localVarPath = `/api/tickets/ticket/{ticket_id}/change-manager`.replace(
        `{${'ticket_id'}}`,
        encodeURIComponent(String(ticketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editManagerTicket,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменение статуса тикета
     * @param {number} ticketId id тикета для изменения
     * @param {EditStatusTicket} editStatusTicket модель тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdChangeStatusPatch: async (
      ticketId: number,
      editStatusTicket: EditStatusTicket,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketId' is not null or undefined
      assertParamExists('apiTicketsTicketTicketIdChangeStatusPatch', 'ticketId', ticketId);
      // verify required parameter 'editStatusTicket' is not null or undefined
      assertParamExists(
        'apiTicketsTicketTicketIdChangeStatusPatch',
        'editStatusTicket',
        editStatusTicket
      );
      const localVarPath = `/api/tickets/ticket/{ticket_id}/change-status`.replace(
        `{${'ticket_id'}}`,
        encodeURIComponent(String(ticketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editStatusTicket,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление комментария
     * @param {number} ticketId id тикета
     * @param {number} commentId id комментария для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdDeleteCommentCommentIdDelete: async (
      ticketId: number,
      commentId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketId' is not null or undefined
      assertParamExists(
        'apiTicketsTicketTicketIdDeleteCommentCommentIdDelete',
        'ticketId',
        ticketId
      );
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists(
        'apiTicketsTicketTicketIdDeleteCommentCommentIdDelete',
        'commentId',
        commentId
      );
      const localVarPath = `/api/tickets/ticket/{ticket_id}/delete-comment/{comment_id}`
        .replace(`{${'ticket_id'}}`, encodeURIComponent(String(ticketId)))
        .replace(`{${'comment_id'}}`, encodeURIComponent(String(commentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TicketApi - functional programming interface
 * @export
 */
export const TicketApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TicketApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Отображение списка статусов и их id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsListStatusesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsListStatusesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отображение списка тем и их id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsListThemesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTheme>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsListThemesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отображение списка полей модели тикет для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsListTicketTableFieldsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTicketFields>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsListTicketTableFieldsGet(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление статуса
     * @param {CreateTicketStatus} createTicketStatus Данные статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsStatusAddStatusPost(
      createTicketStatus: CreateTicketStatus,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsStatusAddStatusPost(
        createTicketStatus,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение порядка статуса
     * @param {number} statusId id статуса для изменения
     * @param {EditOrderStatus} editOrderStatus модель статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsStatusStatusIdChangeOrderPatch(
      statusId: number,
      editOrderStatus: EditOrderStatus,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsStatusStatusIdChangeOrderPatch(
          statusId,
          editOrderStatus,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление статуса
     * @param {number} statusId id статуса для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsStatusStatusIdDeleteStatusDelete(
      statusId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsStatusStatusIdDeleteStatusDelete(
          statusId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение статуса
     * @param {number} statusId id статуса для изменения
     * @param {CreateTicketStatus} createTicketStatus Данные статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsStatusStatusIdUpdateStatusPut(
      statusId: number,
      createTicketStatus: CreateTicketStatus,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsStatusStatusIdUpdateStatusPut(
          statusId,
          createTicketStatus,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление темы
     * @param {CreateTicketTheme} createTicketTheme схема темы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsThemeAddThemePost(
      createTicketTheme: CreateTicketTheme,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsThemeAddThemePost(
        createTicketTheme,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление темы
     * @param {number} themeId id темы для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsThemeThemeIdDeleteThemeDelete(
      themeId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsThemeThemeIdDeleteThemeDelete(themeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение темы
     * @param {number} themeId id темы для изменения
     * @param {CreateTicketTheme} createTicketTheme схема темы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsThemeThemeIdUpdateThemePut(
      themeId: number,
      createTicketTheme: CreateTicketTheme,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsThemeThemeIdUpdateThemePut(
          themeId,
          createTicketTheme,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание тикета
     * @param {CreateTicketByManager} createTicketByManager Данные для создания тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketAddTicketByManagerPost(
      createTicketByManager: CreateTicketByManager,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsTicketAddTicketByManagerPost(
          createTicketByManager,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание тикета
     * @param {CreateTicket} createTicket Данные для создания тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketAddTicketPost(
      createTicket: CreateTicket,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsTicketAddTicketPost(
        createTicket,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Закрытие одного или нескольких тикетов
     * @param {CloseTickets} closeTickets список тикетов на закрытие
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketCloseTicketsPost(
      closeTickets: CloseTickets,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipleResults>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsTicketCloseTicketsPost(
        closeTickets,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление одного или нескольких тикетов
     * @param {SelectedTickets} selectedTickets список тикетов на удаление
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketDeleteTicketsPost(
      selectedTickets: SelectedTickets,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipleResults>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsTicketDeleteTicketsPost(
        selectedTickets,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отображение всей информации о тикете вместе с комментариями
     * @param {number} ticketId id тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketDetailedViewTicketIdGet(
      ticketId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketTheme>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsTicketDetailedViewTicketIdGet(ticketId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
     * @param {string} [managerId] id пользователя, назначенного менеджером к тикету
     * @param {string} [userId] id пользователя, создавшего тикет
     * @param {number} [statusId] id статуса
     * @param {number} [ticketRating] рейтинг
     * @param {string} [fromDate] начальная дата
     * @param {string} [toDate] конечная дата
     * @param {number} [page] Номер страницы
     * @param {number} [itemsPerPage] Количество объектов на странице, default&#x3D;10
     * @param {string} [sortBy] Поле для сортировки, default&#x3D;Ticket.created_date
     * @param {string} [sortOrder] Отобразить в порядке возрастания - asc, убывания - desc, default&#x3D;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketListTicketsGet(
      managerId?: string,
      userId?: string,
      statusId?: number,
      ticketRating?: number,
      fromDate?: string,
      toDate?: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?: string,
      sortOrder?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationTicket>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTicketsTicketListTicketsGet(
        managerId,
        userId,
        statusId,
        ticketRating,
        fromDate,
        toDate,
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Добавление комментария
     * @param {number} ticketId id тикета
     * @param {CreateTicketComment} createTicketComment Содержание комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketTicketIdAddCommentPost(
      ticketId: number,
      createTicketComment: CreateTicketComment,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsTicketTicketIdAddCommentPost(
          ticketId,
          createTicketComment,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение менеджера тикета
     * @param {number} ticketId id тикета для изменения
     * @param {EditManagerTicket} editManagerTicket модель тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketTicketIdChangeManagerPatch(
      ticketId: number,
      editManagerTicket: EditManagerTicket,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsTicketTicketIdChangeManagerPatch(
          ticketId,
          editManagerTicket,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Изменение статуса тикета
     * @param {number} ticketId id тикета для изменения
     * @param {EditStatusTicket} editStatusTicket модель тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketTicketIdChangeStatusPatch(
      ticketId: number,
      editStatusTicket: EditStatusTicket,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsTicketTicketIdChangeStatusPatch(
          ticketId,
          editStatusTicket,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление комментария
     * @param {number} ticketId id тикета
     * @param {number} commentId id комментария для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(
      ticketId: number,
      commentId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(
          ticketId,
          commentId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TicketApi - factory interface
 * @export
 */
export const TicketApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TicketApiFp(configuration);
  return {
    /**
     *
     * @summary Отображение списка статусов и их id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsListStatusesGet(options?: any): AxiosPromise<ListStatus> {
      return localVarFp
        .apiTicketsListStatusesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отображение списка тем и их id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsListThemesGet(options?: any): AxiosPromise<ListTheme> {
      return localVarFp
        .apiTicketsListThemesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отображение списка полей модели тикет для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsListTicketTableFieldsGet(options?: any): AxiosPromise<ListTicketFields> {
      return localVarFp
        .apiTicketsListTicketTableFieldsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление статуса
     * @param {CreateTicketStatus} createTicketStatus Данные статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusAddStatusPost(
      createTicketStatus: CreateTicketStatus,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsStatusAddStatusPost(createTicketStatus, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение порядка статуса
     * @param {number} statusId id статуса для изменения
     * @param {EditOrderStatus} editOrderStatus модель статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusStatusIdChangeOrderPatch(
      statusId: number,
      editOrderStatus: EditOrderStatus,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsStatusStatusIdChangeOrderPatch(statusId, editOrderStatus, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление статуса
     * @param {number} statusId id статуса для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusStatusIdDeleteStatusDelete(
      statusId: number,
      options?: any
    ): AxiosPromise<InlineResponse2007> {
      return localVarFp
        .apiTicketsStatusStatusIdDeleteStatusDelete(statusId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение статуса
     * @param {number} statusId id статуса для изменения
     * @param {CreateTicketStatus} createTicketStatus Данные статуса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsStatusStatusIdUpdateStatusPut(
      statusId: number,
      createTicketStatus: CreateTicketStatus,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsStatusStatusIdUpdateStatusPut(statusId, createTicketStatus, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление темы
     * @param {CreateTicketTheme} createTicketTheme схема темы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsThemeAddThemePost(
      createTicketTheme: CreateTicketTheme,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsThemeAddThemePost(createTicketTheme, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление темы
     * @param {number} themeId id темы для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsThemeThemeIdDeleteThemeDelete(
      themeId: number,
      options?: any
    ): AxiosPromise<InlineResponse2007> {
      return localVarFp
        .apiTicketsThemeThemeIdDeleteThemeDelete(themeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение темы
     * @param {number} themeId id темы для изменения
     * @param {CreateTicketTheme} createTicketTheme схема темы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsThemeThemeIdUpdateThemePut(
      themeId: number,
      createTicketTheme: CreateTicketTheme,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsThemeThemeIdUpdateThemePut(themeId, createTicketTheme, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Создание тикета
     * @param {CreateTicketByManager} createTicketByManager Данные для создания тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketAddTicketByManagerPost(
      createTicketByManager: CreateTicketByManager,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsTicketAddTicketByManagerPost(createTicketByManager, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Создание тикета
     * @param {CreateTicket} createTicket Данные для создания тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketAddTicketPost(
      createTicket: CreateTicket,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsTicketAddTicketPost(createTicket, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Закрытие одного или нескольких тикетов
     * @param {CloseTickets} closeTickets список тикетов на закрытие
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketCloseTicketsPost(
      closeTickets: CloseTickets,
      options?: any
    ): AxiosPromise<MultipleResults> {
      return localVarFp
        .apiTicketsTicketCloseTicketsPost(closeTickets, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление одного или нескольких тикетов
     * @param {SelectedTickets} selectedTickets список тикетов на удаление
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketDeleteTicketsPost(
      selectedTickets: SelectedTickets,
      options?: any
    ): AxiosPromise<MultipleResults> {
      return localVarFp
        .apiTicketsTicketDeleteTicketsPost(selectedTickets, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отображение всей информации о тикете вместе с комментариями
     * @param {number} ticketId id тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketDetailedViewTicketIdGet(
      ticketId: number,
      options?: any
    ): AxiosPromise<TicketTheme> {
      return localVarFp
        .apiTicketsTicketDetailedViewTicketIdGet(ticketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
     * @param {string} [managerId] id пользователя, назначенного менеджером к тикету
     * @param {string} [userId] id пользователя, создавшего тикет
     * @param {number} [statusId] id статуса
     * @param {number} [ticketRating] рейтинг
     * @param {string} [fromDate] начальная дата
     * @param {string} [toDate] конечная дата
     * @param {number} [page] Номер страницы
     * @param {number} [itemsPerPage] Количество объектов на странице, default&#x3D;10
     * @param {string} [sortBy] Поле для сортировки, default&#x3D;Ticket.created_date
     * @param {string} [sortOrder] Отобразить в порядке возрастания - asc, убывания - desc, default&#x3D;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketListTicketsGet(
      managerId?: string,
      userId?: string,
      statusId?: number,
      ticketRating?: number,
      fromDate?: string,
      toDate?: string,
      page?: number,
      itemsPerPage?: number,
      sortBy?: string,
      sortOrder?: string,
      options?: any
    ): AxiosPromise<PaginationTicket> {
      return localVarFp
        .apiTicketsTicketListTicketsGet(
          managerId,
          userId,
          statusId,
          ticketRating,
          fromDate,
          toDate,
          page,
          itemsPerPage,
          sortBy,
          sortOrder,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавление комментария
     * @param {number} ticketId id тикета
     * @param {CreateTicketComment} createTicketComment Содержание комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdAddCommentPost(
      ticketId: number,
      createTicketComment: CreateTicketComment,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsTicketTicketIdAddCommentPost(ticketId, createTicketComment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение менеджера тикета
     * @param {number} ticketId id тикета для изменения
     * @param {EditManagerTicket} editManagerTicket модель тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdChangeManagerPatch(
      ticketId: number,
      editManagerTicket: EditManagerTicket,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsTicketTicketIdChangeManagerPatch(ticketId, editManagerTicket, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменение статуса тикета
     * @param {number} ticketId id тикета для изменения
     * @param {EditStatusTicket} editStatusTicket модель тикета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdChangeStatusPatch(
      ticketId: number,
      editStatusTicket: EditStatusTicket,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsTicketTicketIdChangeStatusPatch(ticketId, editStatusTicket, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление комментария
     * @param {number} ticketId id тикета
     * @param {number} commentId id комментария для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(
      ticketId: number,
      commentId: number,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(ticketId, commentId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TicketApi - object-oriented interface
 * @export
 * @class TicketApi
 * @extends {BaseAPI}
 */
export class TicketApi extends BaseAPI {
  /**
   *
   * @summary Отображение списка статусов и их id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsListStatusesGet(options?: AxiosRequestConfig) {
    return TicketApiFp(this.configuration)
      .apiTicketsListStatusesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отображение списка тем и их id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsListThemesGet(options?: AxiosRequestConfig) {
    return TicketApiFp(this.configuration)
      .apiTicketsListThemesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отображение списка полей модели тикет для сортировки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsListTicketTableFieldsGet(options?: AxiosRequestConfig) {
    return TicketApiFp(this.configuration)
      .apiTicketsListTicketTableFieldsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление статуса
   * @param {CreateTicketStatus} createTicketStatus Данные статуса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsStatusAddStatusPost(
    createTicketStatus: CreateTicketStatus,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsStatusAddStatusPost(createTicketStatus, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение порядка статуса
   * @param {number} statusId id статуса для изменения
   * @param {EditOrderStatus} editOrderStatus модель статуса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsStatusStatusIdChangeOrderPatch(
    statusId: number,
    editOrderStatus: EditOrderStatus,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsStatusStatusIdChangeOrderPatch(statusId, editOrderStatus, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление статуса
   * @param {number} statusId id статуса для удаления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsStatusStatusIdDeleteStatusDelete(
    statusId: number,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsStatusStatusIdDeleteStatusDelete(statusId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение статуса
   * @param {number} statusId id статуса для изменения
   * @param {CreateTicketStatus} createTicketStatus Данные статуса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsStatusStatusIdUpdateStatusPut(
    statusId: number,
    createTicketStatus: CreateTicketStatus,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsStatusStatusIdUpdateStatusPut(statusId, createTicketStatus, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление темы
   * @param {CreateTicketTheme} createTicketTheme схема темы
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsThemeAddThemePost(
    createTicketTheme: CreateTicketTheme,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsThemeAddThemePost(createTicketTheme, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление темы
   * @param {number} themeId id темы для удаления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsThemeThemeIdDeleteThemeDelete(themeId: number, options?: AxiosRequestConfig) {
    return TicketApiFp(this.configuration)
      .apiTicketsThemeThemeIdDeleteThemeDelete(themeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение темы
   * @param {number} themeId id темы для изменения
   * @param {CreateTicketTheme} createTicketTheme схема темы
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsThemeThemeIdUpdateThemePut(
    themeId: number,
    createTicketTheme: CreateTicketTheme,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsThemeThemeIdUpdateThemePut(themeId, createTicketTheme, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание тикета
   * @param {CreateTicketByManager} createTicketByManager Данные для создания тикета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketAddTicketByManagerPost(
    createTicketByManager: CreateTicketByManager,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketAddTicketByManagerPost(createTicketByManager, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание тикета
   * @param {CreateTicket} createTicket Данные для создания тикета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketAddTicketPost(createTicket: CreateTicket, options?: AxiosRequestConfig) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketAddTicketPost(createTicket, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Закрытие одного или нескольких тикетов
   * @param {CloseTickets} closeTickets список тикетов на закрытие
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketCloseTicketsPost(
    closeTickets: CloseTickets,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketCloseTicketsPost(closeTickets, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление одного или нескольких тикетов
   * @param {SelectedTickets} selectedTickets список тикетов на удаление
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketDeleteTicketsPost(
    selectedTickets: SelectedTickets,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketDeleteTicketsPost(selectedTickets, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отображение всей информации о тикете вместе с комментариями
   * @param {number} ticketId id тикета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketDetailedViewTicketIdGet(ticketId: number, options?: AxiosRequestConfig) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketDetailedViewTicketIdGet(ticketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
   * @param {string} [managerId] id пользователя, назначенного менеджером к тикету
   * @param {string} [userId] id пользователя, создавшего тикет
   * @param {number} [statusId] id статуса
   * @param {number} [ticketRating] рейтинг
   * @param {string} [fromDate] начальная дата
   * @param {string} [toDate] конечная дата
   * @param {number} [page] Номер страницы
   * @param {number} [itemsPerPage] Количество объектов на странице, default&#x3D;10
   * @param {string} [sortBy] Поле для сортировки, default&#x3D;Ticket.created_date
   * @param {string} [sortOrder] Отобразить в порядке возрастания - asc, убывания - desc, default&#x3D;desc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketListTicketsGet(
    managerId?: string,
    userId?: string,
    statusId?: number,
    ticketRating?: number,
    fromDate?: string,
    toDate?: string,
    page?: number,
    itemsPerPage?: number,
    sortBy?: string,
    sortOrder?: string,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketListTicketsGet(
        managerId,
        userId,
        statusId,
        ticketRating,
        fromDate,
        toDate,
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавление комментария
   * @param {number} ticketId id тикета
   * @param {CreateTicketComment} createTicketComment Содержание комментария
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketTicketIdAddCommentPost(
    ticketId: number,
    createTicketComment: CreateTicketComment,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketTicketIdAddCommentPost(ticketId, createTicketComment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение менеджера тикета
   * @param {number} ticketId id тикета для изменения
   * @param {EditManagerTicket} editManagerTicket модель тикета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketTicketIdChangeManagerPatch(
    ticketId: number,
    editManagerTicket: EditManagerTicket,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketTicketIdChangeManagerPatch(ticketId, editManagerTicket, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменение статуса тикета
   * @param {number} ticketId id тикета для изменения
   * @param {EditStatusTicket} editStatusTicket модель тикета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketTicketIdChangeStatusPatch(
    ticketId: number,
    editStatusTicket: EditStatusTicket,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketTicketIdChangeStatusPatch(ticketId, editStatusTicket, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление комментария
   * @param {number} ticketId id тикета
   * @param {number} commentId id комментария для удаления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TicketApi
   */
  public apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(
    ticketId: number,
    commentId: number,
    options?: AxiosRequestConfig
  ) {
    return TicketApiFp(this.configuration)
      .apiTicketsTicketTicketIdDeleteCommentCommentIdDelete(ticketId, commentId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Confirmation of a specific user\'s email and account using the admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersConfirmUserUserIdGet: async (
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersConfirmUserUserIdGet', 'userId', userId);
      const localVarPath = `/api/users/confirm_user/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login as other user (other user emulation) using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersLoginUserIdPost: async (
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersLoginUserIdPost', 'userId', userId);
      const localVarPath = `/api/users/login/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logout another user emulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersLogoutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/users/logout/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Downloading the user\'s avatar using the admin panel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfileAvatarGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/users/profile/avatar/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Uploading a user\'s avatar using admin panel.
     * @param {any} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfileAvatarPost: async (
      image?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/profile/avatar/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting a user profile in the admin panel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/users/profile/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updating user profile data using admin panel.
     * @param {UpdateUserProfileSchema} [updateUserProfileSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfilePost: async (
      updateUserProfileSchema?: UpdateUserProfileSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/profile/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserProfileSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Restore the account of a deleted user using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersRestoreUserUserIdGet: async (
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersRestoreUserUserIdGet', 'userId', userId);
      const localVarPath = `/api/users/restore_user/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting a list of user roles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/users/roles/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Adding a user using the admin panel.
     * @param {CreateUserSchema} [createUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserPost: async (
      createUserSchema?: CreateUserSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleting a user using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdDelete: async (
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersUserUserIdDelete', 'userId', userId);
      const localVarPath = `/api/users/user/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting user information using the admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdGet: async (
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersUserUserIdGet', 'userId', userId);
      const localVarPath = `/api/users/user/{user_id}`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Activation/deactivation of the user using the admin panel.
     * @param {string} userId
     * @param {boolean} [status] User status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdPatch: async (
      userId: string,
      status?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersUserUserIdPatch', 'userId', userId);
      const localVarPath = `/api/users/user/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updating user information using admin panel.
     * @param {string} userId
     * @param {UpdateUserSchema} [updateUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdPost: async (
      userId: string,
      updateUserSchema?: UpdateUserSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('apiUsersUserUserIdPost', 'userId', userId);
      const localVarPath = `/api/users/user/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting a list of users using admin panel.
     * @param {number} page page number
     * @param {number} page2
     * @param {'asc' | 'desc'} [sortType]
     * @param {boolean} [status]
     * @param {string} [username]
     * @param {string} [email]
     * @param {number} [itemsPerPage]
     * @param {boolean} [isRegistration]
     * @param {'id' | 'email' | 'username' | 'role' | 'status' | 'phone_number' | 'temp_email' | 'new_email' | 'is_email_change' | 'email_confirmed' | 'confirmed'} [sortBy]
     * @param {Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>} [role]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUsersPageGet: async (
      page: number,
      page2: number,
      sortType?: 'asc' | 'desc',
      status?: boolean,
      username?: string,
      email?: string,
      itemsPerPage?: number,
      isRegistration?: boolean,
      sortBy?:
        | 'id'
        | 'email'
        | 'username'
        | 'role'
        | 'status'
        | 'phone_number'
        | 'temp_email'
        | 'new_email'
        | 'is_email_change'
        | 'email_confirmed'
        | 'confirmed',
      role?: Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('apiUsersUsersPageGet', 'page', page);
      // verify required parameter 'page2' is not null or undefined
      assertParamExists('apiUsersUsersPageGet', 'page2', page2);
      const localVarPath = `/api/users/users/{page}/`.replace(
        `{${'page'}}`,
        encodeURIComponent(String(page))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page2 !== undefined) {
        localVarQueryParameter['page'] = page2;
      }

      if (sortType !== undefined) {
        localVarQueryParameter['sort_type'] = sortType;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter['items_per_page'] = itemsPerPage;
      }

      if (isRegistration !== undefined) {
        localVarQueryParameter['is_registration'] = isRegistration;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (role) {
        localVarQueryParameter['role'] = role;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Confirmation of a specific user\'s email and account using the admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersConfirmUserUserIdGet(
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersConfirmUserUserIdGet(
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Login as other user (other user emulation) using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersLoginUserIdPost(
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersLoginUserIdPost(
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Logout another user emulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersLogoutGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersLogoutGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Downloading the user\'s avatar using the admin panel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersProfileAvatarGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersProfileAvatarGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Uploading a user\'s avatar using admin panel.
     * @param {any} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersProfileAvatarPost(
      image?: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersProfileAvatarPost(
        image,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting a user profile in the admin panel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersProfileGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileUser>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersProfileGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updating user profile data using admin panel.
     * @param {UpdateUserProfileSchema} [updateUserProfileSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersProfilePost(
      updateUserProfileSchema?: UpdateUserProfileSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersProfilePost(
        updateUserProfileSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Restore the account of a deleted user using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersRestoreUserUserIdGet(
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersRestoreUserUserIdGet(
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting a list of user roles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersRolesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRoleSchema>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersRolesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Adding a user using the admin panel.
     * @param {CreateUserSchema} [createUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersUserPost(
      createUserSchema?: CreateUserSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserPost(
        createUserSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deleting a user using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersUserUserIdDelete(
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserUserIdDelete(
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting user information using the admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersUserUserIdGet(
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserUserIdGet(
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Activation/deactivation of the user using the admin panel.
     * @param {string} userId
     * @param {boolean} [status] User status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersUserUserIdPatch(
      userId: string,
      status?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserUserIdPatch(
        userId,
        status,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updating user information using admin panel.
     * @param {string} userId
     * @param {UpdateUserSchema} [updateUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersUserUserIdPost(
      userId: string,
      updateUserSchema?: UpdateUserSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserUserIdPost(
        userId,
        updateUserSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Getting a list of users using admin panel.
     * @param {number} page page number
     * @param {number} page2
     * @param {'asc' | 'desc'} [sortType]
     * @param {boolean} [status]
     * @param {string} [username]
     * @param {string} [email]
     * @param {number} [itemsPerPage]
     * @param {boolean} [isRegistration]
     * @param {'id' | 'email' | 'username' | 'role' | 'status' | 'phone_number' | 'temp_email' | 'new_email' | 'is_email_change' | 'email_confirmed' | 'confirmed'} [sortBy]
     * @param {Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>} [role]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersUsersPageGet(
      page: number,
      page2: number,
      sortType?: 'asc' | 'desc',
      status?: boolean,
      username?: string,
      email?: string,
      itemsPerPage?: number,
      isRegistration?: boolean,
      sortBy?:
        | 'id'
        | 'email'
        | 'username'
        | 'role'
        | 'status'
        | 'phone_number'
        | 'temp_email'
        | 'new_email'
        | 'is_email_change'
        | 'email_confirmed'
        | 'confirmed',
      role?: Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUsersPageGet(
        page,
        page2,
        sortType,
        status,
        username,
        email,
        itemsPerPage,
        isRegistration,
        sortBy,
        role,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Confirmation of a specific user\'s email and account using the admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersConfirmUserUserIdGet(userId: string, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersConfirmUserUserIdGet(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login as other user (other user emulation) using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersLoginUserIdPost(userId: string, options?: any): AxiosPromise<InlineResponse2008> {
      return localVarFp
        .apiUsersLoginUserIdPost(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logout another user emulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersLogoutGet(options?: any): AxiosPromise<AuthToken> {
      return localVarFp.apiUsersLogoutGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Downloading the user\'s avatar using the admin panel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfileAvatarGet(options?: any): AxiosPromise<void> {
      return localVarFp
        .apiUsersProfileAvatarGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Uploading a user\'s avatar using admin panel.
     * @param {any} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfileAvatarPost(image?: any, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersProfileAvatarPost(image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting a user profile in the admin panel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfileGet(options?: any): AxiosPromise<ProfileUser> {
      return localVarFp.apiUsersProfileGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updating user profile data using admin panel.
     * @param {UpdateUserProfileSchema} [updateUserProfileSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersProfilePost(
      updateUserProfileSchema?: UpdateUserProfileSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersProfilePost(updateUserProfileSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Restore the account of a deleted user using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersRestoreUserUserIdGet(userId: string, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersRestoreUserUserIdGet(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting a list of user roles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersRolesGet(options?: any): AxiosPromise<Array<GetRoleSchema>> {
      return localVarFp.apiUsersRolesGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Adding a user using the admin panel.
     * @param {CreateUserSchema} [createUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserPost(
      createUserSchema?: CreateUserSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersUserPost(createUserSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleting a user using admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdDelete(userId: string, options?: any): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersUserUserIdDelete(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting user information using the admin panel.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdGet(userId: string, options?: any): AxiosPromise<UserSchema> {
      return localVarFp
        .apiUsersUserUserIdGet(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Activation/deactivation of the user using the admin panel.
     * @param {string} userId
     * @param {boolean} [status] User status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdPatch(
      userId: string,
      status?: boolean,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersUserUserIdPatch(userId, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updating user information using admin panel.
     * @param {string} userId
     * @param {UpdateUserSchema} [updateUserSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUserUserIdPost(
      userId: string,
      updateUserSchema?: UpdateUserSchema,
      options?: any
    ): AxiosPromise<BinaryResponse> {
      return localVarFp
        .apiUsersUserUserIdPost(userId, updateUserSchema, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Getting a list of users using admin panel.
     * @param {number} page page number
     * @param {number} page2
     * @param {'asc' | 'desc'} [sortType]
     * @param {boolean} [status]
     * @param {string} [username]
     * @param {string} [email]
     * @param {number} [itemsPerPage]
     * @param {boolean} [isRegistration]
     * @param {'id' | 'email' | 'username' | 'role' | 'status' | 'phone_number' | 'temp_email' | 'new_email' | 'is_email_change' | 'email_confirmed' | 'confirmed'} [sortBy]
     * @param {Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>} [role]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersUsersPageGet(
      page: number,
      page2: number,
      sortType?: 'asc' | 'desc',
      status?: boolean,
      username?: string,
      email?: string,
      itemsPerPage?: number,
      isRegistration?: boolean,
      sortBy?:
        | 'id'
        | 'email'
        | 'username'
        | 'role'
        | 'status'
        | 'phone_number'
        | 'temp_email'
        | 'new_email'
        | 'is_email_change'
        | 'email_confirmed'
        | 'confirmed',
      role?: Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>,
      options?: any
    ): AxiosPromise<UserList> {
      return localVarFp
        .apiUsersUsersPageGet(
          page,
          page2,
          sortType,
          status,
          username,
          email,
          itemsPerPage,
          isRegistration,
          sortBy,
          role,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Confirmation of a specific user\'s email and account using the admin panel.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersConfirmUserUserIdGet(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersConfirmUserUserIdGet(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login as other user (other user emulation) using admin panel.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersLoginUserIdPost(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersLoginUserIdPost(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logout another user emulation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersLogoutGet(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersLogoutGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Downloading the user\'s avatar using the admin panel.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersProfileAvatarGet(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersProfileAvatarGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Uploading a user\'s avatar using admin panel.
   * @param {any} [image]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersProfileAvatarPost(image?: any, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersProfileAvatarPost(image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting a user profile in the admin panel.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersProfileGet(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersProfileGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updating user profile data using admin panel.
   * @param {UpdateUserProfileSchema} [updateUserProfileSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersProfilePost(
    updateUserProfileSchema?: UpdateUserProfileSchema,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .apiUsersProfilePost(updateUserProfileSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Restore the account of a deleted user using admin panel.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersRestoreUserUserIdGet(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersRestoreUserUserIdGet(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting a list of user roles.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersRolesGet(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersRolesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Adding a user using the admin panel.
   * @param {CreateUserSchema} [createUserSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersUserPost(createUserSchema?: CreateUserSchema, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersUserPost(createUserSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleting a user using admin panel.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersUserUserIdDelete(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersUserUserIdDelete(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting user information using the admin panel.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersUserUserIdGet(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersUserUserIdGet(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Activation/deactivation of the user using the admin panel.
   * @param {string} userId
   * @param {boolean} [status] User status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersUserUserIdPatch(userId: string, status?: boolean, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .apiUsersUserUserIdPatch(userId, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updating user information using admin panel.
   * @param {string} userId
   * @param {UpdateUserSchema} [updateUserSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersUserUserIdPost(
    userId: string,
    updateUserSchema?: UpdateUserSchema,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .apiUsersUserUserIdPost(userId, updateUserSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Getting a list of users using admin panel.
   * @param {number} page page number
   * @param {number} page2
   * @param {'asc' | 'desc'} [sortType]
   * @param {boolean} [status]
   * @param {string} [username]
   * @param {string} [email]
   * @param {number} [itemsPerPage]
   * @param {boolean} [isRegistration]
   * @param {'id' | 'email' | 'username' | 'role' | 'status' | 'phone_number' | 'temp_email' | 'new_email' | 'is_email_change' | 'email_confirmed' | 'confirmed'} [sortBy]
   * @param {Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>} [role]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersUsersPageGet(
    page: number,
    page2: number,
    sortType?: 'asc' | 'desc',
    status?: boolean,
    username?: string,
    email?: string,
    itemsPerPage?: number,
    isRegistration?: boolean,
    sortBy?:
      | 'id'
      | 'email'
      | 'username'
      | 'role'
      | 'status'
      | 'phone_number'
      | 'temp_email'
      | 'new_email'
      | 'is_email_change'
      | 'email_confirmed'
      | 'confirmed',
    role?: Array<'employee' | 'support_manager' | 'user' | 'superuser' | 'admin'>,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .apiUsersUsersPageGet(
        page,
        page2,
        sortType,
        status,
        username,
        email,
        itemsPerPage,
        isRegistration,
        sortBy,
        role,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
