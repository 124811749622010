import {
  internalAnalyticsFiltersActions,
  internalAnalyticsFiltersReducer,
} from 'src/store/entities/tools/internalAnalytics/internalAnalyticsFilters';

import {
  ABCAnalyzeActions,
  ABCAnalyzeReducer,
} from '@/store/entities/tools/internalAnalytics/ABCAnalyze';
import * as selectors from '@/store/entities/tools/internalAnalytics/internalAnalyticsFilters/selectors';
import {
  marginAnalysisActions,
  marginAnalysisReducer,
} from '@/store/entities/tools/internalAnalytics/marginAnalysis';

export const internalAnalyticsCombineReducer = {
  ABCAnalyze: ABCAnalyzeReducer,
  internalAnalyticsFilters: internalAnalyticsFiltersReducer,
  marginAnalysis: marginAnalysisReducer,
};

export const internalAnalyticsActionsCombine = {
  ...ABCAnalyzeActions,
  ...internalAnalyticsFiltersActions,
  ...marginAnalysisActions,
};

export const internalAnalyticsSelectors = {
  ...selectors,
};
