import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import {
  getABCAnalyze,
  getABCAnalyzeTotal,
} from '@/store/entities/tools/internalAnalytics/ABCAnalyze/actions';
import { ABCAnalyzeConst } from '@/store/entities/tools/internalAnalytics/ABCAnalyze/constants';
import {
  ABCAnalyzeStore,
  IABCAnalyzeResponse,
  ITotalABCResponse,
} from '@/store/entities/tools/internalAnalytics/ABCAnalyze/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: ABCAnalyzeStore = {
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  categories: {
    A: undefined,
    B: undefined,
    C: undefined,
  },
  loading: {
    abc: false,
    abcTotal: false,
  },
  productAnalyze: [],
  sorting: {
    sortBy: 'sales',
    sortOrder: 'desc',
  },
};
export const ABCAnalyzeSlice = createSlice({
  name: 'ABCAnalyze',
  initialState,
  reducers: {
    setPageABCAnalyzeTable: (state, action: PayloadAction<{ page: number }>) => {
      state.pagination.page = action.payload.page;
    },
    setSortingABCAnalyze: (state, action: PayloadAction<ISorting>) => {
      state.sorting = action.payload;
    },
  },
  extraReducers: {
    // get top sellers
    [getABCAnalyzeTotal.pending.type]: (state) => {
      state.loading.abcTotal = true;
    },
    [getABCAnalyzeTotal.fulfilled.type]: (state, action: PayloadAction<ITotalABCResponse>) => {
      state.categories.A = action.payload.A;
      state.categories.B = action.payload.B;
      state.categories.C = action.payload.C;
      state.loading.abcTotal = false;
    },
    [getABCAnalyzeTotal.rejected.type]: (state, action) => {
      state.loading.abcTotal = false;
      showNotification({ message: action.payload.message || SYSTEM_ERROR, color: 'red' });
    },

    ////////////////////////////
    [getABCAnalyze.pending.type]: (state) => {
      state.loading.abc = true;
    },
    [getABCAnalyze.fulfilled.type]: (state, action: PayloadAction<IABCAnalyzeResponse>) => {
      state.productAnalyze = action.payload.rows;
      state.loading.abc = false;
      state.pagination = {
        total: action.payload.pagination.total_items,
        pages: action.payload.pagination.total_pages,
        page: action.payload.pagination.page,
        size: action.payload.pagination.items_per_page,
      };
    },
    [getABCAnalyze.rejected.type]: (state, action) => {
      state.loading.abc = false;
      showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
    },
  },
});
