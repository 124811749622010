import axios from 'axios';

const TOKEN = '6845253841:AAEYC1Gcc2xgXDR-fP7GjZdPZiedmcjXSrY';
const BOT_API = 'https://api.telegram.org/bot';
const CHAT_ID = '-1002076423399';

const THREAD_ID_DEV = '483';
const THREAD_ID_PROD = '482';

export default class Telegram {
  static postSendMessageBot = async (textMessage: string) => {
    const url = BOT_API + TOKEN + '/sendMessage';
    const threadId = (process.env.REACT_APP_HOST as string).includes('dev')
      ? THREAD_ID_DEV
      : THREAD_ID_PROD;

    const formData = new FormData();
    formData.append('chat_id', CHAT_ID);
    formData.append('message_thread_id', threadId);
    formData.append('text', textMessage);
    formData.append('disable_notification', 'true');
    formData.append('parse_mode', 'HTML');

    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // return axios.post(
    //   BOT_API + TOKEN + `/sendMessage`,
    //   {
    //     chat_id: CHAT_ID,
    //     message_thread_id: THREAD_ID,
    //     text: textMessage,
    //     disable_notification: true,
    //   },
    //   { headers: { 'Content-Type': 'application/json' } }
    // );
  };
}
