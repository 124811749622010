import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '@/fsd/app/App';
import { Providers } from '@/fsd/app/providers';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

// import '@/fsd/shared/api/_index';

root.render(
  <Providers>
    <App />
  </Providers>
);
