import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Center, Loader, Stack, Text } from '@mantine/core';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { CookieService } from '@/api/CookieService';
import { TokenService } from '@/api/TokenService';

import { profile } from '@/store/entities/auth/actions';
import { resetResult } from '@/store/entities/auth/authSlice';
import { AuthLoading } from '@/store/entities/auth/constants';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const OAuth = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();

  const { t } = useTranslation('APPCON');
  const { authSuccess, authError, authLoading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    let authToken = CookieService.getCookie('auth_token');
    let refreshToken = CookieService.getCookie('refresh_token');
    let authErrorGetToken = CookieService.getCookie('auth_error');
    if (authErrorGetToken) {
      setError(authErrorGetToken);
    } else if (authToken && refreshToken) {
      TokenService.setToken(authToken);
      TokenService.setRefreshedToken(refreshToken);
      dispatch(profile());
    } else {
      setError(t('appcon002--16'));
    }
    CookieService.deleteCookie('refresh_token');
    CookieService.deleteCookie('auth_token');
    CookieService.deleteCookie('auth_error');
    // console.log(getCookie('refresh_token'));
  }, []);

  useEffect(() => {
    if (authSuccess === AuthLoading.GET_PROFILE) {
      dispatch(resetResult());
      navigate(AppPaths.HOME);
    } else if (authError === AuthLoading.GET_PROFILE) {
      dispatch(resetResult());
      setError(t('appcon002--17'));
      TokenService.removeToken();
      TokenService.removeRefreshedToken();
    }
    // console.log(getCookie('refresh_token'));
  }, [authSuccess]);

  return (
    <Center style={{ height: '100vh' }}>
      <Stack align={'center'}>
        {authLoading === AuthLoading.GET_PROFILE ? <Loader variant={'dots'} /> : <></>}
        {error && (
          <>
            <Text>{error}</Text>
            <Button component={Link} to={AppPaths.LOGIN}>
              Login
            </Button>
          </>
        )}
      </Stack>
    </Center>
  );
};

export default OAuth;
