import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { AppPaths } from 'src/fsd/shared/lib/router';

import styles from './LayoutNavbarLogo.module.scss';

interface ILayoutNavbarLogoProps {
  clickable?: boolean;
}

const LayoutNavbarLogo: FC<ILayoutNavbarLogoProps> = ({ clickable = true }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname !== '/') {
      navigate(AppPaths.HOME);
    }
  };

  return (
    <div className={styles.root}>
      {clickable ? (
        <Box className={styles.logo} onClick={handleClick}>
          sellmatica
        </Box>
      ) : (
        <Box className={styles.logo} sx={{ cursor: 'auto!important' }}>
          sellmatica
        </Box>
      )}
    </div>
  );
};

export default LayoutNavbarLogo;
