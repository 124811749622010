import { tariffActions, tariffReducer } from './tariff';
import { tariffFiltersActions, tariffFiltersReducer } from './tariffFilters';

export const tariffCombineReducer = {
  tariff: tariffReducer,
  tariffFilters: tariffFiltersReducer,
};

export const tariffActionsCombine = {
  ...tariffActions,
  ...tariffFiltersActions,
};
