import React, { FC, ReactNode } from 'react';
import { Box, clsx, Container, createStyles, Overlay } from '@mantine/core';

import PurchaseTariffPlanOverlay from '@/ui/PurchaseTariffPlanOverlay/PurchaseTariffPlanOverlay';

interface ContentContainerProps {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  overlay?: boolean;
}

const useStyles = createStyles((theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    overflow: 'hidden',
    // position: 'relative',
    // zIndex: 0,
  },
}));

const ContentContainer: FC<ContentContainerProps> = ({
  children,
  style,
  className,
  overlay = false,
}) => {
  const { classes } = useStyles();

  return (
    <Container
      pos={overlay ? 'relative' : undefined}
      className={clsx(classes.root, className)}
      fluid={true}
      p={20}
      style={style}
    >
      {/*{overlay && <PurchaseTariffPlanOverlay />}*/}
      {children}
      {overlay && (
        <Box top={0} left={0} w={'100%'} h={'100%'} pos={'absolute'} sx={{ zIndex: 1 }}>
          <Box top={0} left={0} w={'100%'} h={'100%'} pos={'absolute'} sx={{ zIndex: 0 }}>
            <Overlay blur={5} radius={12} />
          </Box>

          <PurchaseTariffPlanOverlay />
        </Box>
      )}
    </Container>
  );
};

export default ContentContainer;
