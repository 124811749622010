import * as selectors from './geoAnalysis/selectors';

import {
  geoAnalysisActions,
  geoAnalysisReducer,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis';

export const geoAnalysisCombineReducer = {
  geoAnalysis: geoAnalysisReducer,
};

export const geoAnalysisActionsCombine = {
  ...geoAnalysisActions,
};

export const geoAnalysisSelectors = {
  ...selectors,
};
