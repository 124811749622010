import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cleanNotifications } from '@mantine/notifications';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { ReactComponent as ExitIcon } from '@/fsd/shared/assets/icons/exit.svg';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import { Button, ThemeButton } from '@/fsd/shared/ui/Button';

import cls from './LogoutButton.module.scss';

import { logoutUserAction } from '@/store/entities/auth/actions';
import { logoutUser } from '@/store/entities/auth/authSlice';
import { useAppDispatch } from '@/store/hooks';

interface LogoutButtonProps {
  className?: string;
}

export const LogoutButton = ({ className }: LogoutButtonProps) => {
  const { t } = useTranslation('APPCON');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await dispatch(logoutUserAction());
    dispatch(logoutUser());
    cleanNotifications();
    navigate(AppPaths.LOGIN);
  };

  return (
    <div className={classNames(cls.LogoutButton, {}, [className])}>
      <Button onClick={handleLogout} theme={ThemeButton.ICON_CLEAR} icon={<ExitIcon />}>
        {t('appcon040-0A-34')}
      </Button>
    </div>
  );
};
