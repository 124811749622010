import { useLocation } from 'react-router-dom';

export const useIsLocation = (href?: string) => {
  const location = useLocation();

  const isActiveLocationHandle = (hrefLink?: string) => {
    return location.pathname === hrefLink;
  };

  return { isActiveLocation: location.pathname === href, isActiveLocationHandle };
};
