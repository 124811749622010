import { apiParser } from '@/fsd/shared/api';

import { Categories } from '@/store/entities/tools/categories/types';

export const categoriesApi = {
  getCategories(data: Categories) {
    return apiParser.service.request({
      method: 'get',
      url: '/api/categories/',
      params: {
        marketplace: data.marketplace,
        search_for: data.search_for,
      },
    });
  },

  getChildren(data: Categories) {
    return apiParser.service.request({
      method: 'get',
      url: '/api/categories/tree/',
      params: {
        marketplace: data.marketplace,
        parent_id: data.parent_id,
        search_for: data.search_for,
      },
    });
  },

  getCategoriesSearch(data: Categories) {
    return apiParser.service.request({
      method: 'get',
      url: '/api/categories/',
      params: {
        marketplace: data.marketplace,
        parent_id: data.parent_id,
        search_for: data.search_for,
      },
    });
  },
};
