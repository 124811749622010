import { internalAnalyticsActionsCombine } from 'src/store/entities/tools/internalAnalytics';

import { noticesActions } from './entities/notices';
import { notificationsActions } from './entities/notifications';

import { connectionsActions } from '@/store/entities/connections';
import { locationsActions } from '@/store/entities/locations';
import { tariffActionsCombine } from '@/store/entities/tariff';
import { treeCategoriesActions } from '@/store/entities/tools/categories/tree-categories';
import { categoryAnalysisActions } from '@/store/entities/tools/categoryAnalysis';
import { geoAnalysisActionsCombine } from '@/store/entities/tools/geoAnalysis';
import { newProductsReportActionsCombine } from '@/store/entities/tools/newProductsReport';
import { sellerAuditActions } from '@/store/entities/tools/sellerAudit';
import { trendAnalysisActions } from '@/store/entities/tools/trendAnalysis';
import { usersActionsCombine } from '@/store/entities/users';

export default {
  ...noticesActions,
  ...notificationsActions,
  ...categoryAnalysisActions,
  ...treeCategoriesActions,
  ...trendAnalysisActions,
  ...newProductsReportActionsCombine,
  ...tariffActionsCombine,
  ...internalAnalyticsActionsCombine,
  ...usersActionsCombine,
  ...sellerAuditActions,
  ...connectionsActions,
  ...geoAnalysisActionsCombine,
  ...locationsActions,
};
