import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, clsx, Group, Menu, Sx, Text } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { ChevronDown } from 'tabler-icons-react';

import { languages } from '@/fsd/shared/constants/languages';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';

import cls from './SelectLanguage.module.scss';

import { changeLanguage } from '@/store/entities/profile/actions';
import { useAppDispatch } from '@/store/hooks';

export type SelectLanguageProps = {
  className?: string;
  label?: string;
  autocomplete?: boolean;
  labelStyle?: Sx;
  size?: 'md' | 'sm' | 'lg';
  width?: number | null;
  mobile?: boolean;
};

export const SelectLanguage: FC<SelectLanguageProps> = ({
  autocomplete,
  labelStyle,
  className,
  size = 'md',
  width = 170,
  mobile,
}) => {
  const [language, setLanguage] = useState<{
    id: string;
    img?: string | undefined;
    label: string;
  }>();
  const [opened, setOpened] = useState(false);
  const { i18n } = useTranslation();

  const data = languages;

  const dispatch = useAppDispatch();

  const ref = useClickOutside(() => setOpened(false));

  useEffect(() => {
    if (autocomplete) {
      const getCurrentLanguage = data.find((item) => item.id === i18n.language);

      if (!!getCurrentLanguage) {
        setLanguage(getCurrentLanguage);
      }
    }
  }, [i18n.language]);

  const getFontSizeLabel = () => {
    switch (size) {
      case 'lg':
        return 18;
      case 'sm':
        return 14;
      default:
        return 16;
    }
  };

  const getFontSizeChevron = () => {
    switch (size) {
      case 'md':
        return 15;
      case 'sm':
        return 13;
      default:
        return 15;
    }
  };

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng.toLocaleLowerCase());
    dispatch(
      changeLanguage({
        locale: lng?.toLocaleLowerCase(),
      })
    );
  };

  const handleChangeLanguale = (lng: { id: string; img?: string | undefined; label: string }) => {
    handleChangeLanguage(lng.id);

    setLanguage(lng);
  };

  return (
    <div className={classNames(cls.SelectLanguage, {}, [className])} ref={ref}>
      <Group
        spacing={'xs'}
        align={'center'}
        sx={{ cursor: 'pointer' }}
        w={mobile ? 50 : width || undefined}
        position={'right'}
        onClick={() => setOpened((prev) => !prev)}
        noWrap={true}
      >
        <Text
          // ta={'right'}
          weight={700}
          color={'#1E1E1E'}
          sx={[labelStyle, { fontFamily: 'Mulish' }]}
          fz={getFontSizeLabel()}
        >
          {!mobile ? language?.label : language?.id.toLocaleUpperCase()}
        </Text>
        <ChevronDown
          size={getFontSizeChevron()}
          strokeWidth={2}
          color={'black'}
          className={clsx(cls.chevronIcon, opened ? cls.chevronIconActive : undefined)}
        />
      </Group>

      <div
        className={classNames(cls.menuDropdown, {
          [cls.active]: opened,
          [cls.notActive]: !opened,
        })}
      >
        <div
          className={classNames(cls.menuDropdownItem, {}, [cls.firstItemDropdown])}
          onClick={() => setOpened(false)}
        >
          {language?.label}
          <ChevronDown
            size={getFontSizeChevron()}
            strokeWidth={2}
            color={'black'}
            className={clsx(cls.chevronIcon, opened ? cls.chevronIconActive : undefined)}
          />
        </div>
        {data.map(
          (item) =>
            item.id !== language?.id && (
              <div
                className={cls.menuDropdownItem}
                key={item.id}
                onClick={() => handleChangeLanguale(item)}
              >
                {item.label}
              </div>
            )
        )}
      </div>
    </div>
  );
};
