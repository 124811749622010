import {
  ITree,
  SelectedElementItem,
  TreeCategoriesChildren,
  TreeCategoriesData,
  TreeMapItem,
  TreeNode,
  TreeNodeState,
  TreeNodeStateEditable,
} from '@/store/entities/tools/categories/tree-categories/types';

export class TreeCategoriesService {
  // Найти элемент в дереве отображения
  public static dataTreeToStateTreeType(
    data: TreeCategoriesData[] | TreeCategoriesChildren[]
  ): ITree {
    let resultTree: ITree = {};
    let res: ITree | null = null;
    for (let i = 0; i < data.length; ++i) {
      let item = data[i];

      if ('children' in item) {
        let v: TreeCategoriesData = item as TreeCategoriesData;
        if (v.children) res = this.dataTreeToStateTreeType(v.children);
      }

      resultTree[item.cat_id] = {
        descendant_match_substring: item.descendant_match_substring,
        match_substring: item.match_substring,
        name: item.name,
        cat_id: item.cat_id,
        id: item.id,
        has_children: item.has_children,
        level: item.level,
        nodes: res,
      };
    }

    return resultTree;
  }

  // Найти элемент в дереве отображения и добавить к нему детей
  public static updateElementInTree(data: ITree, parentId: number, newData: ITree): ITree | false {
    if (data[parentId]) {
      data[parentId].nodes = newData;
      return data;
    }

    let keys: number[] = Object.keys(data).map((item) => Number(item));
    let res: ITree | false = false;

    for (let i = 0; i < keys.length; ++i) {
      let key = keys[i];
      let item = data[key as keyof ITree];

      if (item.nodes) {
        res = this.updateElementInTree(item.nodes, parentId, newData);
        if (res) {
          data[key].nodes = res;
          return data;
        }
      }
    }

    return false;
  }

  public static updateCheckedElementsInTree(tree: TreeMapItem | null, numberLevel: number) {
    if (tree === null) return;

    tree.forEach((value) => {
      if (value.level === numberLevel && value.state === 'checked' && value.hasChildren) {
        for (let child of value.child) {
          let childElement = tree.get(child);
          if (childElement) tree.set(child, { ...childElement, state: 'checked' });
        }
        this.updateCheckedElementsInTree(tree, numberLevel + 1);
      }
    });
  }

  public static generateTree(
    data: ITree,
    level: number,
    currentTree: TreeMapItem | null,
    selectedElements: (number | null)[] = [],
    parent: number | null = null
  ): TreeMapItem {
    const tree = new Map<number, TreeNode>();
    let countChildWithSearch = 0;

    for (let key in data) {
      const dataItem = data[key];
      const id = Number(key);
      const currentItem = currentTree?.get(id);
      const child: number[] = [];

      if (dataItem.nodes) {
        const childTree = this.generateTree(
          dataItem.nodes,
          level + 1,
          currentTree,
          selectedElements,
          id
        );

        for (const [idChild, valueChild] of childTree) {
          tree.set(idChild, valueChild);
          if (valueChild.parent === id) {
            child.push(idChild);
            if (
              !(
                valueChild.descendant_match_substring === false &&
                valueChild.match_substring === false
              )
            ) {
              countChildWithSearch += 1;
            }
          }
        }
      }

      tree.set(id, {
        id: currentItem?.id || id,
        name: currentItem?.name || dataItem.name,
        level: currentItem?.level || level,
        expandable: currentItem?.expandable || child.length !== 0,
        expandableClosed:
          dataItem.descendant_match_substring !== undefined ||
          currentItem?.expandableClosed ||
          false,
        child: child,
        parent: currentItem?.parent || parent,
        // state: currentItem?.state || 'unchecked',
        state:
          selectedElements.includes(id) || selectedElements.includes(parent)
            ? 'checked'
            : 'unchecked',
        hasChildren: currentItem?.hasChildren || dataItem.has_children,
        categoryId: currentItem?.categoryId || dataItem.cat_id,
        isLoading: false, // так как дерево обновилось, убираем все Loading
        match_substring: currentItem?.match_substring || dataItem?.match_substring,
        descendant_match_substring:
          currentItem?.descendant_match_substring || dataItem?.descendant_match_substring,
        countChildWithSearch: countChildWithSearch,
      });
    }

    return tree;
  }

  public static getNodeById(idNode: number, tree: TreeMapItem | null): TreeNode | null {
    if (tree === null) return null;

    const node = tree.get(idNode);
    if (node === undefined) return null;

    return node;
  }

  public static setStateOne(
    idNode: number | null,
    state: TreeNodeStateEditable,
    tree: TreeMapItem | null
  ): TreeMapItem | undefined {
    if (tree === null) return;
    if (idNode === null) return;
    const node = this.getNodeById(idNode, tree);
    if (node === null) return;

    node.state = state;
    tree.set(node.id, node);

    return tree;
    // setStateChildNodes(node, state);
    // setStateParentNodes(node);
  }

  public static setState(
    idNode: number | null,
    state: TreeNodeStateEditable,
    tree: TreeMapItem | null,
    selectedElements: SelectedElementItem
  ): void {
    if (tree === null) return;
    if (idNode === null) return;
    const node = this.getNodeById(idNode, tree);
    if (node === null) return;

    node.state = state;
    tree.set(node.id, node);

    this.setStateChildNodes(node, state, tree, selectedElements);
    this.setStateParentNodes(node, tree, selectedElements);
  }

  public static setExpandableClosed(
    idNode: number,
    expandableClosed: boolean,
    tree: TreeMapItem | null
  ): TreeMapItem | undefined {
    if (tree === null) return;
    const node = this.getNodeById(idNode, tree);
    if (node === null) return;

    node.expandableClosed = expandableClosed;
    tree.set(node.id, node);

    return tree;
  }

  public static setLoading(
    idNode: number,
    loading: boolean,
    tree: TreeMapItem | null
  ): TreeMapItem | undefined {
    if (tree === null) return;
    const node = this.getNodeById(idNode, tree);
    if (node === null) return;

    node.isLoading = loading;
    tree.set(node.id, node);

    return tree;
  }

  // получить дочерние узлы из узла
  public static getChildNodesFromNode(idNode: number, tree: TreeMapItem | null): TreeNode[] {
    if (tree === null) return [];

    const node = this.getNodeById(idNode, tree);
    if (node === null || node.child.length === 0) return [];

    return node.child
      .map((idChild) => this.getNodeById(idChild, tree))
      .filter((item) => item !== null) as TreeNode[];
  }

  public static getParentNode(idNode: number, tree: TreeMapItem | null) {
    if (tree === null) return null;

    const node = this.getNodeById(idNode, tree);
    if (node === null || node.parent === null) return null;

    const parentNode = this.getNodeById(node.parent, tree);
    if (parentNode === null) return null;

    return parentNode;
  }

  private static setStateChildNodes(
    node: TreeNode,
    state: TreeNodeStateEditable,
    tree: TreeMapItem | null,
    selectedElements: SelectedElementItem
  ): void {
    if (tree === null) return;
    if (node.child.length === 0) return;

    const childNodes = this.getChildNodesFromNode(node.id, tree);

    for (const childNode of childNodes) {
      selectedElements.delete(childNode.id);
      if (!(childNode.match_substring === false && childNode.descendant_match_substring === false))
        childNode.state = state;
      tree?.set(childNode.id, childNode);
      this.setStateChildNodes(childNode, state, tree, selectedElements);
    }
  }

  private static setStateParentNodes(
    node: TreeNode,
    tree: TreeMapItem | null,
    selectedElements: SelectedElementItem
  ): void {
    if (tree === null) return;
    const parentNode = this.getParentNode(node.id, tree);

    if (parentNode === null) {
      if (node.state === 'checked') {
        selectedElements.set(node.id, node.name);
      } else {
        selectedElements.delete(node.id);
      }
      return;
    }

    const childNodes = this.getChildNodesFromNode(parentNode.id, tree);
    let parentNodeState: TreeNodeState | null = null;
    const checkedChildNodesCount = childNodes.reduce(
      (acc, curr) => (curr.state === 'checked' ? acc + 1 : acc),
      0
    );
    const uncheckedChildNodesCount = childNodes.reduce(
      (acc, curr) => (curr.state === 'unchecked' ? acc + 1 : acc),
      0
    );
    const indeterminateChildNodesCount = childNodes.reduce(
      (acc, curr) => (curr.state === 'indeterminate' ? acc + 1 : acc),
      0
    );

    if (indeterminateChildNodesCount > 0) {
      parentNodeState = 'unchecked';
    } else if (checkedChildNodesCount > 0 && uncheckedChildNodesCount > 0) {
      parentNodeState = 'unchecked';
    } else if (checkedChildNodesCount === childNodes.length) {
      parentNodeState = 'checked';
    } else if (uncheckedChildNodesCount === childNodes.length) {
      parentNodeState = 'unchecked';
    }

    if (parentNodeState === null) return;

    if (parentNodeState === 'checked') {
      for (let childNode of childNodes) {
        selectedElements.delete(childNode.id);
      }

      selectedElements.set(parentNode.id, parentNode.name);
    } else {
      for (let childNode of childNodes) {
        if (childNode.state === 'checked') {
          selectedElements.set(childNode.id, childNode.name);
        } else {
          selectedElements.delete(childNode.id);
        }
      }
    }

    parentNode.state = parentNodeState;
    tree.set(parentNode.id, parentNode);
    this.setStateParentNodes(parentNode, tree, selectedElements);
  }
}
