import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { categoriesApi } from '@/api/parser/categoriesApi';

import { MarketplaceType } from '@/fsd/shared/constants/marketplaces';

import { Categories } from '@/store/entities/tools/categories/types';

export const getCategories = createAsyncThunk<any, Categories, { rejectValue: string }>(
  'treeCategories/getCategories',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await categoriesApi.getChildren(options);
      return { parentId: options.cat_id, results: data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

interface IGetCategoriesSearchParams {
  marketplace: MarketplaceType;
  search_for: string;
}

export const getCategoriesSearch = createAsyncThunk<
  any,
  IGetCategoriesSearchParams,
  {
    rejectValue: string;
  }
>('treeCategories/getCategories', async (options, { rejectWithValue }) => {
  try {
    const { data } = await categoriesApi.getCategoriesSearch(options);
    return { results: data };
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
