import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { sha256 } from 'js-sha256';

import { UserInfoService } from '@/api/UserInfoService';

import GoogleTagManager from '@/utils/googleUtils/googleTagManager';

import { MarketplaceType } from '@/fsd/shared/constants/marketplaces';

const useAnalyticEventsHook = (flag?: boolean) => {
  const user = UserInfoService.getUserInfo();
  const { i18n } = useTranslation();

  let hashEmail = user?.email ? sha256(user?.email) : undefined;
  let hashPhone = user?.phone_number ? sha256(user?.phone_number) : undefined;

  const userAuthData = {
    user_id: user?.id || null,
    login: !!user?.id,
    language: i18n.language,
    'e-mail': hashEmail,
    phone: hashPhone,
  };

  const pageViewGA = () => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,
      event: 'ga4event',
      event_name: 'page_view',
    });
  };

  useEffect(() => {
    if (flag) {
      pageViewGA();
    }
  }, []);

  const beganTypingFormRegisterGA = (label: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'form',
      scope: 'landing',
      priority: '1',
      event_type: 'marketing',
      category: 'sign_up',
      action: 'input',
      label: label,
      form_id: 'form-register',
    });
  };

  const login = (urlReferrer: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'login',
      scope: 'landing',
      priority: '1',
      event_type: 'product',
      category: urlReferrer,
      form_id: 'form-login',
    });
  };

  const register = (urlReferrer: string, email: string, phoneNumber: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,
      'e-mail': sha256(email),
      phone: sha256(phoneNumber),
      event: 'ga4event',
      event_name: 'sign_up',
      scope: 'landing',
      priority: '1',
      event_type: 'marketing',
      category: urlReferrer,
      form_id: 'form-register',
    });
  };

  const clickCategoryInsights = (marketplace: MarketplaceType, categories: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'click',
      scope: 'category_analysis',
      priority: '1',
      event_type: 'product',
      category: 'category_insights',
      click_text: 'Category Insights',
      marketplace: marketplace,
      product_category: categories,
    });
  };

  const clickTopSellers = (marketplace: MarketplaceType, categories: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'click',
      scope: 'category_analysis',
      priority: '1',
      event_type: 'product',
      category: 'top_sellers',
      click_text: 'Top Sellers',
      marketplace: marketplace,
      product_category: categories,
    });
  };

  const resultSuccessNewProducts = (
    marketplace: MarketplaceType,
    categories: string,
    product_date: string
  ) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'report',
      scope: 'new_product',
      priority: '1',
      event_type: 'product',
      category: 'report',
      marketplace: marketplace,
      product_category: categories,
      product_date: product_date,
    });
  };

  const resultSuccessCategoryAnalysis = (marketplace: MarketplaceType, categories: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'report',
      scope: 'category_analysis',
      priority: '1',
      event_type: 'product',
      category: 'report',
      marketplace: marketplace,
      product_category: categories,
    });
  };

  const sendRequestProfitAnalysis = () => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'click',
      scope: 'profit_analysis',
      priority: '1',
      event_type: 'product',
      category: 'send_request',
      click_text: 'SEND A REQUEST',
    });
  };

  const sendAddingConnections = (step: string, country: string, marketplace: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'form',
      scope: 'connections',
      priority: '1',
      event_type: 'product',
      category: 'add_connection',
      label: step,
      country: country,
      marketplace: marketplace,
    });
  };

  const resultSuccessFavoriteProducts = () => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'report',
      scope: 'favorite_products',
      priority: '1',
      event_type: 'product',
      category: 'report',
    });
  };

  const deleteSuccessFavoriteProducts = () => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'remove',
      scope: 'favorite_products',
      priority: '1',
      event_type: 'product',
      category: 'report',
    });
  };

  const resultProductAnalysis = (marketplace: MarketplaceType, categories: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'report',
      scope: 'product_analysis',
      priority: '1',
      event_type: 'product',
      category: 'report',
      marketplace: marketplace,
      product_category: categories,
    });
  };

  const addingInFavoriteProductsAnalysis = (
    marketplace: MarketplaceType,
    categories: string,
    product: number
  ) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'favorite',
      scope: 'product_analysis',
      priority: '1',
      event_type: 'product',
      category: 'product',
      label: String(product),
      marketplace: marketplace,
      product_category: categories,
    });
  };

  const resultSeller = (marketplace: MarketplaceType, seller: number) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'view',
      scope: 'seller',
      priority: '1',
      event_type: 'product',
      category: 'seller',
      action: String(seller),
      marketplace: marketplace,
    });
  };

  const resultProductCard = (marketplace: string, seller: number, product: number) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'view',
      scope: 'product_card',
      priority: '1',
      event_type: 'product',
      category: 'product_card',
      action: String(seller),
      label: String(product),
      marketplace: marketplace,
    });
  };

  const addFavoriteProductCard = (marketplace: string, seller: number, product: number) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'favorite',
      scope: 'product_card',
      priority: '1',
      event_type: 'product',
      category: 'pr',
      action: String(seller),
      label: String(product),
      marketplace: marketplace,
    });
  };

  const selectTariffProfile = (sum: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'pick',
      scope: 'profile',
      priority: '1',
      event_type: 'monetization',
      category: 'plan',
      action: sum,
    });
  };

  const paymentTariffProfile = (sum: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'checkout',
      scope: 'profile',
      priority: '1',
      event_type: 'monetization',
      category: 'begin_checkout',
      action: sum,
    });
  };

  const successPaymentTariffGA = (sum: string) => {
    GoogleTagManager.dataLayer(null);
    GoogleTagManager.dataLayer({
      ...userAuthData,

      event: 'ga4event',
      event_name: 'order',
      scope: 'profile',
      priority: '1',
      event_type: 'monetization',
      category: 'purchase',
      action: sum,
    });
  };

  return {
    beganTypingFormRegisterGA,
    pageViewGA,
    login,
    register,
    sendAddingConnections,
    clickCategoryInsights,
    clickTopSellers,
    resultSuccessNewProducts,
    resultSuccessCategoryAnalysis,
    sendRequestProfitAnalysis,
    resultSuccessFavoriteProducts,
    deleteSuccessFavoriteProducts,
    resultProductAnalysis,
    addingInFavoriteProductsAnalysis,
    resultSeller,
    resultProductCard,
    addFavoriteProductCard,
    selectTariffProfile,
    paymentTariffProfile,
    successPaymentTariffGA,
  };
};

export { useAnalyticEventsHook };
