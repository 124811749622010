import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SUPPORTED_LANGUAGE } from '@/fsd/shared/constants/languages';

import { updateProfileLanguage } from '@/store/entities/profile/profileSlice';
import { useAppDispatch } from '@/store/hooks';

const useHandleLanguageReferrer = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);

  const lng = searchParams.get('l');

  useEffect(() => {
    if (lng && SUPPORTED_LANGUAGE.includes(lng)) {
      i18n.changeLanguage(lng);
      dispatch(updateProfileLanguage(lng));
    }
  }, []);

  useEffect(() => {
    if (!isLanguageChanged && (lng === i18n.language || !SUPPORTED_LANGUAGE.includes(lng || ''))) {
      setIsLanguageChanged(true);
    }
  }, [i18n.language]);

  return isLanguageChanged;
};

export default useHandleLanguageReferrer;
