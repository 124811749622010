import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';
import { MakePurchaseRequest } from '@/api/swaggerApi';

// Отображение списка тарифных планов
export const getTariffPlans = createAsyncThunk('tariff/getTariffPlans', async (arg, thunkAPI) => {
  try {
    const response = await Api.Billing.apiBillingTariffPlansGet('asc', 'period');
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response?.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

// Покупка подписки
export const postBuyTariff = createAsyncThunk(
  'tariff/postBuyTariff',
  async (arg: MakePurchaseRequest, thunkAPI) => {
    try {
      const response = await Api.Billing.apiBillingSubscriptionsBuyPost(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Просмотр текущей подписки
export const getSubscriptionMe = createAsyncThunk(
  'tariff/getSubscriptionMe',
  async (arg, thunkAPI) => {
    try {
      const response = await Api.Billing.apiBillingSubscriptionsMeGet();
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

// Currently available payment methods
export const getPaymentMethods = createAsyncThunk(
  'tariff/getPaymentMethods',
  async (arg, thunkAPI) => {
    try {
      const response = await Api.Billing.apiBillingSubscriptionsPaymentMethodsGet();
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
