import { FC, FormEvent, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Group, PasswordInput, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { AppPaths } from 'src/fsd/shared/lib/router';

import OAuthButtons from '@/components/OAuthButtons';
import { useAnalyticEventsHook } from '@/hooks/analyticEventsHook/useAnalyticEventsHook';

import { login, profile } from '@/store/entities/auth/actions';
import { resetResult, setUser } from '@/store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

export interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const SignInForm: FC = () => {
  const { t } = useTranslation('APPCON');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isLoading, isSuccess, isError, message, token, loginError, refreshToken } =
    useAppSelector((store) => store.auth);
  const { login: loginData } = useAnalyticEventsHook();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser({ token: token, refreshToken: refreshToken, userInfo: user }));
      dispatch(profile());
      loginData(document.referrer);
      navigate(AppPaths.HOME);
    } else if (isError) {
      if (loginError) {
        Object.values(loginError).forEach((value) => {
          value?.forEach((error) => {
            showNotification({
              // title: t(error),
              message: t(error),
              color: 'red',
              styles: () => ({
                description: { color: 'black' },
              }),
            });
          });
        });
      } else {
        showNotification({
          message: message,
          color: 'red',
        });
      }
    }
    return () => {
      dispatch(resetResult());
    };
  }, [isSuccess, isError]);

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },

    validate: {
      email: (value) => (value.length > 0 ? null : t('appcon002-1B-2')),
      password: (value) => (value.length > 0 ? null : t('appcon002-2B-4')),
    },
  });

  const handleLogin = async (values: FormValues, event: FormEvent) => {
    event.preventDefault();
    let email = values.email;
    let password = values.password;
    await dispatch(login({ email, password }));
  };

  return (
    <>
      <Title weight={700} align={'center'} fz={24} mb={'md'}>
        {t('appcon002-1-2')}
      </Title>
      <form
        id={'form-login'}
        onSubmit={form.onSubmit((values, event) => handleLogin(values, event))}
      >
        <TextInput
          type="email"
          label={t('appcon002-1-3')}
          {...form.getInputProps('email')}
          size="md"
          placeholder={t('appcon002-1-4')}
          mt={15}
          autoComplete="email"
        />

        <PasswordInput
          mt={'sm'}
          label={t('appcon002-1-5')}
          {...form.getInputProps('password')}
          size="md"
          placeholder={t('appcon002-1-6')}
          autoComplete="current-password"
        />

        <Group position={'right'} align={'center'} mt={'xs'}>
          <Text
            variant="link"
            component={Link}
            to={AppPaths.RESET}
            size="sm"
            weight={700}
            style={{ cursor: 'pointer' }}
          >
            {t('appcon002-1-7')}
          </Text>
        </Group>

        <Button type="submit" loading={isLoading} fullWidth mt={'lg'} size="md">
          {t('appcon002-1-8')}
        </Button>

        <Group position={'center'} mt={'lg'}>
          <Text size="sm">
            <Trans
              i18nKey={'appcon002-1-9'}
              t={t}
              components={{
                'style-link': (
                  <Text
                    variant="link"
                    size="sm"
                    weight={700}
                    style={{ cursor: 'pointer' }}
                    component={Link}
                    to={AppPaths.REGISTER}
                  />
                ),
              }}
            />
          </Text>
        </Group>
      </form>
      <OAuthButtons />
    </>
  );
};

export default SignInForm;
