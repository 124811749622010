import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { categoriesApi } from '@/api/parser/newProductsReport';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

import {
  IGetNewProductsReportParams,
  INewProductsReportResponse,
} from '@/store/entities/tools/newProductsReport/types';

export const getNewProductsReport = createAsyncThunk<
  INewProductsReportResponse,
  IGetNewProductsReportParams,
  {
    rejectValue: string;
  }
>('newProductsReport/getNewProductsReport', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await categoriesApi.getNewProductsReport(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
