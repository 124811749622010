import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Container } from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import { AlertCircle } from 'tabler-icons-react';

import { NoticesTypeId } from '@/fsd/shared/constants/notices';
import { PADDING_CONTAINER } from '@/fsd/shared/constants/styles';
import { Header } from '@/fsd/widgets/Header';
import { useAnalyticEventsHook } from '@/hooks/analyticEventsHook/useAnalyticEventsHook';

import { useActions, useAppSelector } from '@/store/hooks';

interface ILayout {
  children?: React.ReactNode;
  title?: string;
  back?: string;
}

const Layout: FC<ILayout> = (props) => {
  const location = useLocation();

  const { getNoticesByUrl } = useActions();

  const [sendedAnalytic, setSendedAnanytics] = useState(false);

  const { user } = useAppSelector((state) => state.auth);
  const { noticesUrl, noticesByUrl } = useAppSelector((store) => store.notices);

  useEffect(() => {
    noticesByUrl?.forEach((item) => {
      hideNotification(`notice-${item.id}`);
    });
    getNoticesByUrl({ url_page: location.pathname });
  }, [location.pathname]);

  const { pageViewGA } = useAnalyticEventsHook();

  useEffect(() => {
    if (user && !sendedAnalytic) {
      pageViewGA();
      setSendedAnanytics(true);
    }
  }, [user]);

  useEffect(() => {
    if (noticesUrl === location.pathname) {
      noticesByUrl
        ?.filter((item) => !!item.active)
        ?.filter((item) => item.type_notice === NoticesTypeId.Notification)
        .forEach((item) => {
          showNotification({
            id: `notice-${item.id}`,
            autoClose: false,
            message: item.body,
          });
        });
    }
  }, [noticesUrl]);

  return (
    <>
      <Header />
      {noticesUrl === location.pathname &&
        noticesByUrl
          ?.filter((item) => !!item.active)
          ?.filter((item) => item.type_notice === NoticesTypeId.Alert)
          .map((item) => (
            <Alert
              key={item.id}
              mx={10}
              mt={10}
              variant="outline"
              icon={<AlertCircle size={16} />}
              children={item.body}
            />
          ))}
      <Container fluid={true} py={PADDING_CONTAINER}>
        {props.children}
      </Container>
    </>
  );
};

export default Layout;
