import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddConnectionResponse, Platform } from '@/api/swaggerApi';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import {
  addConnectionsPlatform,
  getConnectionsPlatforms,
} from '@/store/entities/connections/actions';
import { connectionsPlatformsAdapter } from '@/store/entities/connections/connectionsAdapter';
import { ConnectionStatus } from '@/store/entities/connections/const';
import { ConnectionsState } from '@/store/entities/connections/types';

const initialState: ConnectionsState = {
  isError: null,
  isLoading: null,
  isSuccess: null,
  selectedPlatform: null,
  selectedRegion: null,
  platforms: connectionsPlatformsAdapter.getInitialState(),
  addedConnection: null,
};
export const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setPlatformConnections(state, action: PayloadAction<string>) {
      state.selectedPlatform = action.payload;
    },
    setRegionConnections(state, action: PayloadAction<string>) {
      state.selectedRegion = action.payload;
    },
    resetConnectionsState: () => initialState,
  },
  extraReducers: {
    [getConnectionsPlatforms.pending.type]: (state) => {
      state.isError = null;
      state.isSuccess = null;
      state.isLoading = ConnectionStatus.GET_PLATFORM;
    },
    [getConnectionsPlatforms.fulfilled.type]: (state, action: PayloadAction<Platform[]>) => {
      state.isError = null;
      state.isSuccess = ConnectionStatus.GET_PLATFORM;
      state.isLoading = null;
      connectionsPlatformsAdapter.setAll(state.platforms, action.payload);
    },
    [getConnectionsPlatforms.rejected.type]: (state, action) => {
      state.isError = ConnectionStatus.GET_PLATFORM;
      state.isSuccess = null;
      state.isLoading = null;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },

    [addConnectionsPlatform.pending.type]: (state) => {
      state.isError = null;
      state.isSuccess = null;
      state.isLoading = ConnectionStatus.ADD_PLATFORM;
    },
    [addConnectionsPlatform.fulfilled.type]: (
      state,
      action: PayloadAction<AddConnectionResponse>
    ) => {
      state.isError = null;
      state.isSuccess = ConnectionStatus.ADD_PLATFORM;
      state.isLoading = null;
      state.addedConnection = action.payload;
    },
    [addConnectionsPlatform.rejected.type]: (state, action) => {
      state.isError = ConnectionStatus.ADD_PLATFORM;
      state.isSuccess = null;
      state.isLoading = null;
      showNotification({ color: 'red', message: action.payload || SYSTEM_ERROR });
    },
  },
});
