import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProfileState } from '../../types/IProfile';

import { changeLanguage, editProfile } from '@/store/entities/profile/actions';

const initialState: IProfileState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isChangeName: false,
  isErrorChangeLanguage: false,
  language: null,
  message: null,
  formError: {
    first_name: null,
    last_name: null,
    locale: null,
    _schema: null,
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.formError = initialState.formError;
    },
    resetLanguageChangeState: (state) => {
      state.isErrorChangeLanguage = false;
      state.message = null;
    },
    updateProfileLanguage: (state, action: PayloadAction<string | null>) => {
      state.language = action.payload;
    },
  },
  extraReducers: {
    // Загрузка аватарки
    // [uploadAvatar.pending.type]: (state) => {
    //   state.isLoading = 'UPLOAD_AVATAR';
    // },
    // [uploadAvatar.fulfilled.type]: (state, action) => {
    //   state.isLoading = null;
    //   state.isSuccess = 'UPLOAD_AVATAR';
    //   state.isError = null;
    //   state.message = action.payload?.message;
    // },
    // [uploadAvatar.rejected.type]: (state, action) => {
    //   state.isLoading = null;
    //   state.isSuccess = null;
    //   state.isError = 'UPLOAD_AVATAR';
    //   state.message = action.payload?.message;
    // },
    // Обновить данные профиля
    [editProfile.pending.type]: (state) => {
      state.isError = false;
    },
    [editProfile.fulfilled.type]: (state, action) => {
      state.isSuccess = true;
      state.message = action.payload.message;
      state.isChangeName = !state.isChangeName;
    },
    [editProfile.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message;
      state.formError = action.payload.form_error;
    },
    // Изменить язык интерфейса
    [changeLanguage.rejected.type]: (state, action) => {
      state.isErrorChangeLanguage = true;
      state.message = action.payload.message;
    },
  },
});

export const { resetProfileState, resetLanguageChangeState, updateProfileLanguage } =
  profileSlice.actions;
export default profileSlice.reducer;
