import qs from 'qs';

import { apiParser } from '@/fsd/shared/api';

import {
  GetGeoAnalysisReportForLocationParams,
  GetGeoAnalysisReportForLocationResponse,
  GetGeoAnalysisReportParams,
  GetGeoAnalysisReportResponse,
} from '@/store/entities/tools/geoAnalysis/geoAnalysis/types';

export const geoAnalysis = {
  getGeoAnalysisReport(options: GetGeoAnalysisReportParams) {
    return apiParser.service.request<GetGeoAnalysisReportResponse>({
      method: 'get',
      url: `/api/reports/geo-analysis/`,
      params: {
        ...options,
        location_include_keywords: options?.location_include_keywords,
        location_exclude_keywords: options?.location_exclude_keywords,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  getGeoAnalysisReportForLocation(options: GetGeoAnalysisReportForLocationParams) {
    return apiParser.service.request<GetGeoAnalysisReportForLocationResponse>({
      method: 'get',
      url: `/api/reports/geo-analysis/${options.marketplace}/${options.location}/`,
    });
  },
};
