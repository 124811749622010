import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';
import { AddConnectionRequest, AddConnectionResponse, Platform } from '@/api/swaggerApi';

export const getConnectionsPlatforms = createAsyncThunk<
  Platform[],
  never,
  {
    rejectValue: string;
  }
>('connections/getConnectionsPlatforms', async (_, { rejectWithValue }) => {
  try {
    const { data } = await Api.Connections.apiConnectionsPlatformsGet();
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const addConnectionsPlatform = createAsyncThunk<
  AddConnectionResponse,
  AddConnectionRequest,
  {
    rejectValue: string;
  }
>('connections/addConnectionsPlatform', async (options, { rejectWithValue }) => {
  try {
    const { data } = await Api.Connections.apiConnectionsAddConnectionPost(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
