import {
  ICategoryAnalysisCharts,
  ICategoryAnalysisChartsGetParams,
  ICategoryAnalysisGetParams,
  ICategoryAnalysisInfo,
  ITopSellersGetParams,
  ITopSellersResponse,
} from '@/types/ICategoryAnalysis';

import { apiParser } from '@/fsd/shared/api';

export const categoryAnalysis = {
  getTopSellers(data: ITopSellersGetParams) {
    return apiParser.service.request<ITopSellersResponse>({
      method: 'get',
      url: `/api/reports/categories/${data.marketplace}/${data.categoryId}/top_sellers/`,
      params: {
        page: data.page,
        items_per_page: data.itemsPerPage,
        sort_order: data.sortOrder,
        sort_by: data.sortBy,
      },
    });
  },
  getCategoryAnalysis(data: ICategoryAnalysisGetParams) {
    return apiParser.service.request<ICategoryAnalysisInfo>({
      method: 'get',
      url: `/api/reports/categories/${data.marketplace}/${data.categoryId}/`,
      params: {
        parents: data.parents,
      },
    });
  },
  getChartsCategoryAnalysis(data: ICategoryAnalysisChartsGetParams) {
    return apiParser.service.request<ICategoryAnalysisCharts>({
      method: 'get',
      url: `/api/reports/categories/${data.marketplace}/charts/`,
    });
  },
};
