import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

class GoogleTagManager {
  static gtmId = '';

  static checkIsProd = () => {
    GoogleTagManager.gtmId = process.env.REACT_APP_GTM_APP_KEY as string;
    return true;
  };

  static initializeGtm = () => {
    if (GoogleTagManager.checkIsProd()) {
      TagManager.initialize({ gtmId: GoogleTagManager.gtmId });
    }
  };

  static dataLayer = (event: any) => {
    if (GoogleTagManager.checkIsProd()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.dataLayer && window.dataLayer.push(event);
    }
  };
}

export default GoogleTagManager;
