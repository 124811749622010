import { GettingProductsAnalysis, IProductCard } from '@/types/IProduct';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { productsApi } from '@/api/parser/productsApi';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

import { NOT_SELECTED_MARKETPLACE } from '@/fsd/shared/constants/marketplaces';

import {
  ActionProduct,
  GetFavoriteProducts,
  GetProductCard,
  IFavorite,
  ProductsState,
  Sellers,
} from './types';

import { ITEMS_PER_PAGE } from '@/pages/Tools/ProductAnalysis/ProductAnalysis';
import { RootState } from '@/store';
import { AuthState } from '@/store/entities/auth/types';

export const getProducts = createAsyncThunk<
  GettingProductsAnalysis,
  void,
  {
    rejectValue: string;
    state: RootState;
  }
>('products/getProducts', async (_: void, { rejectWithValue, getState }) => {
  try {
    const stateProducts: ProductsState = getState().products;
    const userState: AuthState = getState().auth;
    const marketplace = stateProducts.marketplace;
    let pagination = stateProducts.pagination;
    let filterParameters = stateProducts.filterParameters;
    let sortBy = stateProducts.sortBy;
    let sortOrder = stateProducts.sortOrder;
    let userId = userState?.user?.id;

    if (marketplace !== NOT_SELECTED_MARKETPLACE) setCurrentCurrencyByMarketplace(marketplace);
    const options1 = {
      userId: userId,
      page: pagination.page,
      items_per_page: pagination.size,
      marketplace: marketplace,
      sort_order: sortOrder,
      sort_by: sortBy,
      requested: {
        category_ids: filterParameters.category_ids,
        store_rating_range: {
          min_val: filterParameters.store_rating_range?.min_val,
          max_val: filterParameters.store_rating_range?.max_val,
        },
        product_rating_range: {
          min_val: filterParameters.product_rating_range?.min_val,
          max_val: filterParameters.product_rating_range?.max_val,
        },
        keywords: {
          include_in_description: filterParameters.keywords?.include_in_description,
          exclude_from_description: filterParameters.keywords?.exclude_from_description,
          include_in_title: filterParameters.keywords?.include_in_title,
          exclude_from_title: filterParameters.keywords?.exclude_from_title,
        },
        description_quality: {
          min_val: filterParameters.description_quality?.min_val,
          max_val: filterParameters.description_quality?.max_val,
        },
        pictures_num_range: {
          min_val: filterParameters.pictures_num_range?.min_val,
          max_val: filterParameters.pictures_num_range?.max_val,
        },
        video_present: filterParameters.video_present,
        cash_on_delivery: filterParameters.cash_on_delivery,
        exclude_zero_stock: filterParameters.exclude_zero_stock,
        rewiew_count_range: {
          min_val: filterParameters.rewiew_count_range?.min_val,
          max_val: filterParameters.rewiew_count_range?.max_val,
        },
        product_views_range: {
          min_val: filterParameters.product_views_range?.min_val,
          max_val: filterParameters.product_views_range?.max_val,
        },
        product_questions_range: {
          min_val: filterParameters.product_questions_range?.min_val,
          max_val: filterParameters.product_questions_range?.max_val,
        },
        product_wishlisted_range: {
          min_val: filterParameters.product_wishlisted_range?.min_val,
          max_val: filterParameters.product_wishlisted_range?.max_val,
        },
        product_price_range: {
          min_val: filterParameters.product_price_range?.min_val,
          max_val: filterParameters.product_price_range?.max_val,
        },
        price_change_range: {
          min_val: filterParameters.price_change_range?.min_val,
          max_val: filterParameters.price_change_range?.max_val,
        },
        monthly_revenue_range: {
          min_val: filterParameters.monthly_revenue_range?.min_val,
          max_val: filterParameters.monthly_revenue_range?.max_val,
        },
        sales_change_range: {
          min_val: filterParameters.sales_change_range?.min_val,
          max_val: filterParameters.sales_change_range?.max_val,
        },
        exclude_sellers_ids: filterParameters.exclude_sellers_ids,
        include_sellers_ids: filterParameters.include_sellers_ids,
        views_to_sales: {
          min_val: filterParameters.views_to_sales?.min_val,
          max_val: filterParameters.views_to_sales?.max_val,
        },
        sales_to_review: {
          min_val: filterParameters.sales_to_review?.min_val,
          max_val: filterParameters.sales_to_review?.max_val,
        },
        monthly_sales_range: {
          min_val: filterParameters.monthly_sales_range?.min_val,
          max_val: filterParameters.monthly_sales_range?.max_val,
        },
        revenue_change_range: {
          min_val: filterParameters.revenue_change_range?.min_val,
          max_val: filterParameters.revenue_change_range?.max_val,
        },
        product_created_from: filterParameters.product_created_from,
        product_created_to: filterParameters.product_created_to,
        location: filterParameters.location,
      },
    };

    const { data } = await productsApi.getProducts(options1);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getExtermum = createAsyncThunk<any, string, { rejectValue: string }>(
  'products/getExtermum',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await productsApi.getExtremum(options);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getSellers = createAsyncThunk<any, Sellers, { rejectValue: string }>(
  'products/getSellers',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await productsApi.getSellers(options);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getSellerLocations = createAsyncThunk<any, Sellers, { rejectValue: string }>(
  'products/getSellerLocations',
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await productsApi.getSellerLocations(options);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getProductCard = createAsyncThunk<
  IProductCard,
  GetProductCard,
  {
    rejectValue: string;
  }
>('product/getProductCard', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(options.marketplace);
    const { data } = await productsApi.getProductCard(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

// favorite product

export const createFavoriteProduct = createAsyncThunk<
  IProductCard,
  ActionProduct,
  {
    rejectValue: string;
  }
>('product/createFavoriteProduct', async (options, { rejectWithValue }) => {
  try {
    const { data } = await productsApi.createFavoriteProduct(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const deleteFavoriteProduct = createAsyncThunk<
  IProductCard,
  ActionProduct,
  {
    rejectValue: string;
  }
>('product/deleteFavoriteProduct', async (options, { rejectWithValue }) => {
  try {
    const { data } = await productsApi.deleteFavoriteProduct(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

// product analyzer

export const addAnalyzerProduct = createAsyncThunk<
  IProductCard,
  ActionProduct,
  {
    rejectValue: string;
  }
>('product/addAnalyzerProduct', async (options, { rejectWithValue }) => {
  try {
    const { data } = await productsApi.addProductAnalyzer(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const deleteAnalyzerProduct = createAsyncThunk<
  IProductCard,
  ActionProduct,
  {
    rejectValue: string;
  }
>('product/deleteAnalyzerProduct', async (options, { rejectWithValue }) => {
  try {
    const { data } = await productsApi.deleteProductAnalyzer(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getFavoriteProducts = createAsyncThunk<
  IFavorite,
  GetFavoriteProducts,
  {
    rejectValue: string;
  }
>('product/getFavoriteProducts', async (options, { rejectWithValue }) => {
  try {
    const { data } = await productsApi.getFavoriteProducts(options);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
