import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tableWrapper: {
    overflowY: 'auto',
  },

  table: {
    '& thead': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },

    '& tbody': {
      '& tr': {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        },

        '&:nth-of-type(even)': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
      },
    },

    '& tr': {
      '& td, & th': {
        '&:first-of-type': {
          paddingLeft: 16 + 16,
        },

        '&:last-of-type': {
          paddingRight: 16 + 16,
        },
      },
    },

    '&[data-hover]': {
      '& tbody tr': {
        cursor: 'pointer',

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
        },
      },
    },
  },
}));
