import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserSchema } from '@/api/swaggerApi';
import { TokenService } from '@/api/TokenService';
import { UserInfoService } from '@/api/UserInfoService';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import {
  authUserWithOAuth,
  changeEmail,
  changePhoneNumber,
  ConfirmChangedEmailByToken,
  confirmEmailByToken,
  confirmTokenForChangePassword,
  deactivateAllSessionsExceptOne,
  getOAuthClient,
  getSessions,
  getUserUsageLimitTokens,
  login,
  logoutUserAction,
  profile,
  register,
  resetPassword,
  setNewPassword,
} from './actions';

import { AuthLoading } from '@/store/entities/auth/constants';
import {
  AuthState,
  IClientOAuth,
  IRegisterResponse,
  IUserUsageTokens,
} from '@/store/entities/auth/types';

const initialState: AuthState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  confirmTokenFromEmail: null,
  message: null,
  user: null,
  token: null,
  refreshToken: null,
  tokens: null,
  sessions: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: 0,
  isDeactivate: false,
  registrationError: undefined,
  loginError: {
    email: null,
    password: null,
    _schema: null,
  },
  changePhoneNumberError: {
    _schema: null,
  },
  changeEmailError: {
    new_email: null,
  },
  isChangePhoneNumber: false,
  isChangeEmail: false,
  authLoading: null,
  oAuthClients: [],
  authSuccess: null,
  authError: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        token: string | null;
        refreshToken: string | null;
        userInfo: UserSchema | null;
      }>
    ) => {
      if (action.payload.token) TokenService.setToken(action.payload.token);

      if (action.payload.refreshToken) TokenService.setRefreshedToken(action.payload.refreshToken);

      state.user = action.payload.userInfo;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    logoutUser: (state) => {
      UserInfoService.removeUserInfo();
      TokenService.removeToken();
      TokenService.removeRefreshedToken();
      state.token = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.confirmTokenFromEmail = null;
      state.message = null;
      state.user = null;
    },
    reset: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.user = null;

      state.registrationError = initialState.registrationError;
    },
    resetRegistrationError: (state) => {
      state.registrationError = initialState.registrationError;
    },
    resetResult: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.authSuccess = null;
      state.authError = null;
    },
    setTokenFromEmail: (state, action: PayloadAction<{ token: string | null }>) => {
      state.confirmTokenFromEmail = action.payload.token;
    },
    resetDefaultState(state) {
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.changePhoneNumberError = initialState.changePhoneNumberError;
      state.changeEmailError = initialState.changeEmailError;
    },
  },
  extraReducers: {
    // Получить данные о пользователе
    [profile.pending.type]: (state) => {
      // state.isLoading = true;
      state.authSuccess = null;
      state.authError = null;
      state.authLoading = AuthLoading.GET_PROFILE;
    },
    [profile.fulfilled.type]: (state, action: PayloadAction<UserSchema>) => {
      // state.isLoading = false;
      state.user = action.payload;
      UserInfoService.setUserInfo(action.payload);
      state.authSuccess = state.authSuccess = AuthLoading.GET_PROFILE;
      state.authError = null;
      state.authLoading = null;
    },
    [profile.rejected.type]: (state, action) => {
      TokenService.removeToken();
      TokenService.removeRefreshedToken();
      // state.isLoading = false;
      state.isError = true;
      state.authSuccess = state.authSuccess = null;
      state.message = action.payload.message || SYSTEM_ERROR;
      state.authError = AuthLoading.GET_PROFILE;
      state.authLoading = null;
    },

    // Регистрация
    [register.pending.type]: (state) => {
      state.isLoading = true;
    },
    [register.fulfilled.type]: (state, action: PayloadAction<IRegisterResponse>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      state.token = action.payload?.auth_token;
      state.refreshToken = action.payload?.refresh_token;
    },
    [register.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      state.registrationError = action.payload.form_error;
    },

    // Вход
    [login.pending.type]: (state) => {
      state.isLoading = true;
    },
    [login.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.token = action.payload?.auth_token;
      state.refreshToken = action.payload?.refresh_token;
    },
    [login.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload?.message || SYSTEM_ERROR;
      state.loginError = action.payload?.form_error || undefined;
    },

    // Запрос на изменение пароля
    [resetPassword.pending.type]: (state) => {
      state.authLoading = AuthLoading.RESET_PASSWORD_ERROR;
    },
    [resetPassword.fulfilled.type]: (state, action) => {
      // state.isLoading = false;
      // state.message = action.payload.message;
      state.authLoading = null;
      showNotification({ message: action.payload.message || SYSTEM_ERROR });
    },
    [resetPassword.rejected.type]: (state, action) => {
      // state.isLoading = false;
      state.authLoading = null;
      showNotification({ color: 'red', message: action.payload.message || SYSTEM_ERROR });
    },

    // Подтверждение электронной почты по токену
    [confirmEmailByToken.pending.type]: (state) => {
      state.isLoading = true;
    },
    [confirmEmailByToken.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.token = action.payload.auth_token;
      state.refreshToken = action.payload.refresh_token;
      state.message = action.payload.message;
    },
    [confirmEmailByToken.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },

    // Подтверждение токена для смены пароля
    [confirmTokenForChangePassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [confirmTokenForChangePassword.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message;
    },
    [confirmTokenForChangePassword.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },

    // Задать новый пароль
    [setNewPassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [setNewPassword.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message;
      state.confirmTokenFromEmail = null;
    },
    [setNewPassword.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },

    // Выход
    [logoutUserAction.pending.type]: (state) => {
      state.isLoading = true;
    },
    [logoutUserAction.fulfilled.type]: (state) => {
      TokenService.removeToken();
      TokenService.removeRefreshedToken();
      state.token = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.confirmTokenFromEmail = null;
      state.message = null;
      state.user = null;
    },
    [logoutUserAction.rejected.type]: (state) => {
      TokenService.removeToken();
      TokenService.removeRefreshedToken();
      state.token = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.confirmTokenFromEmail = null;
      state.message = null;
      state.user = null;
    },

    // Запрос на получение списка сессий
    [getSessions.pending.type]: (state) => {
      state.isError = false;
    },
    [getSessions.fulfilled.type]: (state, action) => {
      state.sessions = action.payload.results;
      state.page = action.payload.pagination.page;
      state.totalItems = action.payload.pagination.total_items;
      state.itemsPerPage = action.payload.pagination.items_per_page;
    },
    [getSessions.rejected.type]: (state, action) => {
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },

    // Деактивировать все сессии кроме текущей
    [deactivateAllSessionsExceptOne.pending.type]: (state) => {
      state.isError = false;
    },
    [deactivateAllSessionsExceptOne.fulfilled.type]: (state, action) => {
      state.isDeactivate = true;
      state.message = action.payload.message;
    },
    [deactivateAllSessionsExceptOne.rejected.type]: (state, action) => {
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },

    // Изменить номер телефона
    [changePhoneNumber.pending.type]: (state) => {
      state.isError = false;
    },
    [changePhoneNumber.fulfilled.type]: (state, action) => {
      state.isSuccess = true;
      state.message = action.payload.message;
      state.isChangePhoneNumber = !state.isChangePhoneNumber;
    },
    [changePhoneNumber.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      state.changePhoneNumberError = action.payload.form_error;
    },

    // Изменить электронную почту
    [changeEmail.pending.type]: (state) => {
      state.isError = false;
    },
    [changeEmail.fulfilled.type]: (state, action) => {
      state.isSuccess = true;
      state.message = action.payload.message;
      state.isChangeEmail = !state.isChangeEmail;
    },
    [changeEmail.rejected.type]: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      state.changeEmailError = action.payload.form_error;
    },

    // Подтверждение изменения адреса электронной почты по токену
    [ConfirmChangedEmailByToken.pending.type]: (state) => {
      state.isLoading = true;
    },
    [ConfirmChangedEmailByToken.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message;
    },
    [ConfirmChangedEmailByToken.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },

    [getOAuthClient.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getOAuthClient.fulfilled.type]: (state, action: PayloadAction<IClientOAuth[]>) => {
      state.isLoading = false;
      state.oAuthClients = action.payload;
    },
    [getOAuthClient.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    },

    [authUserWithOAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [authUserWithOAuth.fulfilled.type]: (
      state,
      action: PayloadAction<{
        redirect_url: string;
      }>
    ) => {
      state.isLoading = false;
      window.location.href = action.payload.redirect_url;
    },
    [authUserWithOAuth.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload || SYSTEM_ERROR;
    },

    //getUserUsageLimitTokens
    [getUserUsageLimitTokens.pending.type]: (state) => {},
    [getUserUsageLimitTokens.fulfilled.type]: (state, action: PayloadAction<IUserUsageTokens>) => {
      state.tokens = action.payload;
    },
    [getUserUsageLimitTokens.rejected.type]: (state, action) => {},
  },
});

export const {
  resetRegistrationError,
  setUser,
  logoutUser,
  reset,
  resetResult,
  setTokenFromEmail,
  resetDefaultState,
} = authSlice.actions;

export default authSlice.reducer;
