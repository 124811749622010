import { userActions, userReducer } from '@/store/entities/users/user';
import { usersActions, usersReducer } from '@/store/entities/users/users';
import { usersFiltersActions, usersFiltersReducer } from '@/store/entities/users/usersFilters';

export const usersCombineReducer = {
  user: userReducer,
  users: usersReducer,
  usersFilters: usersFiltersReducer,
};

export const usersActionsCombine = {
  ...usersActions,
  ...userActions,
  ...usersFiltersActions,
};
