import {
  ICategoryChildren,
  IRubricator,
  ISelectedRubricatorItem,
} from '@/store/entities/tools/categories/types';

export const recursionFunction = (
  categories: ICategoryChildren[],
  parentId: number,
  checked: boolean,
  selectedItems: ISelectedRubricatorItem[]
): IRubricator[] => {
  return categories.map((item) => {
    let children: IRubricator[] | null;

    let checked1 = checked;
    if (selectedItems.find((selectedItem) => selectedItem.id === item.id)) {
      checked1 = true;
    }

    if (item.has_children && item.children) {
      children = recursionFunction(item.children, item.id, checked1, selectedItems);
    } else {
      children = [];
    }

    return {
      id: item.id,
      name: item.name,
      checked: checked1,
      children: children,
      hasChildren: item.has_children,
      expanded: false,
      isLoading: false,
      parentId: parentId,
      catId: item.cat_id,
    };
  });
};

export const getCategoryById = (categories: IRubricator[], id: number): IRubricator | null => {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === id) {
      return categories[i];
    } else if (categories[i].hasChildren) {
      let result = getCategoryById(categories[i].children || [], id);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

// category - то на какой объект надо поменять в стейте
// constants - изначальный стейт
export const recursionFunctionEdit = (
  categories: IRubricator[],
  category: Partial<IRubricator>
): IRubricator[] => {
  return categories.map((item) => {
    let children: IRubricator[] | null;
    if (item.children) {
      children = recursionFunctionEdit(item.children, category);
    } else {
      children = [];
    }

    if (item.id === category.id) {
      return { ...item, ...category };
    } else {
      return {
        ...item,
        children: children,
      };
    }
  });
};

export const recHelp = (categories: IRubricator[], checked: boolean): IRubricator[] => {
  return categories.map((item) => {
    let children: IRubricator[] | null;
    if (item.hasChildren && item.children) {
      children = recHelp(item.children, checked);
    } else {
      children = [];
    }

    return {
      ...item,
      checked: checked,
      children: children,
    };
  });
};

export const recursionFunctionSetCheckbox = (
  categories: IRubricator[],
  id: number,
  checked: boolean
): IRubricator[] => {
  return categories.map((item) => {
    let children: IRubricator[] = [];

    if (item.id === id) {
      const ch = item.children && recHelp(item.children, checked);
      return {
        ...item,
        checked: checked,
        children: ch,
      };
    }

    if (item.children) {
      children = recursionFunctionSetCheckbox(item.children, id, checked);
    } else {
      children = [];
    }
    return {
      ...item,
      children: children,
    };
  });
};

export let parentIdValue: number | null = null;

// export const arrayName: string[] = [];
// export const arrayId: number[] = [];

export const manageParentCheckbox = (
  categories: IRubricator[],
  parentId: number | null
): IRubricator[] => {
  if (!parentId) {
    return categories;
  }

  return categories.map((item) => {
    let children: IRubricator[] | null = [];

    if (item.hasChildren && item.children) {
      children = manageParentCheckbox(item.children, parentId);
    }

    if (item.id === parentId && item.children) {
      const allChecked = children.every((value) => value.checked);
      parentIdValue = item.parentId;

      return {
        ...item,
        checked: allChecked,
        children: children,
      };
    }

    // if (item.checked) {
    //   arrayName.push(item.name);
    //   arrayId.push(item.id);
    // }

    return {
      ...item,
      children: children,
    };
  });
};

export const getArraysByCategories = (
  categories: IRubricator[],
  selectedCategories: ISelectedRubricatorItem[],
  deleteIds: number[]
): {
  categories: IRubricator[];
  selectedCategories: ISelectedRubricatorItem[];
  deleteIds: number[];
} => {
  const cat = categories.map((item) => {
    if (!item.checked) {
      selectedCategories = selectedCategories.filter((value) => value.id !== item.id);
    }
    if (item.children) {
      if (item.checked && item.children) {
        const allChecked = item.children.every((value) => value.checked);
        deleteIds.push(item.id);
        if (allChecked) {
          selectedCategories.push({
            id: item.id,
            parentId: item.parentId,
            name: item.name,
            catId: item.catId,
          });
          const { deleteIds: d } = getArraysByCategories(
            item.children,
            selectedCategories,
            deleteIds
          );
          deleteIds = d;
        }
      } else {
        const { selectedCategories: s } = getArraysByCategories(
          item.children,
          selectedCategories,
          deleteIds
        );
        selectedCategories = s;
      }
    }

    return item;
  });

  return { categories: cat, selectedCategories: selectedCategories, deleteIds: deleteIds };
};

// сбросить все категории в false
export const resetCategoryCheckboxInFalse = (categories: IRubricator[]): IRubricator[] => {
  return categories.map((item) => {
    let children: IRubricator[] | null;
    if (item.hasChildren && item.children) {
      children = resetCategoryCheckboxInFalse(item.children);
    } else {
      children = [];
    }

    return {
      ...item,
      checked: false,
      children: children,
    };
  });
};

// есть ли в стейте нужная категория
export const categoryExistInState = (categories: IRubricator[], id: number): boolean => {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === id) {
      return true;
    } else if (categories[i].hasChildren) {
      let result = categoryExistInState(categories[i].children || [], id);
      if (result) {
        return true;
      }
    }
  }

  return false;
};
