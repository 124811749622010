import { parserUrl, url } from '@/api/url';

export const baseConfig = {
  baseURL: url,
  withCredentials: true,
  httpsAgent: { rejectUnauthorized: false },
};

export const parserConfig = {
  baseURL: parserUrl,
  withCredentials: true,
};
