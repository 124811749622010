import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import { classNames } from '@/fsd/shared/lib/classNames/classNames';

import cls from './Button.module.scss';

export enum ThemeButton {
  CLEAR = 'clear',
  ICON_CLEAR = 'clearIcon',
}

export enum IconPositionButton {
  RIGHT = 'right',
  LEFT = 'left',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: ThemeButton;
  iconPosition?: IconPositionButton;
  icon?: ReactNode;
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    className,
    children,
    theme,
    iconPosition = IconPositionButton.RIGHT,
    icon,
    ...otherProps
  } = props;

  const handleIconButton = () => {
    return <div className={cls.iconButton}>{icon}</div>;
  };

  return (
    <button
      className={classNames(cls.Button, {}, [className, theme && cls[theme]])}
      {...otherProps}
    >
      {iconPosition === IconPositionButton.LEFT && handleIconButton()}
      {children}
      {iconPosition === IconPositionButton.RIGHT && handleIconButton()}
    </button>
  );
};
