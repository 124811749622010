export enum AppPaths {
  /** auth */
  LOGIN = '/login',
  REGISTER = '/signup',
  RESET = '/reset',
  OAUTH = '/oauth-social',
  CONFIRM = '/confirm',
  CONFIRM_CHANGE_EMAIL = '/confirm_change_email',
  NEW_PASSWORD = '/new_password',
  OFFER = 'https://sellmatica.com/terms/',
  PRIVACY_POLICY = 'https://sellmatica.com/policy/',
  NOT_FOUND = '*',
  HOME = '/',
  PROFILE = '/profile/:profileTabValue',
  PROFILE_TARIFF = '/profile/tariff',
  PROFILE_LANGUAGE = '/profile/language',
  PROFILE_CHANGE_PASSWORD = '/profile/change-password',
  PROFILE_INFO = '/profile/info',
  // TOOLS = '/tools',
  PRODUCT_ANALYSIS = '/product_analysis',
  // EXTERNAL_ANALYSIS = '/external_analysis',
  // INTERNAL_ANALYSIS = '/internal_analysis',
  EXTERNAL_ANALYSIS = '/market',
  INTERNAL_ANALYSIS = '/analytics',
  PRODUCT_CARD = '/product_card',
  CATEGORY = '/category_analysis',
  COMPETITOR_TRACKING = '/competitor_tracking',
  PRODUCTS_ANALYZER = '/products_analyzer',
  PROFIT_ANALYSIS = '/profit-analysis',
  MARGIN_ANALYSIS = '/margin-analysis',
  NEW_PRODUCTS_REPORT = '/new-products',
  SEARCH_RANK_TRACKING = '/search_rank_tracking',
  FAVORITE = '/favorite_products',
  TREND_ANALYSIS = '/market-analysis',
  SELLER = '/seller',
  CONNECTIONS = '/connections',
  TARIFF_JOIN = '/tariff_join',
  TELEGRAM = '/telegram',
  SUPPORT = '/support',
  HELP = '/help',
  CLIENTS = '/clients',
  PAYMENT = '/payment',
  ADMIN_NOTIFICATIONS = '/admin/notifications',
  ADMIN_TICKETS = '/admin/tickets',
  ADMIN_TICKET_DETAIL = '/admin/ticketdetail',
  ADMIN_ANALYTICS = '/admin/analytics',
  ADMIN_SETTINGS = '/admin/settings',
  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_ANALYTICS = '/admin/users/analytics',
  ADMIN_CONTENT = '/admin/content',
  SELLER_AUDIT = '/seller-audit',

  SUCCESS_PAYMENT = '/success-payment',
  CANCEL_PAYMENT = '/cancel-payment',
  FACEBOOK_DELETION = '/facebook-deletion',
  GEO_ANALYSIS = '/geo-analysis',
}

export enum ExternalLinks {
  MAIN_SELLMATICA = 'https://sellmatica.com/',
  EXTERNAL_ANALYTICS = '/tools/external-analytics/',
  INTERNAL_ANALYTICS = '/tools/internal-analytics/',
  BLOG = '/blog/',
  PRICE = '/price/',
  FREE_ASSESSMENT = '/offers/free-assessment/',
}
