import { apiParser } from '@/fsd/shared/api';

import { IGetReportsCategoriesRecommendation } from '@/store/entities/tools/trendAnalysis/types';

export const trendAnalysisApi = {
  getReportsCategoriesRecommendation(data: IGetReportsCategoriesRecommendation) {
    return apiParser.service.request({
      method: 'get',
      url: '/api/reports/categories/recommendation/',
      params: {
        marketplace: data.marketplace,
        parent_id: data.parent_id,
        page: data.page,
        items_per_page: data.items_per_page,
        sort_order: data.sort_order,
        sort_by: data.sort_by,
      },
    });
  },
};
