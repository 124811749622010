import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, List, Text, Title } from '@mantine/core';
import { useActions, useAppSelector } from 'src/store/hooks';
import { Check } from 'tabler-icons-react';

import { TokenService } from '@/api/TokenService';

import { TariffGroup } from '@/fsd/shared/constants/tariffGroup';
import { useAnalyticEventsHook } from '@/hooks/analyticEventsHook/useAnalyticEventsHook';

import ContentContainer from '@/ui/ContentContainer/ContentContainer';

import { useStyles } from './TariffStyles';

import TariffPaymentList from '@/pages/Profile/ProfileTariff/components/TariffPaymentList/TariffPaymentList';
import TariffPaymentSystems from '@/pages/Profile/ProfileTariff/components/TariffPaymentSystems/TariffPaymentSystems';
import { LOADING } from '@/store/entities/tariff/constants';

const TariffPlans: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('APPCON');
  const { getTariffPlans, getPaymentMethods, postBuyTariff, resetBuyTariffLink } = useActions();
  const { buyTariffLink, isLoading } = useAppSelector((state) => state.tariff);
  const { paymentSystem, selectedTariffValue, selectedCurrency } = useAppSelector(
    (state) => state.tariffFilters
  );

  const userTokenInfo = TokenService.getUserInfoByToken();
  const { paymentTariffProfile } = useAnalyticEventsHook();

  useEffect(() => {
    getTariffPlans();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (buyTariffLink) {
      paymentTariffProfile(selectedTariffValue);
      window.location.href = buyTariffLink;
      resetBuyTariffLink();
    }
  }, [buyTariffLink]);

  const handleClick = () => {
    postBuyTariff({
      slug: selectedTariffValue,
      payment_system: paymentSystem as any,
      currency: selectedCurrency,
    });
  };

  return (
    <>
      <ContentContainer className={classes.root}>
        <Title fz={22} order={3} pb={3}>
          {userTokenInfo?.tariff_group === TariffGroup.TRIAL
            ? t('appcon036-1B-21')
            : userTokenInfo?.tariff_group === TariffGroup.BASIC
            ? t('You are currently on the Basic plan')
            : t('You are currently on the Professional plan')}
        </Title>

        {(userTokenInfo?.tariff_group === TariffGroup.TRIAL ||
          userTokenInfo?.tariff_group === TariffGroup.BASIC) && (
          <Text fz={16}>{t('appcon036-1B-22')}</Text>
        )}
      </ContentContainer>
      <ContentContainer className={classes.root}>
        {/*<div className={classes.contentContainer}>*/}
        <Grid mt={'xl'}>
          <Grid.Col sm={6} md={4.5} lg={3.5}>
            <Box>
              <Title size={22} mb={28}>
                {t('appcon036-1B-23')}
              </Title>
              <List
                styles={{ itemIcon: { marginTop: 4 } }}
                spacing="xl"
                w={310}
                fz={16}
                icon={<Check size={20} color={'#7f56d9'} strokeWidth={2} />}
              >
                <List.Item>{t('appcon036-1B-24')}</List.Item>
                <List.Item>{t('appcon036-1B-25')}</List.Item>
                <List.Item>{t('appcon036-1B-26')}</List.Item>
                <List.Item>{t('appcon036-1B-27')}</List.Item>
                <List.Item>{t('appcon036-1B-28')}</List.Item>
              </List>
            </Box>
            <TariffPaymentSystems />
          </Grid.Col>
          <Grid.Col sm={6} md={7.5} lg={8.5}>
            <TariffPaymentList />
          </Grid.Col>
        </Grid>
        {/*</div>*/}
        <Box>
          <Button
            styles={{
              root: {
                minWidth: 300,
              },
              label: {
                fontSize: 14,
              },
            }}
            color="#3B82F6"
            radius={6}
            loading={isLoading === LOADING.BUY_PLAN}
            onClick={handleClick}
            mt={'md'}
            disabled={!selectedCurrency || !selectedTariffValue || !paymentSystem}
          >
            {t('appcon036-1B-29')}
          </Button>
        </Box>
      </ContentContainer>
    </>
  );
};
export default TariffPlans;
