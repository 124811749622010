import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '@/fsd/shared/api';

import { DeleteTelegramAccountData, NotificationStatusData } from '@/store/entities/telegram/types';

export const getUrlTelegram = createAsyncThunk('telegram/getUrlTelegram', async (arg, thunkAPI) => {
  try {
    const response = await api.service.get('/api/telegram/url/');
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const getQRTelegram = createAsyncThunk('telegram/getQRTelegram', async (arg, thunkAPI) => {
  try {
    const response = await api.service.get('/api/telegram/qr/');
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const setTelegramNotification = createAsyncThunk(
  'telegram/setTelegramNotification',
  async (arg: NotificationStatusData, thunkAPI) => {
    try {
      const response = await api.service.patch(
        '/api/telegram/notifications/?set_type=' + arg.status
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getTelegramAccounts = createAsyncThunk(
  'telegram/getTelegramAccounts',
  async (arg, thunkAPI) => {
    try {
      const response = await api.service.get('/api/telegram/account/');
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const deleteTelegramAccount = createAsyncThunk(
  'telegram/deleteTelegramAccount',
  async (arg: DeleteTelegramAccountData, thunkAPI) => {
    try {
      const response = await api.service.delete('/api/telegram/account/', {
        data: { nickname: arg.nickname },
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
