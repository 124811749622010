import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'src/fsd/shared/lib/router';

import LayoutAuth from '@/ui/Layouts/LayoutAuth/LayoutAuth';

import SignUpForm from './SignUpForm/SignUpForm';

import ImageAuth from '@/assets/images/auth/auth.png';
import { profile } from '@/store/entities/auth/actions';
import { resetResult, setUser } from '@/store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const SignUp: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    isSuccess,
    isError,
    token: authToken,
    refreshToken,
    user,
  } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser({ token: authToken, refreshToken: refreshToken, userInfo: user }));
      dispatch(profile());
      navigate(AppPaths.SELLER_AUDIT);
    }
    return () => {
      dispatch(resetResult());
    };
  }, [isSuccess, isError]);

  return (
    <LayoutAuth maw={450} image={ImageAuth}>
      <SignUpForm />
    </LayoutAuth>
  );
};

export default SignUp;
