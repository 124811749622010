import React, { useState } from 'react';
import { Burger } from '@mantine/core';

import { SelectLanguage } from '@/fsd/features/selectLanguage';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import { useGetScreenSize } from '@/fsd/shared/lib/hooks/useGetScreenSize/useGetScreenSize';
import { Bell } from '@/fsd/shared/ui/Bell';
import { Logo } from '@/fsd/shared/ui/Logo';

import NotificationsPopover from '@/ui/Layouts/Layout/components/LayoutHeader/components/NotificationsPopover/NotificationsPopover';

import { Navbar } from '../../LayoutNavbar';
import { NavbarDrawer } from '../../NavbarDrawer';

import cls from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

export const Header = ({ className }: HeaderProps) => {
  const [opened, setOpened] = useState(false);

  const { lgScreen, smScreen } = useGetScreenSize();

  return (
    <div className={classNames(cls.Header, {}, [className])}>
      <Logo />
      {!lgScreen ? <Navbar /> : <NavbarDrawer opened={opened} setOpened={setOpened} />}
      <div className={cls.headerRight}>
        <SelectLanguage autocomplete={true} labelStyle={{ fontWeight: 500 }} mobile={smScreen} />
        <NotificationsPopover bell={<Bell />} />
        {lgScreen && (
          <Burger color={'#787878'} opened={opened} onClick={() => setOpened((prev) => !prev)} />
        )}
      </div>
    </div>
  );
};
