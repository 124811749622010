import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MarketplaceSelectValue,
  NOT_SELECTED_MARKETPLACE,
} from '@/fsd/shared/constants/marketplaces';

import { NewProductsReportFiltersState } from '@/store/entities/tools/newProductsReport/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: NewProductsReportFiltersState = {
  marketplace: NOT_SELECTED_MARKETPLACE,
  productCreatedFrom: '7',
  sorting: {
    sortBy: 'revenue',
    sortOrder: 'desc',
  },
};

export const newProductsReportFiltersSlice = createSlice({
  name: 'newProductsReportFilters',
  initialState,
  reducers: {
    setMarketplaceNewProductsReport(state, action: PayloadAction<MarketplaceSelectValue>) {
      state.marketplace = action.payload;
    },

    setDateNewProductsReport(state, action: PayloadAction<string>) {
      state.productCreatedFrom = action.payload;
    },

    setSortNewProductsReport: (state, action: PayloadAction<ISorting>) => {
      state.sorting = action.payload;
    },
  },
  extraReducers: {
    // [getCategories.fulfilled.type]: (
    //   state,
    //   action: PayloadAction<{
    //     parentId: number | undefined;
    //     results: TreeCategoriesData[];
    //   }>
    // ) => {
    //   let parentId = action.payload.parentId;
    //   let categoriesData = action.payload.results;
    //   let tree = state.categoriesTree;
    //   let result: ITree | null;
    //
    //   // Создаем или дополняем отображаемое дерево
    //   if (!parentId) {
    //     result = TreeCategoriesService.dataTreeToStateTreeType(categoriesData);
    //   } else {
    //     let v = TreeCategoriesService.dataTreeToStateTreeType(categoriesData);
    //     result = v && (TreeCategoriesService.updateElementInTree(tree, parentId, v) || null);
    //   }
    //
    //   state.categoriesTree = result || {};
    //
    //   // Создаем или обновляем дерево состояния
    // },
  },
});
