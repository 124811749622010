import { Regions } from '@/fsd/shared/constants/regions';

import {
  lazada,
  lazadaIcon,
  shopee,
  shopeeIcon,
  tokopedia,
  tokopediaIcon,
} from '@/assets/images/marketplaces';

export const MARKETPLACE = Object.freeze({
  LAZADA: 'Lazada',
  SHOPEE_INDONESIA: 'shopee-indonesia',
  SHOPEE_MALAYSIA: 'shopee-malaysia',
  SHOPEE_PHILIPPINES: 'shopee-philippines',
  SHOPEE_SINGAPORE: 'shopee-singapore',
  SHOPEE_THAILAND: 'shopee-thailand',
  SHOPEE_VIETNAM: 'shopee-vietnam',
  TOKOPEDIA: 'Tokopedia',
});

// export const MARKETPLACE = Object.freeze({
//   LAZADA: 'Lazada',
//   SHOPEE_INDONESIA: 'ShopeeMarketplaces.INDONESIA',
//   SHOPEE_MALAYSIA: 'ShopeeMarketplaces.MALAYSIA',
//   SHOPEE_PHILIPPINES: 'ShopeeMarketplaces.PHILIPPINES',
//   SHOPEE_SINGAPORE: 'ShopeeMarketplaces.SINGAPORE',
//   SHOPEE_THAILAND: 'ShopeeMarketplaces.THAILAND',
//   SHOPEE_VIETNAM: 'ShopeeMarketplaces.VIETNAM',
//   TOKOPEDIA: 'Tokopedia',
// });

export const NOT_SELECTED_MARKETPLACE = 'not_selected';

export type MarketplaceType = (typeof MARKETPLACE)[keyof typeof MARKETPLACE];

export type MarketplaceSelectValue = typeof NOT_SELECTED_MARKETPLACE | MarketplaceType;

export const marketplaces = [
  {
    id: 'lz',
    name: 'Lazada',
    img: lazada,
    icon: lazadaIcon,
  },
  {
    id: 'sh',
    name: 'Shopee',
    img: shopee,
    icon: shopeeIcon,
  },
  {
    id: 'tk',
    name: 'Tokopedia',
    img: tokopedia,
    icon: tokopediaIcon,
  },
];

export const selectMarketplace = (
  country: string | undefined,
  nameNotSelected?: string
): {
  id: MarketplaceType | typeof NOT_SELECTED_MARKETPLACE;
  name: string;
  icon?: any;
}[] => {
  let marketp = [];

  marketp.push({
    id: NOT_SELECTED_MARKETPLACE as typeof NOT_SELECTED_MARKETPLACE,
    name: nameNotSelected || 'Select a marketplace',
  });

  switch (country) {
    case Regions.INDONESIA:
      marketp.push(
        {
          id: MARKETPLACE.TOKOPEDIA,
          name: 'Tokopedia',
          icon: tokopediaIcon,
        },
        {
          id: MARKETPLACE.SHOPEE_INDONESIA,
          name: 'Shopee Indonesia (beta)',
          icon: shopeeIcon,
        }
      );
      break;
    case Regions.MALAYSIA:
      marketp.push({
        id: MARKETPLACE.SHOPEE_MALAYSIA,
        name: 'Shopee Malaysia',
        icon: shopeeIcon,
      });
      break;
    case Regions.PHILIPPINES:
      marketp.push({
        id: MARKETPLACE.SHOPEE_PHILIPPINES,
        name: 'Shopee Philippines',
        icon: shopeeIcon,
      });
      break;
    case Regions.SINGAPORE:
      marketp.push(
        {
          id: MARKETPLACE.SHOPEE_SINGAPORE,
          name: 'Shopee Singapore',
          icon: shopeeIcon,
        },
        {
          id: MARKETPLACE.LAZADA,
          name: 'Lazada',
          icon: lazadaIcon,
        }
      );
      break;
    case Regions.THAILAND:
      marketp.push({
        id: MARKETPLACE.SHOPEE_THAILAND,
        name: 'Shopee Thailand',
        icon: shopeeIcon,
      });
      break;
    case Regions.VIETNAM:
      marketp.push({
        id: MARKETPLACE.SHOPEE_VIETNAM,
        name: 'Shopee Vietnam',
        icon: shopeeIcon,
      });
      break;
  }

  return marketp;
};
