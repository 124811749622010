import { useTranslation } from 'react-i18next';

import { marketplaces } from '@/fsd/shared/constants/marketplaces';

import { id, my, ph, sg, th, vn } from '@/assets/images/languages';

export const useRegions = () => {
  const { t } = useTranslation('APPCON');

  return [
    {
      id: 'ind',
      img: id,
      label: t('appcon019-0B-11'),
      name: 'Indonesia',
      marketplaces: marketplaces,
    },
    {
      id: 'mal',
      img: my,
      label: t('appcon019-0B-12'),
      name: 'Malaysia',
      marketplaces: marketplaces,
    },
    {
      id: 'tha',
      img: th,
      label: t('appcon019-0B-13'),
      name: 'Thailand',
      marketplaces: marketplaces,
    },
    {
      id: 'vie',
      img: vn,
      label: t('appcon019-0B-14'),
      name: 'Vietnam',
      marketplaces: marketplaces,
    },
    {
      id: 'phi',
      img: ph,
      label: t('appcon019-0B-15'),
      name: 'Philippines',
      marketplaces: marketplaces,
    },
    {
      id: 'sin',
      img: sg,
      label: t('appcon019-0B-16'),
      name: 'Singapore',
      marketplaces: marketplaces,
    },
  ];
};
