import { FC } from 'react';

import LayoutAuth from '@/ui/Layouts/LayoutAuth/LayoutAuth';

import SignInForm from './SignInForm/SignInForm';

import ImageAuth from '@/assets/images/auth/auth.png';

const SignIn: FC = () => {
  return (
    <LayoutAuth maw={400} image={ImageAuth}>
      <SignInForm />
    </LayoutAuth>
  );
};

export default SignIn;
