import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, Group, Radio, Stack, Text } from '@mantine/core';

import { TariffPlan, TariffPlanPrice, TariffPlanWithPrices } from '@/api/swaggerApi';
import { UserInfoService } from '@/api/UserInfoService';

import { Mdash } from '@/ui/Mdash/Mdash';
import NumberValuesFormat from '@/ui/NumberFormat/NumberValues/NumberValuesFormat';

import { useStyles } from '@/pages/Profile/ProfileTariff/TariffStyles';
import { useActions } from '@/store/hooks';

interface ITariffPlanItemProps {
  prices: TariffPlanPrice[];
  days?: string;
  item: TariffPlanWithPrices;
  setValue: (value1: string, value2: string) => void;
  value: string;
}

const TariffPlanItem: FC<ITariffPlanItemProps> = ({ prices, days, item, setValue, value }) => {
  const { classes } = useStyles();

  const { t, i18n } = useTranslation('APPCON');
  const { setSelectedCurrency } = useActions();

  const getPriceItemByCurrency = (currency: string): TariffPlanPrice | undefined => {
    return prices.find((price) => price.currency === currency) || undefined;
  };

  const handlePriceItem = (): TariffPlanPrice | undefined => {
    switch (UserInfoService.getUserInfo()?.region) {
      case 'Indonesia':
        return getPriceItemByCurrency('IDR');
      case 'Malaysia':
        return getPriceItemByCurrency('MYR');
      case 'Thailand':
        return getPriceItemByCurrency('THB');
      case 'Vietnam':
        return getPriceItemByCurrency('VND');
      case 'Philippines':
        return getPriceItemByCurrency('PHP');
      case 'Singapore':
        return getPriceItemByCurrency('SGD');
      default:
        return getPriceItemByCurrency('USD');
    }
  };

  const priceItem = handlePriceItem();

  useEffect(() => {
    if (value === item.slug) {
      setSelectedCurrency(priceItem?.currency);
    }
  }, [priceItem]);

  return (
    <Box
      key={item.id + 'tariff'}
      className={classes.tariffPlanBox}
      mb={'xs'}
      sx={value === item.slug ? { borderColor: '#7F56D9' } : undefined}
      onClick={() => setValue(item.slug, priceItem?.currency)}
    >
      <Radio.Group value={value} miw={100} mb={6}>
        <Radio
          styles={{
            label: { fontSize: 16, fontWeight: 500 },
            description: {
              fontSize: 14,
              fontWeight: 500,
              color: '#3B82F6',
            },
          }}
          size="xs"
          value={item.slug}
          label={item.title && !days ? `${item.title.replace('ProPlan ', '')}` : days}
          description={
            priceItem?.discount_percent
              ? t(`appcon036-1B-35`, {
                  x: Math.floor(priceItem?.discount_percent),
                })
              : ''
          }
        />
      </Radio.Group>
      <Stack align="flex-end" spacing={0}>
        {!!item.additional_data?.best_choice && priceItem && (
          <Text className={classes.mostPopular} fz={10} weight={500} mb={'xs'}>
            {t('BEST CHOICE')}
          </Text>
        )}
        <Group align="flex-end" spacing={7} position={'right'}>
          {priceItem?.amount_before_discount &&
            priceItem?.amount_before_discount !== priceItem?.amount && (
              <Text className={classes.discount} size={16} strikethrough>
                {priceItem?.currency}{' '}
                <NumberValuesFormat
                  value={priceItem?.amount_before_discount}
                  sx={{ textDecoration: 'line-through' }}
                />{' '}
              </Text>
            )}
          <Text className={classes.price} size={30} lh={1.2}>
            {priceItem?.currency} {<NumberValuesFormat value={priceItem?.amount} /> || <Mdash />}
          </Text>
        </Group>
      </Stack>
    </Box>
  );
};

export default TariffPlanItem;
