import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Select, Space, Text } from '@mantine/core';
import { Notes } from 'tabler-icons-react';

import styles from './SupportOverlay.module.scss';

import TicketsCommentsAdd from '@/pages/Tickets/TicketsCommentsAdd';
import { getThemesList, postTicket } from '@/store/entities/ticket/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

interface SupportDrawerProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  theme?: string;
}

const SupportDrawer: FC<SupportDrawerProps> = ({ opened, setOpened }) => {
  const { t } = useTranslation('APPCON');
  const dispatch = useAppDispatch();
  const [valueTheme, setValueTheme] = useState<string | null>(null);
  const [valueDescription, setValueDescription] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [base64Img, setBase64Img] = useState<string | ArrayBuffer | null>('');
  const [sendComment, setSendComment] = useState<boolean>(false);
  const { themesList } = useAppSelector((state) => state.ticket);
  const themeTitle = themesList?.detailed_results?.map((result) => ({
    value: result.id.toString(),
    label: result.title,
  })) as { value: string; label: string }[];

  const clearState = () => {
    setValueDescription('');
    setSendComment(false);
    setImageFile(null);
    setValueTheme('');
  };

  // const mockThemesList = [
  //   { value: 'Connection problem', label: 'Connection problem' },
  //   { value: 'Payment problem', label: 'Payment problem' },
  //   { value: 'Data inaccuracies', label: 'Data inaccuracies' },
  //   { value: 'Bugs in the system', label: 'Bugs in the system' },
  //   { value: 'Wishes for the system', label: 'Wishes for the system' },
  //   { value: 'Other questions', label: 'Other questions' },
  // ];

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        setBase64Img(reader.result);
      };
    }
  }, [imageFile]);

  useEffect(() => {
    if (sendComment) {
      dispatch(
        postTicket({
          description: valueDescription,
          file: base64Img,
          theme_id: Number(valueTheme),
        })
      );
      clearState();
      setOpened(false);
    }
  }, [sendComment]);

  useEffect(() => {
    dispatch(getThemesList());
  }, []);

  // useEffect(() => {
  //   // if (theme) {
  //   //   setValueTheme(theme);
  //   // }
  // }, []);

  return (
    <Drawer
      classNames={{ drawer: styles.drawer, title: styles.title }}
      onClose={() => setOpened(false)}
      transitionDuration={500}
      overlayOpacity={0.5}
      position="right"
      opened={opened}
      padding="xl"
      size={680}
    >
      <Text mb={20}>{t('appcon040-0A-19')}</Text>

      <form>
        <Select
          styles={{ rightSection: { pointerEvents: 'none' } }}
          placeholder={t('appcon040-0A-35')}
          classNames={{ dropdown: styles.selectDropdown }}
          style={{ msOverflowStyle: 'none' }}
          rightSection={<Notes size={22} />}
          rightSectionWidth={30}
          value={valueTheme}
          data={themeTitle}
          size="md"
          required
          onChange={setValueTheme}
        />
        <Space h="sm" />
        <TicketsCommentsAdd
          imageFile={imageFile}
          sendComment={sendComment}
          setImageFile={setImageFile}
          setSendComment={setSendComment}
          valueDescription={valueDescription}
          setValueDescription={setValueDescription}
        />
      </form>
    </Drawer>
  );
};

export default SupportDrawer;
