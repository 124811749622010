import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnstyledButton } from '@mantine/core';
import { getNavigation } from 'src/fsd/shared/lib/router';

import { TokenService } from '@/api/TokenService';

import { LogoutButton } from '@/fsd/features/logout';
import { RolesEnum } from '@/fsd/shared/constants/roles';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import { useIsLocation } from '@/fsd/shared/lib/hooks/useIsLocation/useIsLocation';
import { DropdownMenu } from '@/fsd/shared/ui/DropdownMenu';
import { NavLink } from '@/fsd/shared/ui/NavLink';

import cls from './Navbar.module.scss';

interface NavbarProps {
  className?: string;
}

export const Navbar = ({ className }: NavbarProps) => {
  const { t, i18n } = useTranslation('APPCON');
  const { main, profile, web, admin, dashboard } = getNavigation(t, i18n.language);
  const { isActiveLocationHandle } = useIsLocation();

  return (
    <div className={classNames(cls.Navbar, {}, [className])}>
      {dashboard.map((item) => (
        <DropdownMenu
          label={
            <UnstyledButton>
              <NavLink
                className={classNames(
                  cls.navbarItem,
                  { [cls.activeNavbarItem]: isActiveLocationHandle(item.href) },
                  []
                )}
                externalLink={item.externalLink}
                name={item.title}
                href={item.href}
              />
            </UnstyledButton>
          }
          key={item.href}
        ></DropdownMenu>
      ))}
      {main.map((item) => (
        <DropdownMenu
          label={
            <UnstyledButton>
              <NavLink
                className={classNames(
                  cls.navbarItem,
                  { [cls.activeNavbarItem]: isActiveLocationHandle(item.href) },
                  []
                )}
                externalLink={item.externalLink}
                name={item.title}
                href={item.href}
              />
            </UnstyledButton>
          }
          key={item.href}
        >
          {item?.links?.map((link) => (
            <NavLink
              className={classNames(
                cls.navbarItem,
                { [cls.activeNavbarItem]: isActiveLocationHandle(link.href) },
                []
              )}
              externalLink={link.externalLink}
              name={link.title}
              href={link.href}
              key={link.href}
              icon={link.icon}
            />
          ))}
        </DropdownMenu>
      ))}
      {profile.map((item) => (
        <DropdownMenu
          label={
            <UnstyledButton>
              <NavLink
                className={classNames(
                  cls.navbarItem,
                  { [cls.activeNavbarItem]: isActiveLocationHandle(item.href) },
                  []
                )}
                externalLink={item.externalLink}
                name={item.title}
                href={item.href}
              />
            </UnstyledButton>
          }
          key={item.href}
        >
          {item?.links?.map((link) => (
            <NavLink
              className={classNames(
                cls.navbarItem,
                { [cls.activeNavbarItem]: isActiveLocationHandle(link.href) },
                []
              )}
              externalLink={link.externalLink}
              name={link.title}
              href={link.href}
              key={link.href}
            />
          ))}
          <LogoutButton className={cls.navbarLogout} />
        </DropdownMenu>
      ))}
      {web.map((item) => (
        <DropdownMenu
          label={
            <UnstyledButton>
              <NavLink
                className={classNames(
                  cls.navbarItem,
                  { [cls.activeNavbarItem]: isActiveLocationHandle(item.href) },
                  []
                )}
                externalLink={item.externalLink}
                name={item.title}
                href={item.href}
              />
            </UnstyledButton>
          }
          key={item.href}
        >
          {item?.links?.map((link) => (
            <NavLink
              className={classNames(
                cls.navbarItem,
                { [cls.activeNavbarItem]: isActiveLocationHandle(item.href) },
                []
              )}
              name={link.title}
              href={link.href}
              key={link.href}
              externalLink={link.externalLink}
            />
          ))}
        </DropdownMenu>
      ))}
      {TokenService.getRoleUserByToken() === RolesEnum.ADMIN &&
        admin.map((item) => (
          <DropdownMenu label={<UnstyledButton>{item.title}</UnstyledButton>} key={item.href}>
            {item?.links?.map((link) => (
              <NavLink
                className={classNames(
                  cls.navbarItem,
                  { [cls.activeNavbarItem]: isActiveLocationHandle(link.href) },
                  []
                )}
                externalLink={link.externalLink}
                name={link.title}
                href={link.href}
                key={link.href}
              />
            ))}
          </DropdownMenu>
        ))}
    </div>
  );
};
