import { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { AppPaths } from 'src/fsd/shared/lib/router';

import { resetPassword } from '@/store/entities/auth/actions';
import { reset, resetResult } from '@/store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

export interface FormValues {
  email: string;
}

const ResetPasswordForm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('APPCON');
  const { isLoading, isSuccess, isError, message } = useAppSelector((state) => state.auth);
  const [isMessageSend, setIsMessageSend] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (value.length > 0 ? null : t('appcon004-1C-2')),
    },
  });

  const handleResetPassword = (values: FormValues, event: FormEvent) => {
    event.preventDefault();
    let email = values.email;
    dispatch(resetPassword({ email }));
  };

  useEffect(() => {
    if (isSuccess) {
      setIsMessageSend(true);
      showNotification({
        message: message,
        color: 'green',
      });
    } else if (isError) {
      showNotification({
        message: message,
        color: 'red',
      });
    }
    return () => {
      dispatch(resetResult());
    };
  }, [isSuccess, isError]);

  return (
    <form onSubmit={form.onSubmit((values, event) => handleResetPassword(values, event))}>
      <Title order={2} weight={700}>
        {t('appcon004-1-1')}
      </Title>
      <Text fz={'sm'} mt={'xs'}>
        {t('appcon004-1-2')}
      </Text>
      <TextInput
        label={t('appcon004-1-3')}
        size="md"
        {...form.getInputProps('email')}
        placeholder={t('appcon004-1-4')}
        mt={15}
      />

      <Button
        type="submit"
        loading={isLoading}
        disabled={isMessageSend}
        fullWidth
        mt={30}
        size="md"
      >
        {t('appcon004-1-5')}
      </Button>

      <Center mt={10}>
        <Text
          variant="link"
          onClick={() => navigate(AppPaths.LOGIN)}
          size="sm"
          weight={700}
          style={{ cursor: 'pointer' }}
        >
          {t('appcon004-1-6')}
        </Text>
      </Center>
    </form>
  );
};

export default ResetPasswordForm;
