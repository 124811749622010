import { UserSchema } from '@/api/swaggerApi';

export class UserInfoService {
  /* Save a user info in Local Storage
   *
   * @param {string} token
   */

  static storage = localStorage;

  static setUserInfo(data: UserSchema) {
    localStorage.setItem('userInfo', JSON.stringify(data));
  }

  /* Remove a user info from Local Storage.
   *
   */
  static removeUserInfo() {
    localStorage.removeItem('userInfo');
  }

  /**
   * Get a token user info.
   *
   * @returns {UserSchema}
   */

  static getUserInfo() {
    const data: UserSchema | null = JSON.parse(localStorage.getItem('userInfo') || 'null');
    return data;
  }
}
