import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';

import { api } from '@/fsd/shared/api';

import {
  AddUserData,
  DeleteUserData,
  EditUserData,
  GetManagersData,
  GetUsersData,
  IUsersList,
  LogInToUserEmulationData,
  UpdateStatusUserData,
} from '../types';

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (arg: DeleteUserData, thunkAPI) => {
    try {
      const response = await api.service.delete(`/api/users/user/${arg.id}/`);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const updateStatusUser = createAsyncThunk(
  'user/updateStatusUser',
  async (arg: UpdateStatusUserData, thunkAPI) => {
    try {
      const response = await api.service.patch(`/api/users/user/${arg.id}/?status=${arg.status}`);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const editUser = createAsyncThunk(
  'user/editUser',
  async (arg: Partial<IUsersList> & { id: string }, thunkAPI) => {
    try {
      const response = await api.service.post(`/api/users/user/${arg.id}/`, {
        business_type: arg.business_type,
        email: arg.email,
        first_name: arg.first_name,
        last_name: arg.last_name,
        phone_number: arg.phone_number,
        region: arg.region,
        role: arg.role,
        email_confirmed: arg.email_confirmed,
        confirmed: arg.confirmed,
        is_test_user: arg.is_test_user,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const logInToUserEmulation = createAsyncThunk(
  'user/logInToUserEmulation',
  async (arg: LogInToUserEmulationData, thunkAPI) => {
    try {
      const response = await api.service.post(`/api/users/login/${arg.id}/`);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const logOutUserEmulation = createAsyncThunk(
  'user/logOutUserEmulation',
  async (arg, thunkAPI) => {
    try {
      const response = await api.service.get(`/api/users/logout/`);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getUser = createAsyncThunk('user/getUser', async (arg: { id: string }, thunkAPI) => {
  try {
    const response = await api.service.get(`/api/users/user/${arg.id}`);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const addUser = createAsyncThunk('user/addUser', async (arg: AddUserData, thunkAPI) => {
  try {
    const response = await api.service.post(`/api/users/user/`, {
      first_name: arg.firstName,
      last_name: arg.lastName,
      business_type: arg.businessType,
      region: arg.region,
      receive_news: arg.receiveNews,
      email: arg.email,
      password: arg.password,
      phone_number: arg.phoneNumber,
      role: arg.role,
    });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const confirmEmail = createAsyncThunk(
  'user/confirmEmail',
  async (arg: { id: string }, thunkAPI) => {
    try {
      const response = await api.service.get(`/api/users/confirm_user/${arg.id}/`);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
