import { ReactNode } from 'react';
import {
  ChartBar,
  ChartDonut3,
  ChartPie,
  Geometry,
  // Diamond,
  GridDots,
  Heart,
  Help,
  UserSearch,
} from 'tabler-icons-react';

import { AppPaths } from '@/fsd/shared/lib/router/index';
import { ExternalLinks } from '@/fsd/shared/lib/router/routes-enums';

import { ReactComponent as NewProductsAnalysisSvg } from '@/assets/icons/new-products-report-icon.svg';

// const HOST = 'app.sellmatica.com';

export type INavigation = {
  href?: string;
  title: string;
  icon?: ReactNode;
  externalLink?: boolean;
  links?: {
    href: string;
    title: string;
    icon?: ReactNode;
    externalLink?: boolean;
  }[];
};

export const getNavigation = (
  t: (arg: string) => string,
  lang: string
): {
  [key: string]: INavigation[];
} => {
  const handleGetExternalLinkWithLang = (route: string) => {
    return ExternalLinks.MAIN_SELLMATICA + lang + route;
  };

  return {
    dashboard: [
      {
        href: AppPaths.HOME,
        title: t('appcon040-0A-1'),
        icon: <GridDots size={24} />,
      },
    ],
    main: [
      {
        title: t('appcon040-0A-2'),
        href: AppPaths.EXTERNAL_ANALYSIS,
        links: [
          {
            title: t('appcon047--22'),
            href: AppPaths.SELLER_AUDIT,
            icon: <UserSearch />,
          },
          {
            title: t('appcon040-0A-3'),
            href: AppPaths.PRODUCT_ANALYSIS,
            icon: <ChartPie size={24} />,
          },
          {
            title: t('appcon040-0A-7'),
            href: AppPaths.NEW_PRODUCTS_REPORT,
            icon: <NewProductsAnalysisSvg />,
          },
          {
            title: t('appcon040-0A-4'),
            href: AppPaths.CATEGORY,
            icon: <ChartDonut3 size={24} />,
          },
          {
            title: t('appcon040-0A-6'),
            href: AppPaths.TREND_ANALYSIS,
            icon: <ChartBar size={24} />,
          },
          {
            title: t('appcon040--37'),
            href: AppPaths.GEO_ANALYSIS,
            icon: <Geometry />,
          },
          { title: t('appcon040-0A-5'), href: AppPaths.FAVORITE, icon: <Heart size={24} /> },
        ],
      },
      // {
      //   title: t('appcon040-0A-8'),
      //   href: AppPaths.INTERNAL_ANALYSIS,
      //   links: [
      //     {
      //       title: t('appcon040-0A-11'),
      //       href: AppPaths.CONNECTIONS,
      //       icon: <ApiApp size={24} />,
      //     },
      //     {
      //       title: t('appcon040-0A-9'),
      //       href: AppPaths.PROFIT_ANALYSIS,
      //       icon: <ReportAnalytics size={24} />,
      //     },
      //     {
      //       title: t('Margin Analyzer'),
      //       href: AppPaths.MARGIN_ANALYSIS,
      //       icon: <ReportMoney size={24} />,
      //     },
      //   ],
      // },
      {
        href: AppPaths.SUPPORT,
        title: t('appcon040-0A-12'),
        icon: <Help size={24} />,
      },
    ],
    admin: [
      {
        title: t('appcon040-0A-13'),
        links: [
          {
            href: AppPaths.ADMIN_USERS,
            title: t('appcon040-0A-14'),
            // icon: <Users size={24} />,
          },
          {
            href: AppPaths.ADMIN_CONTENT,
            title: t('appcon040-0A-15'),
            // icon: <News size={24} />,
          },
          {
            href: AppPaths.ADMIN_NOTIFICATIONS,
            title: t('appcon040-0A-16'),
            // icon: <Mail size={24} />,
          },
          {
            href: AppPaths.ADMIN_TICKETS,
            title: t('appcon040-0A-17'),
            // icon: <Help size={24} />,
          },
          {
            href: AppPaths.ADMIN_SETTINGS,
            title: t('appcon040-0A-18'),
            // icon: <Adjustments size={24} />,
          },
        ],
      },
    ],
    profile: [
      {
        title: t('appcon040--38'),
        href: AppPaths.PROFILE_INFO,
        links: [
          {
            title: t('appcon040-0A-31'),
            href: AppPaths.PROFILE_TARIFF,
          },
          {
            title: t('appcon040-0A-30'),
            href: AppPaths.PROFILE_CHANGE_PASSWORD,
          },
        ],
      },
    ],
    web: [
      {
        href: handleGetExternalLinkWithLang(''),
        externalLink: true,
        title: t('appcon040--39'),
        links: [
          {
            title: t('appcon040--40'),
            href: handleGetExternalLinkWithLang(ExternalLinks.EXTERNAL_ANALYTICS),
            externalLink: true,
          },
          {
            title: t('appcon040--41'),
            href: handleGetExternalLinkWithLang(ExternalLinks.INTERNAL_ANALYTICS),
            externalLink: true,
          },
          {
            title: t('appcon040--42'),
            href: handleGetExternalLinkWithLang(ExternalLinks.FREE_ASSESSMENT),
            externalLink: true,
          },
          {
            title: t('appcon040--43'),
            href: handleGetExternalLinkWithLang(ExternalLinks.BLOG),
            externalLink: true,
          },
          {
            title: t('appcon040--44'),
            href: handleGetExternalLinkWithLang(ExternalLinks.PRICE),
            externalLink: true,
          },
        ],
      },
    ],
  };
};
