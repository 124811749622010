import { apiParser } from '@/fsd/shared/api';

import { IGetReportsCategoriesRecommendation } from '@/store/entities/tools/trendAnalysis/types';

export const userTokens = {
  getUserUsageLimitTokens(data: { endpoint_id: number }) {
    return apiParser.service.request({
      method: 'get',
      url: `/api/api-usage/${data.endpoint_id}/limit`,
    });
  },
};
