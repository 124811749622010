import { GetSeller, GettingSeller } from '@/types/ISeller';

import { apiParser } from '@/fsd/shared/api';

export const sellerApi = {
  getSeller(data: GetSeller) {
    return apiParser.service.request<GettingSeller>({
      method: 'get',
      url: `api/reports/sellers/${data.marketplace}/${data.seller_id}`,
      params: {
        page: data.page,
        items_per_page: data.items_per_page,
        sort_by: data.sort_by,
        sort_order: data.sort_order,
      },
    });
  },
};
