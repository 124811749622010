import { GettingMarketplaces } from '@/types/IMarketplace';

import { apiParser } from '@/fsd/shared/api';

export const marketplacesApi = {
  getMarketplaces() {
    return apiParser.service.request<GettingMarketplaces>({
      method: 'get',
      url: `api/info/marketplaces/`,
    });
  },
};
