export const Regions = Object.freeze({
  INDONESIA: 'Indonesia',
  MALAYSIA: 'Malaysia',
  PHILIPPINES: 'Philippines',
  SINGAPORE: 'Singapore',
  THAILAND: 'Thailand',
  VIETNAM: 'Vietnam',
});

export const ShortRegions = Object.freeze({
  ind: 'Indonesia',
  mal: 'Malaysia',
  phi: 'Philippines',
  sin: 'Singapore',
  tha: 'Thailand',
  vie: 'Vietnam',
});
