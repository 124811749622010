import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { getSeller } from '@/store/entities/tools/seller/actions';
import { GETTING_SELLER, GETTING_SELLER_PRODUCTS } from '@/store/entities/tools/seller/constants';
import { SellerState } from '@/store/entities/tools/seller/types';
import { ISorting } from '@/store/types/ICommon';

const initialState: SellerState = {
  info: null,
  sellerProducts: {
    totalItems: 0,
    items: [],
  },
  chart: null,
  sellerCategories: [],
  isLoading: null,
  isSuccess: null,
  isError: null,
  message: null,
  sorting: {
    sortBy: 'share_in_revenue',
    sortOrder: 'desc',
  },
};

const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    clearSellerState(state) {
      state.info = null;
      state.sellerCategories = [];
      state.sellerProducts = {
        totalItems: 0,
        items: [],
      };
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = null;
      state.message = null;
    },
    setSorting(state, action: PayloadAction<ISorting>) {
      state.sorting = action.payload;
    },
    resetStatusSeller(state) {
      state.isSuccess = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSeller.pending, (state) => {
      state.isLoading = !state.info ? GETTING_SELLER : GETTING_SELLER_PRODUCTS;
    });
    builder.addCase(getSeller.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = GETTING_SELLER;
      state.info = action.payload.seller;
      state.info.revenue_sum = action.payload.revenue_sum;
      state.info.sold_sum = action.payload.sold_sum;
      state.sellerProducts.totalItems = action.payload.pagination_data.total_items;
      state.sellerProducts.items = action.payload.seller_products;
      state.sellerCategories = action.payload.seller_categories;
      state.chart = action.payload.categories_chart;
    });
    builder.addCase(getSeller.rejected, (state, action) => {
      state.isLoading = null;
      state.isError = GETTING_SELLER;
      state.message = action.payload || SYSTEM_ERROR;
      showNotification({ message: action.payload || SYSTEM_ERROR, color: 'red' });
    });
  },
});

export const { resetStatusSeller, clearSellerState, setSorting } = sellerSlice.actions;

export default sellerSlice.reducer;
