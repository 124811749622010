import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';
import { NotificationListUpdate, NotificationSending } from '@/api/swaggerApi';

import { GetNotificationData, NotificationsState } from './types';

export const getNotifications = createAsyncThunk<any, GetNotificationData, { rejectValue: string }>(
  'notifications/getNotifications',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await Api.Notifications.apiNotificationsGet(arg.limit, arg.page);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const readNotifications = createAsyncThunk<any, void, { rejectValue: string }>(
  'notifications/readNotifications',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const {
        notifications: { notifications },
      } = getState() as { notifications: NotificationsState };
      const { data } = await Api.Notifications.apiNotificationsPatch({
        user_notification_ids: notifications?.map((item) => item.id) || [],
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;
      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteNotifications = createAsyncThunk<
  any,
  NotificationListUpdate,
  {
    rejectValue: string;
  }
>('notifications/deleteNotifications', async (arg, { getState, rejectWithValue }) => {
  try {
    const {
      notifications: { notifications },
    } = getState() as { notifications: NotificationsState };

    const { data } = await Api.Notifications.apiNotificationsDelete({
      user_notification_ids: notifications?.map((item) => item.id) || [],
    });
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const readNotificationById = createAsyncThunk<any, number, { rejectValue: string }>(
  'notifications/readNotificationById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await Api.Notifications.apiNotificationsIdPatch(id);
      return { id, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;
      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteNotificationById = createAsyncThunk<any, number, { rejectValue: string }>(
  'notifications/deleteNotificationById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await Api.Notifications.apiNotificationsIdDelete(id);
      return { id, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;
      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const createNotification = createAsyncThunk<
  any,
  NotificationSending,
  { rejectValue: string }
>('notifications/createNotification', async (formData, { rejectWithValue }) => {
  try {
    const { data } = await Api.Notifications.apiNotificationsPost(formData);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;
    return rejectWithValue(data?.message || statusText);
  }
});
