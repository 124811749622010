import { cancelErrors } from '@/types/ICancelErrors';

import axiosLib, { AxiosInstance, AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import {
  AuthApi,
  BillingApi,
  ConnectionsApi,
  InternalAnalyticsApi,
  InternalAnalyticsReportsApi,
  NotificationsApi,
  OAuthApi,
  OAuthFacebookApi,
  TicketApi,
  UsersApi,
} from '@/api/swaggerApi';
import { TokenService } from '@/api/TokenService';
import { url } from '@/api/url';

import { AppDispatch } from '@/store';
import { logoutUser } from '@/store/entities/auth/authSlice';

export const baseURL: string = url as string;

interface IGetRefreshTokenResponse {
  auth_token: string;
  message: string;
  refresh_token: string;
  result: boolean;
}

export async function getRefreshToken() {
  const refreshToken = TokenService.getRefreshedToken();
  return axiosLib.post<IGetRefreshTokenResponse>(url + '/api/auth/refresh/', {
    refresh_token: refreshToken,
  });
}

export class Api {
  static Auth: AuthApi;

  static OAuth: OAuthApi;

  static OAuthFacebook: OAuthFacebookApi;

  static Notifications: NotificationsApi;

  static Billing: BillingApi;

  static Ticket: TicketApi;

  static Users: UsersApi;

  static InternalAnalytics: InternalAnalyticsApi;

  static InternalAnalyticsReports: InternalAnalyticsReportsApi;

  static Connections: ConnectionsApi;

  service: AxiosInstance;

  dispatch: AppDispatch;

  baseUrl: string;

  constructor(dispatch: AppDispatch, config: AxiosRequestConfig) {
    this.dispatch = dispatch;
    let service = axiosLib.create(config);
    this.baseUrl = config.baseURL || baseURL;

    service.interceptors.response.use(this.handleSuccess, this.handleError);
    service.interceptors.request.use(
      (request) => {
        if (!request?.headers) {
          throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
        }
        const token = TokenService.getToken();
        request.headers.Authorization = 'Bearer ' + token;
        return request;
      },
      (error) => Promise.reject(error)
    );
    this.service = service;
    createAuthRefreshInterceptor(this.service, this.refreshAuthLogic, {
      retryInstance: this.service,
      onRetry: (requestConfig) => ({ ...requestConfig, baseURL: this.baseUrl }),
    });

    createAuthRefreshInterceptor(this.service, () => Promise.resolve(), {
      retryInstance: this.service,
      statusCodes: [408],
      onRetry: (requestConfig) => ({ ...requestConfig, baseURL: this.baseUrl }),
    });
  }

  init = () => {
    Api.Auth = new AuthApi(undefined, undefined, this.service);
    Api.Notifications = new NotificationsApi(undefined, undefined, this.service);

    Api.Ticket = new TicketApi(undefined, undefined, this.service);
    Api.Users = new UsersApi(undefined, undefined, this.service);
    Api.Billing = new BillingApi(undefined, undefined, this.service);
    Api.InternalAnalytics = new InternalAnalyticsApi(undefined, undefined, this.service);
    Api.Connections = new ConnectionsApi(undefined, undefined, this.service);
    Api.InternalAnalyticsReports = new InternalAnalyticsReportsApi(
      undefined,
      undefined,
      this.service
    );
    Api.OAuth = new OAuthApi(undefined, undefined, this.service);
    Api.OAuthFacebook = new OAuthFacebookApi(undefined, undefined, this.service);
  };

  handleSuccess(response: AxiosRequestConfig) {
    return response;
  }

  async handleError(error: any) {
    if (axiosLib.isCancel(error)) return Promise.reject(cancelErrors.cancelToken);
    return Promise.reject(error);
  }

  refreshAuthLogic = async (failedRequest: any) => {
    const originalConfig = failedRequest.config;
    const failedData = failedRequest.response.data;
    if (!TokenService.isRefreshedTokenExists() || failedData.form_error) {
      return Promise.resolve();
    }
    try {
      const { data } = await getRefreshToken();
      if (data.result) {
        const { auth_token: authToken, refresh_token: refreshToken } = data;
        TokenService.setToken(authToken);
        TokenService.setRefreshedToken(refreshToken);
        originalConfig.headers.Authorization = `Bearer ${authToken}`;
        return await Promise.resolve();
      } else {
        this.dispatch(logoutUser());

        return await Promise.reject();
      }
    } catch (e) {
      this.dispatch(logoutUser());
      return Promise.reject();
    }
  };

  _getProgress(callback: (percentCompleted: number) => void = () => {}) {
    return {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        callback(percentCompleted);
      },
    };
  }
}

export function getCancelTokenSource() {
  return axiosLib.CancelToken.source();
}

export function updateCancelToken(data: any) {
  if (data === undefined || data === null) return {};
  if (data.source) data.source.cancel('Operation canceled due to new request.');
  data.source = getCancelTokenSource();
  return data.source.token;
}
