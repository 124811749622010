export const dateToDDMMYYYY = (date: string) => {
  if (date) {
    const d = new Date(date);
    const dd = String(d.getDate()).padStart(2, '0');
    const mm = String(d.getMonth() + 1).padStart(2, '0');
    const yyyy = d.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
  } else {
    return date;
  }
};

export const htmlTextContent = (html: string) => {
  return html.replace(/<[^>]+>/g, '').trim();
};

export function uniqFast(a: any[]) {
  let seen: any = {};
  let out = [];
  let len = a.length;
  let j = 0;
  for (let i = 0; i < len; i++) {
    let item = JSON.stringify(a[i]);
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = JSON.parse(item);
    }
  }
  return out;
}

export const getLocaleForApiParam = (locale: string | undefined): string => {
  switch (locale) {
    case 'my':
      return 'ms';
    case 'cn':
      return 'zh';
    case 'vn':
      return 'vi';
    case undefined:
      return 'en';
    default:
      return locale;
  }
};
