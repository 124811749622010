import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // resetUsersState: () => initialState,
    // resetUsersByPageState: (state) => {
    //   state.users = [];
    //   state.countUsers = 0;
    //   state.isLoading = false;
    //   state.isError = false;
    //   state.isSuccess = false;
    // },
  },
  extraReducers: {},
});
