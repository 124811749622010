// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import dayjs from 'dayjs';

const options: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const dateFormat = (joinDate: string, locale: string) => {
  let dateInMSec = Date.parse(joinDate);
  let date = new Date(dateInMSec);
  return date.toLocaleString(locale, options);
};

export const convertToDate = (joinDate: string): Date => {
  return new Date(joinDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
};

type DatePatterns = 'yyyy-MM-dd' | 'dd.MM.yyyy' | 'HH:mm' | 'yyyy-MM-dd HH:mm:ss';

export const dateParse = (date: Date | string | undefined | null, pattern: DatePatterns) => {
  if (!date) {
    return '';
  }

  const toDate = typeof date === 'string' ? new Date(date) : date;

  return format(toDate, pattern);
};

export function getDateAgo(date: Date, days: number) {
  let dateCopy = new Date(date);

  dateCopy.setDate(date.getDate() - days);
  return dateCopy;
}

export const getDefaultDate = () => {
  const date = new Date();

  let date1 = [dayjs(date).subtract(30, 'day').toDate(), date];

  const firstDate = date1[0]?.toDateString();
  const secondDate = date1[1]?.toDateString();
  const firstDateFormat = firstDate ? dayjs(firstDate).format('MMM DD, YYYY') : null;
  const secondDateFormat = secondDate ? dayjs(secondDate).format('MMM DD, YYYY') : null;

  const from = `${dayjs(firstDateFormat).format('YYYY-MM-DD')} 00:00:00.000`;
  const to = `${dayjs(secondDateFormat).format('YYYY-MM-DD')} 00:00:00.000`;

  return { from, to };
};
