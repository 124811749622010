import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';
import { UpdateUserProfileSchema, UpdateUserProfileSchemaLocaleEnum } from '@/api/swaggerApi';

export const editProfile = createAsyncThunk(
  'profile/editProfile',
  async (arg: UpdateUserProfileSchema, thunkAPI) => {
    try {
      const response = await Api.Users.apiUsersProfilePost(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const changeLanguage = createAsyncThunk(
  'profile/changeLanguage',
  async (arg: { locale: string }, thunkAPI) => {
    const argNew = (): { locale: UpdateUserProfileSchemaLocaleEnum } => {
      switch (arg.locale) {
        case 'my':
          return { locale: 'ms' as UpdateUserProfileSchemaLocaleEnum };
        case 'cn':
          return { locale: 'zh' as UpdateUserProfileSchemaLocaleEnum };
        case 'vn':
          return { locale: 'vi' as UpdateUserProfileSchemaLocaleEnum };
        default:
          return { locale: arg.locale as UpdateUserProfileSchemaLocaleEnum };
      }
    };

    try {
      const response = await Api.Users.apiUsersProfilePost(argNew());
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
