import { showNotification } from '@mantine/notifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SYSTEM_ERROR } from '@/fsd/shared/constants/errors';

import { UsersConst } from '@/store/entities/users/constants';
import { IUsers, IUsersState, UpdateStatusUserData } from '@/store/entities/users/types';
import {
  addUser,
  confirmEmail,
  deleteUser,
  editUser,
  getUser,
  logInToUserEmulation,
  logOutUserEmulation,
  updateStatusUser,
} from '@/store/entities/users/user/actions';
import { getManagers, getUsers } from '@/store/entities/users/users/actions';
import { usersAdapter } from '@/store/entities/users/users/usersAdapters';
import { ISorting } from '@/store/types/ICommon';

const initialState: IUsersState = {
  isLoading: false,
  isError: false,
  isSuccess: false,

  isLoadingEmulate: false,
  isErrorEmulate: false,
  isSuccessEmulate: false,

  isLoadingLogoutEmulate: false,
  isErrorLogoutEmulate: false,
  isSuccessLogoutEmulate: false,

  isLoadingDelete: false,
  isErrorDelete: false,
  isSuccessDelete: false,

  isLoadingUpdateStatus: false,
  isErrorUpdateStatus: false,
  isSuccessUpdateStatus: false,

  isLoadingEdit: false,
  isErrorEdit: false,
  isSuccessEdit: false,

  isLoadingGetUser: false,
  isErrorGetUser: false,
  isSuccessGetUser: false,

  isLoadingConfirmEmail: false,
  isErrorConfirmEmail: false,
  isSuccessConfirmEmail: false,

  isLoadingAddUser: false,
  isErrorAddUser: false,
  isSuccessAddUser: false,
  addUserError: {
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    password: null,
    role: null,
    business_type: null,
    region: null,
    _schema: null,
  },

  message: null,
  userList: [],
  managerList: [],
  managerListPage: 1,
  hasNextManagerListPage: true,
  page: 1,
  itemsPerPage: 0,
  countUsers: 0,
  hasNext: true,
  emulateToken: null,
  emulateRefreshToken: null,
  updatedToken: null,
  updatedRefreshToken: null,
  isEmulation: false,
  editUserError: {
    user_id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    business_type: null,
    region: null,
    role: null,
    _schema: null,
  },

  /////////////
  sorting: {
    sortBy: 'email',
    sortOrder: 'asc',
  },
  isLoadingState: null,
  pagination: {
    total: 0,
    page: 1,
    size: 10,
    pages: 0,
  },
  users: usersAdapter.getInitialState(),
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersState: () => initialState,
    resetUsersByPageState: (state) => {
      state.users = usersAdapter.getInitialState();
      state.countUsers = 0;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
    resetDeleteUserState: (state) => {
      state.isLoadingDelete = false;
      state.isErrorDelete = false;
      state.isSuccessDelete = false;
    },
    resetUpdateUserState: (state) => {
      state.isLoadingUpdateStatus = false;
      state.isErrorUpdateStatus = false;
      state.isSuccessUpdateStatus = false;
    },
    resetEditUserState: (state) => {
      state.isLoadingEdit = false;
      state.isErrorEdit = false;
      state.isSuccessEdit = false;
      state.editUserError = initialState.editUserError;
    },
    resetEmulateUserState: (state) => {
      state.isLoadingEmulate = false;
      state.isSuccessEmulate = false;
      state.isErrorEmulate = false;
    },
    resetLogoutEmulateState: (state) => {
      state.isLoadingLogoutEmulate = false;
      state.isErrorLogoutEmulate = false;
      state.isSuccessLogoutEmulate = false;
    },
    resetGetUserState: (state) => {
      state.isErrorGetUser = false;
      state.isSuccessGetUser = false;
      state.isLoadingGetUser = false;
    },
    resetAddUserState: (state) => {
      state.isLoadingAddUser = false;
      state.isErrorAddUser = false;
      state.isSuccessAddUser = false;
      state.addUserError = initialState.addUserError;
    },
    resetConfirmEmail: (state) => {
      state.isSuccessConfirmEmail = false;
      state.isErrorConfirmEmail = false;
      state.isLoadingConfirmEmail = false;
    },
    resetAddUserError: (state) => {
      state.addUserError = initialState.addUserError;
    },
    resetEditUserError: (state) => {
      state.editUserError = initialState.editUserError;
    },
    setUserSortingTable: (state, action: PayloadAction<ISorting>) => {
      state.sorting = action.payload;
    },
    setPageUsersTable: (state, action: PayloadAction<{ page: number }>) => {
      state.pagination.page = action.payload.page;
    },
  },
  extraReducers: {
    [getUsers.pending.type]: (state) => {
      state.isLoadingState = UsersConst.GET_USERS;
    },
    [getUsers.fulfilled.type]: (state, action: PayloadAction<IUsers>) => {
      state.isLoadingState = null;
      state.pagination = {
        total: action.payload.total_items,
        page: action.payload.page,
        size: action.payload.items_per_page,
        pages: Math.ceil(
          (action.payload?.total_items || 0) / (action.payload?.items_per_page || 0)
        ),
      };
      usersAdapter.setAll(state.users, action.payload.users);

      ///
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.page = action.payload.page;
      state.countUsers = action.payload.total_items;
      state.itemsPerPage = action.payload.items_per_page;
      state.hasNext = action.payload.has_next;
      if (state.page === 1) {
        state.userList = action.payload.users;
      } else {
        state.userList = state.userList.concat(action.payload.users);
      }
    },
    [getUsers.rejected.type]: (state, action) => {
      state.isLoadingState = null;
      state.message = action.payload.message || SYSTEM_ERROR;
      showNotification({ color: 'red', message: action.payload.message || SYSTEM_ERROR });
    },
    // получение менеджеров
    [getManagers.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getManagers.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.managerListPage = action.payload.page;
      state.hasNextManagerListPage = action.payload.has_next;
      if (state.managerListPage === 1) {
        state.managerList = action.payload.users;
      } else {
        state.managerList = state.managerList.concat(action.payload.users);
      }
    },
    [getManagers.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // удаление пользователя
    [deleteUser.pending.type]: (state) => {
      state.isLoadingDelete = true;
    },
    [deleteUser.fulfilled.type]: (state, action) => {
      state.isLoadingDelete = false;
      state.isSuccessDelete = true;
      state.isErrorDelete = false;
      state.message = action.payload.message;
    },
    [deleteUser.rejected.type]: (state, action) => {
      state.isLoadingDelete = false;
      state.isSuccessDelete = false;
      state.isErrorDelete = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // обновить статус пользователя
    [updateStatusUser.pending.type]: (state) => {
      state.isLoadingUpdateStatus = true;
    },
    [updateStatusUser.fulfilled.type]: (state, action) => {
      state.isLoadingUpdateStatus = false;
      state.isSuccessUpdateStatus = true;
      state.isErrorUpdateStatus = false;
      state.message = action.payload.message;
      let arg = action.meta.arg as UpdateStatusUserData;
      usersAdapter.updateOne(state.users, { id: arg.id, changes: { status: arg.status } });
      showNotification({ message: action.payload.message });
    },
    [updateStatusUser.rejected.type]: (state, action) => {
      state.isLoadingUpdateStatus = false;
      state.isSuccessUpdateStatus = false;
      state.isErrorUpdateStatus = true;
      state.message = action.payload.message || SYSTEM_ERROR;
      showNotification({ message: action.payload.message, color: 'red' });
    },

    // войти в эмуляцию другого пользователя
    [logInToUserEmulation.pending.type]: (state) => {
      state.isLoadingEmulate = true;
    },
    [logInToUserEmulation.fulfilled.type]: (state, action) => {
      state.isLoadingEmulate = false;
      state.isSuccessEmulate = true;
      state.isErrorEmulate = false;
      state.emulateToken = action.payload.auth_emulated_token;
      state.emulateRefreshToken = action.payload.refresh_emulated_token;
      state.isEmulation = true;
    },
    [logInToUserEmulation.rejected.type]: (state, action) => {
      state.isLoadingEmulate = false;
      state.isSuccessEmulate = false;
      state.isErrorEmulate = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // выйти из эмуляции другого пользователя
    [logOutUserEmulation.pending.type]: (state) => {
      state.isLoadingLogoutEmulate = true;
    },
    [logOutUserEmulation.fulfilled.type]: (state, action) => {
      state.isLoadingLogoutEmulate = false;
      state.isErrorLogoutEmulate = false;
      state.isSuccessLogoutEmulate = true;
      state.updatedToken = action.payload.auth_token;
      state.updatedRefreshToken = action.payload.refresh_token;
      state.message = action.payload.message;
      state.isEmulation = false;
    },
    [logOutUserEmulation.rejected.type]: (state, action) => {
      state.isLoadingLogoutEmulate = false;
      state.isErrorLogoutEmulate = true;
      state.isSuccessLogoutEmulate = false;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // получить конкретного пользователя
    [getUser.pending.type]: (state) => {
      state.isLoadingGetUser = true;
    },
    [getUser.fulfilled.type]: (state, action) => {
      state.isSuccessGetUser = true;
      state.isLoadingGetUser = false;
      state.isErrorGetUser = false;
      state.message = action.payload.message;
      // state.users = state.users.map((item) =>
      //   action.payload.id === item.id ? action.payload : item
      // );
    },
    [getUser.rejected.type]: (state, action) => {
      state.isSuccessGetUser = false;
      state.isLoadingGetUser = false;
      state.isErrorGetUser = true;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
    // Добавить пользователя в систему
    [addUser.pending.type]: (state) => {
      state.isLoadingAddUser = true;
      state.isLoadingState = UsersConst.ADD_USER;
      state.addUserError = initialState.addUserError;
    },
    [addUser.fulfilled.type]: (state, action) => {
      state.isLoadingState = null;
      state.message = action.payload.message;
      state.pagination.page = 1;
      showNotification({ message: action.payload.message });
    },
    [addUser.rejected.type]: (state, action) => {
      state.isLoadingState = null;
      state.message = action.payload.message || SYSTEM_ERROR;
      showNotification({ message: action.payload.message, color: 'red' });
      state.addUserError = action.payload.form_error;
    },
    // редактировать пользователя
    [editUser.pending.type]: (state) => {
      state.isLoadingState = UsersConst.EDIT_USER;
      state.editUserError = initialState.editUserError;
    },
    [editUser.fulfilled.type]: (state, action) => {
      state.isLoadingState = null;
      state.message = action.payload.message;
      let arg = action.meta.arg as UpdateStatusUserData;
      usersAdapter.updateOne(state.users, { id: arg.id, changes: { ...arg } });

      showNotification({ message: action.payload.message });
    },
    [editUser.rejected.type]: (state, action) => {
      state.isLoadingState = null;
      state.message = action.payload.message || SYSTEM_ERROR;
      state.editUserError = action.payload.form_error;
      showNotification({ message: action.payload.message, color: 'red' });
    },
    // Подтвердить почту пользователя
    [confirmEmail.pending.type]: (state) => {
      state.isSuccessConfirmEmail = false;
      state.isLoadingConfirmEmail = true;
      state.isErrorConfirmEmail = false;
      state.isLoadingState = UsersConst.GET_USERS;
    },
    [confirmEmail.fulfilled.type]: (state, action) => {
      state.isSuccessConfirmEmail = true;
      state.isLoadingConfirmEmail = false;
      state.isErrorConfirmEmail = false;
      state.message = action.payload.message;
      state.isLoadingState = null;
      showNotification({ color: 'green', message: action.payload.message });
    },
    [confirmEmail.rejected.type]: (state, action) => {
      state.isSuccessConfirmEmail = false;
      state.isLoadingConfirmEmail = false;
      state.isErrorConfirmEmail = true;
      state.isLoadingState = null;
      state.message = action.payload.message || SYSTEM_ERROR;
    },
  },
});
