import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';

import { api } from '@/fsd/shared/api';

import { GetManagersData, GetUsersData } from '@/store/entities/users/types';

export const getUsers = createAsyncThunk('users/getUsers', async (arg: GetUsersData, thunkAPI) => {
  try {
    const response = await api.service.get(`/api/users/users/${arg.page}/`, {
      params: {
        username: undefined,
        is_registration: arg.isRegistration,
        sort_type: arg.sortType,
        items_per_page: arg.itemsPerPage,
        sort_by: arg.sortBy,
        page: arg.page,
        status: arg.status,
        role: arg.role,
        email: arg.email,
      },
    });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const getManagers = createAsyncThunk(
  'users/getManagers',
  async (arg: GetManagersData, thunkAPI) => {
    try {
      const response = await api.service.get('/api/users/users/' + arg.page + '/', {
        params: {
          sort_type: arg.sortType,
          items_per_page: arg.itemsPerPage,
          sort_by: arg.sortBy,
          status: arg.status,
          role: arg.role,
          email: arg.email,
        },
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
