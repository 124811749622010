import { FC, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Input, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import XRegExp from 'xregexp';

import { UserSchema } from '@/api/swaggerApi';

import { useRegions } from '@/hooks/useRegions';

import FlagsSelect from '@/ui/FlagsSelect/FlagsSelect';

import styles from '@/pages/SignUp/SignUpForm/SignUpForm.module.scss';

import { changeEmail, changePhoneNumber } from '@/store/entities/auth/actions';
import { editProfile } from '@/store/entities/profile/actions';
import { useAppDispatch } from '@/store/hooks';

interface ProfileEditModalProps {
  opened: boolean;
  inputLabel: string;
  userData: UserSchema | null;

  onClose(): void;
}

const ProfileEditModal: FC<ProfileEditModalProps> = ({ opened, onClose, inputLabel, userData }) => {
  const { t } = useTranslation('APPCON');
  const dispatch = useAppDispatch();
  const phoneNumberFormat =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  const emailFormat = /^\S+@\S+$/;
  const unicodeWord = XRegExp('^\\pL+$');

  const nameForm = useForm({
    initialValues: {
      firstName: String(userData?.first_name),
      secondName: String(userData?.last_name),
    },
    validate: {
      firstName: (value) => (value.length > 0 ? null : t('The name should not be empty')),
      secondName: (value) => (value.length > 0 ? null : t('The second name should not be empty')),
    },
  });

  const emailForm = useForm({
    initialValues: {
      email: String(userData?.email),
    },
    validate: {
      email: (value) =>
        value.length > 0
          ? emailFormat.test(value)
            ? null
            : t('Incorrect email')
          : t('Email should not be empty'),
    },
  });

  const regions = useRegions();

  const phoneForm = useForm({
    initialValues: {
      phoneNumber: String(userData?.phone_number),
    },
    validate: {
      phoneNumber: (value) =>
        value.length > 0
          ? phoneNumberFormat.test(value)
            ? null
            : t('Incorrect phone number')
          : t('The phone number should not be empty'),
    },
  });

  const regionForm = useForm({
    initialValues: {
      region: String(userData?.region),
    },
    // validate: {
    //
    // },
  });

  useEffect(() => {
    nameForm.setValues({
      firstName: userData?.first_name,
      secondName: userData?.last_name,
    });
    emailForm.setValues({
      email: userData?.email,
    });
    phoneForm.setValues({
      phoneNumber: userData?.phone_number,
    });
    regionForm.setValues({
      region: userData?.region,
    });
  }, [opened]);

  const changeProfileName = (
    values: {
      firstName: string;
      secondName: string;
    },
    event: FormEvent
  ) => {
    event.preventDefault();
    dispatch(editProfile({ first_name: values.firstName, last_name: values.secondName }));
  };

  const changeProfilePhone = (
    values: {
      phoneNumber: string;
    },
    event: FormEvent
  ) => {
    event.preventDefault();
    dispatch(changePhoneNumber(values.phoneNumber));
  };

  const changeProfileEmail = (
    values: {
      email: string;
    },
    event: FormEvent
  ) => {
    event.preventDefault();
    dispatch(changeEmail(values.email));
  };

  const changeProfileRegion = (
    values: {
      region: string;
    },
    event: FormEvent
  ) => {
    event.preventDefault();
    // dispatch(
    //   editProfile({
    //     region: values.region,
    //   })
    // );
  };

  return (
    <Modal opened={opened} onClose={onClose} styles={{ header: { marginBottom: 0 } }}>
      {inputLabel === 'name' && (
        <form onSubmit={nameForm.onSubmit((values, event) => changeProfileName(values, event))}>
          <TextInput mb={10} label={t('appcon036-1W-1')} {...nameForm.getInputProps('firstName')} />
          <TextInput
            mb={10}
            label={t('appcon036-1W-2')}
            {...nameForm.getInputProps('secondName')}
          />
          <Group align="center" position="center" mt={10} spacing="xs">
            <Button type="submit">{t('appcon036-1W-3')}</Button>
            <Button onClick={onClose}>{t('appcon036-1W-4')}</Button>
          </Group>
        </form>
      )}
      {inputLabel === 'phone' && (
        <form onSubmit={phoneForm.onSubmit((values, event) => changeProfilePhone(values, event))}>
          <TextInput
            type="tel"
            mb={10}
            label={t('appcon036-1W-5')}
            {...phoneForm.getInputProps('phoneNumber')}
          />
          <Group align="center" position="center" mt={10} spacing="xs">
            <Button type="submit">{t('appcon036-1W-6')}</Button>
            <Button onClick={onClose}>{t('appcon036-1W-7')}</Button>
          </Group>
        </form>
      )}
      {inputLabel === 'email' && (
        <form onSubmit={emailForm.onSubmit((values, event) => changeProfileEmail(values, event))}>
          <TextInput
            type="email"
            mb={10}
            label={t('appcon036-1W-8')}
            {...emailForm.getInputProps('email')}
          />
          <Group align="center" position="center" mt={10} spacing="xs">
            <Button type="submit">{t('appcon036-1W-9')}</Button>
            <Button onClick={onClose}>{t('appcon036-1W-10')}</Button>
          </Group>
        </form>
      )}

      {inputLabel === 'region' && (
        <form onSubmit={regionForm.onSubmit((values, event) => changeProfileRegion(values, event))}>
          <Input.Wrapper
            label={t('appcon045-7A-36')}
            {...regionForm.getInputProps('region')}
            styles={{ error: { marginTop: 5 }, label: { fontWeight: 700 } }}
            className={{ ...regionForm.getInputProps('region') }.error ? styles.error : ''}
            mt={15}
            size="md"
          >
            <FlagsSelect
              data={regions}
              className={styles.regions}
              {...regionForm.getInputProps('region')}
            />
          </Input.Wrapper>
          <Group align="center" position="center" mt={10} spacing="xs">
            <Button type="submit">{t('appcon036-1W-9')}</Button>
            <Button onClick={onClose}>{t('appcon036-1W-10')}</Button>
          </Group>
        </form>
      )}
    </Modal>
  );
};

export default ProfileEditModal;
