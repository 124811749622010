import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Api } from '@/api/apiService';

import { setCurrentCurrencyByMarketplace } from '@/utils/setCurrentCurrencyByMarketplace';

import {
  IGetABCAnalyze,
  IGetABCAnalyzeTotal,
} from '@/store/entities/tools/internalAnalytics/ABCAnalyze/types';

export const getABCAnalyzeTotal = createAsyncThunk<
  any,
  IGetABCAnalyzeTotal,
  {
    rejectValue: string;
  }
>('ABCAnalyze/getABCAnalyzeTotal', async (options, { rejectWithValue }) => {
  try {
    setCurrentCurrencyByMarketplace(null);
    const { data } = await Api.InternalAnalyticsReports.apiInternalAnalyticsAbcAnalyzeTotalGet(
      options.dateFrom,
      options.dateTo,
      options.connectionIds,
      options.demo,
      options.brandFilter,
      options.productCategoryFilter
    );
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getABCAnalyze = createAsyncThunk<
  any,
  IGetABCAnalyze,
  {
    rejectValue: string;
  }
>('ABCAnalyze/getABCAnalyze', async (options, { rejectWithValue }) => {
  try {
    const { data } = await Api.InternalAnalyticsReports.apiInternalAnalyticsAbcAnalyzeGet(
      options.dateFrom,
      options.dateTo,
      options.page,
      options.itemsPerPage,
      options.sortBy as 'sales' | 'margin' | 'turnover' | undefined,
      options.sort,
      options.connectionIds,
      options.demo,
      options.brandFilter,
      options.productCategoryFilter,
      options.variantIds,
      options.abcCategoryFilter,
      options.abcCategory
    );
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
