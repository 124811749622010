import { ReactNode } from 'react';
import { Accordion as AccordionM } from '@mantine/core';

import { colors } from '@/fsd/shared/constants/colors';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import { useIsLocation } from '@/fsd/shared/lib/hooks/useIsLocation/useIsLocation';
import { INavigation } from '@/fsd/shared/lib/router/navigation';
import { NavLink } from '@/fsd/shared/ui/NavLink';

import cls from './AccordionMenu.module.scss';

interface AccordionMenuProps {
  className?: string;
  items: INavigation[];
}

export const AccordionMenu = ({ className, items }: AccordionMenuProps) => {
  const { isActiveLocationHandle } = useIsLocation();

  return (
    <div className={classNames(cls.AccordionMenu, {}, [className])}>
      <AccordionM
        defaultValue=""
        classNames={{
          label: cls.AccordionMenuLabel,
          chevron: cls.AccordionMenuChevron,
          control: cls.AccordionMenuControl,
          panel: cls.AccordionMenuPanel,
          item: cls.AccordionMenuItem,
        }}
      >
        {items.map((item) => (
          <AccordionM.Item value={item.title} key={item.href}>
            <AccordionM.Control chevron={!item?.links && <></>}>
              <NavLink
                externalLink={item.externalLink}
                name={item.title}
                href={item.href}
                className={classNames(
                  cls.accordionItem,
                  { [cls.accordionItemActive]: isActiveLocationHandle(item.href) },
                  []
                )}
              />
            </AccordionM.Control>
            {item?.links && (
              <AccordionM.Panel>
                <div className={cls.links}>
                  {item?.links.map((link) => (
                    <NavLink
                      externalLink={item.externalLink}
                      name={link.title}
                      href={link.href}
                      icon={link.icon}
                      className={classNames(
                        cls.itemsInAccordion,
                        { [cls.accordionItemActive]: isActiveLocationHandle(link.href) },
                        []
                      )}
                    />
                  ))}
                </div>
              </AccordionM.Panel>
            )}
          </AccordionM.Item>
        ))}
      </AccordionM>
    </div>
  );
};
