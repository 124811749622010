import dayjs from 'dayjs';

export const CREATE_FAVORITE_PRODUCT = 'CREATE_FAVORITE_PRODUCT';
export const DELETE_FAVORITE_PRODUCT = 'DELETE_FAVORITE_PRODUCT';
export const GET_FAVORITE_PRODUCTS = 'GET_FAVORITE_PRODUCTS';
export const GET_FAVORITE_PRODUCT_FOR_PRODUCTS_TABLE = 'GET_FAVORITE_PRODUCT_FOR_PRODUCTS_TABLE';
export const GET_PRODUCTS = 'GET_PRODUCTS';

export const FORBIDDEN_RESOURCES = 'FORBIDDEN_RESOURCES';

const getDefaultDate = () => {
  const date = new Date();

  let date1 = [dayjs(date).subtract(30, 'day').toDate(), date];

  const firstDate = date1[0]?.toDateString();
  const secondDate = date1[1]?.toDateString();
  const firstDateFormat = firstDate ? dayjs(firstDate).format('MMM DD, YYYY') : null;
  const secondDateFormat = secondDate ? dayjs(secondDate).format('MMM DD, YYYY') : null;

  const from = `${dayjs(firstDateFormat).format('YYYY-MM-DD')} 00:00:00.000`;
  const to = `${dayjs(secondDateFormat).format('YYYY-MM-DD')} 00:00:00.000`;

  return { from, to };
};

export const defaultFilterParameterValues = {
  category_ids: undefined,
  store_rating_range: {
    min_val: undefined,
    max_val: undefined,
  },
  product_rating_range: {
    min_val: undefined,
    max_val: undefined,
  },
  variations_num: {
    min_val: undefined,
    max_val: undefined,
  },
  keywords: {
    include_in_description: undefined,
    exclude_from_description: undefined,
    include_in_title: undefined,
    exclude_from_title: undefined,
  },
  description_quality: {
    min_val: undefined,
    max_val: undefined,
  },
  pictures_num_range: {
    min_val: undefined,
    max_val: undefined,
  },
  video_present: undefined,
  cash_on_delivery: undefined,
  exclude_zero_stock: undefined,
  rewiew_count_range: {
    min_val: undefined,
    max_val: undefined,
  },
  product_views_range: {
    min_val: undefined,
    max_val: undefined,
  },
  product_questions_range: {
    min_val: undefined,
    max_val: undefined,
  },
  product_wishlisted_range: {
    min_val: undefined,
    max_val: undefined,
  },
  product_price_range: {
    min_val: undefined,
    max_val: undefined,
  },
  price_change_range: {
    min_val: undefined,
    max_val: undefined,
  },
  monthly_revenue_range: {
    min_val: undefined,
    max_val: undefined,
  },
  sales_change_range: {
    min_val: undefined,
    max_val: undefined,
  },
  exclude_sellers_ids: undefined,
  include_sellers_ids: undefined,
  monthly_sales_range: {
    min_val: undefined,
    max_val: undefined,
  },
  revenue_change_range: {
    min_val: undefined,
    max_val: undefined,
  },
  views_to_sales: {
    min_val: undefined,
    max_val: undefined,
  },
  sales_to_review: {
    min_val: undefined,
    max_val: undefined,
  },
  product_created_from: getDefaultDate().from,
  product_created_to: getDefaultDate().to,
  location: undefined,
};

export const SORT_BY_DEFAULT = 'revenue';
export const SORT_ORDER_DEFAULT = 'desc';
