import { FC, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';

import { TokenService } from '@/api/TokenService';
import { UserInfoService } from '@/api/UserInfoService';

import AdminRoutes from './AdminRoutes';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { adminRoutes, privateRoutes, publicRoutes, routes } from './routes';

import { setUser } from '@/store/entities/auth/authSlice';
import { useAppDispatch } from '@/store/hooks';

const AppRouter: FC = () => {
  const dispatch = useAppDispatch();
  const token = TokenService.getToken();
  const refreshToken = TokenService.getRefreshedToken();
  const userInfo = UserInfoService.getUserInfo();
  const { t } = useTranslation('meta-tags');

  useEffect(() => {
    dispatch(setUser({ token: token, refreshToken: refreshToken, userInfo: userInfo }));
  }, []);

  return (
    <Suspense
      fallback={
        <Center style={{ height: '100vh' }}>
          <Loader variant="dots" />
        </Center>
      }
    >
      <Routes>
        {publicRoutes(t).map((route) =>
          route.path ? (
            <Route
              path={route.path}
              element={
                <PublicRoutes description={route.descriptionTag} title={route.titleTag}>
                  {route.component}
                </PublicRoutes>
              }
              key={route.path}
            />
          ) : null
        )}
        {privateRoutes(t).map((route) =>
          route.path ? (
            <Route
              path={route.path}
              element={
                <PrivateRoutes title={route.titleTag} description={route.descriptionTag}>
                  {route.component}
                </PrivateRoutes>
              }
              key={route.path}
            />
          ) : null
        )}

        {adminRoutes.map((route) =>
          route.path ? (
            <Route
              path={route.path}
              element={<AdminRoutes>{route.component}</AdminRoutes>}
              key={route.path}
            />
          ) : null
        )}

        {routes.map((route) =>
          route.path ? <Route path={route.path} element={route.component} key={route.path} /> : null
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
