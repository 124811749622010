import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@mantine/core';
import { AppPaths } from 'src/fsd/shared/lib/router';

import styles from './PurchaseTariffPlanOverlay.module.scss';

const PurchaseTariffPlanOverlay = () => {
  const { t } = useTranslation('APPCON');

  return (
    <Box className={styles.unavailableText}>
      <Box>
        <Box maw={360} w={'100%'}>
          <Text weight={700} size={'lg'}>
            {t('Upgrade to the Professional Plan for exclusive access to detailed insights')}
          </Text>
        </Box>
        <Button component={'a'} href={AppPaths.PROFILE_TARIFF} mt={'md'}>
          {t('appcon040--48')}
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseTariffPlanOverlay;
